import { SvgIcon } from "@mui/material";

export default function Icon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="m 5.25,21 h 13.5 a 0.75,0.75 0 0 0 0.75,-0.75 v -9 A 0.75,0.75 0 0 0 18.75,10.5 H 5.25 A 0.75,0.75 0 0 0 4.5,11.25 v 9 A 0.75,0.75 0 0 0 5.25,21 Z M 15,16.5 V 12 h 3 v 7.5 H 15 Z M 10.5,12 h 3 v 3 h -3 z m 0,4.5 h 3 v 3 h -3 z M 6,12 h 3 v 3 H 6 Z m 0,4.5 h 3 v 3 H 6 Z M 20.25,0 H 3.75 A 2.307,2.307 0 0 0 1.5,2.25 v 19.5 A 2.307,2.307 0 0 0 3.75,24 h 16.5 A 2.307,2.307 0 0 0 22.5,21.75 V 2.25 A 2.307,2.307 0 0 0 20.25,0 Z M 21,21.75 A 0.812,0.812 0 0 1 20.25,22.5 H 3.75 A 0.812,0.812 0 0 1 3,21.75 V 9 H 21 Z M 21,7.5 H 3 V 2.25 A 0.812,0.812 0 0 1 3.75,1.5 h 16.5 A 0.812,0.812 0 0 1 21,2.25 Z"
      />
    </SvgIcon>
  );
}
