import { action, makeObservable, observable } from "mobx";

import LANGUAGES from "@config/i18n/langs.json";
import i18n from "@core/i18n";

class LanguageStore {
  languageOptions = new Map(LANGUAGES);

  defaultLanguage = "en";

  currentLanguage = "";

  constructor(parent) {
    makeObservable(this, {
      languageOptions: true,
      currentLanguage: observable,
      defaultLanguage: observable,
      updateCurrentLanguage: action.bound,
    });

    const languageKey = localStorage.getItem("language");
    (async () => {
      if (!languageKey) {
        await i18n.changeLanguage(this.defaultLanguage);
        this.currentLanguage = this.defaultLanguage;
        localStorage.setItem("language", this.defaultLanguage);
        return;
      }
      await i18n.changeLanguage(languageKey);
    })();

    this.currentLanguage = languageKey;
    this.parent = parent;
  }

  updateCurrentLanguage(newLanguage) {
    this.currentLanguage = newLanguage;
  }
}

export default LanguageStore;
