import { COLUMN_PERIOD, COLUMN_SECTION } from "./constants";

const HOTWATER_USE_COLUMNS = {
  hourly_average_heat_energy: {
    label: "text_power_hot_water_use_kw",
    sublabel: "text_average",
    info: "text_power_hot_water_use_desc",
    section: COLUMN_SECTION.usage_patterns,
    periods: [COLUMN_PERIOD.year],
  },
  yearly_estimate_heat_energy: {
    label: "text_energy_water_use_kwh",
    info: "text_energy_water_use_desc",
    section: COLUMN_SECTION.usage_patterns,
    periods: [COLUMN_PERIOD.year],
  },
};

// eslint-disable-next-line import/no-default-export
export default HOTWATER_USE_COLUMNS;
