import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isObservable, toJS } from "mobx";
import { observer } from "mobx-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { useInject } from "@hooks";

import type InteractionStore from "./interaction.store";

const InteractionDialog = observer(() => {
  const { t } = useTranslation(["_action"]);
  const {
    interaction: interactionStore,
  }: {
    interaction: InteractionStore;
  } = useInject("interaction");

  const { type, title, message, options, label } = interactionStore.dialog || {};
  const [value, setValue] = useState("");

  if (!interactionStore.dialog) {
    return null;
  }

  const handleClose = () => {
    interactionStore.cancelDialog();
  };

  const handleConfirm = () => {
    let _value = value;
    if (!value && isObservable(options)) {
      _value = toJS(options)?.defaultInput ?? "";
    }
    interactionStore.closeDialog("confirmed", _value);
    // reset state
    setValue("");
  };

  const handleCancel = () => {
    interactionStore.cancelDialog();
  };

  return (
    <Dialog open onClose={handleClose} data-testid="dialog" aria-labelledby="form-dialog-title">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {/* Maybe we add more types here */}
        {type === "prompt" && (
          <TextField
            autoFocus
            required
            margin="dense"
            id="value"
            name="value"
            label={label}
            type="string"
            fullWidth
            variant="standard"
            inputProps={{
              "data-testid": "dialog-prompt-input",
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {type === "confirm" && (
          <>
            <Button onClick={handleCancel}>{options?.cancelText || t("action_cancel")}</Button>
            <Button onClick={handleConfirm} autoFocus>
              {options?.confirmText || t("action_confirm")}
            </Button>
          </>
        )}
        {type === "alert" && (
          <Button onClick={handleConfirm} autoFocus>
            {options?.confirmText || t("action_ok")}
          </Button>
        )}
        {type === "prompt" && (
          <>
            <Button onClick={handleCancel} data-testid="dialog-prompt-cancel">
              {options?.cancelText || t("action_cancel")}
            </Button>
            <Button onClick={handleConfirm} autoFocus data-testid="dialog-prompt-confirm">
              {options?.confirmText || t("action_confirm")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default InteractionDialog;
