/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo, useState } from "react";
import type { TextFieldProps, Theme } from "@mui/material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import type {
  DateOrTimeView,
  DateTimeValidationError,
  DesktopDatePickerProps,
  PickerChangeHandlerContext,
  PickerValidDate,
} from "@mui/x-date-pickers";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import type { DateTime } from "luxon";

import { logger as baseLogger } from "@core/logger";
import CloseIcon from "@icons/Close";

import { FontAwesomeSvgIcon } from "../../FontAwesomeSvgIcon";

const logger = baseLogger.getSubLogger({ name: "DateTimePicker" });

export type DateTimeValue = DateTime | undefined | null;

export type UTFDateTimeFieldProps = {
  label?: string;
  labelPosition?: "start" | "top";
  "data-testid"?: string;
  allowClear?: boolean;
  views?: DateOrTimeView[];
  inputProps?: TextFieldProps;
  color?: string;
  onChange?: (
    nextValue: DateTimeValue,
    ctx?: PickerChangeHandlerContext<DateTimeValidationError>
  ) => void;
  shouldShowError?: boolean;
} & DesktopDatePickerProps<PickerValidDate>;

function OpenPickerIcon() {
  return <FontAwesomeSvgIcon icon={faCalendarDays} sx={{ fontSize: 16 }} />;
}

export default function DatePicker({
  color = "inherit",
  label,
  value: propValue = null,
  onChange,
  minDate,
  maxDate,
  disabled,
  labelPosition = "top",
  views = ["year", "month", "day"],
  format = "yyyy-MM-dd",
  inputProps = {},
  slotProps = {},
  allowClear,
  shouldShowError = true,
  ...props
}: Readonly<UTFDateTimeFieldProps>) {
  const theme = useTheme<Theme>();
  const testId = props["data-testid"] ?? `utf-datepicker-${label}`;
  const [value, setValue] = useState<DateTimeValue>(null);
  const [error, setError] = useState<DateTimeValidationError | undefined>();

  const errorMessage = useMemo(() => {
    if (!shouldShowError) return "";
    switch (error) {
      case "maxDate":
        return `Please select a date before ${maxDate?.toFormat(format)}`;
      case "minDate":
        return `Please select a date after ${minDate?.toFormat(format)}`;
      case "invalidDate":
        return "Your date is not valid";
      default:
        return "";
    }
  }, [error, format, maxDate, minDate, shouldShowError]);

  const onPickerChange = useCallback(
    (
      nextValue: DateTimeValue,
      { validationError }: PickerChangeHandlerContext<DateTimeValidationError>
    ) => {
      logger.debug(
        "onPickerChange Next: %s - ValidationError: %s",
        String(nextValue),
        validationError
      );
      setValue(nextValue);
      if (onChange) onChange(nextValue, { validationError });
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    logger.debug("onClear Next: null");
    onPickerChange(null, { validationError: null });
  }, [onPickerChange]);

  useEffect(() => {
    let nextValue = propValue;
    const isNullish = nextValue === null || nextValue === undefined;

    if (isNullish || nextValue?.isValid !== true) {
      // @ts-expect-error Luxon type mismatch
      logger.warn("Invalid Luxon date provided: %s", nextValue?.invalid?.explanation);
      nextValue = null;
      return;
    }

    if (String(value) === String(nextValue)) return;

    logger.debug(
      "`value` prop updated for *%s* [%s -> %s]",
      label ?? "",
      String(value),
      String(nextValue)
    );
    setValue(nextValue);
  }, [label, propValue, value]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} data-testid={testId}>
      {labelPosition === "start" && (
        <Typography pr={1} variant="caption">
          {`${label}:`}
        </Typography>
      )}
      <DesktopDateTimePicker
        defaultValue={propValue}
        value={value}
        minDateTime={minDate}
        maxDateTime={maxDate}
        label={labelPosition !== "start" ? label : undefined}
        onChange={onPickerChange}
        onError={setError}
        data-testid={`${label}-picker`}
        slots={{
          openPickerIcon: OpenPickerIcon,
        }}
        slotProps={{
          ...slotProps,
          textField: {
            size: "small",
            helperText: errorMessage,
            ...inputProps,
            sx: {
              "& .MuiInputBase-root": {
                color,
                paddingRight: 0,
                input: {
                  padding: theme.spacing(0.8, 1),
                  "&.Mui-disabled": {
                    color: "#bdbdbd",
                    WebkitTextFillColor: "#bdbdbd",
                  },
                },
                fieldset: {
                  borderColor: disabled ? "#bdbdbd !important" : "currentColor",
                },
                "&.Mui-disabled": {
                  color: "#bdbdbd",
                },
              },
              "& .MuiFormLabel-root": {
                ...theme.typography.h6,
                textTransform: "lowercase",
                "&::first-letter": {
                  textTransform: "uppercase",
                },
                "&.Mui-focused": {
                  color: theme.palette.primary.main,
                },
              },
              "& .MuiInputAdornment-root": {
                color: "inherit",
                button: {
                  color: "currentColor",
                  marginRight: 0,
                },
              },
              "&:hover .MuiInputLabel-root": {
                color: "currentColor",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "currentColor",
              },
              "&.Mui-focused .MuiInputLabel-root": {
                color: "currentColor",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "currentColor",
              },
              ".Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: "currentColor",
                border: 1,
              },
              ...inputProps.sx,
            },
            InputProps: {
              startAdornment: allowClear && value && (
                <IconButton
                  color="error"
                  onClick={onClear}
                  sx={{
                    padding: 0,
                    paddingTop: "12px",
                    "& > svg": {
                      width: "24px",
                      fontSize: "24px",
                    },
                  }}
                  data-testid="clear-button"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              ),
              ...inputProps?.InputProps,
            },
            ...slotProps?.textField,
          },
        }}
        // User overrides
        {...{ disabled, views, format, ...props }}
      />
    </Box>
  );
}
