import { useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

type BigListItemType = {
  id: number;
  name: string;
  subLabel: string;
  color?: string;
  sortOrder?: number;
};

type ItemEntryBlockProps = {
  item: BigListItemType;
  selected: boolean;
  onClick?: () => void;
  showSortOrder?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  onSortOrderChange?: (moveUp: boolean) => void;
};

function ItemEntryBlock({
  item,
  selected,
  onClick,
  showSortOrder,
  isFirst,
  isLast,
  onSortOrderChange,
}: ItemEntryBlockProps) {
  const useStyles = makeStyles((theme: Theme) => ({
    dot: {
      width: 8,
      height: 8,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      borderRadius: 2,
      backgroundColor: item.color ? item.color : "#000",
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation(["_action", "_common"]);

  const handleOnSortOrderChange = (moveUp: boolean) => {
    // eslint-disable-next-line ts/no-unused-expressions
    onSortOrderChange && onSortOrderChange(moveUp);
  };

  return (
    <Box
      py={1}
      px={2}
      border={1}
      borderColor={selected ? "secondary.main" : "grey.300"}
      borderRadius="1px"
      style={{
        cursor: "pointer",
      }}
      width="100%"
      minHeight={60}
      onClick={() => !showSortOrder && onClick && onClick()}
    >
      <Grid container direction="row" alignItems="center" justifyContent="space-between" flex={1}>
        <Grid item xs>
          <Box display="flex" flexDirection="row" justifyContent="start">
            <div className={classes.dot} />
            <Typography
              color={selected ? "secondary" : "primary"}
              variant="body2"
              pb={0.5}
              gap={1}
              data-testid="utf-item-item-name"
            >
              {item.name}
            </Typography>
          </Box>
          <Typography color="grey.600" variant="body1" data-testid="utf-item-item-label">
            {item.subLabel}
          </Typography>
        </Grid>
        {showSortOrder && (
          <Grid item xs={1}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Tooltip title={t("action_move_up")} placement="top">
                <IconButton
                  size="small"
                  data-testid="utf-item-item-sort-up"
                  disabled={isFirst}
                  onClick={() => handleOnSortOrderChange(true)}
                  color="primary"
                >
                  <KeyboardArrowUp />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("action_move_down")}>
                <IconButton
                  size="small"
                  data-testid="utf-item-item-sort-down"
                  disabled={isLast}
                  onClick={() => handleOnSortOrderChange(false)}
                  color="primary"
                >
                  <KeyboardArrowDown />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

ItemEntryBlock.defaultProps = {
  onClick: () => {},
};

type BigListProps = {
  items?: BigListItemType[];
  // itemTypes: { id: number; label: string }[];
  loading?: boolean;
  createButtonLabel?: string;
  changeOrderButtonLabel?: string;
  selectedItem?: BigListItemType | null;
  setSelectedItem?: (item: BigListItemType | null) => void;
  onAddItemClick?: () => void;
  onItemsSortOrderChange?: (items: BigListItemType[]) => void;
  onSortOrderApply?: () => void;
  "data-testid"?: string;
};

function BigList({
  items,
  loading,
  createButtonLabel,
  changeOrderButtonLabel,
  selectedItem,
  setSelectedItem,
  onAddItemClick,
  onItemsSortOrderChange,
  onSortOrderApply,
  "data-testid": dataTestId,
}: BigListProps) {
  const { t } = useTranslation(["_action", "_common"]);
  const [sortOrderLock, setSortOrderLock] = useState<boolean>(false);

  const handleSetSelectedItem = (item: BigListItemType | null) => {
    // eslint-disable-next-line ts/no-unused-expressions
    setSelectedItem && setSelectedItem(item);
  };

  const handleOnAddItemClick = () => {
    // eslint-disable-next-line ts/no-unused-expressions
    onAddItemClick && onAddItemClick();
  };

  const handleSortChange = (moveUp: boolean, item: BigListItemType) => {
    if (items && items.length > 1 && onItemsSortOrderChange) {
      // Change order of items
      const newItems = [...items];
      const index = newItems.findIndex((i) => i.id === item.id);
      const newIndex = moveUp ? index - 1 : index + 1;
      const temp = newItems[newIndex];
      newItems[newIndex] = newItems[index];
      newItems[index] = temp;

      // Update all sort orders
      newItems.forEach((_item, i) => {
        // eslint-disable-next-line no-param-reassign
        _item.sortOrder = i;
      });
      onItemsSortOrderChange(newItems);
    }
  };

  const toggleSortOrderLock = () => {
    setSortOrderLock(!sortOrderLock);
    if (sortOrderLock && items && items?.length > 1) {
      // eslint-disable-next-line ts/no-unused-expressions
      onSortOrderApply && onSortOrderApply();
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="space-between"
      component={Paper}
      minHeight={300}
      minWidth={200}
      height="100%"
      data-testid={`${dataTestId}-container`}
    >
      {/* COMPONENT LIST */}
      <Grid item xs px={2} my={2} overflow="auto" data-testid={`${dataTestId}-item-list`}>
        {items &&
          items
            .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
            .map((item) => (
              <ItemEntryBlock
                key={item.id}
                item={item}
                selected={item.id === selectedItem?.id}
                onClick={() => handleSetSelectedItem(item)}
                showSortOrder={sortOrderLock}
                onSortOrderChange={(moveUp) => handleSortChange(moveUp, item)}
                isFirst={items[0].id === item.id}
                isLast={items[items.length - 1].id === item.id}
              />
            ))}
      </Grid>
      {/* CREATE COMPONENT */}
      {loading && <LinearProgress />}
      <Grid item xs={0} p={2} component={Paper} elevation={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ flex: 1, minWidth: 140 }}
            onClick={() => handleOnAddItemClick()}
            data-testid={`${dataTestId}-item-add`}
          >
            {createButtonLabel || t("action_create")}
          </Button>
          <Button
            variant="outlined"
            color={sortOrderLock ? "secondary" : "primary"}
            size="large"
            style={{ flex: 1, minWidth: 140 }}
            onClick={toggleSortOrderLock}
            data-testid={`${dataTestId}-item-change-order`}
          >
            {changeOrderButtonLabel || t("action_change_order")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export { BigList, BigListItemType as BigListItem };
