import { FC, lazy, Suspense } from "react";
import { observer } from "mobx-react";
import { Box, Drawer as MuiDrawer } from "@mui/material";
import { DrawerProps } from "@mui/material/Drawer";

import { APP_BAR_HEIGHT, DRAWER_WIDTH } from "@conf/ui_constants";
import { useInject, useReaction } from "@hooks";

import SubPane from "../SubPane";
import { NavDrawer } from "./NavDrawer";

const ClusterExplore = lazy(() => import("../SubList/Explore/Cluster"));
const SubstationExplore = lazy(() => import("../SubList/Explore/Substation"));
const GlobalFiltersPanel = lazy(() => import("../Filters/GlobalFiltersPanel"));
const SubstationSummary = lazy(() => import("../SubList/Summary"));

function DrawerLoader() {
  return <span>...</span>;
}

const Drawer: FC<Partial<DrawerProps>> = observer(
  ({ open, children, ...props }: Readonly<DrawerProps>) => {
    const { ui } = useInject("ui");
    /* Close menu-sidebar when component request */
    const reqCloseDrawer = () => {
      ui.closeDrawer();
    };
    const isSubOrClusterDetailOpen = ui.is_subdetail_open || ui.is_cluster_detail_open;
    let isDrawerShouldClose;

    // this conditional block is used to avoid tab switching in substation explore when screen shrinking
    if (ui.is_small_screen) {
      if (isSubOrClusterDetailOpen) {
        isDrawerShouldClose = false;
      } else {
        isDrawerShouldClose = true;
      }
    } else if (isSubOrClusterDetailOpen) {
      isDrawerShouldClose = false;
    } else {
      isDrawerShouldClose = false;
    }

    return (
      <MuiDrawer
        open={open}
        anchor="left"
        variant={isDrawerShouldClose ? "temporary" : "persistent"}
        onClose={reqCloseDrawer}
        PaperProps={{
          sx: {
            overflow: "visible",
            backgroundColor: "#f5f5f5",
            color: (theme) => theme.palette.grey["800"],
            width: DRAWER_WIDTH,
          },
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Suspense fallback={<DrawerLoader />}>{children}</Suspense>
      </MuiDrawer>
    );
  }
);

Drawer.displayName = "SidebarDrawer";

function ToolbarSpacer() {
  return <Box sx={{ minHeight: APP_BAR_HEIGHT }} />;
}

export const SidebarDrawerManager = observer<FC>(() => {
  const { ui } = useInject("ui");
  const isSubOrClusterDetailOpen: boolean = ui.is_subdetail_open || ui.is_cluster_detail_open;

  // automatically close SideNav on small screen
  useReaction(
    () => [ui.windowWidth],
    () => {
      if (ui.windowWidth && ui.windowWidth <= 1440) {
        ui.closeDrawer();
        return;
      }
      ui[ui.activeDrawer] = true;
    },
    {
      fireImmediately: true,
    }
  );

  return (
    <>
      {/* Primary App Navigation */}
      <Drawer
        sx={{ position: "relative !important" }}
        open={ui.is_sidenav_open}
        data-testid="drawer-navigation"
      >
        <ToolbarSpacer />
        <NavDrawer />
      </Drawer>

      {/* Global Substation Filters */}
      <Drawer open={ui.is_filteroptions_open} data-testid="drawer-global-filters">
        <ToolbarSpacer />
        {ui.is_filteroptions_open && <GlobalFiltersPanel />}
      </Drawer>

      {/* Explorers */}
      <Drawer open={ui.is_substation_model_open} data-testid="drawer-substation-explore">
        <ToolbarSpacer />
        {ui.is_substation_model_open && <SubstationExplore />}
      </Drawer>
      <Drawer open={ui.is_cluster_model_open}>
        <ToolbarSpacer />
        {ui.is_cluster_model_open && <ClusterExplore />}
      </Drawer>

      {/* Summaries */}
      <Drawer open={ui.is_subsummary_open} data-testid="drawer-substation-summary">
        {ui.is_subsummary_open && <SubstationSummary type="substation" />}
      </Drawer>
      <Drawer open={ui.is_cluster_summary_open}>
        {ui.is_cluster_summary_open && <SubstationSummary type="cluster" />}
      </Drawer>

      {/* Substation/Cluster Overview, Consumption and Fault Detection views */}
      <Drawer
        open={isSubOrClusterDetailOpen}
        elevation={1}
        PaperProps={{
          style: {
            width: isSubOrClusterDetailOpen ? ui.max_drawer_width : 0,
            height: ui.drawerHeight,
          },
        }}
        data-testid="drawer-substation-detail"
      >
        <ToolbarSpacer />
        {isSubOrClusterDetailOpen && <SubPane />}
      </Drawer>
    </>
  );
});

SidebarDrawerManager.displayName = "SidebarDrawerManager";
