/* eslint-disable @typescript-eslint/no-explicit-any */
import { runInAction } from "mobx";
import { DateTime, Duration } from "luxon";

export function isValue(value: string | number | null) {
  return (
    !Number.isNaN(Number(value)) &&
    value !== "" &&
    value !== null &&
    value !== Number.POSITIVE_INFINITY &&
    value !== Number.NEGATIVE_INFINITY
  );
}

export function getRandomHexColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function range(start: number, stop: number, step: number | undefined = 1) {
  const result = [];
  if (typeof stop === "undefined") {
    stop = start;
    start = 0;
  }
  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }
  return result;
}

/**
 * Formats given number based on locale and amount of digits.
 *
 * - Space should be used as a 1000-separator;
 *      1 475 for 1475,123123
 *      1 234 567 890 for 1234567890,111
 *
 * - All numerical input with 2 or less digits before the decimal separator should be rounded to 3 non-zero digits;
 *      54,1 for 54,08
 *      0,000145 for 0,000145123212
 *      100 for 99,99
 *      99,9 for 99,94
 *
 * - All numercal input with 3 or more digits before the decimal separator should show zero decimal;
 *      142 130 for 142129,5876
 *      -679 for -678,6842
 *
 * - Invalid and/or non-numeric data (if {@link isValue(number)} falsy) returns nodata argument as output.
 *
 * @param {number | string} number
 * @param {string} [locale]
 * @param {string} [nodata]
 * @param {number | null} fractionalDigits : max digits after decimal allowed
 * @return {string} formatted string representation of the number argument or @param nodata as fallback
 */
export const formatNumberForLocale = (
  number: number | string,
  locale = "sv-SE",
  nodata = "",
  fractionalDigits: number | null = null
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  if (isValue(number)) {
    if (typeof number !== "number") return number;

    const num = Math.abs(number);
    if (num > 99.95) {
      return number.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionalDigits !== null ? fractionalDigits : 0,
      });
    }

    if (num > 9) {
      return number.toLocaleString(locale, {
        minimumFractionDigits: fractionalDigits !== null ? fractionalDigits : 1,
        maximumFractionDigits: fractionalDigits !== null ? fractionalDigits : 1,
      });
    }

    if (num >= 1) {
      return number.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionalDigits !== null ? fractionalDigits : 2,
      });
    }

    return number.toLocaleString(locale, {
      maximumSignificantDigits: 3,
    });
  }
  return nodata;
};

export function toBlkMonth(month: string) {
  return Number.parseInt(month).toString().padStart(2, "0");
}

export function formatDateAPI(date: DateTime) {
  /* as per spec api dates should be in utc time */
  return date.setZone("UTC+00:00").toFormat("yyyy-MM-dd'T'HH:mm:ss");
}

export function sleep(ms: number | undefined) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const SI_PREFIXES_REV: any = {
  [-12]: "p",
  [-9]: "n",
  [-6]: "μ",
  [-3]: "m",
  0: "",
  3: "k",
  6: "M",
  9: "G",
  12: "T",
  15: "P",
};

export const SI_PREFIXES: any = {
  p: -12,
  n: -9,
  μ: -6,
  m: -3,
  "": 0,
  k: 3,
  M: 6,
  G: 9,
  T: 12,
  P: 15,
};

const CURRENCY_PREFIX: any = {
  B: 9,
  M: 6,
  T: 3,
  "": 0,
};

const CURRENCY_REV_PREFIX: any = {
  9: "B",
  6: "M",
  3: "T",
  0: "",
};

const CURRENCY = {
  sek: "SEK",
  nok: "NOK",
};

const getCurrencyToSIPrefix = (unit: string) => {
  if (unit?.includes(CURRENCY.sek) || unit?.includes(CURRENCY.nok)) {
    switch (unit) {
      case unit:
        return ["", unit, "c", 3];
      case `${unit}/year`:
        return ["", `${unit}/year`, "c", 3];
      case `${unit}/kWh`:
        return ["k", `${unit}/Wh`, "c/si", 3];
      default:
        return [];
    }
  }
  return [];
};

const getUnitToSiPrefix = (unit: string) => {
  const currency = getCurrencyToSIPrefix(unit);

  const defaultConfig: { [key: string]: any } = {
    kWh: ["k", "Wh", "si", 3],
    kW: ["k", "W", "si", 3],
    W: ["", "W", "si", 3],
    "m³": ["", "m³", "e", 3],
    "m³/h": ["", "m³/h", "e", 3],
    "kWh/h": ["k", "Wh/h", "si", 3],
    "°C": ["", "°C", "t", ""],
    "%": ["", "%", "e", 3],
    "": ["", "", "n", 3],
    h: ["", "h", ""],
    [currency?.[1]]: currency,
  };

  return defaultConfig[unit];
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function formatNumberForUnitMainLabel(inum: string | number | null, iunit: string) {
  const config = getUnitToSiPrefix(iunit);
  if (!config) return [inum, "", "", iunit];

  // eslint-disable-next-line prefer-const
  let [prefix, sunit, strategy, precision] = config;
  let cnum = Math.abs(Number(inum));
  const sign = Number(inum) >= 0 ? 1 : -1;
  let exp = 0;
  let scaledExp = 0;
  const fallback = ["", 0, "", iunit];

  if (!isValue(inum)) return fallback;

  switch (strategy) {
    case "si":
      while (cnum > 1000) {
        cnum /= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] + 3];
        scaledExp += 3;
      }
      while (cnum < 1 && cnum > 0) {
        cnum *= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] - 3];
        scaledExp -= 3;
      }
      break;

    case "c/si":
      while (cnum > 1000) {
        cnum /= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] - 3];
        scaledExp += 3;
      }
      while (cnum < 1 && cnum > 0) {
        cnum *= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] + 3];
        scaledExp -= 3;
      }
      prefix = `${iunit.split("/")[0]}/${prefix}`;
      break;

    case "e":
      if (cnum > 1000) {
        while (cnum > 1000) {
          cnum /= 1000;
          exp += 3;
          scaledExp += 3;
        }
      } else if (cnum < 0.1 && cnum !== 0) {
        while (cnum < 0.1) {
          cnum *= 1000;
          exp -= 3;
          scaledExp -= 3;
        }
      }
      break;

    case "c":
      while (cnum >= 1000) {
        cnum /= 1000;
        prefix = CURRENCY_REV_PREFIX[CURRENCY_PREFIX[prefix] + 3];
        scaledExp += 3;
      }
      break;
  }

  let output = [];
  switch (strategy) {
    case "t":
      // Temperature has no other special calculations, it should just be rounded to a single decimal.
      output = [
        Number.parseFloat((cnum * sign).toPrecision()).toLocaleString("sv-SE", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }),
        exp,
        prefix,
        sunit,
      ];
      break;
    case "n":
      output = [cnum.toLocaleString("sv-SE"), exp, prefix, sunit];
      break;
    default:
      output = [
        (sign * Number.parseFloat(cnum.toPrecision(precision))).toLocaleString("sv-SE"),
        exp,
        prefix,
        sunit,
        scaledExp,
      ];
  }

  // return fallback if the final value is nullish
  if (output[0] === null || output[0] === undefined) return fallback;

  return output;
}

/*
  coerces a value/unit to main unit/exponent
*/
export function fmtRef(inum: any, iunit: any, exp: number, prefix: string | number) {
  const config = getUnitToSiPrefix(iunit);
  if (!config) return [inum, "", "", iunit];

  const [iprefix, sunit, strategy, precision] = config;
  let cnum = inum;
  if (strategy === "e" || strategy === "si" || strategy === "t") {
    cnum /= 10 ** (SI_PREFIXES[prefix] - SI_PREFIXES[iprefix]);
    cnum /= 10 ** exp;
  }
  if (strategy === "c") {
    cnum /= 10 ** (CURRENCY_PREFIX[prefix] - CURRENCY_PREFIX[iprefix]);
  }
  return [Number.parseFloat(cnum.toPrecision(precision)), exp, prefix, sunit];
}

export function sortRows(
  rows: any[],
  property: string | number,
  direction: string,
  deviation = false,
  includeNulls = false
) {
  let filteredRows = rows;
  /* rows format: should be array of json */
  if (!includeNulls) {
    filteredRows = rows.filter((r: { [x: string]: string | number | null }) =>
      isValue(r[property])
    );
  }
  return filteredRows.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
    let x = a[property];
    let y = b[property];
    if (x === y) {
      return 0;
    }
    if (!isValue(x)) {
      return direction === "asc" ? -1 : 1;
    }
    if (!isValue(y)) {
      return direction === "asc" ? 1 : -1;
    }
    if (deviation) {
      x = Math.abs(x);
      y = Math.abs(y);
    }
    if (direction === "asc") {
      return x < y ? -1 : 1;
    }
    return x < y ? 1 : -1;
  });
}

/**
 * Sort object by given property.
 * Relays on Intl.Collator.compare in order to generate compareFunction.
 *
 * @see https://mzl.la/3jDNHZS
 *
 * @param {*} property Object property for sort comparision
 * @param {string} [direction] Direction
 * @param {string} [type]
 * @param {string} [locale]
 * @return {Function} compareFunction to pass Array.sort method
 */
export function localeObjectSort(
  property: string | number,
  direction = "asc",
  type = "number",
  locale = "sv"
) {
  const ascending = direction === "asc";
  const options = {
    numeric: type === "number",
  };
  const cmpf = new Intl.Collator(locale, options).compare;

  function compareFunction(a: { [x: string]: any }, b: { [x: string]: any }) {
    const x = a[property];
    const y = b[property];
    if (x === y) {
      return 0;
    }

    return ascending ? cmpf(x, y) : cmpf(y, x);
  }

  return compareFunction;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function have_blocks(blks: { hasOwnProperty: (arg0: any) => any }, blocknames: any) {
  if (blks) {
    for (const blk of blocknames) {
      if (!Object.prototype.hasOwnProperty.call(blks, blk)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function debounce(
  func: { apply: (arg0: any, arg1: any[]) => void },
  wait: number | undefined,
  immediate = false
) {
  let timeout: string | number | NodeJS.Timeout | undefined;

  return function executedFunction(this: any, ...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;

    const later = function laterFn() {
      timeout = undefined;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args);
  };
}

function grn(c: string | number) {
  const r = (Math.random() * 16) | 0;

  const v = c === "x" ? r : (r & 0x3) | 0x8;
  return v.toString(16);
}

export function randstring(len: number) {
  return range(0, len, undefined)
    .map((i) => grn(i))
    .join("");
}

export function affectEvent(object: { [x: string]: number }, attr: string | number) {
  return function affectEventAction() {
    runInAction(() => {
      object[attr] = object[attr] < 1000 ? (object[attr] += 1) : 1;
    });
  };
}

export function downloadFile(exportObject: any, filename: string) {
  const contentType = "application/json;charset=utf-8;";
  // @ts-expect-error msSaveOrOpenBlob does not exists
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(exportObject)))], {
      type: contentType,
    });
    // @ts-expect-error msSaveOrOpenBlob does not exists
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    a.download = filename;
    a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(exportObject))}`;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function exportCurrentConfig(pricingStore: {
  current_conf: any;
  pricing_components: any[];
  customer_groups: any[];
}) {
  const cfg: any = {};
  const pcfg = pricingStore.current_conf;
  cfg.name = pcfg.name;
  cfg.models = pcfg.price_models.map(
    (mdl: { components: any[]; customer_groups: any[]; id: any; name: any }) => {
      const mdlcmp = mdl.components.map((c: { id: any }) => c.id);
      const mdlcgs = mdl.customer_groups.map((cg: { id: any }) => cg.id);
      return { id: mdl.id, name: mdl.name, components: mdlcmp, groups: mdlcgs };
    }
  );
  cfg.components = pricingStore.pricing_components.map(
    (cg: { id: any; name: any; parameters: any; type: any }) => ({
      id: cg.id,
      name: cg.name,
      parameters: cg.parameters,
      type: cg.type,
    })
  );
  cfg.groups = pricingStore.customer_groups.map(
    (cg: { id: any; condition: any; name: any; path: any; source: any; split: any }) => ({
      id: cg.id,
      condition: cg.condition,
      name: cg.name,
      path: cg.path,
      source: cg.source,
      split: cg.split,
    })
  );
  return cfg;
}

function defaultMap(fns: any) {
  const r: any = {};
  for (const fname of fns) {
    r[fname] = [];
  }
  return r;
}

export function deriveByArray({ reader, arr, fns, mapper = defaultMap }: any) {
  const r = mapper(fns.map((f: any[]) => f[0]));
  for (const idx of arr) {
    for (const [fname, fn] of fns) {
      fn(reader(idx), r[fname]);
    }
  }
  return r;
}

export function validCoords(ds: any, prop: string | number = "coordinates") {
  return (
    ds &&
    Object.prototype.hasOwnProperty.call(ds, prop) &&
    ds[prop] !== undefined &&
    ds[prop] !== null &&
    ds[prop].length === 2 &&
    isValue(ds[prop][0]) &&
    isValue(ds[prop][1])
  );
}

/* deep equality check for arrays */
function arrayEquals(a: any[], b: string | any[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

/* helper function to fire reaction only in event of values change, not value assign */
export function valueChangeReact(init: any, fn: () => any, debug = false) {
  let oldValue = init;
  let lastOut = false;
  return () => {
    const newVal = fn();
    if (debug) {
      // eslint-disable-next-line no-console
      console.log("value change", oldValue, newVal);
    }
    if (!arrayEquals(newVal, oldValue)) {
      oldValue = newVal;
      lastOut = !lastOut;
    }
    return lastOut;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function week_string(dt: {
  isValid: any;
  weekNumber: any;
  startOf: (arg0: string) => {
    (): any;
    new (): any;
    toFormat: { (arg0: string): any; new (): any };
  };
  endOf: (arg0: string) => { (): any; new (): any; toFormat: { (arg0: string): any; new (): any } };
}) {
  if (dt && dt.isValid) {
    return `w.${dt.weekNumber} (${dt.startOf("week").toFormat("MMM dd")} - ${dt
      .endOf("week")
      .toFormat("MMM dd")})`;
  }
  return "";
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function have_cols(row: { [x: string]: string | number | null }, columnNames: any) {
  for (const colName of columnNames) {
    if (!isValue(row[colName])) {
      return false;
    }
  }
  return true;
}

/**
 * convert a number from 0.00 to 0_00 for csv file naming
 * @param number
 * @returns {string}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function to_csv_filename(number: { toString: () => string }) {
  return number.toString().replace(".", "_");
}

/**
 * calculate sum taking care of divide by zero and edge cases
 * @param sum
 * @param over
 * @returns {number}
 */
export function calcAvg(sum: number, over: number) {
  if (over === 0 || !isValue(sum) || !isValue(over)) {
    return null;
  }
  return sum / over;
}

/**
 * Omits array of keys from the object
 *
 * @param keys {Array}
 * @param obj {Object}
 * @returns {null|object} the object without keys
 */
export function omit(keys: any[], obj: any) {
  return keys.reduce((a: { [x: number]: string }, e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [e]: omitted, ...rest } = a || { [e]: "" };
    return rest;
  }, obj);
}

/**
 * Replaces all undefined values inside a single dimensional or nested array with null
 *
 * Useful with Highcharts since Highcharts connects undefined values
 *
 * @param {*} originalArray
 * @returns an array with null as undefined
 */
export function replaceUndefinedWithNulls(originalArray: any[]): any[] {
  return (originalArray || []).map((a: undefined) =>
    Array.isArray(a) ? replaceUndefinedWithNulls(a) : a === undefined ? null : a
  );
}

/**
 * Checks if the given parameter is a Number
 *
 * @export
 * @param {*} number
 * @return {*} boolean
 */
export function numbersOnly(number: any) {
  return !Number.isNaN(Number.parseFloat(number)) && Number.isFinite(number);
}

/**
 * This function returns the current UTC offset in the format of UTC+HH:MM
 *
 * @example
 * minutesToTimezone(-60) // returns UTC-01:00
 * minutesToTimezone(60) // returns UTC+01:00
 * minutesToTimezone(0) // returns UTC+00:00
 *
 * @param {number} offsetMinutes
 * @returns {string} UTC offset in the format of UTC+HH:MM
 */
export function minutesToTimezone(offsetMinutes: number) {
  function padZero(num: number) {
    return num < 10 ? `0${num}` : num;
  }
  const offsetHours = Math.abs(offsetMinutes / 60);
  const offsetSign = offsetMinutes >= 0 ? "-" : "+";
  return `UTC${offsetSign}${padZero(offsetHours)}:${padZero(Math.abs(offsetMinutes % 60))}`;
}

/**
 * If five days have passed since month started we can be assured jobs for last month
 * have run and use it, otherwise we use month prior to it.
 *
 * @export
 * @param {Luxon.DateTime} currentTime
 * @param {Array} networkMonth
 * @param {boolean} [active]
 * @return {Luxon.DateTime} month of Last processed month or latest blocks available
 */
export function getLatestProcessedMonth(
  currentTime: DateTime,
  networkMonth: [number, number] | undefined = undefined,
  active = false
) {
  if (networkMonth && !active) {
    return DateTime.fromObject({ year: networkMonth[0], month: networkMonth[1], day: 5 });
  }
  const daysSinceLastMonth = Duration.fromMillis(
    currentTime.toMillis() - currentTime.startOf("month").toMillis()
  ).shiftTo("days").days;
  let LastProcessedMonth = null;
  if (daysSinceLastMonth >= 7) {
    LastProcessedMonth = currentTime.plus({ months: -1 });
  } else {
    LastProcessedMonth = currentTime.plus({ months: -2 });
  }
  return LastProcessedMonth;
}

/**
 *
 *
 * @export
 * @param {Luxon.DateTime} currentTime
 * @param {number} networkYear
 * @param {boolean} [active]
 * @return {Luxon.DateTime}
 */
export function getLatestProcessedYear(currentTime: any, networkYear: any, active = false) {
  if (networkYear && !active) {
    return DateTime.fromObject({ year: networkYear, month: 12, day: 5 });
  }
  const lpm = getLatestProcessedMonth(currentTime);
  if (lpm.month < 12) {
    return lpm.plus({ years: -1 });
  }
  return lpm;
}

/**
 * This adds a new key to the url query string
 *
 * @param {string} key Key used  for the query params
 * @param {string} value Value of the query param
 */
export function insertUrlParam(key: string, value: string) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = `${window.location.origin}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

/**
 * This removes a key from the url query string
 *
 * @param {string} key Key used  for the query params
 */
export function removeUrlParam(key: string, win = window) {
  if (win.history.pushState) {
    const searchParams = new URLSearchParams(win.location.search);
    searchParams.delete(key);
    const newurl = `${win.location.origin}${win.location.pathname}?${searchParams.toString()}`;
    win.history.pushState({ path: newurl }, "", newurl);
  }
}

/**
 * This gets a value from the url query string
 *
 * @param {string} key Key used  for the query params
 * @returns {string} the value of the query param or null if it doesn't exist
 */
export function getUrlParam(key: string) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

export const toCamelCase = (str: string) => {
  return str
    .split(/[-_ ]+/) // Split by hyphens, underscores, or spaces
    .map((word, index) => {
      // Capitalize all words except the first one
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(""); // Join all words without spaces
};
