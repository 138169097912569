import { toJS } from "mobx";
import { Typography } from "@mui/material";
import type { TOptions } from "i18next";
import type { MRT_Cell, MRT_Column, MRT_RowData } from "material-react-table";

import i18n from "@core/i18n";
import { getCoreBlockName } from "@core/utils/columns/utils";

import DefaultCell from "./Cell";
import type { InfoBlockColumn } from "./const";

const aggregationColums = [
  "core.heat_energy_sum",
  "average_heat_energy",
  "core.volume_sum",
  "average_volume",
  "epcore_normalized.heat_energy_sum",
  "epcore_normalized.volume_sum",
  "ep_systemdesign_1h.volume",
  "ep_systemdesign_24h.volume",
  "ep_systemdesign_1h.heat_energy",
  "ep_systemdesign_24h.heat_energy",
];

/**
 * Replaces the core block name in the given column path.
 */
export function replaceCoreId(columnPath: string, isRolling: boolean) {
  return columnPath.replace(`${getCoreBlockName(!isRolling)}.`, `${getCoreBlockName(isRolling)}.`);
}

/**
 * Returns the translated string if the field is not null.
 */
export function t(field: string, i18nOptions: TOptions) {
  return field ? i18n.t(field, i18nOptions) : field;
}

/**
 * Populates the column alignment related properties
 */
export function getColAlignmentProps({ align, muiTableBodyCellProps = {}, type }: InfoBlockColumn) {
  const defaultAlign = type === "number" ? "right" : "left";
  const alignment = align ?? defaultAlign;

  return {
    align: alignment,
    muiTableBodyCellProps: { ...muiTableBodyCellProps, align: alignment },
  };
}
/**
 * Populates the column filter related properties
 */
export function getColFilterProps({ type }: InfoBlockColumn) {
  const filterProps: Partial<InfoBlockColumn> = {};
  const typeToVariant: { [type: string]: InfoBlockColumn["filterVariant"] } = {
    string: "autocomplete",
    number: "text",
    link: "text",
  };
  const typeToFilterFn: { [type: string]: InfoBlockColumn["filterFn"] } = {
    number: "between",
  };
  const stringModes = ["fuzzy", "contains", "startsWith", "endsWith", "empty", "notEmpty"];
  const typeToColumnFilterModes: { [type: string]: InfoBlockColumn["columnFilterModeOptions"] } = {
    link: stringModes,
    string: stringModes,
    number: [
      "equals",
      "notEquals",
      "between",
      "betweenInclusive",
      "inNumberRange",
      "greaterThan",
      "greaterThanOrEqualTo",
      "lessThan",
      "lessThanOrEqualTo",
      "empty",
      "notEmpty",
    ],
  };

  // filterVariant
  if (type in typeToVariant) {
    filterProps.filterVariant = typeToVariant[type];
  } else {
    filterProps.enableColumnFilter = false;
  }

  // filterFn
  if (type in typeToFilterFn) filterProps.filterFn = typeToFilterFn[type];

  // columnFilterModeOptions
  if (type in typeToColumnFilterModes)
    filterProps.columnFilterModeOptions = typeToColumnFilterModes[type];

  return filterProps;
}

/**
 * Populates the "aggregation" related properties
 */
export function getColAggregationProps({ type, id }: InfoBlockColumn) {
  const props: Partial<InfoBlockColumn> = {};
  const typeToAggregationFn: { [type: string]: InfoBlockColumn["aggregationFn"] } = {
    number: "sum",
  };
  const typeToAggregatedCell: { [type: string]: InfoBlockColumn["AggregatedCell"] } = {
    number: ({ cell }) => {
      return (
        // eslint-disable-next-line i18next/no-literal-string
        <Typography variant="body1" fontWeight="400">
          Total by {cell.column.columnDef.header}: <br />{" "}
          <DefaultCell cell={cell} column={cell.column} />
        </Typography>
      );
    },
  };
  const typeToFooter: { [type: string]: InfoBlockColumn["Footer"] } = {
    number: ({ table, column }) => (
      // eslint-disable-next-line i18next/no-literal-string
      <Typography variant="body1" fontWeight="500">
        Total {column.columnDef.header}: <br />
        <DefaultCell
          cell={
            {
              getValue() {
                return table.getFilteredRowModel().flatRows.reduce((acc, row) => {
                  if (row.getValue(column.id)) return acc + Number(row.getValue(column.id));
                  return acc;
                }, 0);
              },
            } as MRT_Cell<MRT_RowData>
          }
          column={column as MRT_Column<MRT_RowData>}
        />
      </Typography>
    ),
  };

  // aggregationFn
  if (type in typeToAggregationFn) props.aggregationFn = typeToAggregationFn[type];

  // AggregatedCell
  if (type in typeToAggregatedCell && id && aggregationColums.includes(id))
    props.AggregatedCell = typeToAggregatedCell[type];

  // Footer
  if (type in typeToFooter && id && aggregationColums.includes(id))
    props.Footer = typeToFooter[type];

  return props;
}

/**
 * Maps the column with default values and translations.
 */
export function mapColumnWithDefaults(_column: InfoBlockColumn, enableAggregation: boolean) {
  const column = toJS(_column);
  const { info, header, sublabel, translateNs, translateOptions = {}, id, accessorKey } = column;
  let i18nOptions = { ns: translateNs, ...translateOptions };

  let modifiedColumn = {
    ...column,
    header: t(header, i18nOptions),
    sublabel: sublabel ? t(sublabel, i18nOptions) : sublabel,
    info: info ? t(info, i18nOptions) : info,
    muiTableHeadCellProps: { "data-testid": `utf-col-${id ?? accessorKey}` },
    ...getColAlignmentProps(column),
    ...getColFilterProps(column),
  };

  if (enableAggregation) {
    modifiedColumn = {
      ...modifiedColumn,
      ...getColAggregationProps(column),
    };
  }

  modifiedColumn.columns = column.columns?.map((_childColumn: InfoBlockColumn) => {
    const childColumn = toJS(_childColumn);

    if (childColumn.translateNs) i18nOptions.ns = childColumn.translateNs;
    i18nOptions = { ...i18nOptions, ...(childColumn.translateOptions ?? {}) };

    let modifiedChildColumn = {
      ...childColumn,
      header: t(childColumn.header, i18nOptions),
      sublabel: childColumn.sublabel ? t(childColumn.sublabel, i18nOptions) : childColumn.sublabel,
      info: childColumn.info ? t(childColumn.info, i18nOptions) : childColumn.info,
      muiTableHeadCellProps: {
        "data-testid": `utf-col-${childColumn.id ?? childColumn.accessorKey}`,
      },
      ...getColAlignmentProps(childColumn),
      ...getColFilterProps(childColumn),
    };
    if (enableAggregation) {
      modifiedChildColumn = {
        ...modifiedChildColumn,
        ...getColAggregationProps(childColumn),
      };
    }

    return modifiedChildColumn as InfoBlockColumn;
  });

  return modifiedColumn;
}

export function getFilteredFlatColumns(
  userColumns: InfoBlockColumn[],
  filterFn: (column: Partial<InfoBlockColumn>) => boolean | undefined
) {
  return [
    ...userColumns.filter(filterFn).map((col) => col.id),
    ...userColumns
      .filter((col) => col.columns)
      .filter(filterFn)
      .flatMap((col) => col?.columns?.map((subCol) => subCol.id)),
  ];
}
