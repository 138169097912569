import { SvgIcon } from "@mui/material";

export default function Icon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M 23.719,11.25 H 22.1 A 10.126,10.126 0 0 0 12.75,1.9 V 0.281 A 0.281,0.281 0 0 0 12.469,0 H 11.532 A 0.281,0.281 0 0 0 11.251,0.281 V 1.9 A 10.126,10.126 0 0 0 1.9,11.25 H 0.281 A 0.281,0.281 0 0 0 0,11.531 v 0.938 A 0.281,0.281 0 0 0 0.281,12.75 H 1.9 a 10.126,10.126 0 0 0 9.35,9.35 v 1.621 a 0.281,0.281 0 0 0 0.281,0.281 h 0.938 A 0.281,0.281 0 0 0 12.75,23.721 V 22.1 a 10.126,10.126 0 0 0 9.35,-9.35 h 1.621 A 0.281,0.281 0 0 0 24,12.469 V 11.532 A 0.281,0.281 0 0 0 23.719,11.25 Z M 12.75,20.592 V 17.531 A 0.281,0.281 0 0 0 12.469,17.25 h -0.937 a 0.281,0.281 0 0 0 -0.281,0.281 v 3.061 A 8.621,8.621 0 0 1 3.408,12.75 H 6.469 A 0.281,0.281 0 0 0 6.75,12.469 V 11.532 A 0.281,0.281 0 0 0 6.469,11.251 H 3.408 A 8.621,8.621 0 0 1 11.25,3.408 v 3.061 a 0.281,0.281 0 0 0 0.281,0.281 h 0.938 A 0.281,0.281 0 0 0 12.75,6.469 V 3.408 a 8.621,8.621 0 0 1 7.842,7.842 h -3.061 a 0.281,0.281 0 0 0 -0.281,0.281 v 0.938 a 0.281,0.281 0 0 0 0.281,0.281 h 3.061 a 8.621,8.621 0 0 1 -7.842,7.842 z"
      />
    </SvgIcon>
  );
}
