/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import { styled } from "@mui/system";

const Accordion = styled(MuiAccordion)(() => ({
  "&&": {
    border: 0,
    margin: 0,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    ".Mui-expanded": {
      margin: 0,
    },
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  "&&": {
    minHeight: 48,
    height: 48,
    padding: 0,
    margin: 0,
    alignItems: "stretch",
    backgroundColor: theme.palette.grey["50"],
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    color: theme.palette.primary.main,
    zIndex: 2,
    "&.Mui-expanded": {
      marginBottom: 0,
    },
    ".MuiAccordionSummary-content": {
      minHeight: theme.spacing(6),
      padding: theme.spacing(2),
      margin: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginBottom: 0,
      "& > svg": {
        flex: 1,
        height: "100%",
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
      },
    },
  },
  expanded: {},
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  "&&": {
    display: "flex",
    padding: 0,
    border: 0,
  },
}));

type CollapsablePanelProps = {
  index: number;
  header: ReactNode;
  children: ReactNode;
};
export function CollapsablePanel({ index, header, children, ...props }: CollapsablePanelProps) {
  return (
    <Accordion defaultExpanded square {...props}>
      <AccordionSummary
        expandIcon={
          <Box
            sx={{ width: "44px", display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <ExpandMoreIcon color="primary" />
          </Box>
        }
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
        sx={{ mr: 1 }}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
