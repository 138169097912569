export const TOOLBOX_OPTION_GRAPH_GRID = "graphGrid";
export const TOOLBOX_OPTION_MAX = "max";
export const TOOLBOX_OPTION_MIN = "min";
export const TOOLBOX_OPTION_AVERAGE = "average";
export const TOOLBOX_OPTION_REFERENCE = "ref";

export const TOOLBOX_DEFAULT_OPTIONS = {
  [TOOLBOX_OPTION_GRAPH_GRID]: "action_graph_grid",
  [TOOLBOX_OPTION_MAX]: "action_maximum_value",
  [TOOLBOX_OPTION_MIN]: "action_minimum_value",
  [TOOLBOX_OPTION_AVERAGE]: "action_average_value",
};
