/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactElement } from "react";
import { createElement } from "react";
import { Button, Checkbox } from "@mui/material";
import type { MRT_Cell, MRT_Column, MRT_Row, MRT_RowData } from "material-react-table";

import { COL_PARSERS, UNITS } from "@config/constants";
import { defaultTheme } from "@config/utilifeed.theme";
import { formatNumberForLocale } from "@core/utils";

import type { InfoBlockColumn } from "./const";

export type DefaultCellProps = {
  cell: MRT_Cell<MRT_RowData, any>;
  column: MRT_Column<MRT_RowData, any>;
};

function getRowId(row: MRT_Row<InfoBlockColumn>): string {
  return (row?.original?.sub_id ?? row?.id) || "";
}

/**
 *
 * Type Renderers
 *
 */

const stringRenderer = ({ value = "" }: { value?: string }): string => value;

export function numberRenderer({ value }: { value: any }): string {
  return formatNumberForLocale(value, "sv-SE", value || undefined);
}

function linkRenderer({
  value,
  cell: { column, row },
}: {
  value: string;
  cell: DefaultCellProps["cell"];
}): ReactElement {
  return (
    <Button
      disableRipple
      component="a"
      onClick={() =>
        // @ts-expect-error migration
        column.columnDef?.onClick(getRowId(row))
      }
      sx={{ fontFamily: "Roboto", fontWeight: 300, p: 0, color: "primary.main" }}
    >
      {value}
    </Button>
  );
}

function coordRenderer({ value }: { value: string[] }): string {
  if (value) return value.join(", ");

  return "";
}

function iconRenderer({ value, cell }: { value: string; cell: DefaultCellProps["cell"] }): string {
  // @ts-expect-error migration
  return cell.column.columnDef?.iconMapping?.[value] ?? "";
}

function actionsRenderer({ value, cell }: { value: string; cell: DefaultCellProps["cell"] }) {
  const {
    column: { columnDef },
  } = cell;
  // @ts-expect-error migration
  if (columnDef.actions) {
    // @ts-expect-error migration
    return columnDef.actions.map((action) => (
      <Button
        key={action.id}
        onClick={() => action.onClick(value)}
        variant="text"
        color="primary"
        style={{
          marginRight: "5px",
        }}
      >
        {action.label}
      </Button>
    ));
  }
  return null;
}

function checkboxRenderer({
  value,
  cell,
}: {
  value: boolean;
  cell: DefaultCellProps["cell"];
}): ReactElement {
  return (
    <Checkbox
      checked={value}
      // @ts-expect-error migration
      onChange={() => cell.column.columnDef.onChange(getRowId(cell.row), value)}
      inputProps={{ "aria-label": cell.column.header }}
    />
  );
}

const renderersByColumnType: Record<string, (props: any) => string | ReactElement | null> = {
  number: numberRenderer,
  link: linkRenderer,
  string: stringRenderer,
  datestring: stringRenderer,
  coord: coordRenderer,
  icon: iconRenderer,
  actions: actionsRenderer,
  checkbox: checkboxRenderer,
};

function TypeRenderer({
  type,
  value,
  cell,
}: {
  type: string;
  value: string;
  cell: DefaultCellProps["cell"];
}): ReactElement | null {
  return createElement(renderersByColumnType[type], {
    value,
    cell,
  });
}

/**
 *
 * Unit Renderers
 *
 */
const unitParsers: { [unit: string]: any } = {
  [UNITS.temp]: ({ value }: { value: number }) => Number(value).toFixed(1).replace(/\./g, ","),
};

function UnitRenderer({ value, unit }: { value: string; unit: string }): ReactElement | null {
  if (Number.isNaN(Number.parseFloat(value))) return null;
  return createElement(unitParsers[unit], { value });
}

/**
 *
 * InfoBlock Renderers
 *
 */
function InfoBlockRenderer({
  type,
  value: initialValue,
}: {
  type: keyof typeof COL_PARSERS;
  value: any;
}): ReactElement {
  return createElement(({ value }: { value: any }) => COL_PARSERS[type](value), {
    value: initialValue,
  });
}

function CellWrapper({
  children,
  isFiltered,
}: {
  isFiltered: boolean;
  children: ReactElement;
}): ReactElement {
  if (!isFiltered) return children;

  // highlight filtered cells
  return (
    <div
      style={{
        display: "inline",
        color: defaultTheme.palette.primary.main,
      }}
    >
      {children}
    </div>
  );
}

/**
 *
 * Default Cell Renderer for InfoBlockGrid
 *
 */
function DefaultCell({ cell, column }: DefaultCellProps): ReactElement | null {
  const value = cell.getValue();
  const { type = "number", spec = { unit: "" } } = (column?.columnDef as any) || {};

  let componentToRender = null;
  if (spec.unit in unitParsers) componentToRender = <UnitRenderer value={value} unit={spec.unit} />;
  else if (type in renderersByColumnType)
    componentToRender = <TypeRenderer value={value} type={type} cell={cell} />;
  else if (type in COL_PARSERS) componentToRender = <InfoBlockRenderer value={value} type={type} />;
  else if (value) componentToRender = value;

  return <CellWrapper isFiltered={column.getIsFiltered()}>{componentToRender}</CellWrapper>;
}

export default DefaultCell;
