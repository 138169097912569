import { coordinatesSort } from "@core/utils/columns/coordinatesSort";

import { COLUMN_SECTION } from "./constants";

const CUSTOMER_COLUMNS = {
  name: {
    label: "text_customer_name",
    info: "text_customer_name_desc",
    section: COLUMN_SECTION.customer,
  },
};

const INSTALL_ADDRESS_COLUMNS = {
  street: {
    label: "text_install_address",
    info: "text_install_address_desc",
    section: COLUMN_SECTION.location,
  },
  coordinates: {
    label: "text_coordinates",
    info: "text_coordinates_desc",
    section: COLUMN_SECTION.location,
    sortingFn: coordinatesSort,
  },
  zip: {
    label: "text_zip_code",
    info: "text_zip_code_desc",
    section: COLUMN_SECTION.location,
  },
};

const DISTRIBUTION_COLUMNS = {
  grid_area: {
    label: "text_grid_area",
    info: "text_grid_area_desc",
    section: COLUMN_SECTION.location,
  },
};

export { CUSTOMER_COLUMNS, INSTALL_ADDRESS_COLUMNS, DISTRIBUTION_COLUMNS };
