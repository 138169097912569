/* eslint-disable i18next/no-literal-string */

import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

type Props = {
  date: DateTime;
};

const useStyles = makeStyles((theme: any) => ({
  // Define styles here
  icon: {
    fontSize: "0.9em",
    display: "block",
    position: "relative",
    width: "5.5em",
    height: "5.5em",
    backgroundColor: "#fff",
    borderRadius: "0.6em",
    boxShadow:
      "0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd",
    overflow: "hidden",
    "& *": {
      display: "block",
      width: "100%",
      fontSize: "1em",
      fontWeight: "bold",
      fontStyle: "normal",
      textAlign: "center",
    },
    "& strong": {
      position: "absolute",
      top: 0,
      padding: "0.25em 0 0.1em 0",
      color: "#fff",
      backgroundColor: theme.palette.secondary.light,
      borderBottom: "1px dashed #fff",
      boxShadow: `0 2px 0 ${theme.palette.secondary.light}`,
      fontWeight: "bold",
      fontSize: "1.2em",
    },
    "& em": {
      position: "absolute",
      bottom: "0.3em",
      color: theme.palette.secondary.main,
    },
    "& span": {
      fontSize: "1.7em",
      letterSpacing: "-0.05em",
      paddingTop: "1.4em",
      color: theme.palette.text.main,
    },
  },
}));

function MiniCalendar({ date }: Props) {
  const classes = useStyles();

  // Component logic here

  return (
    <time dateTime="2014-19-20" className={classes.icon}>
      {/* Day */}
      <em>{date.weekdayShort}</em>
      {/* Month */}
      <strong>{date.monthShort}</strong>
      {/* Date */}
      <span>{date.day}</span>
    </time>
  );
}

export { MiniCalendar };
