/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MRT_Row, MRT_RowData } from "material-react-table";

import { COLUMN_PERIOD } from "@config";
import { SUBSTATION_BLOCK_TYPES } from "@config/blocks";
import { COLUMN_META } from "@config/Columns/meta";
import type { ColumnMeta } from "@core/types/common";
import { isValue } from "@core/utils";

import { getRowValueByColumnID } from "./getRowValueByColumnId";

export function compareBasic(a: any, b: any) {
  return a === b ? 0 : a > b ? 1 : -1;
}

export function numberSort(
  rowA: MRT_Row<MRT_RowData>,
  rowB: MRT_Row<MRT_RowData>,
  columnId: string
) {
  const a = getRowValueByColumnID(rowA, columnId);
  const b = getRowValueByColumnID(rowB, columnId);
  if (a === b) return 0;

  if (!isValue(a)) {
    return -1;
  }
  if (!isValue(b)) {
    return 1;
  }

  return compareBasic(a, b);
}

export function numberSortWithCustom(
  rowA: MRT_Row<MRT_RowData>,
  rowB: MRT_Row<MRT_RowData>,
  columnId: string
) {
  const a = getRowValueByColumnID(rowA, columnId);
  const b = getRowValueByColumnID(rowB, columnId);
  const customNegatives = ["N/A"];

  if (customNegatives.includes(a)) {
    return -1;
  }

  if (customNegatives.includes(b)) {
    return 1;
  }

  return numberSort(rowA, rowB, columnId);
}

export function iconSort(rowA: MRT_Row<MRT_RowData>, rowB: MRT_Row<MRT_RowData>, columnId: string) {
  const a = getRowValueByColumnID(rowA, columnId);
  const b = getRowValueByColumnID(rowB, columnId);
  // @ts-expect-error tricky one
  return (b === null || b === undefined) - (a === null || a === undefined) || +(b > a) || -(b < a);
}

type ColumnDefinition<Definition extends Record<string, any>> = ColumnMeta & Definition;

/**
 * Generates a column definition object used to configure table columns.
 *
 * @template DefinitionType - The type of the additional properties specific to the column type.
 *
 * @param {ColumnDefinition<DefinitionType>} params - An object containing the column definition parameters.
 * @param {string} params.id - The unique identifier for the column.
 * @param {any} [params.block='SBT.core'] - The block or module the column belongs to.
 * @param {string} params.label - The user-friendly display label for the column.
 * @param {string} [params.info] - Optional help text or information about the column.
 * @param {string} [params.section] - Optional section the column belongs to (for grouping).
 * @param {string} params.type - The data type of the column (e.g., 'text', 'number', 'date').
 * @param {DefinitionType} params.definitions - Additional properties specific to the column type.
 *
 * @returns {ColumnDefinition<DefinitionType>} The generated column definition object.
 */
export function generateColumnDefinition<DefinitionType extends ColumnMeta>({
  id,
  block = SUBSTATION_BLOCK_TYPES.core,
  label,
  info,
  section,
  type,
  ...definitions
}: ColumnDefinition<DefinitionType>): ColumnDefinition<DefinitionType> & {
  blockName: (period: any) => string;
} {
  // generate block name based on period
  const blockName = (activePeriod = { year: null, month: null }) => {
    const { year } = activePeriod;
    const { month } = activePeriod;

    // populate period arguments for name generators
    const periodArgs = { year, month: null, roll: false };
    if (year !== "R12" && month !== null && month !== 0) {
      // Handle R12 separately
      periodArgs.month = month;
    }
    if (year === "R12") {
      periodArgs.roll = true;
    }

    if (typeof block === "function") {
      return block(periodArgs);
    }
    // -> "{block}_{YEAR}-{MONTH}" -> "core_2021-12"
    return block.to_block_name({ ...activePeriod, ...periodArgs });
  };

  // @ts-expect-error TO BE MIGRATED.
  return {
    id,
    blockName,
    blockKey: block.name,
    label: label ?? definitions.header,
    info,
    section,
    type,
    ...definitions,
  };
}

/**
 * Generates column information from all available block types of substation
 * It combines data from block definitions with the meta information...
 * needed for presentational components like InfoBlockGrid
 *
 * @param {Array} [substationBlockTypes]
 * @return {Array} Column definitions
 */
export function generateSBTColumnsFromBlocks(
  substationBlockTypes = Object.keys(SUBSTATION_BLOCK_TYPES)
) {
  const colDef = new Map();
  substationBlockTypes.forEach((blockKey) => {
    // @ts-expect-error TO BE MIGRATED.
    const block = SUBSTATION_BLOCK_TYPES[blockKey];
    const blockColumns = block?.columns;

    if (blockColumns) {
      Object.keys(blockColumns).forEach((colId) => {
        // Ignore the blocks without meta defined
        if (!(blockKey in COLUMN_META)) {
          console.warn(`No meta information for block ${blockKey}`);
          return;
        }

        const spec = blockColumns[colId];
        // convert incompatible types
        const colType = spec.type.replace("num", "number").replace("str", "string");
        const defaultMeta = {
          id: colId,
          type: colType,
          block,
          spec,
        };

        // Label, Info, Section,...
        // @ts-expect-error TO BE MIGRATED.
        const colMeta = COLUMN_META[blockKey][colId];
        // Do not add the columns with empty or missing meta
        if (!colMeta || !("label" in colMeta)) return;

        const path = `${blockKey}.${colId}`;
        colDef.set(path, generateColumnDefinition({ ...defaultMeta, ...colMeta, path }));
      });
    }
  });
  return Array.from(colDef.values());
}

export function getCoreBlockName(roll = false) {
  return roll ? "core_rolling_yearly" : "core";
}

/**
 * Generate combined data with requested block types and predefined computed columns
 *
 * @param {COLUMN_PERIOD} [activePeriod] year|month|r12
 * @param {string[]} sbtBlocks default blocks to be included
 * @param {string[]} defaultColumnIds column id's to set `visible=true`
 * @return {Column[]} Column definitions
 */
export function generateColumnsBySBT(
  activePeriod: string = COLUMN_PERIOD.year,
  sbtBlocks: string[] = [],
  defaultColumnIds: string[] = [],
  idColumnOnClick?: (substationId: string) => void,
  idColumnBlockName?: (_: string, index: string) => string | undefined
): ColumnMeta[] {
  const sbtBlocksYearly = [getCoreBlockName()];
  // Set blocks based on active period
  let activeBlocks = [...sbtBlocks, ...sbtBlocksYearly];
  if (activePeriod === COLUMN_PERIOD.r12) {
    const sbtBlocksRolling1Y = [getCoreBlockName(true)];
    activeBlocks = sbtBlocks.concat(sbtBlocksRolling1Y);
  } else if (activePeriod === COLUMN_PERIOD.month) {
    const sbtBlocksMonthly = [getCoreBlockName()];
    activeBlocks = sbtBlocks.concat(sbtBlocksMonthly);
  }

  // Column definitions
  // @ts-expect-error TO BE MIGRATED.
  const computedColumns = [];
  const sbtColumns = generateSBTColumnsFromBlocks(activeBlocks);
  const computedColumnsMeta = COLUMN_META.computed;
  Object.keys(computedColumnsMeta).forEach((colId) => {
    // @ts-expect-error TO BE MIGRATED.
    const { output, ...colMeta } = computedColumnsMeta[colId];
    const metaData = {
      id: colId,
      path: colId,
      block: () => output,
      ...colMeta,
    };
    computedColumns.push(generateColumnDefinition(metaData));
  });

  let idColumn = undefined;
  if (idColumnOnClick && idColumnBlockName)
    idColumn = {
      id: "id",
      path: "id",
      label: "ID",
      type: "link",
      blockName: () => idColumnBlockName,
      onClick: idColumnOnClick,
      disabled: false,
      hideable: false,
      visible: true,
      enableGrouping: false,
      grow: false,
      size: 210,
      minSize: 210,
    };

  // !!! Computed columns must be always placed after other columns since they depends their data
  // @ts-expect-error TO BE MIGRATED.
  return [idColumn, ...sbtColumns, ...computedColumns]
    .filter(Boolean)
    .map(({ periods = null, block, ...col }) => {
      let disabled = false;
      if (periods !== null && !periods.includes(activePeriod)) disabled = true;

      let visible = true;
      if ((defaultColumnIds || []).length > 0 && !defaultColumnIds.includes(col.path))
        visible = false;

      return {
        disabled,
        visible,
        periods,
        block: typeof block === "function" ? block : () => block, // Ensure 'block' is a function
        ...col,
      };
    });
}

// @ts-expect-error TO BE MIGRATED.
export function parseBlockName(blockName, periodArgs) {
  let name = null;
  if (typeof blockName === "function") {
    name = blockName(periodArgs);
  } else if (
    typeof blockName === "object" &&
    !Array.isArray(blockName) &&
    blockName !== null &&
    "to_block_name" in blockName
  ) {
    name = blockName.to_block_name(periodArgs);
  } else {
    name = blockName;
  }
  return name;
}

// @ts-expect-error TO BE MIGRATED.
export function generateBlockNamesFromArr(columns, periodArgs) {
  const blockNames = columns
    // do not include "derive" type of block name generators
    .filter(
      // @ts-expect-error TO BE MIGRATED.
      ([blockName]) =>
        typeof blockName !== "function" ||
        (typeof blockName === "function" && typeof blockName() !== "function")
    )
    // @ts-expect-error TO BE MIGRATED.
    .map(([blockName]) => parseBlockName(blockName, periodArgs));
  return [...new Set(blockNames)];
}

// @ts-expect-error TO BE MIGRATED.
export function generateBlockNames(columns, periodArgs): string[] {
  const blockNames = columns
    // shallow-copy
    .slice()
    // do not include "derive" type of block name generators
    // @ts-expect-error TO BE MIGRATED.
    .filter((col) => typeof col?.blockName(periodArgs) !== "function")
    // @ts-expect-error TO BE MIGRATED.
    .map((col) => col.blockName(periodArgs));
  // @ts-expect-error TO BE MIGRATED.
  return [...new Set(blockNames)];
}

// @ts-expect-error TO BE MIGRATED.
export function getPeriodArgs(inputYear, inputMonth, activePeriod, networks) {
  let year = inputYear;
  let month = inputMonth;
  if (activePeriod === COLUMN_PERIOD.r12) {
    year = networks.lpMonth.year;
    month = networks.lpMonth.month;
  }
  if (inputMonth === null) return { year };
  return { year, month };
}

// @ts-expect-error TO BE MIGRATED.
export function generateSpecsFromArr(columns, periodArgs) {
  return (
    columns
      // @ts-expect-error TO BE MIGRATED.
      .map(([blockName, id, path]) => {
        if (!blockName) return null;
        const name = parseBlockName(blockName, periodArgs);
        const type = typeof name === "function" ? "derive" : "name";
        return [
          type,
          // spec
          type === "derive" ? name : [name, id],
          // dest
          path || id,
        ];
      })
      // filter nullish
      .filter(Boolean)
  );
}

// @ts-expect-error TO BE MIGRATED.
export function generateSpecs(columns, periodArgs) {
  return generateSpecsFromArr(
    // @ts-expect-error TO BE MIGRATED.
    columns.map((col) => [col.blockName, col.id, col.path]),
    periodArgs
  );
}
