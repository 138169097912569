import { createElement } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { Alert, AlertTitle, Button } from "@mui/material";
import * as Sentry from "@sentry/react";

const isDev = import.meta.env.DEV;

function DefaultErrorFallback({
  error,
  componentStack,
  resetError,
}: {
  error: any;
  componentStack: string;
  resetError: () => void;
}) {
  let message =
    "You have encountered an error. Try to reload the page and contact support if the problem is consistent.";
  let title = "Unexpected error occured!";
  if (isDev) {
    title = "Component has crashed due to an unhandled error. See the error stack;";
    message = error.toString();
  }

  return (
    <Alert
      severity="error"
      variant="filled"
      action={
        isDev ? (
          // eslint-disable-next-line i18next/no-literal-string
          <Button color="inherit" size="small" onClick={resetError}>
            Reload
          </Button>
        ) : null
      }
      sx={{ m: 1 }}
      data-testid="default-error-fallback"
    >
      <AlertTitle>{title}</AlertTitle>
      <pre>{message}</pre>
      {/* Print Error stacktrace during the development */}
      {isDev ? <pre>{componentStack}</pre> : null}
    </Alert>
  );
}

export function UtfErrorBoundary({
  children,
  ...props
}: PropsWithChildren<Sentry.ErrorBoundaryProps>): JSX.Element {
  return createElement(
    Sentry.ErrorBoundary,
    { fallback: DefaultErrorFallback, ...props },
    children as ReactNode
  );
}

export default UtfErrorBoundary;
