import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, LinearProgress } from "@mui/material";

import { useInject } from "@hooks";

import { useConsumption } from "./Consumption.store";
import { FetchRangeSelector } from "./FetchRangeSelector";
import { FilterBar } from "./FilterBar";
import { MeterData } from "./MeterChartLines";
import { SignatureDiagram } from "./SignatureDiagram";
import { DurationDiagram } from "./SortedMetrics";

export const ConsumptionOverview = observer(() => {
  const consumption = useConsumption();
  const { ui } = useInject("networks", "ui");
  const { t } = useTranslation(["extendView"]);

  const title = ui.is_subsummary_open
    ? t("text_substation_meter_data")
    : t("text_cluster_meter_data");

  return (
    <Box p={4} pb={0} mb={4}>
      <Box>
        <FetchRangeSelector title={title} />
      </Box>
      <Box>
        {!consumption.hasPartialData && <LinearProgress />}

        <MeterData />

        <SignatureDiagram />

        <DurationDiagram />
      </Box>
      {/* Filter Bar | Interval, Speed Options and Date Ranges */}
      <FilterBar />
    </Box>
  );
});

ConsumptionOverview.displayName = "ConsumptionOverview";
