import { COLUMN_SECTION } from "./constants";

const INSTALLATION_COLUMNS = {
  secondary_id: {
    label: "text_secondary_substation_id",
    info: "",
    section: COLUMN_SECTION.customer,
  },
};

export default INSTALLATION_COLUMNS;
