import { Suspense } from "react";
import { observer } from "mobx-react";

import { logger } from "@core/logger";
import { useInject } from "@hooks";
import { LoaderScreen } from "@shared/LoaderScreen";
import withErrorBoundary from "@shared/ui/withErrorBoundary";

import { routes } from "../../routes";
import { Layout } from "./Layout";

logger.getSubLogger({ name: "router" });

function RouteContentLoader() {
  return <LoaderScreen />;
}

function PageLoader() {
  return <LoaderScreen fullScreen />;
}

export const RouteContent = observer(() => {
  const { routerStore, networks } = useInject("routerStore", "networks");
  const { routeName } = routerStore.routerState;
  const routeData = routes[routeName];
  let RoutePageComponent = routeData?.component || PageLoader;

  if (!routeData) {
    if (routeName !== "__initial__") {
      logger.debug(
        `No route mapping was found for "${routeName}". Available routes: ${Object.keys(
          routes
        ).toString()}`
      );
    }

    return <PageLoader />;
  }

  if (!RoutePageComponent) throw new Error(`No "component" mapping was found for "${routeName}"`);

  // Show the loader during network changes
  if (!networks.ready && !routeData?.options?.bare) RoutePageComponent = RouteContentLoader;

  /*
    Top-level error boundary to avoid full-page/white-screen crashes.
    In case of unhandled error, this boundary will protect top and side navigations.
  */
  const SafeRoutePageComponent = withErrorBoundary(RoutePageComponent);

  return (
    <Layout>
      <Suspense fallback={<RouteContentLoader />}>
        <SafeRoutePageComponent />
      </Suspense>
    </Layout>
  );
});
