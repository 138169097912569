import { action, flow, makeObservable, observable } from "mobx";

import { CODES } from "@conf/errors";

import { SCREEN } from "./constants";

const default_resources = () => ({ network: [], cluster: [], substation: [] });

function get_resources_from_policies(policies) {
  const resources = default_resources();
  const attrs = ["network", "cluster", "substation"];
  for (const pol of policies) {
    for (const attr of attrs) {
      if (pol[`${attr}s`]) {
        for (const r of pol[`${attr}s`]) {
          resources[attr].push(r);
        }
      }
    }
  }
  return resources;
}

class ProfileStore {
  profile = {};
  usergroups = [];
  policies = [];
  currentGroup = null;
  currentUser = null;
  currentPolicy = null;
  currentPolicyDetails = null;
  all_resources = default_resources();

  tab = SCREEN.profile;
  leftPane = null;
  rightPane = null;
  pLeftPane = null;
  pRightPane = null;

  constructor(parent) {
    makeObservable(this, {
      profile: observable,
      usergroups: observable,
      policies: observable,
      currentGroup: observable,
      currentUser: observable,
      currentPolicy: observable,
      currentPolicyDetails: observable,
      all_resources: observable,
      tab: observable,
      leftPane: observable,
      rightPane: observable,
      pLeftPane: observable,
      pRightPane: observable,
      changeTab: action.bound,
      selectGroup: action.bound,
      selectPolicy: action.bound,
      selectUser: action.bound,
      openAddUserToGroup: action.bound,
      openEditGroupUser: action.bound,
      closeAddUserToGroup: action.bound,
      opencreateNewGroup: action.bound,
      openEditGroup: action.bound,
      openCreateNewPolicy: action.bound,
      closeCreateNewPolicy: action.bound,
      closeNewGroup: action.bound,
      closeUserEdit: action.bound,
    });

    this.parent = parent;
  }

  refreshUserGroups = flow(function* () {
    const ret = yield this.parent.utfapi.getApiResourceList({
      source: "mdslv4",
      pathname: "groups_list",
      resource_id: null,
    });
    this.usergroups = ret;

    if (this.currentGroup === null) {
      if (ret.length > 0) {
        this.currentGroup = ret[0];
      }
    } else {
      const prev = ret.filter((g) => g.uid === this.currentGroup.uid);
      if (prev.length === 0) {
        this.currentGroup = ret[0];
      } else {
        this.currentGroup = prev[0];
      }
    }
  });

  refreshUserPolicies = flow(function* () {
    this.all_resources = default_resources();
    const ret = yield this.parent.utfapi.getApiResourceList({
      pathname: "policy_list",
      resource_id: null,
    });
    const policy_uids = ret.map((r) => r.uid);
    const policy_det_promises = [];
    for (const pol of policy_uids) {
      policy_det_promises.push(
        this.parent.utfapi.getApiResource({
          pathname: "policy_detail",
          resource_id: pol,
        })
      );
    }
    const all_policy_details = yield Promise.all(policy_det_promises);
    this.all_resources = get_resources_from_policies(all_policy_details);
    this.policies = ret;
  });

  refreshUserProfile = flow(function* () {
    this.profile = yield this.parent.utfapi.getUserProfile();
  });

  changeTab(tab, leftPane = null, rightPane = null) {
    this.tab = tab;
    if (leftPane) {
      this.leftPane = leftPane;
    }
    if (rightPane) {
      this.rightPane = rightPane;
    }
  }

  selectGroup(gp) {
    this.currentGroup = gp;
  }

  selectPolicy(p) {
    this.currentPolicy = p;
    this.currentPolicyDetails = [];
  }

  selectUser(u) {
    this.currentUser = u;
  }

  openAddUserToGroup() {
    this.rightPane = SCREEN.group_attach_user;
  }

  openEditGroupUser(u) {
    this.currentUser = u;
    this.rightPane = SCREEN.group_attach_user;
  }

  closeAddUserToGroup() {
    this.rightPane = null;
    this.currentUser = null;
  }

  opencreateNewGroup() {
    this.leftPane = SCREEN.create_group;
    this.rightPane = null;
  }

  openEditGroup() {
    this.leftPane = SCREEN.group_update;
  }

  openCreateNewPolicy() {
    this.pLeftPane = SCREEN.policy_create;
  }

  closeCreateNewPolicy() {
    this.pLeftPane = null;
  }

  closeNewGroup() {
    this.leftPane = null;
    this.rightPane = null;
  }

  closeUserEdit() {
    this.rightPane = null;
    this.currentUser = null;
  }

  attachUserToGroup = flow(function* (user) {
    try {
      let detail;
      try {
        detail = yield this.parent.utfapi.getApiResource({
          pathname: "user_detail",
          resource_id: user.name,
        });
      } catch (err) {
        console.log(err);
        this.parent.app.gotError(CODES.bad_request, { msg: "user not found" });
        this.parent.notifications.error("user not found");
        return;
      }
      user.uid = detail.uid;
      const created = yield this.parent.utfapi.createApiResource({
        pathname: "group_user_detail",
        pathargs: {
          group_uid: this.currentGroup.uid,
          user_uid: user.uid,
        },
        data: {
          management_level: user.management_level,
          resource_level: user.resource_level,
        },
      });
      if (created) {
        console.log("created", created, "user", user);
        this.currentUser = user;
        this.parent.notifications.success("User added to group");
        yield this.refreshUserGroups();
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to add user to group",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
    }
  });

  updateUserToGroup = flow(function* (user) {
    try {
      const created = yield this.parent.utfapi.updateApiResource({
        pathname: "group_user_detail",
        pathargs: {
          group_uid: this.currentGroup.uid,
          user_uid: user.id,
        },
        data: {
          management_level: user.management_level,
          resource_level: user.resource_level,
        },
      });
      if (created) {
        this.currentUser = user;
        yield true;
        this.rightPane = SCREEN.group_users;
        yield this.refreshUserGroups();
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to update user for group",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
    }
  });

  createNewGroup = flow(function* (gp) {
    try {
      const created = yield this.parent.utfapi.createApiResource({
        pathname: "groups_list",
        data: {
          management_level: gp.management_level || 4,
          resource_level: gp.resource_level || 4,
          name: gp.name,
        },
      });
      if (created) {
        yield this.refreshUserGroups();
        const newgroupVals = this.usergroups.filter((f) => f.uid === created.uid);
        if (newgroupVals.length > 0) {
          this.currentGroup = newgroupVals[0];
          this.leftPane = SCREEN.group_update;
          this.rightPane = null;
        } else {
          this.currentGroup = created;
        }
        yield created.data;
      } else {
        this.parent.app.gotError(CODES.bad_request, { msg: "unable to add new group", hint: null });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to add new group",
        hint: err.msg,
      });
    }
  });

  deleteGroup = flow(function* ({ group_uid }) {
    try {
      const deleted = yield this.parent.utfapi.deleteApiResource({
        pathname: "group_detail",
        pathargs: { resource_id: group_uid },
      });
      if (deleted) {
        this.currentGroup = null;
        yield this.refreshUserGroups();
        this.leftPane = SCREEN.group_list;
        return null;
      } else {
        this.parent.app.gotError(CODES.bad_request, { msg: "unable to delete group", hint: null });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, { msg: "unable to delete group", hint: err.msg });
    }
  });

  createNewPolicy = flow(function* (pol) {
    try {
      const created = yield this.parent.utfapi.createApiResource({
        pathname: "group_policies",
        pathargs: { group_uid: pol.group_uid },
        data: {
          resource_level: pol.resource_level || 1,
          name: pol.name,
        },
      });
      if (created) {
        yield this.refreshUserPolicies();
        this.currentPolicy = created;
        yield created.data;
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to add new policy",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to add new policy",
        hint: err.msg,
      });
    }
  });

  addPolicyToGroup = flow(function* ({ group_uid, policy_uid }) {
    try {
      yield this.parent.utfapi.createApiResource({
        pathname: "group_policy_detail",
        pathargs: { group_uid, policy_uid },
      });
      //yield this.refreshUserGroups()
      yield this.refreshUserPolicies();
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to add attach policy",
        hint: err.msg,
      });
    }
  });

  removePolicyFromGroup = flow(function* ({ group_uid, policy_uid }) {
    try {
      const created = yield this.parent.utfapi.deleteApiResource({
        pathname: "group_policy_detail",
        pathargs: { group_uid, policy_uid },
      });
      if (created) {
        yield this.refreshUserPolicies();
      } else {
        this.parent.app.gotError(CODES.bad_request, { msg: "unable to detach policy", hint: null });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to add detach policy",
        hint: err.msg,
      });
    }
  });

  getPolicyDetails = flow(function* () {
    if (!this.currentPolicy?.uid) {
      return;
    }
    try {
      const detail = yield this.parent.utfapi.getApiResource({
        pathname: "policy_detail",
        resource_id: this.currentPolicy.uid,
      });
      if (detail) {
        this.currentPolicyDetails = detail;
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to get policy resources",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to get policy resources",
        hint: err.msg,
      });
    }
  });

  deletePolicyResource = flow(function* ({ policy_uid, resource_type, resource_uid }) {
    try {
      const done = yield this.parent.utfapi.deleteApiResource({
        pathname: "policy_resources",
        pathargs: { policy_uid, resource_type, resource_uid },
      });
      if (done) {
        yield this.getPolicyDetails();
        return true;
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to delete policy resources",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to delete policy resources",
        hint: err.msg,
      });
    }
  });

  addResourceToPolicy = flow(function* ({ policy_uid, resource_type, resource_uid }) {
    try {
      const done = yield this.parent.utfapi.createApiResource({
        pathname: "policy_resources",
        pathargs: { policy_uid, resource_type, resource_uid },
      });
      if (done) {
        yield this.getPolicyDetails();
        return true;
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to add policy resources",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to add policy resources",
        hint: err.msg,
      });
    }
  });

  deletePolicy = flow(function* ({ policy_uid }) {
    try {
      const done = yield this.parent.utfapi.deleteApiResource({
        pathname: "policy_detail",
        pathargs: { resource_id: policy_uid },
      });
      if (done) {
        this.currentPolicyDetails = null;
        yield this.refreshUserPolicies();
        return true;
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to delete policy ",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
      this.parent.app.gotError(CODES.bad_request, {
        msg: "unable to delete policy ",
        hint: err.msg,
      });
    }
  });

  removeUserFromGroup = flow(function* (user) {
    try {
      const created = yield this.parent.utfapi.deleteApiResource({
        pathname: "group_user_detail",
        pathargs: {
          group_uid: this.currentGroup.uid,
          user_uid: user.id,
        },
      });
      if (created) {
        this.currentUser = null;
        yield true;
        yield this.refreshUserGroups();
        this.rightPane = SCREEN.group_users;
      } else {
        this.parent.app.gotError(CODES.bad_request, {
          msg: "unable to remove user for group",
          hint: null,
        });
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export default ProfileStore;
