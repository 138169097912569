import { FC, ReactElement } from "react";
import { observer } from "mobx-react";
import { AppBar, Box, Toolbar, useTheme } from "@mui/material";

import { APP_BAR_HEIGHT, DRAWER_WIDTH } from "@conf/ui_constants";
import { PageTitle } from "@components/app/PageTitle";
import SubHeader from "@components/ui/SubHeader";
import { useInject } from "@hooks";
import Banner from "@shared/banner";

import { routes } from "../../routes";
import { PageAppBar } from "./PageAppBar";
import { PageSetting } from "./PageSetting/PageSetting";
import { ServiceWorkerPrompt } from "./ServiceWorkerPrompt";
import { SidebarDrawerManager } from "./SidebarDrawerManager";

type LayoutProps = {
  children: ReactElement;
};

export const Layout = observer<FC<LayoutProps>>(({ children }) => {
  const theme = useTheme();
  const { ui, routerStore } = useInject("ui", "routerStore");
  const { routeName } = routerStore.routerState;
  const { title = "", pageIcon = null, options } = routes[routeName] || {};
  const { filterAffects, bare, wikiUrl } = options ?? {};

  // Do not render layout components if "bare" option is set to true
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (bare) return <>{children}</>;

  // Render the page content with layout components surrounding it
  return (
    <Box
      sx={{
        height: "100%",
        color: "primary.contrastText",
        maxHeight: "100vh",
      }}
    >
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "primary.dark",
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar sx={{ "&": { padding: 0 } }}>
          {/* Drawer top left */}
          <PageAppBar filterAffects={filterAffects} />

          {/* page title */}
          <PageTitle title={title} pageIcon={pageIcon} onClick={() => ui.sideNavToggle()} />

          {/* Setting top right */}
          <PageSetting filterAffects={filterAffects} />
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer Manager */}
      <SidebarDrawerManager />

      {/* Main/Route Content */}
      <Box
        component="main"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          color: "grey.800",
          paddingTop: `${APP_BAR_HEIGHT}px`,
          ...(ui.is_sidepagemodels_open
            ? { marginLeft: `${DRAWER_WIDTH}px`, overflowX: "auto" }
            : {}),
        }}
      >
        {/* Service Worker related notifications */}
        <ServiceWorkerPrompt />

        {/* Legacy Notifications */}
        <Banner />

        {/* Body */}
        <Box
          component="section"
          height="100%"
          overflow="auto"
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          <>
            {children}
            {/* Renderer for the Page level portals */}
            <Box id="utf-page-portal" />
          </>
        </Box>

        {/* Page specific controls & wiki */}
        <SubHeader id="utf-page-controls-portal" wikiUrl={wikiUrl} />
      </Box>
    </Box>
  );
});

Layout.displayName = "Layout";
