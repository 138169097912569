import { DateTime } from "luxon";

import { defaultTheme } from "@conf/utilifeed.theme";
import {
  TOOLBOX_OPTION_AVERAGE,
  TOOLBOX_OPTION_MAX,
  TOOLBOX_OPTION_MIN,
} from "@config/chartToolbox";
import { logger as baseLogger } from "@core/logger";
import {
  formatNumberForLocale,
  formatNumberForUnitMainLabel,
  numbersOnly,
  SI_PREFIXES,
} from "@core/utils";

import FUnitValueHtml from "../FunitValueHTML";

const logger = baseLogger.getSubLogger({ name: "consumption-util" });

const getAverageValues = (values) => values.reduce((a, b) => a + b, 0) / values.length || 0;

const getToolValue = (chart, type, seriesIndex) => {
  const series = chart.series[seriesIndex];
  const yData = (series?.processedYData || []).filter(numbersOnly);

  switch (type) {
    case TOOLBOX_OPTION_AVERAGE:
      return getAverageValues(yData);
    case TOOLBOX_OPTION_MIN:
      return series?.dataMin;
    case TOOLBOX_OPTION_MAX:
      return series?.dataMax;
    default:
      return 0;
  }
};

const addShapeLine = ({ seriesIndex, color, yAxis, type }) => ({
  stroke: color,
  type: "path",
  dashStyle: "LongDash",
  points: [
    function annotationPointStart({ chart }) {
      return {
        x: chart.xAxis[0].min,
        y: getToolValue(chart, type, seriesIndex),
        xAxis: 0,
        yAxis,
      };
    },
    function annotationPointEnd({ chart }) {
      return {
        x: chart.xAxis[0].max,
        y: getToolValue(chart, type, seriesIndex),
        xAxis: 0,
        yAxis,
      };
    },
  ],
});

const addLabel = ({
  seriesIndex,
  label,
  yPos,
  valueSuffix,
  selfCalculatedValue,
  yAxis,
  type,
  textColor,
}) => {
  return {
    y: yPos,
    x: 30,
    padding: 4,
    formatter() {
      const { chart } = this.series;
      const seriesId = chart.series[seriesIndex].userOptions.id;
      const dateMillis =
        chart.series[seriesIndex].xData[
          chart.series[seriesIndex].yData.indexOf(selfCalculatedValue)
        ];
      return `<span data-testid="${seriesId}-${type}">${label}: ${formatNumberForLocale(
        selfCalculatedValue
      )} ${valueSuffix}
    </br>
    ${
      chart.series[seriesIndex].userOptions.type !== "scatter" && dateMillis
        ? DateTime.fromMillis(dateMillis).toFormat("yyyy-MM-dd HH:mm")
        : ""
    }
    </span>`;
    },
    style: {
      color: textColor,
      fontSize: "11px",
      fontFamily: defaultTheme.typography.fontFamily,
    },
    point({ chart }) {
      const point = {
        x: 0,
        y: 0,
        xAxis: 0,
        yAxis,
      };
      point.x =
        chart.series[seriesIndex].xData[
          chart.series[seriesIndex].yData.indexOf(selfCalculatedValue)
        ];
      if (type === TOOLBOX_OPTION_AVERAGE) {
        point.y = selfCalculatedValue;
        point.x = chart.xAxis[0].min;
      } else if (type === TOOLBOX_OPTION_MIN) {
        // These are to ensure the value renders, even when there is a lower resolution being used
        point.y = chart.series[seriesIndex].dataMin;
      } else if (type === TOOLBOX_OPTION_MAX) {
        point.y = chart.series[seriesIndex].dataMax;
      }
      return point;
    },
  };
};

function createToolboxAnnotation({
  seriesIndex,
  yAxis,
  selfCalculatedValue,
  valueSuffix,
  type,
  label,
  yPos,
  color,
  textColor,
  isRefSeries,
}) {
  // This is the part that shows the line and the label
  return {
    labelOptions: {
      allowOverlap: true,
      backgroundColor: color,
      opacity: 1,
      borderColor: color,
    },
    shapes: [
      addShapeLine({
        seriesIndex,
        color,
        yAxis,
        type,
      }),
    ],
    labels: [
      addLabel({
        seriesIndex,
        type,
        label: isRefSeries ? `Ref ${label}` : label,
        yPos,
        valueSuffix,
        selfCalculatedValue,
        yAxis,
        textColor,
      }),
    ],
  };
}

function populateRowsFromSeries(series) {
  let seriesWithData = [];
  try {
    series.forEach((serie, seriesIndex) => {
      if (!serie.timeStamp) return;

      if (!seriesWithData[seriesIndex]) seriesWithData[seriesIndex] = [];

      if (!serie.visible) return;

      const isObject = serie.data[0] !== null && typeof serie.data[0] === "object";
      serie.timeStamp.forEach((x, xIndex) => {
        let y = serie.data[xIndex];
        if (isObject) {
          y = y?.y;
        }

        if (!numbersOnly(y)) return;

        seriesWithData[seriesIndex].push(y);
      });
    });
  } catch (e) {
    logger.error("Cannot generate timeseries data for calculations!");
    seriesWithData = [];
  }
  return seriesWithData;
}

const optionsToAnnotate = [TOOLBOX_OPTION_MIN, TOOLBOX_OPTION_MAX, TOOLBOX_OPTION_AVERAGE];
export function generateToolboxAnnotations({ series, options }) {
  const aciveOptionsToAnnotate = options.filter((option) => optionsToAnnotate.includes(option));
  if (!aciveOptionsToAnnotate.length) return [];

  const annotations = [];
  // Create "timestamp" to "value" array of series
  const rowsOfSeries = populateRowsFromSeries(series);

  rowsOfSeries.forEach((yAxisData, seriesIndex) => {
    const xAxis = series[seriesIndex].timeStamp;
    const { tooltip, yAxis } = series[seriesIndex];
    const { valueSuffix } = tooltip;
    const labelColors = {
      color: series[seriesIndex].color,
      textColor: series[seriesIndex].textColor || "white",
    };
    const isRefSeries = series[seriesIndex]?.ref === true;

    if (yAxisData?.length <= 0) return;

    aciveOptionsToAnnotate.forEach((option) => {
      switch (option) {
        // MINIMUM LABEL & REFERENCE LINE
        case TOOLBOX_OPTION_MIN:
          annotations.push(
            createToolboxAnnotation({
              seriesIndex,
              yAxis,
              selfCalculatedValue: Math.min(...yAxisData),
              valueSuffix,
              type: TOOLBOX_OPTION_MIN,
              label: "Min",
              yPos: 20,
              isRefSeries,
              ...labelColors,
            })
          );
          break;

        // MAXIMUM LABEL & REFERENCE LINE
        case TOOLBOX_OPTION_MAX:
          annotations.push(
            createToolboxAnnotation({
              seriesIndex,
              yAxis,
              selfCalculatedValue: Math.max(...yAxisData),
              valueSuffix,
              type: TOOLBOX_OPTION_MAX,
              label: "Max",
              yPos: -1,
              isRefSeries,
              ...labelColors,
            })
          );
          break;

        // AVERAGE LABEL & REFERENCE LINE
        case TOOLBOX_OPTION_AVERAGE:
          annotations.push(
            createToolboxAnnotation({
              seriesIndex,
              yAxis,
              selfCalculatedValue: getAverageValues(yAxisData),
              valueSuffix,
              type: TOOLBOX_OPTION_AVERAGE,
              label: "Avg",
              yPos: 9,
              isRefSeries,
              ...labelColors,
            })
          );
          break;
        default:
          break;
      }
    });
  });

  return annotations;
}

export const INCLUDES_UNIT = ["kW", "SEK/kWh", "NOK/kWh", "kWh"];

/**
 * the tooltip number shown should correspond to a prefix 1000x lower than the title prefix.
 * E.g. if the title prefix is GWh, the hover prefix is MWh.
 *
 * The lowest allowed prefix is k (e.g. kW, kWh)
 * @param {number} value
 * @param {string} unit
 */
export const formatTooltipValue = (value, unit) => {
  if (INCLUDES_UNIT.includes(unit)) {
    const [, , mainPrefix, prefixUnit] = formatNumberForUnitMainLabel(value, unit);
    const prefixOrder = Object.keys(SI_PREFIXES);
    const prefixIndex = prefixOrder.indexOf(mainPrefix);
    const mainValue = value.toString().split(".")[0];
    let mainUnit = "";

    if (prefixIndex > 0) {
      mainUnit = prefixOrder[prefixIndex - 1];
    }

    // The lowest allowed prefix is k
    if (mainUnit === "") {
      mainUnit = "k";
    }
    return `${mainValue} ${mainUnit}${prefixUnit}`;
  }
  return FUnitValueHtml(value, unit);
};

export const formatYAxisLabel = (value, maxValue, yUnit, isLast) => {
  const yAxisUnitLabel = (displayUnit, lastTickValue) =>
    `<span><span style="font-size:13px; color:${defaultTheme.palette.secondary.main};font-family:${defaultTheme.typography.body2.fontFamily}">${displayUnit}</span><br /><span style="float: right;">${lastTickValue}</span></span>`;

  let scaleExponent = 0;
  let scaledPrefix = "";
  let scaledUnit = yUnit;
  let scaledValue = value;

  if (maxValue && INCLUDES_UNIT.includes(yUnit)) {
    const [, , prefix, unit, scaledExp] = formatNumberForUnitMainLabel(maxValue, yUnit);
    scaledUnit = unit;
    scaledPrefix = prefix;
    scaleExponent = scaledExp; // This is wrong, function should return scaling used
    scaledValue = value / 10 ** scaleExponent;
  }

  return isLast
    ? yAxisUnitLabel(`${scaledPrefix}${scaledUnit}`, scaledValue)
    : `<span>${scaledValue}</span>`;
};
