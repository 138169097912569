/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Grow,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
} from "@mui/material";
import { styled } from "@mui/styles";
import clsx from "clsx";

import { TOOLBOX_DEFAULT_OPTIONS } from "@config/chartToolbox";
import ToolIcon from "@icons/Tool";

const PREFIX = "ChartToolbox";

const classes = {
  ButtonDropDown: `${PREFIX}-ButtonDropDown`,
  menuItem: `${PREFIX}-menuItem`,
  checkboxRoot: `${PREFIX}-checkboxRoot`,
  checked: `${PREFIX}-checked`,
};

const Root = styled("div")(({ theme }: { theme: Theme }) => ({
  [`& .${classes.ButtonDropDown}`]: {
    width: theme.spacing(4),
    minWidth: theme.spacing(4),
    border: "none !important",
  },

  [`& .${classes.menuItem}`]: {
    gap: theme.spacing(2),
  },

  [`& .${classes.checkboxRoot}`]: {
    "&$checked": {
      "& > svg": {
        fill: "white",
        background: theme.palette.secondary.main,
        borderRadius: theme.spacing(0.5),
        width: theme.spacing(1.75),
        height: theme.spacing(1.75),
      },
    },
    color: theme.palette.primary.main,
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    "& > svg": {
      strokeWidth: 2,
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },

  // without this the checkboxRoot class wont apply
  [`& .${classes.checked}`]: {},
}));

type Props = PropsWithChildren<{
  options: { [key: string]: string };
  values: string[];
  setValues: (values: string[]) => void;
  disabled?: boolean;
  className?: string;
}>;

// eslint-disable-next-line import/no-default-export
export default function ChartToolbox({
  options = TOOLBOX_DEFAULT_OPTIONS,
  disabled = false,
  values,
  setValues,
  className = "",
  ...restProps
}: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef();
  const { t } = useTranslation(["_action"]);

  function toggleMenuOpen() {
    setMenuOpen((current) => !current);
  }

  function handleClose() {
    setMenuOpen(false);
  }

  function handleSelectItem(value: string) {
    if (!values.includes(value)) {
      // @ts-expect-error legacy
      setValues((prev) => [...prev, value]);
    } else {
      // @ts-expect-error legacy
      setValues((current) => current.filter((item) => item !== value));
    }
  }

  return (
    <Root>
      <Button
        ref={anchorRef}
        key="button"
        onClick={() => toggleMenuOpen()}
        color={menuOpen ? "secondary" : "primary"}
        variant="contained"
        aria-controls={menuOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={clsx(classes.ButtonDropDown, className)}
        disabled={disabled}
        {...restProps}
      >
        <ToolIcon />
      </Button>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        key="popper"
        disablePortal
        placement="bottom-end"
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "bottom top" : "center top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => handleClose()}>
                {/* @ts-expect-error legacy */}
                <MenuList sx={{ maxHeight: "400px", overflowY: options.size >= 15 && "scroll" }}>
                  {Object.entries(options).map(([key, label]) => (
                    <MenuItem
                      key={key}
                      onClick={() => handleSelectItem(key)}
                      className={classes.menuItem}
                      data-testid={`${restProps["data-testid"] || "dropdown-option"}__${key}`}
                    >
                      <ListItemText primary={t(label)} />
                      <Checkbox
                        checked={values.includes(key)}
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checked,
                        }}
                        size="small"
                        disableRipple
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Root>
  );
}
