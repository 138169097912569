import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useInject } from "@hooks";

const LogoutPage = () => {
  const { t } = useTranslation(["_common"]);

  const { session } = useInject("session");

  // On load, clear the session
  // wait
  session.logout();

  const handleLogin = () => {
    session.login();
  };

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h1">{t("logoutPage.title")}</Typography>
      <Typography variant="body1">{t("logoutPage.message")}</Typography>
      <Button onClick={handleLogin}>{t("logoutPage.login")}</Button>
    </Box>
  );
};

export default LogoutPage;
