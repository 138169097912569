import { toast } from "react-toastify";
import { autorun, makeAutoObservable, runInAction, toJS } from "mobx";

import { RootStoreType as RootStore } from "@stores/root_store";

type SnackbarType = "success" | "info" | "warning" | "error";

type Snackbar = {
  type: SnackbarType;
  msg: string;
  id: string;
  options: object;
};

type SnackbarOptions = {
  id?: string;
  autoClose?: number;
};

export default class NotificationStore {
  parent: RootStore;

  snackbars: Snackbar[];

  types: SnackbarType[] = ["success", "info", "warning", "error"];

  constructor(parent: RootStore) {
    this.parent = parent;
    makeAutoObservable(
      this,
      {
        types: false,
      },
      {
        autoBind: true,
      }
    );
    autorun(() => this.displaySnackbar());
    this.snackbars = [];
  }

  /*
  / Public Methods
  */

  /*
  / This function displays a info notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  info(msg: string, options = {}) {
    return this.addSnackbar("info", msg, options);
  }

  /*
  / This function displays a success notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  success(msg: string, options = {}) {
    return this.addSnackbar("success", msg, options);
  }

  /*
  / This function displays an error notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  error(msg: string, options = { autoClose: 10000 }) {
    return this.addSnackbar("error", msg, options);
  }

  /*
  / This function displays a warning notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  warning(msg: string, options = { autoClose: 8000 }) {
    this.addSnackbar("warning", msg, options);
  }

  /*
  / Internal Methods
  */

  /*
  / This function adds a notification to the store
  / @param {string} type - The type of notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  addSnackbar(type: SnackbarType, msg: string, options: SnackbarOptions = {}) {
    if (!this.types.includes(type)) {
      throw new Error("Invalid notification type");
    }
    const id = options.id || Math.random().toString(36);
    this.snackbars.push({
      type,
      msg,
      // Random id
      id,
      options,
    });
    return id;
  }

  /*
  / If a notification is added to the store, this function will display it
  */
  displaySnackbar() {
    if (this.snackbars?.length > 0) {
      const notification = toJS(this.snackbars[0]);
      toast[notification.type](notification.msg, {
        toastId: notification.id,
        ...notification.options,
      });
      this.removeSnackbar(notification.id);
    }
  }

  dismissSnackbar(id: string | "all") {
    toast.dismiss(id);
    this.removeSnackbar(id);
  }

  /*
  / This function removes a notification from the store
  / @param {number} id - The id of the notification to remove
  */
  removeSnackbar(id: string) {
    if (id) {
      runInAction(() => {
        this.snackbars = this.snackbars.filter((k) => k.id !== id);
      });
    }
  }

  /*
  / This function removes all snackbars from the store
  */
  removeAllSnackbars() {
    this.snackbars = [];
  }
}
