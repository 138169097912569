/* eslint-disable sonarjs/no-duplicate-string, react/jsx-props-no-spreading */
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/styles";
import { faLockKeyhole } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeSvgIcon } from "./FontAwesomeSvgIcon";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    minHeight: 40,
    justifyContent: "center",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 1px 3px 0 #00000029",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&:focus": {
      color: theme.palette.secondary.main,
    },
    "&:disabled": {
      color: "grey.400",
    },
    "&.Mui-selected": {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
      "&:focus": {
        color: "white",
      },
    },
  }),
  { name: "UtfTab" }
);

const LockIcon = () => {
  return (
    <Box
      data-testid="tab-lock-icon"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "0px !important",
        py: 0.5,
      }}
    >
      <FontAwesomeSvgIcon icon={faLockKeyhole} sx={{ fontSize: 13 }} />
    </Box>
  );
};

export function UtfTab({ isLocked = false, ...props }) {
  const baseProps = props;
  if (isLocked) {
    baseProps.icon = <LockIcon />;
  }
  return <StyledTab data-testid={`tab-${props.value}`} {...baseProps} />;
}

export const UtfTabs = styled((props) => <Tabs {...props} />)(
  () => ({
    minHeight: 40,
  }),
  { name: "UtfTabs" }
);
