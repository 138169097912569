import { DateTime } from "luxon";

import type { ApiError } from "@customTypes/generic";
import type {
  OptComponent,
  OptConfig,
  SavedSimulation,
} from "@customTypes/production/optimization";

import { DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS } from "@conf/constants";
import URLS from "@conf/urls";

import { ConfigCategory } from "../pages/Optimization/Opt.const";
import type { RootStoreType } from "./root_store";

// The two functions below are used to create errors. The first one is used when the API returns
// an error with a message and a details field. The second one is used when a generic error is
// thrown, and we want to add a message to it.
const ApiDetailError = function (result: { result: ApiError }): Error {
  if (result.result.error) {
    return Error(`${result.result.error}`);
  }
  return Error(`${JSON.stringify(result)}`);
} as any;

const ApiGenericError = function (obj: ApiError): Error {
  // If the error is a SyntaxError, it means that the response was not JSON
  if (obj instanceof SyntaxError) {
    return Error("Something went wrong");
  }
  // We dont want to throw ApiGenericError if the error is already an ApiError
  if (obj instanceof Error) {
    return Error(obj.message);
  }
  return Error(obj.error);
} as any;

function urlWithParams(nurl: string, params: Record<string, string>) {
  const url = new URL(nurl);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  return url;
}

function StrRepr(msg: string | object) {
  if (typeof msg === "object") {
    return JSON.stringify(msg);
  }
  return msg;
}

function parseCategory(official: boolean, default_conf: boolean) {
  if (default_conf) return ConfigCategory.DEFAULT;
  if (official) return ConfigCategory.OFFICIAL;
  return ConfigCategory.PRIVATE;
}

const contentType = "application/json";

export default class OptApi {
  parent: RootStoreType;

  processErrors(err: any, msg: string | object) {
    let errorMessage = "";
    if (err.response) {
      if (err.response.status === 401) {
        this.parent.session.logout();
        return;
      }
      errorMessage = StrRepr(msg);
    } else {
      errorMessage = StrRepr(err.message);
    }
    this.parent.notifications.error(errorMessage);
  }

  constructor(parent: RootStoreType) {
    this.parent = parent;
  }

  async startSimulation(options: any) {
    const headers = await this.parent.session.authHeaders();
    const postData = {
      name: "optimization",
      params: options,
    };
    const baseUrl = `${URLS.baseOptimization}/jobs/optimization`;
    const res = await fetch(urlWithParams(baseUrl, {}), {
      method: "post",
      headers,
      body: JSON.stringify(postData),
    });

    const { job_id: jobId } = await res.json();
    return jobId;
  }

  async getJobStatus(jobId: string) {
    const headers = await this.parent.session.authHeaders();
    const baseUrl = `${URLS.baseOptimization}/jobs/optimization/${jobId}`;
    const res = await fetch(urlWithParams(baseUrl, {}), {
      headers,
    });
    // If not ok, throw an error
    if (!res.ok) {
      if (res.status === 404) {
        throw new ApiGenericError({
          error: "Not found",
          message: `Simulation not found: ${res.status}`,
        });
      }
      throw new ApiGenericError({
        error: res.statusText,
        message: `Error when fetching simulation status: ${res.status}`,
      });
    }
    return res.json();
  }

  async getSimulationResult(jobId: string) {
    const headers = await this.parent.session.authHeaders();
    const baseUrl = `${URLS.baseOptimization}/jobs/optimization/${jobId}/result`;
    const simRes = await fetch(urlWithParams(baseUrl, {}), { headers });
    if (!simRes.ok) {
      throw new ApiGenericError({
        error: simRes.statusText,
        message: `Error when fetching simulation result: ${simRes.status}`,
      });
    }
    return (
      simRes
        .text()
        // Replace NaN with null, but only in value arrays
        .then((text: string | null) => {
          if (text) {
            return JSON.parse(text.replace(/(\s*)NaN(\s*)/g, "$1null$2"));
          }
          return null;
        })
    );
  }

  async saveSimulationResult(networkId: string, jobId: string, simulation_name: string) {
    const headers = await this.parent.session.authHeaders();
    const baseUrl = `${URLS.baseOptimization}/api/network/${networkId}/task/${jobId}/result/save`;
    const simRes = await fetch(urlWithParams(baseUrl, {}), {
      method: "post",
      headers,
      // TODO: Add a name field to the save simulation dialog
      body: JSON.stringify({
        simulation_name,
      }),
    });
    if (!simRes.ok) {
      throw new ApiGenericError({
        error: simRes.statusText,
        message: `Error when saving simulation: ${simRes.status}`,
      });
    }
    return simRes.json();
  }

  async getNetworkSettings(network_id: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.baseOptimization}/api/network/${network_id}/settings`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        return await res.json();
      }
      throw new ApiDetailError({
        result: await res.json(),
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getConfigurations(baseUrl: string, networkId: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${baseUrl}/config/network/${networkId}/configurations`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resultData = await res.json();
        // TODO: Just imagine this was typed - glorious!
        return resultData.map((c: any) => ({
          name: c.name,
          category: parseCategory(c.official, c.default),
          id: c.id,
          created: DateTime.fromISO(`${c.created_at}Z`),
          updated: DateTime.fromISO(`${c.last_modified}Z`),
          components: c.components ?? [],
          connection_matrix: c.connection_matrix ?? "",
          fetch_external_prices: c.fetch_external_prices ?? false,
          electricity_price_internal_use: c.electricity_price_internal_use ?? true,
          electricity_price_buy: c.electricity_price_buy ?? "",
          electricity_price_buy_addon: c.electricity_price_buy_addon,
          electricity_price_sell: c.electricity_price_sell ?? "",
          electricity_price_sell_addon: c.electricity_price_sell_addon,
          grid_losses: c.grid_losses,
        }));
      }
      throw new ApiDetailError({
        result: await res.json(),
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getOptConfigurations(networkId: string) {
    return this.getConfigurations(URLS.optimization, networkId);
  }

  async getScenarioAnalysisConfigurations(networkId: string) {
    return this.getConfigurations(URLS.scenarioAnalysis, networkId);
  }

  async createNewConfig(baseUrl: string, networkId: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${baseUrl}/config/network/${networkId}/configuration`;
      const res = await fetch(urlWithParams(url, {}), { method: "post", headers });
      if (res.ok) {
        return await res.json();
      }
      throw new ApiDetailError({
        result: await res.json(),
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async createNewOptConfig(networkId: string) {
    return this.createNewConfig(URLS.optimization, networkId);
  }

  async createNewScenarioAnalysisConfig(networkId: string) {
    try {
      return await this.createNewConfig(URLS.scenarioAnalysis, networkId);
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  /**
   * @param baseUrl
   * @param network_id
   * @param config_id
   */
  async copyConfig(baseUrl: string, networkId: string, sourceConfigId: number) {
    const headers = await this.parent.session.authHeaders();
    const url = `${baseUrl}/config/network/${networkId}/configuration/${sourceConfigId}/copy`;
    const res = await fetch(urlWithParams(url, {}), {
      method: "post",
      headers,
    });
    if (!res.ok) {
      throw new ApiGenericError({
        error: res.statusText,
        message: `Error when copying configuration: ${res.status ?? "Server error"}`,
      });
    }
    return res.json();
  }

  async copyOptConfig(networkId: string, sourceConfigId: number) {
    return this.copyConfig(URLS.optimization, networkId, sourceConfigId);
  }

  async copyScenarioAnalysisConfig(networkId: string, sourceConfigId: number) {
    return this.copyConfig(URLS.scenarioAnalysis, networkId, sourceConfigId);
  }

  /**
   * @param network_id
   * @param config_id
   */
  async deleteConfig(network_id: string, config_id: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}`;
      const res = await fetch(urlWithParams(url, {}), { method: "delete", headers });
      if (res.status !== 204) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when deleting config: ${res.status}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
    return null;
  }

  async getSavedSimulationsMinimal(
    networkId: string,
    is_scenario = false
  ): Promise<SavedSimulation> {
    const headers = await this.parent.session.authHeaders();
    try {
      let url = `${URLS.optimization}/config/network/${networkId}/saved-simulations/minimal`;
      if (is_scenario) {
        url = `${URLS.scenarioAnalysis}/config/network/${networkId}/saved-simulations/minimal`;
      }
      const res = await fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resJson = await res.json();
        // Turn created_at and updated_at into DateTime objects
        return resJson.map((sim: any) => ({
          ...sim,
          created_at: DateTime.fromISO(`${sim.created_at}Z`),
          updated_at: DateTime.fromISO(`${sim.updated_at}Z`),
        }));
      }
      throw new ApiDetailError({
        result: await res.json(),
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getSavedSimulationResults(networkId: string, simId: string): Promise<SavedSimulation> {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.baseOptimization}/api/network/${networkId}/saved-simulations/${simId}`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resJson = await res.json();
        // Turn created_at and updated_at into DateTime objects
        return {
          ...resJson,
          created_at: DateTime.fromISO(`${resJson.created_at}Z`),
          updated_at: DateTime.fromISO(`${resJson.updated_at}Z`),
        };
      }
      throw new ApiDetailError({
        result: await res.json(),
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async deleteSavedSimulation(networkId: string, simId: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.baseOptimization}/api/network/${networkId}/saved-simulations/${simId}`;
      const res = await fetch(urlWithParams(url, {}), { method: "delete", headers });
      // if 403
      if (res.status === 403) {
        throw new ApiGenericError({
          error: await res.text(),
          message: `Error when deleting simulation: ${res.status}`,
        });
      }
      if (res.status !== 204) {
        const body = await res.json();
        throw new ApiGenericError({
          error: body.error || res.statusText,
          message: `${body.message}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async updateSavedSimulation(networkId: string, simId: string, name: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.baseOptimization}/api/network/${networkId}/saved-simulations/${simId}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "put",
        body: JSON.stringify({ name }),
        headers,
      });
      if (res.status !== 204) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when updating simulation: ${res.status}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getNetworkComponents(network_id: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/components`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resultData = await res.json();
        return resultData.map((c: any) => ({
          id: c.component_id,
          type_id: c.component_type_id,
          type: c.type,
          label: c.label,
          flexibility: c.flexibility,
          name: c.name,
          color: c.color,
          properties: c.properties,
          events: [],
          sort_order: c.sort_order,
        }));
      }
      throw new ApiDetailError({
        result: await res.json(),
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getScenarioConfigComponents(networkId: string, configId: number) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/components`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resultData = await res.json();
        return resultData.map((c: any) => ({
          id: c.component_id,
          type_id: c.component_type_id,
          type: c.type,
          label: c.label,
          flexibility: c.flexibility,
          name: c.name,
          events: [],
          properties: c.properties,
          color: c.color,
        }));
      }
      throw new ApiDetailError({
        result: await res.json(),
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getComponentTypes(network_id: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component-types`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      const data = await res.json();
      if (res.ok) {
        return data;
      }
      throw new ApiDetailError({
        result: data,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getScenarioAnalysisComponentTypes() {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/components/types`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      const data = await res.json();
      if (res.ok) {
        return data;
      }
      throw new ApiDetailError({
        result: data,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async updateComponentsSortOrder(
    network_id: string,
    config_id: number,
    components: OptComponent[]
  ) {
    const headers = await this.parent.session.authHeaders();
    // Older configs might be missing a sort_order, so we need to set it to 0
    const setSortOrder = components.map((c) => ({
      ...c,
      sort_order: c.sort_order ?? 0,
    }));

    try {
      // /network/{network_id}/config/{config_id}/components
      const url = `${URLS.baseOptimization}/api/network/${network_id}/config/${config_id}/components`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(setSortOrder),
        headers,
      });
      if (!res.ok) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when updating component: ${res.status}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async createDeviationEvent(network_id: string, config_id: number, component_id: number) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/component/${component_id}/deviation_event`;
      const res = await fetch(urlWithParams(url, {}), { method: "post", headers });
      return await res.json();
    } catch (err: any) {
      console.log(err);
      throw new ApiGenericError(err);
    }
  }

  /**
   * Get deviation events for a component, with the property deviations
   * @param network_id
   * @param config_id
   * @param component_id
   * @returns {Promise<DeviationWithProperties[]>}
   *
   * @typedef DeviationWithProperties
   * @property {number} id
   * @property {number} config_id
   * @property {number} component_id
   * @property {string} start_date
   * @property {string} end_date
   * @property {string} name
   * @property {string} comment
   * @property {boolean} disabled
   * @property {string} last_modified
   * @property {DeviationProperty[]} property_deviations
   *
   * @typedef DeviationProperty
   * @property {string} technical_name
   * @property {string} value
   */
  async getEventsForComponent(network_id: string, config_id: number, component_id: number) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/component/${component_id}/events`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      const resultData = await res.json();
      if (res.ok) {
        return resultData.map((e: any) => ({
          id: e.id,
          config_id: e.config_id,
          component_id: e.component_id,
          name: e.name,
          comment: e.comment,
          disabled: e.disabled,
          start_date: DateTime.fromISO(e.start_date).toFormat(
            DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS
          ),
          end_date: e.end_date,
          last_modified: e.last_modified,
          deviations: e.property_deviations.map((p: any) => ({
            technical_name: p.technical_name,
            value: p.value,
          })),
        }));
      }
      throw new ApiDetailError({
        result: resultData,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async saveEvent(network_id: string, config_id: number, component_id: number, event: any) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/component/${component_id}/event/${event.id}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(event),
        headers,
      });
      if (!res.ok) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when saving event: ${res.status}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async deleteEvent(network_id: string, config_id: number, event: any) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/event/${event.id}`;
      const res = await fetch(urlWithParams(url, {}), { method: "DELETE", headers });
      if (!res.ok) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when deleting event: ${res.status}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async upsertDeviationProperty(
    network_id: string,
    event_id: string,
    property_id: string,
    value: any
  ) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/event/${event_id}/property/${property_id}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify({ value }),
        headers,
      });
      if (!res.ok) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when saving event: ${res.status}`,
        });
      }
      return await res.json();
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async deleteDeviationProperty(network_id: string, event_id: string, property_id: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/event/${event_id}/property/${property_id}`;
      const res = await fetch(urlWithParams(url, {}), { method: "DELETE", headers });
      console.log(res.ok);
      if (res.status !== 204) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when deleting event: ${res.status}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  /**
   * Get deviations for a configuration, without the property deviations
   * @param {uuid} network_id
   * @param {number} config_id
   * @returns {Promise<Deviation[]>}
   *
   * @typedef Deviation
   * @property {number} id
   * @property {number} config_id
   * @property {number} component_id
   * @property {string} start_date
   * @property {string} end_date
   * @property {string} name
   * @property {string} comment
   * @property {boolean} disabled
   * @property {string} last_modified
   */
  async getDeviationsForConfig(network_id: string, config_id: number) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/deviations`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      const resultData = await res.json();
      if (res.ok) {
        return resultData;
      }
      throw new ApiDetailError({
        result: resultData,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async upsertDefaultPropertyValue(
    network_id: string,
    comp_id: string,
    property_id: string,
    value: any
  ) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component/${comp_id}/property/${property_id}/default`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify({ value }),
        headers,
      });
      if (!res.ok) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when saving event: ${res.status}`,
        });
      }
      return await res.json();
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async getOperationalPlan(network_id: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/operational-plan`;
      const res = await fetch(urlWithParams(url, {}), { headers });
      if (!res.ok) {
        throw new ApiDetailError({
          result: await res.json(),
        });
      }
      const result = await res.json();
      if (result) {
        return {
          ...result,
          created_at: DateTime.fromISO(`${result.created_at}Z`),
        };
      }
      return null;
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async createOptimizationComponent(network_id: string, component: OptComponent) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify(component),
        headers,
      });
      if (res.status !== 201) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when creating component: ${res.status}`,
        });
      }
      return await res.json();
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async createScenarioAnalysisComponent(
    networkId: string,
    configId: string,
    component: OptComponent
  ) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/component`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify(component),
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      });
      if (res.status === 201) {
        return await res.json();
      }
      const body = await res.json();
      throw new ApiGenericError({
        error: body?.error || res.statusText,
        message: `Error when creating component: ${body?.message || res.status}`,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async updateScenarioAnalysisComponent(
    networkId: string,
    configId: string,
    component: OptComponent
  ) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/component/${component.id}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "PATCH",
        body: JSON.stringify(component),
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      });
      if (res.status === 200) {
        return await res.json();
      }
      throw new ApiGenericError({
        error: res.statusText,
        message: `Error when updating component: ${res.status}`,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async deleteScenarioAnalysisComponent(networkId: string, configId: string, componentId: number) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/component/${componentId}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "DELETE",
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      });
      if (res.status === 204) {
        return;
      }
      throw new ApiGenericError({
        error: res.statusText,
        message: `Error when deleting component: ${res.status}`,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async deleteScenarioAnalysisConfiguration(networkId: string, configId: string) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "DELETE",
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      });
      if (res.status === 204) {
        return;
      }
      throw new ApiGenericError({
        error: res.statusText,
        message: `Error when deleting component: ${res.status}`,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async saveScenarioAnalysisConfig(networkId: string, config: OptConfig) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${config.id}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "PATCH",
        body: JSON.stringify(config),
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      });
      if (res.status === 200) {
        return await res.json();
      }
      throw new ApiGenericError({
        error: res.statusText,
        message: `Error when saving config: ${res.status}`,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async saveOptConfig(networkId: string, config: OptConfig) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${networkId}/configuration/${config.id}`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(config),
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      });
      if (res.status === 200) {
        return await res.json();
      }
      const body = await res.json();
      throw new ApiGenericError({
        error: body.error || res.statusText,
        message: `${body.message || res.status}`,
      });
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async updateOptimizationComponent(network_id: string, component: OptComponent) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component`;
      const res = await fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(component),
        headers,
      });
      if (res.status !== 200) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when updating component: ${res.status}`,
        });
      }
      return await res.json();
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }

  async deleteOptimizationComponent(network_id: string, component_id: number) {
    const headers = await this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component/${component_id}`;
      const res = await fetch(urlWithParams(url, {}), { method: "delete", headers });
      if (res.status !== 204) {
        throw new ApiGenericError({
          error: res.statusText,
          message: `Error when deleting component: ${res.status}`,
        });
      }
    } catch (err: any) {
      throw new ApiGenericError(err);
    }
  }
}
