import { action, makeObservable, observable } from "mobx";

class SalesStore {
  resource_id = null;

  current_duration = "7";

  constructor(parent) {
    makeObservable(this, {
      resource_id: observable,
      current_duration: observable,
      changeCluster: action.bound,
      resetForNetwork: action.bound,
      selectDuration: action.bound,
    });

    this.parent = parent;
  }

  changeCluster(cluster) {
    this.resource_id = cluster;
  }

  resetForNetwork() {
    const defaultResourceId = this.parent.networks.allSubstationCluster;
    if (defaultResourceId !== this.resource_id) {
      this.resource_id = defaultResourceId;
    }
  }

  selectDuration(current_duration) {
    this.current_duration = current_duration;
  }
}

export { SalesStore };
