import type { FC } from "react";
import { createElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Paper } from "@mui/material";

import { logger } from "@core/logger";
import { getUrlParam, insertUrlParam, removeUrlParam } from "@core/utils";
import { useInject } from "@hooks";
import { UtfErrorBoundary } from "@shared/ui/ErrorBoundary";
import { UtfTab, UtfTabs } from "@shared/ui/UtfTab";

const log = logger.getSubLogger({ name: "SectionSelector" });

type Section = {
  id: string;
  title?: string;
  label: string;
  disabled?: boolean;
  permissions?: string[];
  component: FC;
  props?: Record<string, unknown>;
};

type SectionSelectorProps = {
  selected?: string;
  sections: Section[];
  selectCallback?: (...args: unknown[]) => unknown;
  saveToRoute?: boolean;
  topOfPage?: boolean;
  routeSelector?: string;
  triggerSelectOnMount?: boolean;
};

function NoSectionComponent() {
  // eslint-disable-next-line i18next/no-literal-string
  return <div>No section</div>;
}

const SectionSelector = observer<FC<SectionSelectorProps>>(
  ({
    selected,
    selectCallback,
    sections = [],
    saveToRoute = false,
    topOfPage = false,
    routeSelector = "tab",
    triggerSelectOnMount = false,
  }: SectionSelectorProps) => {
    const { networks } = useInject("networks");

    // Pick first section that has permission granted and not disabled
    const defaultSectionId = useMemo(() => {
      let nonLockedSectionId = "";

      // Make sure the "sections" prop is provided
      if (!Array.isArray(sections) || !sections.length) {
        log.error(`"sections" prop must be provided!`);
        return "";
      }

      //
      if (saveToRoute) {
        nonLockedSectionId = getUrlParam(routeSelector) ?? "";
      }

      // find the first section with an access
      sections.forEach((section) => {
        if (nonLockedSectionId) return;
        const locked = !networks.haveAccess(section.permissions || []);
        if (!locked && !section.disabled) nonLockedSectionId = section.id;
      });

      return nonLockedSectionId;
    }, [networks, routeSelector, saveToRoute, sections]);

    log.debug("Default section ID: ", defaultSectionId);
    const [activeSectionId, setActiveSectionId] = useState(defaultSectionId);
    const isValidSection = useCallback(
      (sectionId: string) => sections.map((section) => section.id).includes(sectionId),
      [sections]
    );

    // Scroll to top on tab change
    const ref = useRef<HTMLElement>();
    useEffect(() => {
      if (!ref.current?.scrollTo) return;
      ref.current?.scrollTo(0, 0);
    }, [activeSectionId]);

    // Make sure to update the url the first time the component is mounted.
    useEffect(() => {
      if (saveToRoute && isValidSection(activeSectionId)) {
        // We can do this, if we want to trigger the state change
        // but this takes a very long time for a simple tab change
        // const state = createRouterState(routerStore.routerState.routeName, {
        //   queryParams: {
        //     [routeSelector]: activeTab,
        //   },
        // });
        // routerStore.goToState(state);
        insertUrlParam(routeSelector, activeSectionId);
      }

      // Make sure to call the callback if the page loads without the default tab.
      if (
        isValidSection(activeSectionId) &&
        (activeSectionId !== defaultSectionId || triggerSelectOnMount) &&
        selectCallback
      ) {
        selectCallback(activeSectionId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSectionChange = useCallback(
      (_component: undefined, newSection: string) => {
        if (activeSectionId === newSection) return;
        setActiveSectionId(newSection);
        if (selectCallback) {
          selectCallback(newSection);
        }
        if (saveToRoute) {
          insertUrlParam(routeSelector, newSection);
        }
      },
      [activeSectionId, routeSelector, saveToRoute, selectCallback]
    );

    // Clear query string once the component unmounted
    useEffect(
      () => () => {
        if (saveToRoute) removeUrlParam(routeSelector);
      },
      [routeSelector, saveToRoute]
    );

    // Update active tab based on "selected" prop
    useEffect(() => {
      if (!selected || !isValidSection(selected)) return;
      onSectionChange(undefined, selected);
    }, [isValidSection, onSectionChange, selected]);

    log.debug(`Rendering with ${sections?.length} sections \nActive section: ${activeSectionId}`);

    // Generate active section component
    const section = sections?.find((section_) => section_.id === activeSectionId);
    const activeSectionComponent = createElement(
      section?.component || NoSectionComponent,
      section?.props || undefined
    );

    return (
      <Grid container direction="column" sx={{ height: "100%" }} wrap="nowrap">
        <Grid item>
          <Paper elevation={2} style={{ zIndex: 2, position: "relative" }}>
            <UtfTabs value={activeSectionId} onChange={onSectionChange} variant="fullWidth">
              {sections.map((option) => {
                const locked = !networks.haveAccess(option.permissions || []);
                const disabled = locked || !!option?.disabled;
                return (
                  <UtfTab
                    key={option.id}
                    label={option.title}
                    value={option.id}
                    disabled={disabled}
                    isLocked={locked}
                    type={undefined}
                  />
                );
              })}
            </UtfTabs>
          </Paper>
        </Grid>
        <Grid item flexGrow={1} sx={{ overflow: "hidden" }} pb={1}>
          <Box
            sx={{
              overflow: "auto",
              height: "100%",
              bgcolor: "background.hover",
            }}
            px={topOfPage ? 4 : 1}
            data-testid={`section-content-${activeSectionId}`}
            ref={ref}
            display={topOfPage ? "block" : "flex"}
          >
            <UtfErrorBoundary>{activeSectionComponent}</UtfErrorBoundary>
          </Box>
        </Grid>
      </Grid>
    );
  }
);

SectionSelector.displayName = "UtfSectionSelector";

export default SectionSelector;
