/* eslint-disable react/no-this-in-sfc */
import { forwardRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@mui/icons-material";
import { Button, GlobalStyles } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import hcMore from "highcharts/highcharts-more";
import * as Highcharts from "highcharts/highstock";
import { type Options } from "highcharts/highstock";
import hcAnnotations from "highcharts/modules/annotations";
import hcBoost from "highcharts/modules/boost";
import hcExportData from "highcharts/modules/export-data";
import hcExporting from "highcharts/modules/exporting";
import hcNoDataToDisplay from "highcharts/modules/no-data-to-display";
import { DateTime } from "luxon";

import { PLAYWRIGHT_RUN } from "@config/config";
import { logger as baseLogger } from "@core/logger";

import { Table } from "../../../analytics/tables";
import { UtfErrorBoundary } from "../../../ErrorBoundary";
import { setupCustomExporters } from "./setupExporters";
import { BaseComponentProps, useChartOptions } from "./utils";

const logger = baseLogger.getSubLogger({ name: "HighchartsBase" });

// Initialize Highcharts modules
hcAnnotations(Highcharts);
hcMore(Highcharts);
hcExporting(Highcharts);
hcExportData(Highcharts);
hcBoost(Highcharts);
hcNoDataToDisplay(Highcharts);

// Initialize our custom Highcharts modules

// Custom CSV & XLSX exporters
setupCustomExporters(Highcharts);

// Style overrides
const injectGlobalStyles = (
  <GlobalStyles
    styles={{
      // Customize datatable
      ".highcharts-data-table": {
        width: "100%",
        overflowX: "scroll",
        marginTop: "10px",
        marginBottom: "10px",
        table: {
          borderCollapse: "collapse",
          borderSpacing: 0,
          minWidth: "100%",
          "td, th, caption": {
            border: "1px solid #dbe0e4",
            padding: "0.5em",
          },
        },
      },
    }}
  />
);

// Allow custom data attributes in HC elements
Highcharts.AST.allowedAttributes.push("data-testid");

export type HighchartsBaseProps = BaseComponentProps &
  React.PropsWithoutRef<HighchartsReact.Props> &
  Partial<Options>;

export type HighchartsBaseRef = HighchartsReact.RefObject;

/**
 * A helper component to create charts with HighchartsReact
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const HighchartsBase = forwardRef<HighchartsBaseRef, HighchartsBaseProps>((props, ref) => {
  const {
    // Custom props
    customMenu,
    disableBoost = false,
    disableExporting = false,
    enableFilteredExport = false,
    exportFileName = "chartData",
    showGraphUnit = true,
    isLog = false,
    loading = false,
    yAxis,
    yAxisScale = false,
    yAxisOptions = {},
    yUnit,
    xAxisAppend = "",
    exportData = [],
    showViewData,
    // Alias props for "options"
    // These options are "combined" with the defaults
    ...hcProps
  } = props;
  const { t } = useTranslation(["_messages"]);
  const chartRef = useRef<HighchartsBaseRef>();
  const [isViewDataTable, setIsViewDataTable] = useState(false);
  const [dataTableData, setDataTableData] = useState<{ rows: any[]; columns: any[] }>({
    rows: [],
    columns: [],
  });
  const customProps = {
    yAxis,
    yAxisScale,
    yAxisOptions,
    yUnit,
    xAxisAppend,
    disableBoost,
    isLog,
    customMenu,
    enableFilteredExport,
    exportFileName,
    disableExporting,
    showGraphUnit,
    isViewDataTable,
    setIsViewDataTable,
    exportData,
    showViewData,
  };
  const { options = {}, series } = hcProps;
  const chartOptions = useChartOptions(hcProps, customProps, {
    isViewDataTable,
    // @ts-expect-error Something is strange here
    setDataTableData,
    setIsViewDataTable,
  });

  // If options.series is set, log an error
  if (import.meta.env.DEV && options.series) {
    throw new Error("options.series is deprecated, please use series prop instead");
  }

  // Link the chart instance to the ref
  useImperativeHandle(
    ref,
    () => ({
      get chart() {
        return chartRef?.current?.chart as Highcharts.Chart;
      },
      get container() {
        return chartRef?.current?.container as RefObject<HTMLDivElement>;
      },
    }),
    []
  );

  // Setup the chart options
  useEffect(() => {
    // Set timezones for the chart instance
    Highcharts.setOptions({
      time: {
        timezone: DateTime.local().zoneName!,
        useUTC: false, // if you enable it, there are no daylight time saving changes.
      },
    });

    // Expose library for testing
    if (PLAYWRIGHT_RUN) {
      window.Highcharts = Highcharts;
    }
  }, []);

  // loading and no data view toggles
  useEffect(() => {
    const { chart } = chartRef.current ?? {};
    if (!chart) return;

    // @ts-expect-error this HC module is not typed
    chart.hideNoData();

    if (series === undefined || loading) {
      // show loading on initial mount if, the data is not set
      chart.showLoading();
    } else if (series?.length === 0 || !loading) {
      chart.hideLoading();
      // display no data message if there are no series set
      // @ts-expect-error this HC module is not typed
      if (series?.length === 0) chart.showNoData(t("message_no_data_available"));
    } else if (series?.length > 0) {
      // hide loading if there are series set
      chart.hideLoading();
      // @ts-expect-error this HC module is not typed
      chart.hideNoData();
    }
  }, [loading, series, t]);

  logger.debug("RENDER with %o series", Number(series?.length));

  return (
    <UtfErrorBoundary>
      <div style={{ width: "100%", backgroundColor: "white" }}>
        {isViewDataTable ? (
          <div>
            <Table
              data={dataTableData.rows}
              enableColumnActions
              enableColumnFilterModes
              enableSortingRemoval
              enableTopToolbar
              enableFilters
              columns={dataTableData.columns}
              renderTopToolbarCustomActions={() => (
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setIsViewDataTable(false)}
                  style={{ marginBottom: "10px" }}
                  startIcon={<ChevronLeft />}
                >
                  {t("_messages:chart")}
                </Button>
              )}
            />
          </div>
        ) : (
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={chartOptions}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...hcProps}
          />
        )}
        {injectGlobalStyles}
      </div>
    </UtfErrorBoundary>
  );
});

// eslint-disable-next-line import/no-default-export
export default HighchartsBase;
