import { COLUMN_SECTION } from "./constants";

const PRICING_COLUMNS = {
  tariff_id: {
    label: "text_price_tariff",
    info: "text_price_tariff_desc",
    section: COLUMN_SECTION.customer,
  },
  flow_limit: {
    label: "text_flow_limiter",
    info: "text_flow_limiter_desc",
    section: COLUMN_SECTION.customer,
  },
  power_limit: {
    label: "text_power_limiter",
  },
  wn_value: {
    label: "text_wn_value",
    info: "text_wn_value_desc",
    section: COLUMN_SECTION.customer,
  },
};

export default PRICING_COLUMNS;
