/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SetStateAction } from "react";
import type { Typography } from "@mui/material/styles/createTypography";
import { useTheme, type Theme } from "@mui/system";
import { defaultComposer } from "default-composer";
import type HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import type {
  AxisLabelsFormatterContextObject,
  Chart,
  ChartZoomingOptions,
  CSSObject,
  Dictionary,
  ExportingMenuObject,
  Options,
  XAxisOptions,
  YAxisOptions,
} from "highcharts/highstock";
import { DateTime } from "luxon";
import memoizeOne from "memoize-one";

import { formatNumberForLocale } from "@core/utils";
import { exportGraph, exportGraphAsObject } from "@hooks";

import { formatYAxisLabel } from "../utils";

export type CustomYAxisOptions = {
  yUnit?: string;
} & YAxisOptions;
type CustomXAxisOptions = XAxisOptions & { labelFormatType?: string };

export type BaseComponentProps = {
  customMenu?: {
    names: string[];
    options: Dictionary<ExportingMenuObject>;
  };
  showGraphUnit?: boolean;
  exportFileName?: string;
  enableFilteredExport?: boolean;
  disableExporting?: boolean;
  xAxis?: CustomXAxisOptions;
  yAxis?: CustomYAxisOptions | CustomYAxisOptions[];
  yUnit?: string;
  yAxisOptions?: YAxisOptions;
  yAxisScale?: boolean;
  yAxisAppend?: string;
  xAxisAppend?: string;
  xAxisFractionalDigits?: number;
  disableBoost?: boolean;
  isLog?: boolean;
  loading?: boolean;
  exportData?: any[];
  showViewData?: boolean;
  isStockChart?: boolean;
};

export type TooltipPointTypes = {
  color: string;
  x: string;
  y: number;
  series: {
    userOptions: {
      label: string;
      unit: string;
    };
    name?: string;
    tooltipOptions?: {
      valueSuffix?: string;
    };
  };
};

type ExportMenuItemsArgs = Partial<BaseComponentProps> & {
  isViewDataTable: boolean;
  setIsViewDataTable: (value: SetStateAction<boolean>) => void;
  setDataTableData: (value: SetStateAction<any[]>) => void;
  exportData?: any[];
  showViewData?: boolean;
};

// Compute exporting menu items
function factoryExportMenuItems({
  isViewDataTable,
  setIsViewDataTable,
  setDataTableData,
  customMenu,
  enableFilteredExport,
  exportFileName,
  exportData: customExportData = [],
  showViewData = true,
}: ExportMenuItemsArgs) {
  return function getExportingMenuItems() {
    let menuItems = [
      // Retrieve default options from the library
      ...(Highcharts.getOptions().exporting?.buttons?.contextButton?.menuItems ?? []),
    ].filter((item) => !["viewData"].includes(item));

    const menuItemDefinitions = {
      ...(customMenu?.options ?? {}),
    } as Dictionary<ExportingMenuObject>;

    function getExportData(tableData: (string | number)[][], exportData: (string | number)[][]) {
      let usedExportData: (string | number)[][] = [];
      if (exportData.length > 0) {
        usedExportData = exportData;
      } else if (tableData.length > 0) {
        usedExportData = tableData;
      }
      return usedExportData;
    }

    if (enableFilteredExport || customExportData.length > 0) {
      // Download "filtered" chart data as XLSX
      menuItemDefinitions.downloadXLSX = {
        text: "Download XLSX",
        onclick(this: Chart) {
          const rows = getExportData(this.getDataRows(), customExportData);
          const xMin = this.xAxis[0].min;
          const xMax = this.xAxis[0].max;
          exportGraph(rows, xMin, xMax, "XLSX", exportFileName);
        },
      };

      // Download "filtered" chart data as CSV
      menuItemDefinitions.downloadCSV = {
        text: "Download CSV",
        onclick(this: Chart) {
          const rows = getExportData(this.getDataRows(), customExportData);
          const xMin = this.xAxis[0].min;
          const xMax = this.xAxis[0].max;
          exportGraph(rows, xMin, xMax, "CSV", exportFileName);
        },
      };
    }

    if (showViewData) {
      // View/Hide Data table
      menuItems.push("viewData");
      menuItemDefinitions.viewData = {
        text: `${isViewDataTable ? "Hide" : "View"} data table`,
        onclick(this: Chart) {
          if (isViewDataTable) {
            setIsViewDataTable(false);
          } else {
            const rows = getExportData(this.getDataRows(), customExportData);
            // @ts-expect-error legacy
            setDataTableData(exportGraphAsObject(rows));
            setIsViewDataTable(true);
          }
        },
      };
    }

    if (customMenu?.names) {
      menuItems = [...menuItems, ...customMenu.names];
    }

    return { menuItems, menuItemDefinitions };
  };
}

const factoryExportMenuItemsMemo = memoizeOne(factoryExportMenuItems);

/**
 * The objective of the getYaxisOptions function is to compute the yAxis options
 * for a Highcharts chart based on the provided inputs and return the formatted yAxis options.
 *
 * @param yAxis
 * @param yAxisOptions
 * @param yUnit
 * @param showGraphUnit

 * @returns YAxisOptions | YAxisOptions[]
 */
export function getYaxisOptions({
  yAxis,
  yAxisOptions,
  yUnit,
  showGraphUnit,
}: Partial<BaseComponentProps>): CustomYAxisOptions | Array<CustomYAxisOptions> {
  // Formatter util for yAxis labels
  function yAxisFormatterFactory(unit = yUnit) {
    return function yAxisFormatter(this: AxisLabelsFormatterContextObject) {
      if (showGraphUnit) {
        // Last label should be visible, sorry
        this.axis.options.showLastLabel = true;
        return formatYAxisLabel(this.value, this.axis.max, unit, this.isLast);
      }

      return this.axis.defaultLabelFormatter.call(this);
    };
  }

  if (
    yAxis &&
    !Array.isArray(yAxis) &&
    Object.prototype.toString.call(yAxis) === "[object Object]"
  ) {
    return {
      labels: {
        formatter: yAxisFormatterFactory(yAxis.yUnit),
      },
      ...yAxis,
      ...yAxisOptions,
    } as CustomYAxisOptions;
  }

  let formatedYAxis: CustomYAxisOptions[] = [];
  if (Array.isArray(yAxis)) {
    formatedYAxis = yAxis.map((y) => ({
      ...y,
      labels: {
        ...y.labels,
        formatter: yAxisFormatterFactory(y.yUnit),
      },
    }));
  }

  return formatedYAxis.length > 0
    ? formatedYAxis
    : [
        {
          labels: {
            formatter: yAxisFormatterFactory(yUnit),
          },
          ...yAxisOptions,
        },
      ];
}

const getYaxisOptionsMemo = memoizeOne(getYaxisOptions);

/**
 * Scale the yAxis for the "column" series.
 *
 * @param yAxis
 * @param series
 * @returns
 */
export function scaleYAxis(yAxis: CustomYAxisOptions[], series?: any[]): CustomYAxisOptions[] {
  if (!Array.isArray(yAxis) || !series) return yAxis;

  const scaledYAxis = [...yAxis];
  // Dynamically scale the axis for the "column" series.
  for (let i = 0; i < scaledYAxis.length; i++) {
    const yAxisData = series.filter((serie) => (serie?.yAxis ?? 0) === i);
    let minValue: number | null = null;
    if (yAxisData.filter((y) => y.type === "column").length > 0) {
      yAxisData.forEach((y) => {
        (y?.data || []).forEach((yValue: null | number) => {
          if (minValue === null || (yValue !== null && minValue > yValue)) {
            minValue = yValue;
          }
        });
      });
      scaledYAxis[i].min = minValue ? minValue * 0.9 : 0;
      scaledYAxis[i].startOnTick = true;
    }
  }
  return scaledYAxis;
}

/**
 * A formatter util for xAxis labels.
 *
 * @param xAxis
 * @param xAxisAppend
 * @param xAxisFractionalDigits
 * @param isLog
 *
 * @returns xAxisFormatter
 */
function xAxisFormatterFactory({
  xAxis,
  xAxisAppend = "",
  xAxisFractionalDigits,
  isLog,
}: Partial<Options> & BaseComponentProps): (this: AxisLabelsFormatterContextObject) => string {
  return function xAxisFormatter(this: AxisLabelsFormatterContextObject) {
    let xValue = this.value;
    const xInt = Number(xValue);

    // If the value is a number, then we need to round it to the nearest integer.
    if (isLog) {
      xValue = Math.E ** xInt;
    }

    // If the value is a date, then we need to format it.
    if (xAxis && !Array.isArray(xAxis) && xAxis.type === "datetime") {
      if (xAxis.labelFormatType === "dateOrTime") {
        const format = DateTime.fromMillis(xInt).hour === 0 ? "%d. %b" : "%H:%M";
        return Highcharts.dateFormat(format, xInt);
      }
      return Highcharts.dateFormat("%d %b - %H:%M", xInt);
    }

    // otherwise, we just format the number with fractionalDigits.
    xValue = formatNumberForLocale(xValue, undefined, undefined, xAxisFractionalDigits);
    return xValue + xAxisAppend;
  };
}

type RestProps = {
  isViewDataTable: boolean;
  setIsViewDataTable: (value: SetStateAction<boolean>) => void;
  setDataTableData: (value: SetStateAction<any[]>) => void;
  exportData?: any[];
};

const restInitials = {
  isViewDataTable: false,
  setIsViewDataTable: () => undefined,
  setDataTableData: () => undefined,
};

type ChartOptions = Omit<Partial<Options>, "yAxis" | "chart"> & {
  yAxis?: CustomYAxisOptions | CustomYAxisOptions[];
  chart?: Partial<Options["chart"]> & { zoomType: ChartZoomingOptions["type"] };
};

/**
 * The useChartOptions function is responsible for generating the default options
 * for a Highcharts chart, based on the customProps and hcProps provided as inputs.
 * It also merges the default options with the options prop if defined, and always uses the series prop.
 *
 * @param props HighchartsReact.Props
 * @param customProps Non-highcharts props for customizing the chart and enabling extra functions
 * @param rest
 *
 * @returns Partial<Options>
 */
export function useChartOptions(
  { options, ...hcProps }: React.PropsWithoutRef<HighchartsReact.Props>,
  customProps?: BaseComponentProps,
  rest: RestProps = restInitials
): ChartOptions {
  const { typography, palette } = useTheme<Theme & { typography: Typography }>();
  const {
    yAxis,
    yAxisScale,
    yAxisOptions,
    yUnit = "",
    xAxisAppend,
    xAxisFractionalDigits,
    disableBoost,
    isLog,
    customMenu,
    enableFilteredExport,
    exportFileName,
    disableExporting,
    showGraphUnit,
    exportData,
    showViewData,
  } = customProps ?? {};
  const { series, xAxis, constructorType } = hcProps;
  const { isViewDataTable = false, setIsViewDataTable = () => undefined, setDataTableData } = rest;

  const isStockChart = constructorType === "stockChart";

  const { menuItems, menuItemDefinitions } = factoryExportMenuItemsMemo({
    setIsViewDataTable,
    isViewDataTable,
    setDataTableData,
    customMenu,
    enableFilteredExport,
    exportFileName,
    exportData,
    showViewData,
  })();

  const defaultOptions: ChartOptions = {
    boost: {
      enabled: !disableBoost,
      seriesThreshold: !disableBoost ? 10 : 0,
    },
    chart: {
      zoomType: "x",
      zooming: {
        // "chart.zoomType" is deprecated in Highcharts v11.
        // Mapping for backward compatability
        type: hcProps?.chart?.zoomType ?? "x",
        mouseWheel: {
          enabled: false,
        },
      },
      style: {
        overflow: "visible",
        marginTop: showGraphUnit && !isStockChart ? "10px" : 0, // space for the chart UNIT
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: !disableExporting,
      buttons: {
        contextButton: {
          menuItems,
        },
      },
      menuItemDefinitions,
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: typography.h6.fontSize,
        fontFamily: typography.h6.fontFamily,
        fontWeight: typography.h6.fontWeight,
      } as CSSObject,
    },
    subtitle: {
      x: 120,
      y: 12,
      style: {
        color: palette.primary.main,
        fontFamily: typography.caption.fontFamily,
        fontSize: typography.caption.fontSize as string,
        position: "absolute",
        zIndex: 2,
        right: "125px",
        top: "8px",
      },
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "top",
    },
    navigation: {
      menuStyle: {
        zIndex: 6,
        overflow: "visible",
      },
    },
    noData: {
      style: {
        fontSize: typography.h6.fontSize as string,
        fontFamily: typography.h6.fontFamily,
        fontWeight: typography.h6.fontWeight as string,
        color: palette.error.main,
      },
    },
    plotOptions: {
      series: {
        animation: false,
        stickyTracking: true,
        label: {
          connectorAllowed: false,
        },
        cursor: "pointer",
        color: palette.primary.main,
        turboThreshold: !disableBoost ? 100000 : 0,
        marker: {
          states: {
            select: {
              radius: 7,
              lineWidth: 4,
              lineColor: palette.secondary.dark,
              fillColor: palette.secondary.main,
            },
          },
        },
      },
      line: {
        boostThreshold: !disableBoost ? 1 : 0,
        turboThreshold: !disableBoost ? 1 : 0,
      },
      histogram: {
        turboThreshold: !disableBoost ? 1 : 0,
      },
      area: {
        fillOpacity: 0.5,
      },
    },
    xAxis: {
      type: "linear",
      title: {
        style: {
          fontSize: typography.caption.fontSize,
          fontFamily: typography.caption.fontFamily,
          fontWeight: typography.subtitle2.fontWeight,
        } as CSSObject,
      },
      labels: {
        formatter: xAxisFormatterFactory({
          xAxis,
          xAxisAppend,
          xAxisFractionalDigits,
          isLog,
        }),
      },
    },
    yAxis: getYaxisOptionsMemo({
      yAxis,
      yAxisOptions,
      yAxisScale,
      yUnit,
      showGraphUnit,
    }),
  };

  if (yAxisScale && Array.isArray(defaultOptions.yAxis)) {
    defaultOptions.yAxis = scaleYAxis(defaultOptions.yAxis, series);
  }

  // Merge default options with the props provided
  const defaultsWithProps = defaultComposer<Partial<Options>>(defaultOptions, hcProps);

  return {
    ...defaultsWithProps,
    // If the "options" prop is defined, overwrite the default options with it
    ...(options ?? {}),
    // Always use the "series" prop, ignore if its defined in "options" prop.
    series,
  };
}
