import type { ILogObj } from "tslog";
import { Logger } from "tslog";

const logTemplate = {
  production: "[{{name}}] ({{logLevelName}}) ",
  development: "[{{name}}]\t{{logLevelName}}\t{{hh}}:{{MM}}:{{ss}}\n",
};

const isDev = import.meta.env?.DEV || false;
const isTest = import.meta.env?.MODE === "test" || import.meta.env?.["REACT_APP_PLAYWRIGHT_RUN"];

// 0: silly, 1: trace, 2: debug, 3: info, 4: warn, 5: error, 6: fatal
const minLevel = import.meta.env?.["REACT_APP_LOG_LEVEL"] || 4;

let overwrite = {};

if (isTest) {
  overwrite = {
    transportJSON: (logObjWithMeta: { _meta: { name: string; logLevelName: string } }) => {
      // overwrite the default transport for formatted (e.g. pretty) log levels.
      // e.g. replace console with StdOut
      const { _meta, ...out } = logObjWithMeta;
      // eslint-disable-next-line no-console
      console.log(`[${_meta.name}][${_meta.logLevelName}] `, Object.values(out).join(" "));
      return out;
    },
  };
}

const logger: Logger<ILogObj> = new Logger({
  name: "utf",
  type: isTest ? "json" : "pretty",
  prettyInspectOptions: { depth: 2 },
  prettyLogTemplate: isDev ? logTemplate.development : logTemplate.production,
  stylePrettyLogs: isDev && !isTest,
  minLevel,
  hideLogPositionForProduction: !isDev || isTest,
  overwrite,
});

// A general logger util that can be seen in all environments
export const log = (...args: unknown[]) => logger.info(...args);

// Expose all available loggers
export { logger };
