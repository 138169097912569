import { Tooltip } from "@mui/material";
import { faNoteSticky } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

export function getNoteCell(note: string) {
  return (
    <Tooltip title={note} data-testid="note-cell">
      <FontAwesomeSvgIcon icon={faNoteSticky} color="primary" />
    </Tooltip>
  );
}
