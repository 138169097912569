import { Box } from "@mui/system";

function StepperDot({ activeStep, maxSteps, setActiveHandler }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        cursor: "pointer",
        "& :hover": {
          backgroundColor: "primary.light",
        },
      }}
      data-testid="carousel-steppers"
    >
      {(() => {
        const box = [];
        for (let i = 0; i <= maxSteps; i++) {
          box.push(
            <Box
              sx={{
                display: "flex",
                width: "16px",
                height: "16px",
                borderRadius: "8px",
                bgcolor: i === activeStep ? "secondary.main" : "primary.main",
              }}
              key={i}
              onClick={() => setActiveHandler(i)}
              data-testid={`carousel-stepper-${i}`}
              id={i === activeStep ? "active-dot" : "inactive-dot"}
            />
          );
        }
        return box;
      })().slice(0, 4)}
    </Box>
  );
}

export default StepperDot;
