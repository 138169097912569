import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Button, Popover, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import {
  faClockTwoThirty,
  faCoins,
  faFilter,
  faGlobe,
  faNetworkWired,
} from "@fortawesome/pro-light-svg-icons";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import i18n from "@core/i18n";
import SubstationIcon from "@icons/Substation";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";
import * as CONSTANTS from "@shared/ui/layout/constants";

import NetworkSelect from "../../Networks/NetworkSelect";
import { LanguageSelect } from "./LanguageSelect";

const Row = styled("div")(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2, 3),
  borderBottom: `${theme.palette.grey["200"]} solid 1px`,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.25),
}));

const Slot = styled("div")(({ theme }) => ({
  display: "inherit",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const Actions = styled("div")(({ theme }) => ({
  height: theme.spacing(8),
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(2, 3),
}));

const ActionButton = styled(Button)(() => ({
  width: "140px",
  height: "30px",
}));

const Info = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(3.5),
}));

const Timezone = styled("span")(() => ({
  textTransform: "capitalize",
}));

function SettingPopover({ id = "", open = null, anchorEl = null, handleClose, children }) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transitionDuration={200}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          overflow: "unset",
        },
      }}
    >
      <Box sx={{ minWidth: "360px" }}>{children}</Box>
    </Popover>
  );
}
SettingPopover.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  anchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.object]),
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const iconSx = { width: "20px", height: "20px", fill: "grey.800" };

export const SettingOptions = observer(
  ({ id = "", open = null, anchorEl = null, handleClose, settingType = null }) => {
    const { t } = useTranslation(["navigation", "_action"]);
    const { ui, networks, language, filters } = useInject("ui", "networks", "language", "filters");
    const [selectedNetwork, setSelectedNetwork] = useState(networks.current_network);

    // sync input state on close
    useEffect(() => {
      if (!settingType || open) return;
      setSelectedNetwork(networks.current_network);
      language.updateCurrentLanguage(i18n.language);
    }, [open, anchorEl, networks.current_network, language, settingType]);

    const handleLanguageChange = (key) => {
      language.updateCurrentLanguage(key);
    };

    const handleConfirmLanguage = () => {
      const { currentLanguage } = language;
      (async () => {
        await i18n.changeLanguage(currentLanguage);
        localStorage.setItem("language", currentLanguage);
        handleClose();
      })();
    };

    const handleViewFilter = () => {
      if (ui.is_filteroptions_open) {
        handleClose();
        return;
      }

      ui.toggleFilters();
      handleClose();
    };

    const handleResetFilter = () => {
      filters.resetFilters();
      handleClose();
    };

    const handleSelectNetwork = () => {
      // close if network option not changing
      if (selectedNetwork.uid === networks.current_network.uid) {
        handleClose();
        return;
      }

      if (!ui.is_sidenav_open) ui.sideNavToggle();
      networks.selectNetwork(selectedNetwork);
      handleClose();
    };

    const handleNetworkSettingClose = () => {
      setSelectedNetwork(networks.current_network);
      handleClose();
    };

    const offset = DateTime.local().offsetNameShort;

    return (
      <SettingPopover id={id} open={open} anchorEl={anchorEl} handleClose={handleClose}>
        {/* Language */}
        {settingType === CONSTANTS.LANGUAGE && (
          <section>
            <Row>
              <Slot>
                <FontAwesomeSvgIcon icon={faGlobe} sx={iconSx} />
                <Typography variant="caption">
                  {t("text_language", { ns: "navigation" })}:
                </Typography>
              </Slot>
              <LanguageSelect
                value={language.currentLanguage}
                onChange={handleLanguageChange}
                width={CONSTANTS.DROPDOWN_WIDTH}
                options={language.languageOptions}
              />
            </Row>
            <Actions>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={handleConfirmLanguage}
                data-testid={`${CONSTANTS.LANGUAGE}-confirm-setting-button`}
              >
                {t("action_confirm", { ns: "_action" })}
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="primary"
                onClick={handleClose}
                data-testid={`${CONSTANTS.LANGUAGE}-cancel-setting-button`}
              >
                {t("action_cancel", { ns: "_action" })}
              </ActionButton>
            </Actions>
          </section>
        )}

        {/* Substation */}
        {settingType === CONSTANTS.SUBSTATION && (
          <section>
            <Row>
              <Slot>
                <SubstationIcon sx={iconSx} />
                <Typography variant="caption">
                  {t("text_substation_in_the_network", { ns: "navigation" })}:
                </Typography>
              </Slot>
              <Info variant="body">{networks.ready && networks.current_substations.size}</Info>
            </Row>
            <Row>
              <Slot>
                <FontAwesomeSvgIcon icon={faFilter} sx={iconSx} />
                <Typography variant="caption">
                  {t("text_substation_being_filtered", { ns: "navigation" })}:
                </Typography>
              </Slot>
              <Info variant="body1">{networks.ready && networks.active_substations.size}</Info>
            </Row>
            <Actions>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={handleViewFilter}
                data-testid={`${CONSTANTS.SUBSTATION}-view-filter-setting-button`}
              >
                {t("action_view_filter", { ns: "_action" })}
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                disabled={networks.active_substations.size === networks.current_substations.size}
                onClick={handleResetFilter}
                data-testid={`${CONSTANTS.SUBSTATION}-reset-setting-button`}
              >
                {t("action_reset_filter", { ns: "_action" })}
              </ActionButton>
            </Actions>
          </section>
        )}

        {/* Network */}
        {settingType === CONSTANTS.NETWORK && (
          <section>
            <Row>
              <Slot>
                <FontAwesomeSvgIcon icon={faNetworkWired} sx={iconSx} />
                <Typography variant="caption">
                  {t("text_network", { ns: "navigation" })}:
                </Typography>
              </Slot>
              <Slot>
                <NetworkSelect
                  width={CONSTANTS.DROPDOWN_WIDTH}
                  currentNetwork={selectedNetwork}
                  onChange={setSelectedNetwork}
                />
              </Slot>
            </Row>
            <Row>
              <Slot>
                <FontAwesomeSvgIcon icon={faClockTwoThirty} sx={iconSx} />
                <Typography variant="caption">
                  {t("text_time_zone", { ns: "navigation" })}:
                </Typography>
              </Slot>
              <Info variant="body">
                <Timezone>{`${networks.tz_name} (${offset})`}</Timezone>
              </Info>
            </Row>
            <Row>
              <Slot>
                <FontAwesomeSvgIcon icon={faCoins} sx={iconSx} />
                <Typography variant="caption">
                  {t("text_currency", { ns: "navigation" })}:
                </Typography>
              </Slot>
              <Info variant="body1">{networks.currency}</Info>
            </Row>
            <Actions>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={handleSelectNetwork}
                data-testid={`${CONSTANTS.NETWORK}-confirm-setting-button`}
                disabled={selectedNetwork.uid === networks.current_network.uid}
              >
                {t("action_confirm", { ns: "_action" })}
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="primary"
                onClick={handleNetworkSettingClose}
                data-testid={`${CONSTANTS.NETWORK}-cancel-setting-button`}
              >
                {t("action_cancel", { ns: "_action" })}
              </ActionButton>
            </Actions>
          </section>
        )}
      </SettingPopover>
    );
  }
);

SettingOptions.displayName = "SettingOptions";

SettingOptions.propTypes = {
  id: SettingPopover.propTypes.id,
  open: SettingPopover.propTypes.open,
  anchorEl: SettingPopover.propTypes.anchorEl,
  handleClose: PropTypes.func.isRequired,
  settingType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
