import { defaultTheme } from "@conf/utilifeed.theme";

export const heatEnergy = "heat_energy";
export const volume = "volume";

export const GRAPH_OPTIONS = new Map([
  [heatEnergy, "text_power"],
  [volume, "text_flow"],
]);

export const HOUR_STEPS = [
  { hour: 24, label: "24", duration: { value: "h", shortHandVale: "text_hourly" } },
  { hour: 48, label: "48", duration: { value: "h", shortHandVale: "text_hourly" } },
  { hour: 7 * 24, label: "7", duration: { value: "text_days", shortHandVale: "text_days" } },
];

export const PRODUCTION_HOUR_STEPS = [
  { hour: 24, label: "24h", deltaDesc: "24 hours" },
  { hour: 48, label: "48h", deltaDesc: "48 hours" },
  { hour: 7 * 24, label: "7 Days", deltaDesc: "7 Days" },
];

export const DEFAULT_HOUR_STEP = "48";

let tempMaxStepHour = 0;

PRODUCTION_HOUR_STEPS.forEach((hourStep) => {
  if (hourStep.hour > tempMaxStepHour) {
    tempMaxStepHour = hourStep.hour;
  }
});

export const MAX_STEP_HOURS = tempMaxStepHour;

const CLOCK_ICON = `<svg style="width: 22px; height: 44px"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" fill=${defaultTheme.palette.grey["400"]}></path><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" fill=${defaultTheme.palette.grey["400"]}></path></svg>`;
const DOT_ICON = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" style="width: 22px; height: 44px" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FiberManualRecordIcon"><circle cx="12" cy="12" r="4" fill=${defaultTheme.palette.grey["400"]}></circle></svg>`;

export const CHART_PLOT_LINE_OPTIONS = [
  {
    offsetHour: 0,
    position: { x: 0, y: -27 },
    icon: CLOCK_ICON,
    current: true,
  },
  {
    offsetHour: 12,
    position: { x: -1, y: -27 },
    icon: DOT_ICON,
    current: false,
  },
  {
    offsetHour: 24,
    position: { x: -1, y: -27 },
    icon: DOT_ICON,
    current: false,
  },
  {
    offsetHour: 36,
    position: { x: -1, y: -27 },
    icon: DOT_ICON,
    current: false,
  },
  {
    offsetHour: 48,
    position: { x: -1, y: -27 },
    icon: DOT_ICON,
    current: false,
  },
];
