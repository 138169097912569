const profile = "profile";

const create_group = "create_group";
const group_list = "group_list";
const group_update = "group_update";
const group_manage = "group_manage";
const group_users = "group_users";
const group_attach_user = "attach_user_to_group";

const policy_manage = "policy_manage";
const policy_list = "policy_list";
const policy_create = "policy_create";
const policy_resources = "policy_resources";

const docs = "docs";

export const SCREEN = {
  create_group,
  group_attach_user,
  profile,
  group_manage,
  group_list,
  group_update,
  group_users,
  policy_manage,
  policy_list,
  policy_create,
  policy_resources,
  docs,
};

export const PERMISSION_LEVELS = new Map([
  [1, "text_level_1"],
  [2, "text_level_2"],
  [3, "text_level_3"],
  [4, "text_level_4"],
  [5, "text_level_5"],
]);
export const MAX_RESOURCE_LEVELS = new Map([
  [1, "text_level_1"],
  [2, "text_level_2"],
  [3, "text_level_3"],
  [4, "text_level_4"],
]);

export const RESOURCE_TYPES = new Map([
  ["network", "Networks"],
  ["substation", "Substations"],
  ["cluster", "Clusters"],
]);
