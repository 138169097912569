import { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  IconButton as MuiIconButton,
  Tooltip,
  type IconButtonProps,
  type PopperProps,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { faBars, faFilter, faLockKeyhole } from "@fortawesome/pro-light-svg-icons";

import FEATURE from "@conf/feature_access";
import { APP_BAR_HEIGHT } from "@conf/ui_constants";
import ClusterIcon from "@icons/Cluster";
import SubstationIcon from "@icons/Substation";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

type MenuButtonProps = IconButtonProps & {
  tooltipText?: string;
  active?: boolean;
  PopperProps?: Partial<PopperProps> & { "data-testid": string };
};

function LockIcon() {
  return <FontAwesomeSvgIcon icon={faLockKeyhole} />;
}

function FilterIcon() {
  return <FontAwesomeSvgIcon icon={faFilter} />;
}

const IconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => ![prop].includes("active"),
})<MenuButtonProps>(({ theme, active }) => ({
  "&.MuiButtonBase-root": {
    width: theme.spacing(9),
    height: APP_BAR_HEIGHT,
    borderRadius: 0,
    borderRight: "1px solid",
    borderRightColor: theme.palette.border.divider.dark,
    padding: theme.spacing(0, 2.3),
    color: theme.palette.grey["50"],
    backgroundColor: active ? theme.palette.secondary.main : "unset",
    pointerEvents: "auto",
  },
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
  "&.Mui-disabled": {
    color: theme.palette.grey["600"],
  },
}));

function MenuButton({
  active = false,
  disabled,
  onClick,
  PopperProps,
  tooltipText = "",
  ...props
}: MenuButtonProps) {
  return (
    <Tooltip title={tooltipText} PopperProps={PopperProps} disableInteractive>
      <IconButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        {...{
          active,
          disabled,
          onClick: disabled ? undefined : onClick,
        }}
        size="large"
      />
    </Tooltip>
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PageAppBar = observer<FC<{ filterAffects?: boolean }>>(({ filterAffects }) => {
  const { ui, networks } = useInject("ui", "networks");
  const { t } = useTranslation(["navigation"]);
  const theme = useTheme();
  const filtersDisabled = !networks.haveAccess([FEATURE.substationlist]);
  let filterToolTipText = filterAffects
    ? t("text_the_global_filter_will_apply_to_the_data")
    : t("text_the_global_filter_will_not_affect_the_data");
  if (filtersDisabled) filterToolTipText = t("text_global_filters");

  const substationPanelActive =
    ui.is_substation_model_open || ui.is_subsummary_open || ui.is_subdetail_open;
  const clusterPanelActive =
    ui.is_cluster_model_open || ui.is_cluster_summary_open || ui.is_cluster_detail_open;
  const substationExplorerDisabled = !networks.haveAccess([
    FEATURE.substation_overview,
    FEATURE.substation_consumption,
  ]);
  const clusterExplorerDisabled = !networks.haveAccess([FEATURE.clusterlist]);

  return (
    <div>
      {/* Sidebar */}
      <IconButton
        active={ui.is_sidenav_open}
        onClick={ui.sideNavToggle}
        data-testid="sidebar-toggle-button"
      >
        <FontAwesomeSvgIcon icon={faBars} />
      </IconButton>
      {/* Filter */}
      <MenuButton
        active={ui.is_filteroptions_open}
        disabled={filtersDisabled}
        onClick={ui.toggleFilters}
        tooltipText={filterToolTipText}
        data-inactive={!filterAffects}
        data-testid="filter-toggle-button"
        PopperProps={{
          "data-testid": "filter-popper",
        }}
        style={{
          color: !filterAffects ? theme.palette.grey["600"] : "inherit",
        }}
      >
        {filtersDisabled ? <LockIcon /> : <FilterIcon />}
      </MenuButton>
      {/* Substation */}
      <MenuButton
        active={substationPanelActive}
        disabled={substationExplorerDisabled}
        onClick={ui.toggleSubstations}
        tooltipText={t("text_substation_explore")}
        data-testid="substations-toggle-button"
      >
        {substationExplorerDisabled ? <LockIcon /> : <SubstationIcon />}
      </MenuButton>
      {/* Cluster */}
      <MenuButton
        active={clusterPanelActive}
        disabled={clusterExplorerDisabled}
        onClick={!clusterExplorerDisabled ? ui.toggleCluster : undefined}
        tooltipText={t("text_cluster_explore")}
        data-testid="clusters-toggle-button"
      >
        {clusterExplorerDisabled ? <LockIcon /> : <ClusterIcon />}
      </MenuButton>
    </div>
  );
});

PageAppBar.displayName = "PageAppBar";
