import { Box, Button, Typography } from "@mui/material";

function NotFoundPage() {
  const handleClick = () => {
    window.location.href = "/";
  };

  return (
    <Box display="flex" height="100%" flexGrow={1} justifyContent="center" alignItems="center">
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Typography variant="h1" color="secondary">
          404
        </Typography>
        <Typography variant="h4" color="secondary">
          NOT FOUND
        </Typography>
        <Box mt={4}>
          <Button onClick={handleClick} variant="contained" color="primary">
            BACK TO HOME
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NotFoundPage;
