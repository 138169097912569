import type {
  MRT_Column,
  MRT_ColumnDef,
  MRT_RowData,
  MRT_TableHeadCellProps,
  MRT_TableInstance,
} from "material-react-table";

export type InfoBlockColumn = {
  columns?: InfoBlockColumn[];
  muiTableHeadCellProps?:
    | MRT_TableHeadCellProps<MRT_RowData>
    | ((props: {
        column: MRT_Column<MRT_RowData, unknown>;
        table: MRT_TableInstance<MRT_RowData>;
      }) => MRT_TableHeadCellProps<MRT_RowData>);
  sub_id?: string;
  sublabel?: string;
  info?: string;
  actions?: Array<{
    id: string;
    onClick: (value: string) => void;
    label: string;
  }>;
  align?: "left" | "right" | "center";
  disabled?: boolean;
  type?: string;
  isVisible?: boolean;
  iconMapping?: Record<string, string>;
  translateNs?: string;
  translateOptions?: Record<string, unknown>;
  onClick?: (value: string) => void;
  onChange?: (value: string) => void;
  "data-testid"?: string;
} & MRT_ColumnDef<MRT_RowData>;

export type YearOption = number | string | null;
export type MonthOption = number | null;

// Constants
export const DEFAULT_ROW_PER_PAGE = 25;
export const MIN_COLUMN_WIDTH = 240;
