/* eslint-disable @typescript-eslint/no-explicit-any */
import HandleExport from "./HandleExport";

export function exportGraph(
  rows: any[],
  xMin: number | null | undefined,
  xMax: number | null | undefined,
  exportAs: string,
  chartName = ""
) {
  if (xMin === null || typeof xMin == "undefined" || xMax === null || typeof xMax == "undefined") {
    throw new Error("xMin or xMax must be a number");
  }
  const rowsInExtremes = rows.filter(
    (row: { x: number }) => typeof row.x !== "number" || (row.x >= xMin && row.x <= xMax)
  );
  // Column headers are always the first row
  const headerLabels = rowsInExtremes.shift();
  const labeledRows = rowsInExtremes.map((row: any[]) => {
    const newRow: { [key: string]: any } = {};
    row.forEach((val: any, i: string | number) => {
      newRow[headerLabels[i]] = val;
    });
    return newRow;
  });

  if (exportAs === "CSV") {
    HandleExport.asCSVFile(labeledRows, chartName, "CSV_WITH_DOT_SEPARATOR", headerLabels);
  } else {
    HandleExport.asXLSXFile(labeledRows, chartName, headerLabels);
  }
}

/**
 * Export the graph as an object. This is used to get the graph into a data table. Each row is an object with the column headers as keys.
 * @param {Array} rows
 * @returns {Array} rows
 * @example
 * exportGraphAsObject(rows);
 */
export function exportGraphAsObject(rows: any[]) {
  const res: { [key: string]: any }[] = [];
  const headerLabels = rows[0];

  rows.forEach((row: any[], i: number) => {
    if (i === 0) return; // Skip the first row, which is the column headers
    const newRow: { [key: string]: any } = {};
    row.forEach((val: any, k: string | number) => {
      newRow[headerLabels[k] as string] = val;
    });
    res.push(newRow);
  });

  const columns = (headerLabels || []).map((label: any) => ({
    name: label,
    header: label,
    accessorKey: label,
  }));

  return { rows: res, columns };
}
