import { ReactNode, useMemo } from "react";
import { observer } from "mobx-react";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import { defaultTheme } from "@conf/utilifeed.theme";

import { useInject } from "./hooks/useStores";

export const MuiThemeProvider = observer(({ children }: { children: ReactNode }) => {
  const { ui } = useInject("ui");

  const theme = useMemo(() => {
    const appTheme = createTheme(defaultTheme);
    if (appTheme?.components?.MuiCssBaseline) {
      appTheme.components.MuiCssBaseline.styleOverrides = {
        // @ts-expect-error mui-theme
        ...(appTheme.components.MuiCssBaseline.styleOverrides ?? {}),
        // If ui.maskData is true, then we want to mask the data in the UI.
        // We just blur the elementst that have the data-maskdata attribute.
        ...(ui.maskData && {
          "*[data-maskdata]": {
            filter: "blur(9px)",
          },
        }),
      };
    }
    return appTheme;
  }, [ui.maskData]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
});
