import { COLUMN_PERIOD, COLUMN_SECTION } from "./constants";

const EP_CORE_COLUMNS = {
  heat_energy_sum: {
    label: "text_energy_kwh",
    sublabel: "sub_text_energy_kwh_normal_year",
    info: "text_energy_kwh_normal_year_desc",
    section: COLUMN_SECTION.energy,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
  },
  volume_sum: {
    label: "text_volume_m3",
    sublabel: "sub_text_volume_m3_normal_year",
    info: "sub_text_volume_m3_normal_year_desc",
    section: COLUMN_SECTION.flow,
    periods: [COLUMN_PERIOD.year],
  },
  returntemp_flowweighted_avg: {
    label: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_normal_year",
    info: "sub_text_return_temp_c_normal_year_desc",
    section: COLUMN_SECTION.temperatures,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
  },
  returntemp_powerweighted_avg: {},
  returntemp_unweighted_avg: {},
  supplytemp_flowweighted_avg: {
    label: "text_supply_temperature_c",
    sublabel: "sub_text_supply_temp_c_normal_year",
    info: "sub_text_supply_temp_c_normal_year_desc",
    section: COLUMN_SECTION.temperatures,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
  },
};

export default EP_CORE_COLUMNS;
