import type { ReactNode } from "react";

import { useSubBarPortal } from "./useSubBarPortal";

/**
 * @deprecated This is now just here for backward compat. see routes.ts and useSubBarPortal
 *
 * Remove this component from any file its used.
 *
 * @param {*} {children}
 * @return {*}
 */
function PageWrapper({
  children,
  formComponents,
}: {
  children: ReactNode;
  formComponents: ReactNode;
}) {
  const portalToSubBar = useSubBarPortal(formComponents);

  return (
    <>
      {children}
      {" "}
      {portalToSubBar}
    </>
  );
}

export default PageWrapper;
