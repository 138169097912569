import { observer } from "mobx-react";
import { Box, Tooltip, Typography } from "@mui/material";

type LargeNumberProps = {
  value?: string | number | null;
  exp?: number;
  unit?: string;
  color?: string;
  size?: "default" | "small";
  tooltip?: string | null;
};

const LargeNumberPure: React.FC<LargeNumberProps> = ({
  value = "",
  exp = 0,
  unit = "",
  color = "secondary",
  size = "default",
  tooltip = null,
}) => (
  <Box
    sx={{
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      cursor: "default",
    }}
    data-testid="large-number"
  >
    <Tooltip title={tooltip} followCursor placement="top" TransitionProps={{ timeout: 600 }}>
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          alignItems: "baseline",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            "& > h1": {
              fontSize: "5.714rem",
              "@media (max-width: 1400px)": {
                fontSize: "4.714rem",
              },
            },
          }}
        >
          <Typography
            variant={size === "small" ? "h2" : "h1"}
            component="h5"
            color={color}
            data-maskdata
          >
            {value}
          </Typography>
          {exp !== 0 && (
            <Box>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <Typography variant="h2" color={color}>
                x10<sup>{exp}</sup>
              </Typography>
            </Box>
          )}
        </Box>
        <Typography component="span" variant="subtitle1" color={color} style={{ marginLeft: 4 }}>
          {unit}
        </Typography>
      </Box>
    </Tooltip>
  </Box>
);

export const LargeNumber = observer(LargeNumberPure);
