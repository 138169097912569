import { faFlag } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

export function getFaultLevel(type: string, faults: string[] = []): any {
  // faults is an array eg ["fv", "ft", "fe"]
  // fv = fault_flow
  // ft = fault_supplytemp_dipp
  // fe = fault_return_temperature
  // Return the highest priority fault
  if (faults.includes(type)) {
    return <FontAwesomeSvgIcon icon={faFlag} color="primary" />;
  }
  return null;
}
