import { Component } from "react";
import { inject, observer } from "mobx-react";
import withTheme from "@mui/styles/withTheme";

import { isValue, replaceUndefinedWithNulls } from "@core/utils";

import { HighchartsBase } from "../../ui/analytics/charts";
import { formatTooltipValue } from "./charts/utils";

class GraphColCompare extends Component {
  render() {
    const {
      series1,
      series2,
      yUnit,
      width,
      categories,
      theme,
      dense,
      scaleYAxis = false,
    } = this.props;
    return (
      <HighchartsBase
        yAxisScale={scaleYAxis}
        xAxis={{
          gridLineWidth: 0,
          lineWidth: 0,
          categories,
          labels: {
            formatter() {
              return categories[this.pos];
            },
          },
        }}
        yUnit={yUnit}
        yAxisOptions={{
          title: {
            text: "",
          },
          gridLineWidth: 0,
          lineWidth: 0,
        }}
        chart={{
          height: dense ? 180 : 240,
          marginTop: 20,
          type: "column",
          zoomType: "",
          width,
          backgroundColor: "white",
        }}
        seriesOptions={{ marker: { enabled: false } }}
        tooltip={{
          shared: true,
          formatter() {
            let ttip = ``;
            if (this.points[0] && isValue(this.points[0].y)) {
              ttip += `<span style="color:${this.points[0].color};">&#9679;&nbsp;${
                this.points[0].series.userOptions.label
              } ${formatTooltipValue(this.points[0].y, yUnit)} </span><br/>`;
            }
            if (this.points[1] && isValue(this.points[1].y)) {
              ttip += `<span style="color:${this.points[1].color};">&#9679;&nbsp;${
                this.points[1].series.userOptions.label
              } ${formatTooltipValue(this.points[1].y, yUnit)} </span><br/>`;
            }
            if (
              this.points[1] &&
              this.points[0] &&
              isValue(this.points[1].y) &&
              isValue(this.points[0].y)
            ) {
              ttip += `<span>&nbsp;&nbsp;&nbsp;Difference ${formatTooltipValue(
                this.points[0].y - this.points[1].y,
                yUnit
              )}</span>`;
            }
            return ttip;
          },
        }}
        legend={{ enabled: false }}
        disableBoost
        plotOptions={{
          series: {
            pointWidth: 35,
          },
        }}
        series={[
          {
            ...series1,
            data: replaceUndefinedWithNulls(series1.data),
            type: "column",
            color: theme.palette.graph.blue,
            showInLegend: true,
            pointWidth: 10,
          },
          {
            ...series2,
            data: replaceUndefinedWithNulls(series2.data),
            type: "line",
            showInLegend: true,
            color: theme.palette.secondary.main,
          },
        ]}
      />
    );
  }
}

export default inject("rootStore")(withTheme(observer(GraphColCompare)));
