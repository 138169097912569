import { useRef } from "react";

/**
 * check if current render is first.
 *
 * @export
 * @return {boolean} isFirstMount?
 */
export function useFirstMountState() {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}
