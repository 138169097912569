export const FD_DATE_FORMAT = "yyyy-MM-dd";

export const R12 = "rolling 12 months";

export const SUBSTATION_STATES = [
  {
    label: "text_ignored",
    value: "ignored",
  },
  {
    label: "text_relevant",
    value: "relevant",
  },
];
export const SUBSTATION_OTHER_STATES = [null, "visible"];
export const FILTER_STATES = new Map([
  ["All", "text_all"],
  ["Ignored", "text_ignored"],
  ["Relevant", "text_relevant"],
]);
