import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Autocomplete, Box, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";

import { SubHeaderSection } from "@components/ui/SubHeader";
import type { NetworkStoreType } from "@stores/networks";
import ClusterIcon from "@icons/Cluster";
import SubstationIcon from "@icons/Substation";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

import type { ConsumptionStore } from "./ConsumptionOverview/Consumption.store";

type SearchBoxProps = {
  consumptionStore: ConsumptionStore;
  summary: any;
  explore: any;
  reader: any;
};

const infoLoader = <Skeleton animation="wave" sx={{ bgcolor: "white" }} variant="rectangular" />;

// Utility function to get labels for different UI elements based on state.
function getLabels(summary: any, sub: any, ui: any, isLoading: boolean) {
  const { customer, install_address: installAddress } = summary.data;
  const isSubstation = ui.is_subsummary_open;
  const isCluster = ui.is_cluster_summary_open;
  let idLabel = isSubstation ? summary.data.id : sub.current_cluster?.name;
  let customerLabel = customer?.name ?? "-";
  let streetLabel = installAddress?.street ?? "-";

  // Replace labels with loaders if data is still being fetched
  if (isLoading) {
    customerLabel = infoLoader;
    streetLabel = infoLoader;
    idLabel = infoLoader;
  }

  if (isCluster && !isLoading) [idLabel] = idLabel.split("$");

  return { idLabel, customerLabel, streetLabel, isSubstation, isCluster };
}

// Custom hook to memoize search data for Autocomplete component
function useSearchData(
  networks: NetworkStoreType,
  isSubstation: boolean,
  reader: any,
  explore: any
) {
  const searchRecords = isSubstation ? networks.current_substations : networks.current_clusters;
  // Compute search data based on whether we are in substation or cluster mode
  return useMemo(
    () =>
      // @ts-expect-error Type must have a [Symbol.iterator]() method that returns an iterator.
      Array.from(searchRecords)
        .map(([uuId, entryId]) => {
          const entryData = isSubstation ? reader(uuId) : {};
          const label = isSubstation
            ? `${entryId} | ${entryData.customer} | ${entryData.street}`
            : // @ts-expect-error legacy
              entryId.split("$")[0];

          return {
            label,
            value: uuId,
            entryId,
            ...entryData,
          };
        })
        // Sort options based on the filtered list in the explore store
        .sort(
          (a: any, b: any) =>
            explore.filteredList.indexOf(a.value) - explore.filteredList.indexOf(b.value)
        ),
    [searchRecords, isSubstation, reader, explore.filteredList]
  );
}

export const SearchBox = observer(
  ({ consumptionStore, summary, explore, reader }: SearchBoxProps) => {
    const { networks, sub, ui } = useInject("networks", "sub", "ui");
    const [searchEnabled, setSearchEnabled] = useState(false);
    const [width, setWidth] = useState(300);
    // Reference to the info box for width calculations
    const infoboxRef = useRef<HTMLDivElement>();

    const isLoading = !summary.dataFetched || !explore.dataFetched;

    const { idLabel, customerLabel, streetLabel, isSubstation, isCluster } = getLabels(
      summary,
      sub,
      ui,
      isLoading
    );

    const searchData = useSearchData(networks, isSubstation, reader, explore);

    useEffect(() => {
      if (
        infoboxRef.current &&
        (summary.data.customer?.name || summary.data.install_address?.street)
      )
        setWidth(infoboxRef.current.offsetWidth);
    }, [summary.data]);

    const entryId = isSubstation ? summary.data.id : sub.current_cluster.id;
    const entryData = searchData.find((data) => data.entryId === entryId) ?? { label: "" };

    const handleOptionSelected = useCallback(
      (_: any, selectedItem: any) => {
        if (!selectedItem?.value) return;

        // Update state and refetch data based on selection
        if (isSubstation) sub.updateCurrentSubstation(selectedItem.value);
        else if (isCluster)
          sub.updateCurrentCluster({ id: selectedItem.value, name: selectedItem.label });

        consumptionStore.reset();
      },
      [consumptionStore, isCluster, isSubstation, sub]
    );

    return (
      <>
        {isSubstation && <SubstationIcon />}
        {isCluster && <ClusterIcon />}

        <Box
          display="flex"
          onClick={() => {
            setSearchEnabled(true);
          }}
        >
          {!searchEnabled && (
            <Box
              ref={infoboxRef}
              minWidth="200px"
              display="flex"
              alignItems="center"
              data-testid="extend-header-info"
            >
              <SubHeaderSection sx={{ minWidth: isCluster ? "200px" : "100px" }}>
                <Typography variant="h5" data-testid="extend-panel-title">
                  {idLabel}
                </Typography>
              </SubHeaderSection>
              {isSubstation && (
                <>
                  <SubHeaderSection sx={{ minWidth: "100px" }}>
                    <Typography variant="h6">{customerLabel}</Typography>
                  </SubHeaderSection>
                  <SubHeaderSection sx={{ minWidth: "100px" }}>
                    <Typography variant="h6">{streetLabel}</Typography>
                  </SubHeaderSection>
                </>
              )}
            </Box>
          )}
        </Box>

        {/* Search */}
        <Tooltip arrow placement="top" title="Click to search">
          <Autocomplete
            blurOnSelect
            disableClearable
            disablePortal
            clearOnBlur
            popupIcon={<FontAwesomeSvgIcon icon={faMagnifyingGlass} />}
            open={searchEnabled}
            value={entryData}
            loading={isLoading}
            options={searchData}
            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, { entryId: id, street, customer: customerName }) => (
              <Box
                component="li"
                sx={{
                  "&&": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  },
                }}
                {...props}
              >
                <Typography
                  sx={{
                    typography: "h3",
                    fontSize: 14,
                    lineHeight: 1,
                    color: "grey.600",
                  }}
                >
                  {customerName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: 1.6,
                  }}
                >
                  {street}
                </Typography>
                <Typography
                  sx={{
                    typography: "h6",
                    color: "grey.600",
                    pt: 1.5,
                    fontSize: 12,
                  }}
                >
                  {id}
                </Typography>
              </Box>
            )}
            onChange={handleOptionSelected}
            onOpen={() => {
              setSearchEnabled(true);
            }}
            onClose={() => {
              setSearchEnabled(false);
            }}
            sx={{
              minWidth: `${width + 40}px`,
              marginLeft: searchEnabled ? "0" : `-${width}px`,
              "& .MuiInputBase-root": {
                typography: "h6",
                color: "white",
                input: {
                  // Move the text out of the screen
                  // This is a hack to make invisible input focused
                  textIndent: !searchEnabled ? "-9999px" : "0",
                },
                fieldset: {
                  border: "none",
                },
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: "white",
                transform: "none !important",
                transition: "color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              },
              "&.Mui-expanded": {
                "& .MuiAutocomplete-popupIndicator": {
                  color: "secondary.main",
                },
              },
            }}
          />
        </Tooltip>
      </>
    );
  }
);
