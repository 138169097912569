import { UNIT } from "../blocks";
import { COLUMN_PERIOD, COLUMN_SECTION } from "./constants";

// --- AVERAGE PEAK
const AVERAGE_PEAK_1H_COLUMNS = {
  avg_peak_power: {
    label: "text_power_peak_kw",
    sublabel: "sub_text_power_peak_kw_1h",
    info: "text_power_peak_kw_1h_desc",
    type: "number",
    spec: {
      unit: UNIT.energy,
    },
    section: COLUMN_SECTION.design_load,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.month, COLUMN_PERIOD.r12],
  },
  avg_peak_flow: {
    label: "text_flow_peak_m3_h",
    sublabel: "sub_text_flow_peak_m3_h_1h",
    info: "text_flow_peak_m3_h_1h_desc",
    type: "number",
    spec: {
      unit: UNIT.energy,
    },
    section: COLUMN_SECTION.design_load,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.month, COLUMN_PERIOD.r12],
  },
};

const AVERAGE_PEAK_24H_COLUMNS = {
  avg_peak_power: {
    label: "text_power_peak_kw",
    sublabel: "sub_text_power_peak_kw_24h",
    info: "text_power_peak_kw_24h_desc",
    type: "number",
    spec: {
      unit: UNIT.energy,
    },
    section: COLUMN_SECTION.design_load,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.month, COLUMN_PERIOD.r12],
  },
  avg_peak_flow: {
    label: "text_flow_peak_m3_h",
    sublabel: "sub_text_flow_peak_m3_h_24h",
    info: "text_flow_peak_m3_h_24h_desc",
    type: "number",
    spec: {
      unit: UNIT.energy,
    },
    section: COLUMN_SECTION.design_load,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.month, COLUMN_PERIOD.r12],
  },
};

export { AVERAGE_PEAK_1H_COLUMNS, AVERAGE_PEAK_24H_COLUMNS };
