/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Theme, useTheme } from "@mui/system";

import { formatNumberForUnitMainLabel } from "@core/utils";

const PREFIX = "ArrowDeviation";

const classes = {
  section: `${PREFIX}-section`,
  upIcon: `${PREFIX}-upIcon`,
  downIcon: `${PREFIX}-downIcon`,
};

const Root = styled("div")(() => ({
  [`&.${classes.section}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.upIcon}`]: {
    marginTop: "-8.5px",
  },

  [`& .${classes.downIcon}`]: {
    marginBottom: "-1rem",
  },
}));

const isValue = (value: any) =>
  value !== undefined &&
  value !== null &&
  value !== "" &&
  value !== "NaN" &&
  // eslint-disable-next-line no-restricted-globals
  !isNaN(value) &&
  value !== Infinity;

// eslint-disable-next-line import/no-default-export
export default forwardRef(
  ({ suffix, value, prefix, default_up = true, color = "secondary", ...restProps }: any, ref) => {
    const theme = useTheme<Theme>();
    const up = default_up ? value >= 0 : value > 0;

    let mainNumber = "";
    let mainPrefix = prefix;
    let mainUnit = suffix;
    if (isValue(value)) {
      [mainNumber, , mainPrefix, mainUnit] = formatNumberForUnitMainLabel(Math.abs(value), suffix);
    }

    const colorText = typeof color !== "string" ? theme.palette[color[0]][color[1]] : color;
    const colorProps: any = {};
    const styleProps: any = {};
    if (typeof color === "string") {
      colorProps.color = color;
    } else {
      styleProps.color = colorText;
    }
    return (
      <Root
        className={classes.section}
        style={{ ...styleProps, display: "flex" }}
        {...restProps}
        ref={ref}
      >
        {up ? (
          <div className={classes.upIcon}>
            <ArrowDropUpIcon fontSize="large" data-testid="arrow-up" {...colorProps} />
          </div>
        ) : (
          <div className={classes.downIcon}>
            <ArrowDropDownIcon fontSize="large" data-testid="arrow-down" {...colorProps} />
          </div>
        )}
        <Typography component="span" variant="h3" {...colorProps}>
          {mainPrefix} {mainNumber} {mainUnit}
        </Typography>
      </Root>
    );
  }
);
