import { useEffect, useState } from "react";
import { autorun } from "mobx";

/**
 * Mobx selector util as an replacement for deprecated useObserver
 *
 * @param selectorFn a function to monitor and return desired Mobx Store values
 */
function useMobxSelector(selectorFn) {
  const [selected, setSelected] = useState(selectorFn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => autorun(() => setSelected(selectorFn())), []);
  return selected;
}

export default useMobxSelector;
