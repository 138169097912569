import { useState } from "react";
import { observer } from "mobx-react";
import { Box, Button, Typography } from "@mui/material";

import { logger } from "@core/logger";
import { useInject, useReaction } from "@hooks";

function LoginPage() {
  const { session, routerStore } = useInject("session", "routerStore");
  const [redirecting, setRedirecting] = useState(false);

  const onSignInClick = () => {
    setRedirecting(true);
    session.login();
  };

  useReaction(
    () => [session],
    () => {
      (async () => {
        const isLoggedin = await session.checkSession();
        if (isLoggedin) {
          logger.debug("User is already logged in. Redirecting to dashboard");
          await routerStore.goTo("dash");
        }
      })();
    },
    { fireImmediately: true }
  );

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1E3250",
        backgroundImage: "url('/login-logo.webp')",
        backgroundPosition: "center",
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" mt={26}>
        <Button
          disabled={redirecting}
          variant="contained"
          onClick={onSignInClick}
          color="primary"
          size="large"
          sx={{
            border: "2px solid #FFFFFF",
            borderRadius: "10px",
            backgroundColor: "transparent",
            width: 180,
            height: 50,
            fontSize: "135%",
          }}
          data-testid="login-button"
          // eslint-disable-next-line i18next/no-literal-string
        >
          {redirecting ? "Signing in..." : "Sign in"}
        </Button>
        <Box mt={2}>
          <Typography variant="h6" color="secondary">
            {session.errorMsg}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default observer(LoginPage);
