import { observer } from "mobx-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, IconButton } from "@mui/material";

import StepperDot from "./StepperDot";

function StepperConnector({
  onClickNextHandler,
  onClickPrevHandler,
  activeStep,
  maxSteps,
  setActiveHandler,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "16px",
      }}
    >
      <IconButton data-testid="prev-carousel" size="large" onClick={() => onClickPrevHandler()}>
        <ChevronLeftIcon size="large" />
      </IconButton>
      <StepperDot activeStep={activeStep} maxSteps={maxSteps} setActiveHandler={setActiveHandler} />
      <IconButton data-testid="next-carousel" size="large" onClick={() => onClickNextHandler()}>
        <ChevronRightIcon size="large" />
      </IconButton>
    </Box>
  );
}

export default observer(StepperConnector);
