import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

type UtfButtonProps = {
  loading?: boolean;
  children?: React.ReactNode;
  color?: "primary" | "secondary" | "inherit" | "warning" | "error" | "success" | "info";
  variant?: "text" | "outlined" | "contained";
  disabled?: boolean;
  disableElevation?: boolean;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  autoFocus?: boolean;
} & Omit<ButtonProps, "color" | "size" | "variant">;

export default function UtfButton({
  loading = false,
  children = "Button",
  color = "primary",
  variant = "contained",
  disabled = false,
  disableElevation = false,
  onClick = () => {
    /* noop */
  },
  size = "medium",
  autoFocus = false,
  ...props
}: UtfButtonProps) {
  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      disabled={disabled}
      disableElevation={disableElevation}
      onClick={onClick}
      autoFocus={autoFocus}
      {...props}
    >
      {loading ? <CircularProgress color="inherit" size={20} /> : children}
    </Button>
  );
}
