import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { useMeterSeries } from "@utilifeed/hooks";
import { TOOLBOX_OPTION_GRAPH_GRID } from "@config/chartToolbox";
import { DATE_FORMAT, DATETIME_FORMAT_DISPLAY, DAY_MILIS, HOUR_MILIS } from "@config/constants";
import * as CONSUMPTION from "@config/consumption";
import { formatNumberForLocale } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { ChartToolbox, GraphContainer, HighchartsBase } from "@shared/ui/analytics/charts";
import { generateToolboxAnnotations } from "@shared/ui/analytics/charts/utils";
import withErrorBoundary from "@shared/ui/withErrorBoundary";

import { useConsumption } from "./Consumption.store";
import { consumptionLogger } from "./utils";

const logger = consumptionLogger.getSubLogger({ name: "<MeterData/>" });

const chartHeight = 550;

export const MeterData = withErrorBoundary(
  observer(() => {
    const { t } = useTranslation(["extendView"]);
    const {
      extremes,
      flowLimiter,
      hasPartialData,
      averaged,
      registerChart,
      chartWidth,
      readerRange,
      seriesInExtremes,
    } = useConsumption();
    const theme = useTheme();
    const meterChartRef = useRef();
    const [meterDataToolbox, setMeterDataToolbox] = useState([]);
    const { setVisibleSeries, chartData } = useMeterSeries(
      seriesInExtremes,
      averaged,
      readerRange,
      chartHeight,
      flowLimiter
    );

    useEffect(() => {
      if (!hasPartialData) return;
      registerChart("primary", meterChartRef);
    }, [hasPartialData, registerChart]);

    // Prepare export data for instant CSV and XLSX exports
    const exportData = [];
    const seriesToExport = chartData.series.filter((seriesItem) => seriesItem.visible);
    exportData[0] = ["DateTime", ...seriesToExport.map((seriesItem) => seriesItem.name)];

    seriesToExport.forEach((seriesItem, seriesIndex) => {
      seriesItem.data.forEach((seriesData, dataIndex) => {
        const rowIndex = dataIndex + 1;
        if (!exportData[rowIndex]) exportData[rowIndex] = [];
        exportData[rowIndex][0] = DateTime.fromMillis(seriesItem.timeStamp[dataIndex]).toFormat(
          averaged ? DATE_FORMAT : DATETIME_FORMAT_DISPLAY
        );
        exportData[rowIndex][seriesIndex + 1] = seriesData;
      });
    });
    const exportFileName = `${extremes.start?.year}-${extremes.start?.month} to ${extremes.end?.year}-${extremes.end?.month}`;
    const chartId = "meter";

    logger.trace("RENDER");

    return (
      <GraphContainer
        title={t("text_meter_data")}
        hasData
        subTitles={
          <ChartToolbox
            values={meterDataToolbox}
            setValues={setMeterDataToolbox}
            disabled={!hasPartialData}
            data-testid={`${chartId}-toolbox`}
          />
        }
        data-testid={`chart-${chartId}-container`}
      >
        <HighchartsBase
          constructorType="stockChart"
          ref={meterChartRef}
          loading={!hasPartialData}
          showGraphUnit={false}
          chart={{
            id: chartId,
            height: `${chartHeight}px`,
            width: chartWidth,
          }}
          annotations={generateToolboxAnnotations({
            series: chartData.series,
            options: meterDataToolbox,
          })}
          series={chartData.series}
          plotOptions={{
            series: {
              dataGrouping: {
                units: [
                  ["hour", [1, 3, 6, 12]],
                  ["day", [1]],
                  ["month", [1, 3, 6]],
                  ["year", null],
                ],
                forced: averaged ? true : undefined,
              },
              connectNulls: true,
              marker: {
                enabled: undefined, // This needs to be undefined to use the default value
                enabledThreshold: 2, // Enable if the distance between points is less than 2 x radius
                radius: 3,
              },
              events: {
                legendItemClick: createSeriesToggleHandler(setVisibleSeries),
              },
            },
          }}
          rangeSelector={{
            enabled: false,
          }}
          legend={{
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "top",
            y: 0,
            labelFormatter() {
              const { color, name, userOptions } = this;
              return `<div data-testid="${chartId}__legend__${userOptions.id}" style="border-bottom: 3px solid ${color}">${name}</div>`;
            },
            itemStyle: {
              ...theme.typography.body2,
            },
          }}
          yAxis={chartData.yAxis}
          xAxis={{
            type: "datetime",
            labelFormatType: "dateOrTime",
            tickInterval: averaged ? DAY_MILIS : HOUR_MILIS,
            minRange: 1,
            gridLineWidth: meterDataToolbox.includes(TOOLBOX_OPTION_GRAPH_GRID) ? 1 : 0,
          }}
          navigator={{
            enabled: false,
          }}
          scrollbar={{
            enabled: false,
          }}
          tooltip={{
            split: true,
            formatter() {
              const x = DateTime.fromMillis(this.points[0].x).toFormat(
                averaged
                  ? CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD
                  : CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD_HH_MM
              );
              const k = [`<b>${x}</b>`];
              this.points.forEach((point) => {
                const unit = point.series.tooltipOptions.valueSuffix;
                const exactPointVal =
                  point.series.yData[point.series.xData.indexOf(point.x)] || point.y;
                const valY = formatNumberForLocale(exactPointVal);
                k.push(`<b> ${point.series.name} ${valY} ${unit}</b>`);
              });
              return k;
            },
          }}
          exportData={exportData}
          exporting={{
            filename: exportFileName,
          }}
        />
      </GraphContainer>
    );
  })
);

MeterData.displayName = "Consumption.MeterData";
