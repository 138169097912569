import { flow, makeAutoObservable } from "mobx";

import urls from "@conf/urls";

export default class FaultDetectionApi {
  faultDetectionKey = "fault-detection-visibility";

  constructor(parent) {
    this.parent = parent;
    // eslint-disable-next-line rulesdir/mobx-makeAutoObservable-should-memory-safe
    makeAutoObservable(this);
  }

  // This gets the faults for a specific network between two dates
  getFaultsForNetwork = flow(function* (networkId, fromDate, toDate, substationIds = []) {
    const headers = yield this.parent.session.authHeaders();
    const params = {
      "datetime[ge]": fromDate,
      "datetime[lt]": toDate,
    };
    if (substationIds.length > 0) {
      params["uid[in]"] = substationIds.join(",");
    }
    const response = yield fetch(
      `${urls.mdslv4}/networks/${networkId}/substations/faults?${new URLSearchParams(params)}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.ok) {
      return yield response.json();
    }
    throw new Error("Failed to get faults");
  });

  getSubstationFaultStatus = flow(function* (substationId) {
    // For now we have to get the full substation data, and then filter it
    // https://utilifeed.atlassian.net/wiki/spaces/DV/pages/3012395089/Fault+detection+Finalised+API#GET-status-for-substation
    // TODO: [PLAT-2793] Add an endpoint to get a single info-property on a substation
    const headers = yield this.parent.session.authHeaders();
    const response = yield fetch(`${urls.mdslv4}/substations/${substationId}`, {
      method: "GET",
      headers,
    });
    if (response.ok) {
      const substation = yield response.json();
      const faultStatus = substation.data.info_properties.find(
        (prop) => prop.key === this.faultDetectionKey
      );
      return faultStatus ? faultStatus.value : null;
    }
    throw new Error("Failed to get substation fault status");
  });

  setSubstationFaultStatus = flow(function* (networkId, substationId, status) {
    // PUT /networks/{network_uid}/substations/info-properties
    // https://utilifeed.atlassian.net/wiki/spaces/DV/pages/3012395089/Fault+detection+Finalised+API#GET-status-for-substation
    const headers = yield this.parent.session.authHeaders();
    const response = yield fetch(
      `${urls.mdslv4}/networks/${networkId}/substations/info-properties`,
      {
        method: "PUT",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            property_key: this.faultDetectionKey,
            substation_uid: substationId,
            value: status.toLowerCase(),
          },
        ]),
      }
    );
    if (response.ok) {
      return yield response.json();
    }
    throw new Error("Failed to set substation fault status");
  });

  fetchSubstationNotes = flow(async function* (substationID) {
    const headers = yield this.parent.session.authHeaders();
    let resp;
    try {
      resp = await fetch(`${urls.mdslv4}/substations/notes/?substation_uid[in]=${substationID}`, {
        headers,
        "Content-Type": "application/json",
        method: "GET",
      });
    } catch (err) {
      throw new Error(err);
    }
    const payload = yield resp.json();
    if (resp.ok) {
      return payload;
    }
    throw new Error(payload);
  });

  /*
  To get all substation notes we need to fetch it by chunk since we don't know which
  substation is fault and we can't request all notes at once due to
  error: request-header too large
  * */
  getSubstationNotes = flow(async function* (idOfSubstations) {
    const substationNotes = [];
    const chunkSize = 50;
    let startIdx = 0;
    let endIdx = 50;
    const loopStep = parseInt(idOfSubstations.length / chunkSize) + 1;

    for (let i = 0; i < loopStep; i++) {
      const idOfSubstationByIdx = idOfSubstations.slice(startIdx, endIdx);
      const strOfSubstation = idOfSubstationByIdx.join(",");

      // to avoid cypress failing test
      // eslint-disable-next-line no-continue
      if (strOfSubstation === "") continue;

      const fetchSubstationNotes = yield this.fetchSubstationNotes(strOfSubstation);
      const substationNote = fetchSubstationNotes.data.collection;

      if (substationNote) substationNotes.push(substationNote);

      startIdx = endIdx;
      endIdx += chunkSize;
    }
    return substationNotes;
  });
}
