import { action, flow, makeObservable, observable, toJS } from "mobx";

import { BLOCK, SUBSTATION_BLOCK_TYPES as SBT } from "@conf/blocks";
import {
  PRICING_POWER_COMPONENT_TYPE,
  PRICING_POWER_COSTUNIT,
  PRICING_POWER_PERIOD,
  PRICING_TEMP_AVG_OPTIONS,
  SPLIT_CATEGORY,
  SPLIT_TYPE,
} from "@conf/constants";
import { have_blocks, randstring, range } from "@core/utils";

export function calculateSubs(tree, subs, blocks) {
  tree.node.subs = subs;
  if (tree.node.split !== SPLIT_TYPE.categorical || !tree.node.source) return;
  const sourceMeta = SPLIT_CATEGORY[tree.node.source];
  const blockName = BLOCK[sourceMeta.block].to_block_name({});
  if (!have_blocks(blocks, [blockName])) return;

  const childBins = {};
  const childValues = {};
  let isMissingBin = null;
  const childIds = Object.keys(tree.children);
  for (const childNodeId of childIds) {
    childBins[childNodeId] = new Set();
    if (tree.children[childNodeId].node.condition?.data.missing) {
      isMissingBin = childNodeId;
    }
    childValues[childNodeId] = new Set(tree.children[childNodeId].node.condition?.data.values);
  }

  for (const sub of subs) {
    const data = BLOCK[sourceMeta.block].get_value(blocks, blockName, sub, sourceMeta.column);
    if (data?.value) {
      for (const childId of childIds) {
        if (childValues[childId].has(data["value"])) {
          childBins[childId].add(sub);
        }
      }
    } else if (isMissingBin) {
      childBins[isMissingBin].add(sub);
    }
  }
  for (const childId of childIds) {
    calculateSubs(tree.children[childId], Array.from(childBins[childId]), blocks);
  }
}

function replicateCgtreeNode(node) {
  const rnode = {};
  rnode.children = node.children;
  rnode.node = toJS(node.node);
  Object.keys(node.children).forEach((childId) => {
    rnode.children[childId] = replicateCgtreeNode(node.children[childId]);
  });
  return rnode;
}

function replicateCgtree(cgtree) {
  const res = { children: {} };
  Object.keys(cgtree.children).forEach((childId) => {
    res.children[childId] = replicateCgtreeNode(cgtree.children[childId]);
  });
  return res;
}

function fixed() {
  return { value: 0 };
}

function flowv() {
  return { value: 0 };
}

function energy() {
  const ret = { alternate: false };
  range(1, 13, 1).forEach((month) => {
    ret[month] = { high: 0, low: 0, day: 0, night: 0, weekend: 0 };
  });
  return ret;
}

function rt() {
  const ret = {
    model: "variable",
    avgtype: "flow",
    temptype: "rt_abs",
    costunit: "mwh",
    cu_single: false,
    cu_bonus: 0,
    cu_malus: 0,
    n_rng_agg: false,
    cost: 0,
    activemonths: {},
    dcost: [[0, 0]],
  };
  range(1, 13, 1).forEach((month) => {
    ret.activemonths[month] = true;
  });
  return ret;
}

function power() {
  const ret = {
    pct: PRICING_POWER_COMPONENT_TYPE.measured,
    penalty: 0,
    activemonths: {},
    npeaks: "top1",
    tperiod: PRICING_POWER_PERIOD.twelve_month,
    tavg: PRICING_TEMP_AVG_OPTIONS.DAILY,
    otemp: 0,
    costunit: PRICING_POWER_COSTUNIT.subscribed_power,
    dcost: [[0, 0, 0, 0]],
    ccurve: true,
    temp_dependent: false,
  };
  range(1, 13, 1).forEach((month) => {
    ret.activemonths[month] = true;
  });
  return ret;
}

const FM = {
  fixed,
  flow: flowv,
  energy,
  rt,
  power,
};

class Store {
  conf_detail_tab = "price_components";

  conf_page_no = 0;

  configRowPerPage = 25;

  confSrtId = "name";

  confSortDirection = "asc";

  conf_selected = 2;

  customer_groups = [];

  pricing_components = [];

  pricing_configurations = [];

  pricing_component_types = [];

  current_conf = null;

  priceComponentEdit = {};

  cgroupEdit = { children: {} };

  clone_tree = { children: {} };

  draggedNode = null;

  dragStart(node) {
    this.draggedNode = node;
  }

  getCGTreeClone() {
    this.clone_tree = replicateCgtree(this.cgtree);
  }

  remCGTreeClone() {
    this.clone_tree = { children: {} };
  }

  dragEnd() {
    this.draggedNode = null;
  }

  shouldGenCGTree() {
    return [this.conf_selected, ...this.customer_groups];
  }

  generateCGTree = flow(function* () {
    const tree = { children: {} };
    // create tree view

    this.customer_groups.forEach((node) => {
      const currentPath = node.path.split(".");
      let tempnode = tree;
      currentPath.slice(0, -1).forEach((part) => {
        if (!Object.getOwnPropertyDescriptor(tempnode.children, part)) {
          tempnode.children[part] = { children: {} };
        }
        tempnode = tempnode.children[part];
      });
      const leafPart = currentPath[currentPath.length - 1];

      if (Object.getOwnPropertyDescriptor(tempnode.children, leafPart)) {
        tempnode.children[leafPart].node = node;
      } else {
        tempnode.children[leafPart] = { node, children: {} };
      }
    });
    const subs = new Set(this.parent.networks.current_substations.keys());
    const blocksRequired = [];

    this.customer_groups
      .filter((cg) => cg.split === SPLIT_TYPE.categorical && cg.source)
      .forEach((cg) => {
        blocksRequired.push(SBT[SPLIT_CATEGORY[cg.source].block].to_block_name());
      });
    const blocks = yield this.parent.newapi.getInfoBlocksV4({
      resource_type: "network_substations",
      resource_id: this.parent.networks.current_network.uid,
      block_names: blocksRequired,
    });
    Object.keys(tree.children).forEach((part) => {
      calculateSubs(tree.children[part], subs, blocks);
    });
    this.cgtree = tree;
    this.clone_tree = tree;
  }).bind(this);

  selectComponentType(pct) {
    this.priceComponentEdit = { type: pct, name: "", parameters: FM[pct.name]() };
  }

  editCGgroup(cgroup) {
    this.cgroupEdit = cgroup;
  }

  removeEditCGgroup() {
    this.cgroupEdit = { children: {}, subs: [] };
    this.getCGTreeClone();
  }

  editCGroupName(name) {
    this.cgroupEdit.node.name = name;
  }

  editCGroupSplitType(splitType) {
    this.cgroupEdit.node.split = splitType;
    this.editCGroupSplitSource(null);
  }

  editCGroupSplitSource(splitSource) {
    this.cgroupEdit.node.source = splitSource;
    this.cgroupEdit.children = {};
    this.cgroupEdit.subs = [];
  }

  editCGroupAddSplit() {
    let maxChild = 2;
    Object.keys(this.cgroupEdit.children).forEach((childId) => {
      maxChild = Math.max(maxChild, childId);
    });
    this.cgroupEdit.children[maxChild + 1] = {
      children: {},
      dummy: true,
      node: {
        split: null,
        source: null,
        name: `Container-${randstring(6)}`,
        path: `${this.cgroupEdit.node.path}.${maxChild + 1}`,
        condition: {
          source: this.cgroupEdit.node.source,
          split: this.cgroupEdit.node.split,
          data: {
            values: [],
          },
        },
      },
    };
  }

  cGroupEditChilds(actionVerb, childId, child) {
    if (actionVerb === "delete") {
      delete this.cgroupEdit.children[childId];
    }
    if (actionVerb === "add") {
      this.cgroupEdit.children[childId] = child;
    }
  }

  editComponentTypeName(v) {
    this.priceComponentEdit.name = v;
  }

  changeRowsPerPage(event) {
    this.configRowPerPage = parseInt(event.target.value);
    this.conf_page_no = 0;
  }

  changePageNo(e, value) {
    this.conf_page_no = value;
  }

  selectConf(confId) {
    this.conf_selected = confId;
  }

  changeSortDirection(colId, direction) {
    this.confSrtId = colId;
    this.confSortDirection = direction;
  }

  constructor(parent) {
    // eslint-disable-next-line mobx/exhaustive-make-observable
    makeObservable(this, {
      conf_detail_tab: observable,
      conf_page_no: observable,
      configRowPerPage: observable,
      confSrtId: observable,
      confSortDirection: observable,
      conf_selected: observable,
      customer_groups: observable,
      pricing_components: observable,
      pricing_configurations: observable,
      pricing_component_types: observable,
      current_conf: observable,
      priceComponentEdit: observable,
      cgroupEdit: observable,
      clone_tree: observable,
      draggedNode: observable,
      dragStart: action.bound,
      getCGTreeClone: action.bound,
      remCGTreeClone: action.bound,
      dragEnd: action.bound,
      selectComponentType: action.bound,
      editCGgroup: action.bound,
      removeEditCGgroup: action.bound,
      editCGroupName: action.bound,
      editCGroupSplitType: action.bound,
      editCGroupSplitSource: action.bound,
      editCGroupAddSplit: action.bound,
      cGroupEditChilds: action.bound,
      editComponentTypeName: action.bound,
      changeRowsPerPage: action.bound,
      changePageNo: action.bound,
      selectConf: action.bound,
      changeSortDirection: action.bound,
      changeConfTab: action.bound,
      changeConfDetailTab: action.bound,
      editPriceComponent: action.bound,
      clearPricingComponentEdit: action.bound,
    });

    this.parent = parent;
    this.cgtree = { children: {} };
    this.shouldGenCGTree = this.shouldGenCGTree.bind(this);
    this.generateCGTree = this.generateCGTree.bind(this);
    this.updateCGroupForConfig = this.updateCGroupForConfig.bind(this);
  }

  changeConfTab(tab) {
    this.conf_tab = tab;
  }

  changeConfDetailTab(tab) {
    this.conf_detail_tab = tab;
  }

  getConfigurations = flow(function* () {
    try {
      this.pricing_configurations = yield this.parent.utfapi.getConfigurations({
        network__exact: this.parent.networks.current_network.uid,
      });
      if (this.pricing_configurations.length > 0) {
        this.conf_selected = this.pricing_configurations[0].id;
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to get configuration :${err.error}`);
    }
  }).bind(this);

  updateConfiguration = flow(function* (id, data) {
    try {
      const configuration = yield this.parent.utfapi.updateConfiguration(id, data);
      this.pricing_configurations
        .filter((config) => config.id === id)
        .forEach((config) => {
          Object.keys(data).forEach((confKey) => {
            config[confKey] = configuration[confKey]; // eslint-disable-line no-param-reassign
          });
          config.category = configuration.category; // eslint-disable-line no-param-reassign
        });
    } catch (err) {
      this.parent.app.showMsg("error", `unable to update configuration :${err.error}`);
    }
  }).bind(this);

  copyConfiguration = flow(function* (id) {
    try {
      const configuration = yield this.parent.utfapi.copyConfiguration(id);
      this.pricing_configurations.push(configuration);
    } catch (err) {
      this.parent.app.showMsg("error", `unable to copy configuration :${err.error}`);
    }
  }).bind(this);

  createPriceModelsForCurrentConfiguration = flow(function* (pm) {
    try {
      let currentConfiguration = this.pricing_configurations.filter(
        (p) => p.id === this.conf_selected
      );
      if (currentConfiguration.length > 0) {
        [currentConfiguration] = currentConfiguration;
        yield this.parent.utfapi.createPricingModelsForConfiguration(currentConfiguration.id, pm);
        const pricemodels = yield this.parent.utfapi.getPriceModelsForConfiguration(
          currentConfiguration.id
        );
        if (this.current_conf && this.current_conf.id === currentConfiguration.id) {
          currentConfiguration = this.current_conf;
        }
        currentConfiguration.price_models = pricemodels;
        this.current_conf = currentConfiguration;
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to create model :${err.error}`);
    }
  }).bind(this);

  getPriceModelsForCurrentConfiguration = flow(function* () {
    try {
      let currentConfiguration = this.pricing_configurations.filter(
        (p) => p.id === this.conf_selected
      );
      if (currentConfiguration.length > 0) {
        [currentConfiguration] = currentConfiguration;
        const pricemodels = yield this.parent.utfapi.getPriceModelsForConfiguration(
          currentConfiguration.id
        );
        if (this.current_conf && this.current_conf.id === currentConfiguration.id) {
          currentConfiguration = this.current_conf;
        }
        currentConfiguration.price_models = pricemodels;
        this.current_conf = currentConfiguration;
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to get price models :${err.error}`);
    }
  }).bind(this);

  updatePriceModelForConfiguration = flow(function* (priceModelId, pm) {
    try {
      let currentConfiguration = this.pricing_configurations.filter(
        (p) => p.id === this.conf_selected
      );
      if (currentConfiguration.length > 0) {
        [currentConfiguration] = currentConfiguration;
        yield this.parent.utfapi.updatePricingModelsForConfiguration(
          currentConfiguration.id,
          priceModelId,
          pm
        );
        const pricemodels = yield this.parent.utfapi.getPriceModelsForConfiguration(
          currentConfiguration.id
        );
        if (this.current_conf && this.current_conf.id === currentConfiguration.id) {
          currentConfiguration = this.current_conf;
        }
        currentConfiguration.price_models = pricemodels;
        this.current_conf = currentConfiguration;
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to update price model :${err.error}`);
    }
  }).bind(this);

  deletePriceModelForConfiguration = flow(function* (priceModelId) {
    try {
      let currentConfiguration = this.pricing_configurations.filter(
        (p) => p.id === this.conf_selected
      );
      if (currentConfiguration.length > 0) {
        [currentConfiguration] = currentConfiguration;
        yield this.parent.utfapi.deletePriceModelForConfiguration(
          currentConfiguration.id,
          priceModelId
        );
        const pricemodels = yield this.parent.utfapi.getPriceModelsForConfiguration(
          currentConfiguration.id
        );
        if (this.current_conf && this.current_conf.id === currentConfiguration.id) {
          currentConfiguration = this.current_conf;
        }
        currentConfiguration.price_models = pricemodels;
        this.current_conf = currentConfiguration;
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to delete price model :${err.error}`);
    }
  }).bind(this);

  getPriceComponentsForCurrentConfiguration = flow(function* () {
    try {
      let currentConfiguration = this.pricing_configurations.filter(
        (p) => p.id === this.conf_selected
      );
      if (currentConfiguration.length > 0) {
        [currentConfiguration] = currentConfiguration;
        this.pricing_components = yield this.parent.utfapi.getPriceComponentsForConfiguration(
          currentConfiguration.id
        );
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to get price component :${err.error}`);
    }
  }).bind(this);

  getCGroupForCurrentConfiguration = flow(function* () {
    try {
      let currentConfiguration = this.pricing_configurations.filter(
        (p) => p.id === this.conf_selected
      );
      if (currentConfiguration.length > 0) {
        [currentConfiguration] = currentConfiguration;
        this.customer_groups = yield this.parent.utfapi.getCGroupForConfiguration(
          currentConfiguration.id
        );
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to get customer groups :${err.error}`);
    }
  }).bind(this);

  updateCGroupForConfig = flow(function* updateCGroupForConfigInner() {
    let currentConfiguration = this.pricing_configurations.filter(
      (p) => p.id === this.conf_selected
    );
    if (currentConfiguration.length > 0) {
      [currentConfiguration] = currentConfiguration;
    } else {
      currentConfiguration = null;
    }

    try {
      if (currentConfiguration && this.cgroupEdit.node.id) {
        yield this.parent.utfapi.updateCGroupForConfiguration(
          currentConfiguration.id,
          this.cgroupEdit.node.id,
          this.cgroupEdit.node
        );
        if (this.cgroupEdit.node.split && this.cgroupEdit.node.source) {
          const updatePromises = [];
          Object.keys(this.cgroupEdit.children).forEach((childId) => {
            const childTNode = this.cgroupEdit.children[childId];
            if (childTNode.node.id) {
              updatePromises.push(
                this.parent.utfapi.updateCGroupForConfiguration(
                  currentConfiguration.id,
                  childTNode.node.id,
                  childTNode.node
                )
              );
            } else {
              updatePromises.push(
                this.parent.utfapi.createCGroupForConfiguration(
                  currentConfiguration.id,
                  childTNode.node
                )
              );
            }
          });
          yield Promise.all(updatePromises);
        }
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to update customer groups :${err.error}`);
    } finally {
      if (currentConfiguration) {
        this.customer_groups = yield this.parent.utfapi.getCGroupForConfiguration(
          currentConfiguration.id
        );
        this.cgroupEdit = { children: {} };
      }
    }
  }).bind(this);

  editPriceComponent(component) {
    this.priceComponentEdit = JSON.parse(JSON.stringify(component));
  }

  clearPricingComponentEdit() {
    this.priceComponentEdit = {};
  }

  getPricingComponentTypes = flow(function* () {
    try {
      this.pricing_component_types = yield this.parent.utfapi.getComponentTypes();
    } catch (err) {
      this.parent.app.showMsg("unable to get price component types", err.error);
    }
  }).bind(this);

  savePricingComponent = flow(function* (pc) {
    if (this.conf_selected) {
      try {
        this.priceComponentEdit = yield this.parent.utfapi.savePricingComponent(
          this.conf_selected,
          pc
        );
        yield this.getPriceComponentsForCurrentConfiguration();
        yield this.getPriceModelsForCurrentConfiguration();
      } catch (err) {
        this.parent.app.showMsg("error", `unable to save component :${err.error}`);
      }
    }
  }).bind(this);

  updatePricingComponent = flow(function* (pc) {
    try {
      if (this.conf_selected) {
        this.priceComponentEdit = yield this.parent.utfapi.updatePricingComponent(
          this.conf_selected,
          pc
        );
        yield this.getPriceComponentsForCurrentConfiguration();
        yield this.getPriceModelsForCurrentConfiguration();
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to update component :${err.error}`);
    }
  }).bind(this);

  deletePricingComponent = flow(function* (id) {
    try {
      if (this.conf_selected) {
        yield this.parent.utfapi.deletePricingComponent(this.conf_selected, id);
        this.priceComponentEdit = {};
        yield this.getPriceComponentsForCurrentConfiguration();
        yield this.getPriceModelsForCurrentConfiguration();
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to delete component :${err.error}`);
    }
  }).bind(this);

  addComponentToPricingModel = flow(function* (pmodelId, componentId) {
    try {
      if (this.conf_selected) {
        yield this.parent.utfapi.attachPricingComponentToPriceModel(
          this.conf_selected,
          pmodelId,
          componentId
        );
        yield this.getPriceModelsForCurrentConfiguration();
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to add component to model:${err.error}`);
    }
  }).bind(this);

  addCGroupToPricingModel = flow(function* (priceModelId, customerGroupId) {
    try {
      if (this.conf_selected) {
        yield this.parent.utfapi.attachCGRoupToPriceModel(
          this.conf_selected,
          priceModelId,
          customerGroupId
        );
        yield this.getPriceModelsForCurrentConfiguration();
      }
    } catch (err) {
      this.parent.app.showMsg("error", `unable to add group to model :${err.error}`);
    }
  }).bind(this);

  createNewConfig = flow(function* () {
    try {
      yield this.parent.utfapi.createNewConfig({
        network: this.parent.networks.current_network.uid,
        name: `config-${randstring(6)}`,
      });
      yield this.getConfigurations();
    } catch (err) {
      this.parent.app.showMsg("error", `unable to create new config:${err.error}`);
    }
  }).bind(this);

  deleteConfig = flow(function* (configId) {
    try {
      yield this.parent.utfapi.deleteConfig(configId);
      yield this.getConfigurations();
    } catch (err) {
      this.parent.app.showMsg("error", `unable to delete configuration :${err.error}`);
    }
  }).bind(this);
}

export default Store;
