/**
 * Deeply merges two objects.
 *
 * @param target - The target object to merge into.
 * @param source - The source object to merge from.
 * @param options - Optional options for merging.
 * @returns The merged object.
 */
export function deepMerge(target: any, source: any, options?: any): any {
  const destination = { ...target };
  const sourceKeys = Object.keys(source);

  sourceKeys.forEach((key) => {
    const sourceValue = source[key];
    const targetValue = destination[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      const arrayMerge = options?.arrayMerge ?? ((dest: any, src: any) => src);
      destination[key] = arrayMerge(targetValue, sourceValue, options);
    } else if (typeof targetValue === "object" && typeof sourceValue === "object") {
      destination[key] = deepMerge(targetValue, sourceValue, options);
    } else {
      destination[key] = sourceValue || targetValue;
    }
  });

  return destination;
}
