import type { ReactNode } from "react";
import { Box, Theme } from "@mui/system";

type GraphContainerProps = {
  title: string;
  subTitles?: ReactNode;
  children: ReactNode;
  hasData?: boolean;
  "data-testid"?: string;
};

const GraphContainer: React.FC<GraphContainerProps> = ({
  title,
  subTitles,
  children,
  hasData = true,
  "data-testid": testId = "graph-container",
}) => (
  <Box
    sx={{
      margin: (theme: Theme) => theme.spacing(1, 0, 4, 0),
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    }}
    data-testid={testId}
  >
    <Box
      sx={{
        height: (theme: Theme) => theme.spacing(6),
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "background.paper",
        marginBottom: "2px",
        borderRadius: "2px 2px 0px 0px",
      }}
    >
      <Box
        sx={{
          p: [2, 3],
          border: "none",
          typography: "h5",
        }}
      >
        {title}
      </Box>
      {subTitles && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: [0, 2],
            gap: (theme: Theme) => theme.spacing(2),
            borderLeft: (theme: Theme) => `2px solid ${theme.palette.grey["100"]}`,
            height: "100%",
          }}
        >
          {subTitles}
        </Box>
      )}
    </Box>
    {hasData ? (
      <Box
        sx={{
          backgroundColor: (theme: Theme) => theme.palette.background.paper,
          "& .highcharts-container": {
            margin: "0 !important",
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    ) : (
      <Box
        sx={{
          height: "400px",
          textAlign: "center",
          typography: "h4",
          pt: 4,
          color: "error",
        }}
      >
        No data available
      </Box>
    )}
  </Box>
);

export default GraphContainer;
