/* eslint-disable react/jsx-props-no-spreading */
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import * as CONSUMPTION from "@config/consumption";
import { DatePicker } from "@shared/ui/inputs";

import { useConsumption } from "./Consumption.store";

export const ConsumptionDatePicker = observer(
  ({
    variant,
    minDate,
    maxDate,
    views,
    value,
    onChange,
    format,
    classname,
    disabled,
    id,
    ...props
  }) => {
    const { inputProps, ...restProps } = props;
    const { interval } = useConsumption();

    const defaultFormat =
      interval === CONSUMPTION.INTERVAL_HOURLY
        ? CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD_HH_MM
        : CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD;

    return (
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        closeOnSelect={false}
        ampm={false}
        views={views}
        value={value}
        onChange={onChange}
        disabled={disabled}
        format={format ?? defaultFormat}
        className={classname}
        inputProps={{
          variant,
          helperText: "",
          InputProps: {
            "data-testid": `date-input__${id}`,
          },
          ...inputProps,
          sx: {
            "& .MuiInput-root": {
              border: "1px solid currentColor",
            },
            ...inputProps?.sx,
          },
        }}
        slotProps={{
          popper: {
            placement: "top",
          },
        }}
        {...restProps}
      />
    );
  }
);

ConsumptionDatePicker.defaultProps = {
  variant: "standard",
  minDate: undefined,
  maxDate: undefined,
  views: ["year", "month", "day"],
  format: null,
  disabled: false,
  classname: null,
  id: "consumption",
  value: undefined,
};

ConsumptionDatePicker.propTypes = {
  variant: PropTypes.string,
  minDate: PropTypes.instanceOf(DateTime),
  maxDate: PropTypes.instanceOf(DateTime),
  views: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.instanceOf(DateTime),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  classname: PropTypes.string,
  id: PropTypes.string,
};

ConsumptionDatePicker.displayName = "Consumption.DatePicker";
