/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useCallback, useEffect, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListItem as MuiListItem,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { faCircleQuestion, faGripLines, faXmark } from "@fortawesome/pro-light-svg-icons";

import { COLUMN_PERIOD } from "@config/Columns";

import { FontAwesomeSvgIcon } from "../../../FontAwesomeSvgIcon";
import Checkbox from "../../../UtfCheckbox";

function renderDragHandle(dragRef: any) {
  return (
    <ListItemIcon
      ref={dragRef}
      sx={{
        minWidth: "auto !important",
        cursor: "grab",
        "&:blur": {
          cursor: "grabbing",
        },
      }}
    >
      <IconButton size="small" disableRipple>
        <FontAwesomeSvgIcon icon={faGripLines} color="primary" fontSize="small" />
      </IconButton>
    </ListItemIcon>
  );
}

function renderIconTooltip(info: any) {
  return (
    <Tooltip title={info} placement="right-start">
      <IconButton size="small">
        <FontAwesomeSvgIcon
          icon={faCircleQuestion}
          color="primary"
          sx={{
            width: (theme: any) => theme.spacing(1.5),
            height: (theme: any) => theme.spacing(1.5),
          }}
        />
      </IconButton>
    </Tooltip>
  );
}

function renderPrimaryText(item: any, t: any) {
  return (
    <>
      <Box component="span">{item.columnDef.header}</Box>
      {item.columnDef.info && renderIconTooltip(handleGetInfo(item, t))}
    </>
  );
}

function renderHideButton(toggleVisibility: any) {
  return (
    <IconButton onClick={toggleVisibility} size="small" edge={false}>
      <FontAwesomeSvgIcon
        icon={faXmark}
        color="primary"
        sx={{
          width: (theme: any) => theme.spacing(2.4),
          height: (theme: any) => theme.spacing(2.4),
        }}
      />
    </IconButton>
  );
}

function renderToggleCheckbox(
  isVisible: boolean,
  isDisabled: boolean,
  labelId: string,
  toggleVisibility: any
) {
  return (
    <Checkbox
      // @ts-expect-error legacy
      edge="end"
      onChange={toggleVisibility}
      inputProps={{ "aria-labelledby": labelId }}
      checked={isVisible}
      disabled={isDisabled}
    />
  );
}

function handleGetInfo(item: any, t: any) {
  function getPeriods(period: keyof typeof COLUMN_PERIOD) {
    switch (period) {
      case COLUMN_PERIOD.month:
        return t("text_months");
      case COLUMN_PERIOD.year:
        return t("text_all_year");
      case COLUMN_PERIOD.r12:
        return t("text_rolling_12_months");
      default:
        return "";
    }
  }

  if (item.columnDef.periods && item.columnDef.disabled) {
    const itemPeriods = item.columnDef.periods || [];
    const columnsPeriods = Object.values(COLUMN_PERIOD);
    const newPeriods: any[] = [];
    const spread = [...itemPeriods, ...columnsPeriods];

    // get periods that not include in it columns
    const filteredPeriod = spread.filter(
      (period) => !(itemPeriods.includes(period) && columnsPeriods.includes(period))
    );

    // for rendering
    filteredPeriod.forEach((period) => newPeriods.push(getPeriods(period)));
    return (
      <>
        <div>{item.columnDef.info}</div>
        <Box component="span" color="graph.red">
          {t("message_not_available_for", { ns: "_messages" })} {newPeriods.join(", ")}
        </Box>
      </>
    );
  }
  return item.columnDef.info;
}

function toggleVisibilityHandler(item: any, eventOrNextVisibility: any) {
  const nextVisibility =
    "target" in eventOrNextVisibility
      ? eventOrNextVisibility.target.checked
      : eventOrNextVisibility;

  item.toggleVisibility(nextVisibility);

  // Toggle all child columns visibility too
  if (item.columns && item.columns.length > 0) {
    item.columns.forEach((col: any) => col.toggleVisibility(nextVisibility));
  }
}

export function ColumnList({ listItems = [], dnd = false, onDrop }: any) {
  const handleDrop = useCallback(
    (...args: any[]) => {
      if (onDrop) {
        onDrop(...args);
      }
    },
    [onDrop]
  );

  let items = [];
  const visibleItems = listItems.filter(({ hideable }: any) => hideable !== false);
  if (Array.isArray(visibleItems) && visibleItems.length > 0) {
    items = listItems
      // Here we replace children columns with their parent column
      // Hence, the sub columns are not listed under the selected items
      .map((item: any) => {
        const { parent } = item;
        if (parent) {
          return parent;
        }
        return item;
      })
      // Remove duplicates
      .filter(
        (filter: any, index: number, self: any) =>
          index === self.findIndex((item: any) => item.id === filter.id)
      );
  }

  const renderItem = (item: any, index: number) => {
    const id = `manager-column-${item.id || index}`;
    // do not render items that doesn't allow hiding
    if (item.columnDef?.hideable !== true) return null;
    const itemProps = {
      key: id,
      id,
      item,
    };

    return dnd ? (
      <DropItem {...itemProps} index={index} onDrop={handleDrop} />
    ) : (
      <ListItem {...itemProps} />
    );
  };

  return (
    <List
      dense
      sx={{
        width: "100%",
        height: "100%",
        padding: 0,
        backgroundColor: "grey.50",
        "& .MuiListItem-container": {
          backgroundColor: "grey.50",
        },
      }}
      data-testid="column-list"
    >
      {dnd ? (
        <DndProvider backend={HTML5Backend}>{items.map(renderItem)}</DndProvider>
      ) : (
        items.map(renderItem)
      )}
    </List>
  );
}

const ListItem = forwardRef(({ item, id, dragRef, ...props }: any, ref: any) => {
  const { t } = useTranslation(["_messages"]);
  const labelId = `label-${id}`;
  const isToggleHidden =
    item.toggleVisibility || item.columns.some((col: any) => col.toggleVisibility);
  const isDisabled =
    item.columnDef.disabled || item.columns.some((col: any) => col.columnDef.disabled);
  const isVisible = item.getIsVisible();

  const toggleVisibility = useCallback(
    (eventOrNextVisibility: any) => toggleVisibilityHandler(item, eventOrNextVisibility),
    [item]
  );

  useEffect(() => {
    // if the item is disabled but still visible, make it hidden
    if (item.columnDef.disabled === true && item.columnDef.isVisible === true) {
      toggleVisibility(false);
    }
  }, [item, toggleVisibility]);

  if (!item) return null;

  return (
    <MuiListItem
      divider
      sx={{
        minHeight: (theme) => theme.spacing(5.12),
        padding: 0,
        pl: 2,
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
        border: (theme) => `1px solid ${theme.palette.grey["200"]}`,
        borderBottom: 0,
        "& .MuiListItemText-primary": {
          fontSize: (theme) => theme.typography.pxToRem(13.72),
          lineHeight: 2,
        },
        "& .MuiListItemText-secondary": {
          color: "#757575",
          fontSize: 13.72,
        },
        ...(isDisabled && {
          color: "grey.400",
          "& .MuiListItemText-secondary": {
            color: "grey.400",
          },
        }),
      }}
      data-testid={`column-manager-item-${item.id}`}
      {...props}
      ref={ref}
    >
      {dragRef && renderDragHandle(dragRef)}
      <ListItemText
        id={labelId}
        primary={renderPrimaryText(item, t)}
        secondary={item.columnDef.sublabel}
        sx={{
          pl: dragRef ? 1 : 0,
        }}
      />
      <ListItemSecondaryAction sx={{ display: "flex !important", alignItems: "center !important" }}>
        {dragRef && renderHideButton(toggleVisibility)}
        {isToggleHidden &&
          !dragRef &&
          renderToggleCheckbox(isVisible, isDisabled, labelId, toggleVisibility)}
      </ListItemSecondaryAction>
    </MuiListItem>
  );
});

const dndType = "selectedItem";

function DropItem({ id, index, item: itemData, onDrop }: any) {
  const dropRef = useRef(null);
  const dragRef = useRef(null);
  // Drop
  const [, drop] = useDrop({
    accept: dndType,
    hover(item: any) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      if (item.id !== id) {
        onDrop(dragIndex, hoverIndex);
      }
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  // Drag
  const [{ isDragging }, drag, preview] = useDrag({
    type: dndType,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return <ListItem ref={dropRef} dragRef={dragRef} id={id} item={itemData} style={{ opacity }} />;
}
