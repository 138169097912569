/* eslint-disable i18next/no-literal-string */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { Alert, Button, Typography } from "@mui/material";

import { useInject } from "@hooks";
import { LoaderScreen } from "@shared/LoaderScreen";

export const ServiceWorkerPrompt = observer<React.FC>(() => {
  const { app } = useInject("app");
  const { needsRefresh, updateAndReloadApplication } = app;
  const [updating, setUpdating] = useState(false);

  const onUpdate = () => {
    setUpdating(true);
    updateAndReloadApplication();
  };

  if (updating) return <LoaderScreen fullScreen />;

  return needsRefresh ? (
    <Alert
      severity="info"
      variant="filled"
      sx={{ m: 2, mb: 0, p: 2 }}
      action={
        <Button color="inherit" disabled={updating} onClick={onUpdate}>
          Update
        </Button>
      }
    >
      <Typography variant="body2" component="strong">
        There is a new version available, click “Update” to use the new version!
      </Typography>
    </Alert>
  ) : null;
});

ServiceWorkerPrompt.displayName = "ServiceWorkerPrompt";
