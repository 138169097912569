import { makeAutoObservable } from "mobx";
import { interval, Subject } from "rxjs";
import { debounce } from "rxjs/internal/operators/debounce.js";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged.js";

const NEXT_SUB = new Subject().pipe(
  debounce(() => interval(300)),
  distinctUntilChanged()
);

export class SubstationStore {
  current_substation = null;

  current_cluster = null;

  constructor(parent) {
    makeAutoObservable(this, {
      current_substation: true,
      current_cluster: true,
    });

    this.parent = parent;
    this.current_substation = null;
    this.current_cluster = null;

    NEXT_SUB.subscribe(this.updateNxtSub);
  }

  updateCurrentSubstation(substationId) {
    this.current_substation = substationId;
  }

  updateCurrentCluster(clusterId) {
    this.current_cluster = clusterId;
  }

  // eslint-disable-next-line class-methods-use-this
  updateNxtSubstation(substationId) {
    NEXT_SUB.next(substationId);
  }
}
