import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { ChevronLeft } from "@mui/icons-material";
import { Button, IconButton, LinearProgress, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { Marker } from "@vis.gl/react-google-maps";

import { blkReader } from "@conf/blocks";
import features from "@conf/feature_access";
import { wikiData } from "@conf/wiki_constants";
import { getUrlParam, removeUrlParam, validCoords } from "@core/utils";
import SectionSelector from "@components/ui/SectionSelector";
import SubHeader from "@components/ui/SubHeader";
import { rootStore } from "@stores/root_store";
import StarIcon from "@icons/Star";
import StarfullIcon from "@icons/Starfull";
import SubstationIcon from "@icons/Substation";
import { useMobxSelector } from "@hooks";
import { UtfMap } from "@shared/GoogleMap/UtfMap";
import { markerIconURL } from "@shared/GoogleMap/utils";

import { CLUSTER, NETWORK_SUBSTATION, SUBSTATION } from "../SubList/constant";
import useSubExplore from "../SubList/Explore/useSubExplore";
import useSummary from "../SubList/Summary/useSummary";
import { ConsumptionOverview } from "./ConsumptionOverview";
import { ConsumptionStore, StateContext } from "./ConsumptionOverview/Consumption.store";
import { FaultDetectionOverviewTab } from "./FaultDetectionOverview/FaultDetectionOverview.tab";
import { SearchBox } from "./SearchBox";
import SubstationOverview from "./SubstationOverview";

function SubPane() {
  const { t } = useTranslation();
  const consumptionStore = useMemo(() => new ConsumptionStore(rootStore), []);
  const { networks, sub, ui, preferences, notifications } = rootStore;

  const isSubSummaryOpen = ui.is_subsummary_open;
  const isClusterSummaryOpen = ui.is_cluster_summary_open;
  const summary = useSummary({ type: isSubSummaryOpen ? SUBSTATION : CLUSTER });
  const explore = useSubExplore({ type: NETWORK_SUBSTATION });

  const substation = t("text_substation");
  const cluster = t("text_cluster");
  const activeDataLabel = isSubSummaryOpen ? substation : cluster;

  const SECTION_TAB_LABELS = useMemo(() => {
    const tabs = [
      {
        title: `${activeDataLabel} ${t("text_over_view")}`,
        id: "overview",
        permissions: [isSubSummaryOpen ? features.substation_overview : features.cluster_overview],
        component: SubstationOverview,
      },
      {
        title: `${activeDataLabel} ${t("text_consumption")}`,
        id: "consumption",
        permissions: [
          isSubSummaryOpen ? features.substation_consumption : features.cluster_consumption,
        ],
        component: ConsumptionOverview,
      },
    ];
    // Only for substations
    if (isSubSummaryOpen) {
      tabs.push({
        title: t("text_fault_detection"),
        id: "fault_detection",
        permissions: [features.substation_fault_detection],
        component: FaultDetectionOverviewTab,
      });
    }
    return tabs;
  }, [activeDataLabel, isSubSummaryOpen, t]);

  const reader = blkReader(explore.substations, explore.colSpecs);
  const { install_address: installAddress, dataFetched } = summary.data;

  // Favorite
  const isFavorite = useMobxSelector(() => {
    const networkUid = networks.current_network.uid;
    const currentNetworkList = preferences.fav_subs.get(networkUid);
    if (currentNetworkList) {
      return currentNetworkList.has(sub.current_substation);
    }
    return false;
  });

  function handleClicked() {
    runInAction(() =>
      preferences.toggleFavSub(networks.current_network.uid, sub.current_substation)
    );
  }

  const colorIcon = isFavorite ? "secondary" : "primary";
  const Icon = isFavorite ? <StarfullIcon /> : <StarIcon />;

  // Map
  let mapZoom = 15;
  let mapCenter;
  let mapMarkers = null;
  if (validCoords(installAddress, "coordinates")) {
    mapCenter = {
      lat: installAddress.coordinates[0],
      lng: installAddress.coordinates[1],
    };
  } else {
    mapCenter = {
      lat: networks.coordinates[0],
      lng: networks.coordinates[1],
    };
  }
  mapMarkers = <Marker position={mapCenter} icon={{ url: markerIconURL() }} />;

  if (isClusterSummaryOpen) {
    mapZoom = 8;

    const firstClusterSubstation = explore.filteredList[0];
    if (validCoords(reader(firstClusterSubstation), "coordinates")) {
      mapCenter = {
        lat: reader(firstClusterSubstation).coordinates[0],
        lng: reader(firstClusterSubstation).coordinates[1],
      };
    }

    mapMarkers = explore.substationsUid?.map((substationItem) => {
      const substationReader = reader(substationItem.uid);
      if (!validCoords(substationReader, "coordinates")) return null;
      return <Marker key={`map-marker-${substationItem.uid}`} position={mapCenter} />;
    });
  }

  let totalSubstationInCluster = "...";
  if (explore.dataFetched) {
    const substationsLength = explore?.substationsUid?.length;
    totalSubstationInCluster = substationsLength === 0 ? "..." : substationsLength;
  }

  const MapComponent = dataFetched ? (
    <Skeleton variant="rectangular" height={155} />
  ) : (
    <UtfMap defaultZoom={mapZoom} center={mapCenter}>
      {mapMarkers}
    </UtfMap>
  );

  useEffect(() => {
    if (installAddress && !validCoords(installAddress, "coordinates")) {
      notifications.warning(t("text_missing_weather_coordinates_info"));
    }
  }, [installAddress, notifications, t]);

  const onMinimizeButtonClick = () => {
    ui.closeDetailPanel();
    removeUrlParam("investigate");
    removeUrlParam("explore_tab");
  };
  const urlParam = getUrlParam("explore_tab");
  const computedWikiUrl = useMemo(() => {
    const { extend_tab } = wikiData;

    const urlMappings = {
      substation: {
        overview: extend_tab.substation.overview,
        consumption: extend_tab.substation.consumption,
        fault_detection: extend_tab.substation.fault_detection,
      },
      cluster: {
        overview: extend_tab.cluster.overview,
        consumption: extend_tab.cluster.consumption,
      },
    };

    const context = isSubSummaryOpen ? "substation" : "cluster";
    const page = urlMappings[context][urlParam] || urlMappings[context].overview;

    return page;
  }, [isSubSummaryOpen, urlParam]);

  if (dataFetched) return <LinearProgress />;

  return (
    <Box flex={1} display="flex" flexDirection="column" pt={9}>
      {/* Panel Header with the search box */}
      <SubHeader
        wikiUrl={computedWikiUrl}
        endComponent={
          <Button
            startIcon={<ChevronLeft />}
            onClick={onMinimizeButtonClick}
            color="primary"
            variant="contained"
            disableElevation
          >
            {t("action_minimize", { ns: "_action" })}
          </Button>
        }
      >
        <SearchBox
          consumptionStore={consumptionStore}
          summary={summary}
          explore={explore}
          reader={reader}
        />
      </SubHeader>
      {/* Panel Sub-Header */}
      <Box
        display="flex"
        flexDirection="row-reverse"
        pl={3}
        justifyContent="space-between"
        boxShadow={1}
        bgcolor="white"
        zIndex={1}
        alignItems="center"
      >
        {isSubSummaryOpen ? (
          <Box mr={3}>
            {/* Favorite Icon Button */}
            <IconButton color={colorIcon} onClick={() => handleClicked()} size="large">
              {Icon}
            </IconButton>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            minWidth="80px"
            justifyContent="space-around"
            borderLeft={1}
            height="100%"
            px={1.5}
            borderColor="grey.200"
            data-testid="extend-panel-total-count"
          >
            {/* Total Substation Count in Cluster */}
            {totalSubstationInCluster}
            <SubstationIcon />
          </Box>
        )}
      </Box>

      {/* Google Maps */}
      <Box minHeight="256px" data-maskdata>
        {MapComponent}
      </Box>

      {/* Tabbed Sections */}
      <StateContext.Provider value={consumptionStore}>
        <SectionSelector
          triggerSelectOnMount
          selected={consumptionStore.section}
          selectCallback={consumptionStore.onSectionChange}
          sections={SECTION_TAB_LABELS}
          saveToRoute
          routeSelector="explore_tab"
        />
      </StateContext.Provider>
    </Box>
  );
}

// eslint-disable-next-line import/no-default-export
export default observer(SubPane);
