import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

function GraphTop(props) {
  const {
    id,
    unit,
    legend,
    legendmarkerwidth = "wide",
    unittextvariant = "subtitle2",
    dense,
  } = props;
  const { t } = useTranslation(["_common"]);
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      px={2}
      pt={dense ? 1 : 3}
      width="100%"
    >
      <Typography variant={unittextvariant} color="secondary" data-testid={`${id}-graph-top__unit`}>
        {unit}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        {legend &&
          legend.map((l) => (
            <Box display="flex" flexDirection="row" key={l.name}>
              <Box
                width={legendmarkerwidth === "wide" ? 16 : 2}
                height={16}
                bgcolor={l.color}
                mx={1}
              />
              <Typography variant="caption" data-testid={`${id}-graph-top-${l.name}`}>
                {t(l.name)}
              </Typography>
            </Box>
          ))}
        <Box mr={1} />
      </Box>
      <div />
    </Box>
  );
}

export default GraphTop;
