/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import {
  faArrowDownLong,
  faCompress,
  faExpand,
  faFilter,
  faFilterSlash,
  faGripLines,
} from "@fortawesome/pro-light-svg-icons";
import { faAngleDown, faAnglesDown } from "@fortawesome/pro-regular-svg-icons";
import type { SvgIconTypeMap } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, alpha } from "@mui/system";
import {
  MRT_FilterFns,
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  type MRT_Cell,
  type MRT_Row,
  type MRT_RowData,
  type MRT_TableInstance,
  type MRT_TableOptions,
} from "material-react-table";
import { useTranslation } from "react-i18next";

import { deepMerge } from "@core/utils/deepMerge";

import { FontAwesomeSvgIcon } from "../../../FontAwesomeSvgIcon";
import { MIN_COLUMN_WIDTH } from "../InfoBlockGrid/const";

export type UtfTableProps<TData extends MRT_RowData> = {
  // Disable all border styles in table elements
  borderless?: boolean;
  // Makes the columns stacked to the left instead of streching to the full width of the table
  fluidColumns?: boolean;
  // Custom prop to hide top toolbar (enableTopToolbar=true hides rows in some cases)
  hideTopToolbar?: boolean;
  onDataChange?: (rows: any[]) => void;
  onCellChange?: (row: any, column: any, value: any) => void;
} & Omit<MRT_TableOptions<TData>, "isLoading">;

type ThemeModifiers = {
  rowCount: number;
  isFullScreen: boolean;
};

export const MIN_TABLE_HEIGHT = 300; // about the height of 5 rows

/**
 * A component for displays message when there is no rows to display.
 *
 * @returns JSX.Element
 */
function NoResultsMessage() {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Typography variant="h6" color="error">
        {t("message_no_data_available", { ns: "_messages" })}
      </Typography>
    </Box>
  );
}

/**
 *
 */
export function ActiveSortIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return <FontAwesomeSvgIcon icon={faArrowDownLong} {...props} />;
}

/**
 *
 */
export function SortIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return (
    <FontAwesomeSvgIcon
      icon={faArrowDownLong}
      {...props}
      style={{ transform: "none !important!" }}
    />
  );
}

/**
 *
 */
function FullscreenIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return <FontAwesomeSvgIcon icon={faExpand} color="primary" {...props} />;
}

/**
 *
 */
function FullscreenExitIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return <FontAwesomeSvgIcon icon={faCompress} color="secondary" {...props} />;
}

/**
 *
 */
function DragHandleIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return (
    <FontAwesomeSvgIcon
      icon={faGripLines}
      {...props}
      sx={{ fontSize: (theme: any) => theme.spacing(1.5), ...props.sx }}
    />
  );
}

/**
 *
 */
function ExpandGroupIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return (
    <FontAwesomeSvgIcon
      icon={faAngleDown}
      color="primary"
      {...props}
      sx={{ fontSize: (theme: any) => theme.spacing(2), ...props.sx }}
    />
  );
}

/**
 *
 */
function ExpandAllGroupsIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return (
    <FontAwesomeSvgIcon
      icon={faAnglesDown}
      color="primary"
      {...props}
      sx={{ fontSize: (theme: any) => theme.spacing(2), ...props.sx }}
    />
  );
}

/**
 *
 */
function FilterListIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return <FontAwesomeSvgIcon icon={faFilter} color="primary" {...props} />;
}

/**
 *
 */
function FilterListOffIcon(props: Readonly<SvgIconTypeMap["props"]>) {
  return <FontAwesomeSvgIcon icon={faFilterSlash} color="secondary" {...props} />;
}

/**
 *
 */
export const rowsPerPageOptions = [25, 50, 100, 500];

/**
 * The Utilifeed theme for the table
 *
 * @param initialProps
 * @param initialProps.borderless do not use solid borders
 * @param initialProps.hideTopToolbar do not show toolbar conrols
 * @param modifiers
 * @param modifiers.isFullScreen contain entire screen
 * @param modifiers.rowCount show total rows on footer
 * @returns Partial<MRT_TableOptions<TData>>
 */
export function useDefaultTableTheme<TData extends MRT_RowData>(
  {
    borderless = false,
    hideTopToolbar = false,
    ...initialProps
  }: Omit<UtfTableProps<TData>, "columns" | "data">,
  { isFullScreen, rowCount }: ThemeModifiers
) {
  const theme = useTheme();
  const baseBackgroundColor = theme.palette.mode === "dark" ? "#757575" : "#ffffff";
  const altBackgroundColor = theme.palette.mode === "dark" ? "#424242" : "#f5f5f5";
  const utfTableThemeProps = {
    mrtTheme: {
      baseBackgroundColor,
      draggingBorderColor: theme.palette.primary.main,
      selectedRowBackgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    muiTableContainerProps: {
      sx: {
        height: "100%",
      },
    },
    muiTablePaperProps: {
      elevation: borderless ? 0 : 1,
      sx: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%",
        border: borderless ? 0 : "1px solid",
        borderColor: borderless ? "none" : "border.primary",
        borderRadius: borderless ? 0 : 2,
        backgroundColor: "background.paper",
        zIndex: isFullScreen ? `${theme.zIndex.drawer + 2}!important` : undefined,
      },
    },
    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      hover: false,
      selected: false,
      sx: {
        boxShadow: borderless ? "none" : 1,
        backgroundColor: staticRowIndex % 2 ? baseBackgroundColor : altBackgroundColor,
      },
    }),
    muiTableBodyCellProps: {
      sx: {
        borderBottom: borderless ? 0 : "1px solid",
        borderColor: borderless ? undefined : "#eee",
        color: theme.palette.text.primary,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 300,
        "&:hover": {
          outlineOffset: "-2px",
          textOverflow: "clip",
        },
      },
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: "background.paper",
        "&:last-child th": {
          borderTop: borderless ? 0 : "1px solid",
          borderBottom: borderless ? 0 : "1px solid",
          borderColor: borderless ? undefined : "border.light",
        },
        ".MuiTableSortLabel-root": {
          width: "auto",
          pl: 1,
          ".MuiTableSortLabel-icon": {
            color: `${theme.palette.primary.main} !important`,
            width: theme.spacing(1),
            m: 0,
          },
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: 0,
        typography: "body2",
        fontWeight: "normal",
        justifyContent: "space-between",
        ".Mui-TableHeadCell-Content-Labels": {
          alignItems: "flex-start",
        },
        ".Mui-TableHeadCell-Content-Wrapper": {
          // Set the minimum table cell width
          minWidth: theme.spacing(2),
        },
        ".Mui-TableHeadCell-Content": {
          alignItems: "flex-start",
        },
        ".Mui-TableHeadCell-Content-Actions": {
          svg: {
            fill: "primary.main",
          },
        },
        // Hide multi-sort multiplier number
        ".MuiBadge-overlapCircular": {
          visibility: "hidden",
        },
        // Column resizer handle
        ".Mui-TableHeadCell-ResizeHandle-Wrapper": {
          display: "flex",
          flexDirection: "column",
          height: theme.spacing(3),
          mr: -1.58,
          mt: 0.39,
          "&:focus, &:hover": {
            hr: {
              borderColor: "secondary.main",
            },
          },
          hr: {
            borderWidth: theme.spacing(0.16),
            height: theme.spacing(1.5),
            borderColor: "primary.main",
          },
        },
      },
    },
    muiSelectAllCheckboxProps: {
      disableRipple: true,
      color: "secondary",
    },
    muiSelectCheckboxProps: {
      disableRipple: true,
      color: "secondary",
    },
    muiToolbarAlertBannerChipProps: { color: "primary" },
    muiTopToolbarProps: {
      sx: {
        minHeight: hideTopToolbar ? "1px" : "3.5rem",
        background: "white",
        // This is required to make Backdrop cover all elements
        zIndex: 3,
        "&&": {
          boxShadow: "none",
        },
        "&& .MuiAlert-message": {
          paddingBlock: theme.spacing(0.6),
        },
      },
    },
    // the footer section of table
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: "background.paper",
        flex: "none",
        "& .MuiTablePagination-root": {
          "& .MuiButtonBase-root": {
            border: "1px solid",
            borderColor: theme.palette.border.primary,
            borderRadius: "2px",
            color: theme.palette.primary.main,
            marginLeft: "4px",
            marginRight: "4px",
            "&.Mui-disabled": {
              display: "none",
            },
          },
        },
        "& .MuiSelect-select": {
          "&:focus": {
            backgroundColor: "background.paper",
          },
        },
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [
        ...rowsPerPageOptions.map((pageOption) => ({
          value: pageOption,
          label: String(pageOption),
        })),
        {
          value: rowCount,
          label: "All",
        },
      ],
    },
    // Filter Props
    muiFilterTextFieldProps: {
      sx: {
        ".MuiAutocomplete-input[role=combobox]": {
          minWidth: MIN_COLUMN_WIDTH,
        },
        ".MuiInputAdornment-root button": {
          color: "primary.main",
        },
      },
    },
  } as Partial<MRT_TableOptions<TData>>;

  // All modifications you are doing with `utfTableThemeProps` must be reflected to the below
  const {
    muiTableContainerProps,
    muiTablePaperProps,
    muiSelectAllCheckboxProps,
    muiSelectCheckboxProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    muiTableHeadRowProps,
    muiTableHeadCellProps,
    muiTopToolbarProps,
    muiBottomToolbarProps,
    muiToolbarAlertBannerChipProps,
    muiPaginationProps,
    muiFilterTextFieldProps,
    ...userProps
  } = initialProps;

  // User provided props will override the theme
  const muiProps = deepMerge(utfTableThemeProps, {
    muiTableContainerProps,
    muiTablePaperProps,
    muiSelectAllCheckboxProps,
    muiSelectCheckboxProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    muiTableHeadRowProps,
    muiTableHeadCellProps,
    muiTopToolbarProps,
    muiBottomToolbarProps,
    muiPaginationProps,
    muiToolbarAlertBannerChipProps,
    muiFilterTextFieldProps,
  });

  return {
    ...userProps,
    ...muiProps,
  } as MRT_TableOptions<TData>;
}

/**
 *
 * @param val validate if its falsey
 */
function testFalsey(val: any): boolean {
  return val === undefined || val === null || val === "";
}

/**
 * A helper function to make material-react-table filter functions to work
 * with the `undefined` values without crashing.
 *
 * Also, it normalizes the numbers, as they are stored with decimals but rendered without.
 * A number can be stored like `123.456` but rendered as `123 456` so users can not
 * filter those by typing `123455`
 *
 * @param {*} filterFn
 */
const safeFilterFnFactory = (filterFn: any): any => {
  const safeFilterFn = (
    originalRow: MRT_Row<MRT_RowData>,
    id: string,
    filterValue: number | string,
    addMeta?: (item: any) => void
  ) => {
    const row = { ...originalRow };
    row.getValue = (columnId: string): any => {
      let value = originalRow.getValue<string>(columnId);
      // never send `undefined` to the filter function as it crashes
      if (value === undefined) value = "";
      // always parse numbers to avoid decimals
      return typeof value === "number" ? String(Number.parseInt(value)) : value;
    };
    return filterFn(row, id, filterValue, addMeta);
  };

  if (filterFn.autoRemove) safeFilterFn.autoRemove = filterFn.autoRemove;

  return safeFilterFn;
};

/**
 * re-useable default table options for all tables in the app
 */
export function useDefaultTableOptions<TData extends MRT_RowData>(
  { fluidColumns, ...initialProps }: Omit<UtfTableProps<TData>, "columns" | "data">,
  modifiers: ThemeModifiers
): Partial<UtfTableProps<TData>> {
  const themeProps = useDefaultTableTheme(initialProps, modifiers);

  return {
    layoutMode: fluidColumns ? "grid" : undefined,

    /* -  Features - */
    enableStickyFooter: true,
    enableHiding: false,
    enableSortingRemoval: false,
    enableGrouping: false,
    enableColumnOrdering: false,
    enableColumnResizing: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnDragging: false,

    /* -  Virtualization - */
    enableRowVirtualization: true,
    rowVirtualizerOptions: {
      estimateSize: () => 45, // to make scrollbar size more accurate
    },
    enableColumnVirtualization: true,
    columnVirtualizerOptions: {
      estimateSize: () => MIN_COLUMN_WIDTH, // to make scrollbar size more accurate
    },

    /* -  Icons - */
    icons: {
      ArrowDownwardIcon: ActiveSortIcon,
      ExpandMoreIcon: ExpandGroupIcon,
      KeyboardDoubleArrowDownIcon: ExpandAllGroupsIcon,
      SyncAltIcon: SortIcon,
      FullscreenIcon,
      FullscreenExitIcon,
      DragHandleIcon,
      FilterListIcon,
      FilterListOffIcon,
    },

    /* -  Renderers - */
    renderEmptyRowsFallback: () => <NoResultsMessage />,
    renderToolbarInternalActions: ({ table }) => (
      <Box sx={{ alignItems: "center", display: "flex", zIndex: 3 }}>
        {/* Density Toggler */}
        <ToggleDensePaddingButton table={table} color="primary" />
        {/* Fullscreen Toggler */}
        <ToggleFullScreenButton table={table} />
      </Box>
    ),
    /* -  Filter functions - */
    filterFns: {
      notEmpty: (row, columnId) => !testFalsey(row.getValue(columnId)),
      empty: (row, columnId) => testFalsey(row.getValue(columnId)),
      ...[
        "contains",
        "equals",
        "notEquals",
        "between",
        "betweenInclusive",
        "greaterThan",
        "lessThan",
        "greaterThanOrEqualTo",
        "lessThanOrEqualTo",
        "startsWith",
        "endsWith",
      ].reduce(
        (filters, filterFn) => ({
          ...filters,
          [filterFn]: safeFilterFnFactory(MRT_FilterFns[filterFn]),
        }),
        {}
      ),
    },
    /* -  Theming - */
    mrtTheme: {
      selectedRowBackgroundColor: "#fff",
    },
    ...themeProps,
  };
}

export const renderBottomToolbarWithCounters = ({
  table,
}: {
  table: MRT_TableInstance<MRT_RowData>;
}) => {
  const columnFiltersActive = table.getState().columnFilters.length;
  const columnFilterCounter =
    columnFiltersActive !== 0 ? (
      <>
        <span>{columnFiltersActive} filter(s)</span> active
      </>
    ) : null;

  const visibleRows = table.getFilteredRowModel().rows.length;
  const totalRows = table.getPreFilteredRowModel().rows.length;
  const rowCounter =
    visibleRows !== totalRows ? (
      <>
        <span>Rows {visibleRows}</span> of {totalRows}
      </>
    ) : (
      `${totalRows} rows`
    );

  return (
    <Grid
      container
      justifyContent="space-between"
      borderTop="1px solid"
      borderColor="border.primary"
      sx={{
        span: {
          color: "primary.main",
          fontWeight: 400,
        },
      }}
    >
      <Grid item p={2} data-testid="filter-count">
        {columnFilterCounter}
      </Grid>
      <Grid item p={2} data-testid="row-count">
        {rowCounter}
      </Grid>
    </Grid>
  );
};

export const validateTypeOrEmpty = (value: any, column: any) => {
  if (value === null) return "";
  const colType = column.muiEditTextFieldProps?.type;
  if (colType === "number") {
    if (Number.isNaN(Number(value))) return "";
    return Number(value);
  }
  return value;
};

export const enableEditingOnColumn = (column: any) => {
  const { columnDef } = column;
  // Check columnDef.editingEnabled
  // Return true if it is undefined or true
  // else return false
  return columnDef.editingEnabled === undefined || columnDef.editingEnabled === true;
};

export const handlePaste = (
  event: ClipboardEvent,
  cell: MRT_Cell<MRT_RowData>,
  table: MRT_TableInstance<MRT_RowData>,
  data: any[],
  onDataChange: (data: any[]) => void
) => {
  // Return if the target is not an input
  if (event.target instanceof HTMLInputElement === false) return;
  // If table is not set, return
  if (!table) return;

  // Get the clipboard data
  const { clipboardData } = event;
  if (!clipboardData) return;

  const pastedData = clipboardData
    .getData("Text")
    .split("\n")
    .map((row: string) => row.split("\t"));

  // We need to make a copy of the rows.

  const updatedData = [...data];
  const tableColumns = table.getVisibleFlatColumns();

  const rowIndex = cell.row.index;
  const columnIndex: number = tableColumns.findIndex(
    (column: Record<string, any>) => column.id === cell.column.id
  );

  // Prevent the default paste action
  event.preventDefault();

  if (event.target instanceof HTMLInputElement) {
    // Then we update the cell value to the first value in the pasted data.
    const [firstPastedValue] = pastedData[0];

    event.target.value = firstPastedValue;
  }

  // Here is where it gets interesting.
  // The pasted data may be larger than the selected rows and columns.
  // Or, it may be smaller.
  // We need to update the rows and columns to match the pasted data.
  // Whilst still keeping the data that was already there untouched.
  // So if it is larger, we ignore the extra data.
  // If it is smaller, we only update the data that exists.
  for (let i = 0; i < pastedData.length; i++) {
    // We need to check if the row exists.
    if (data[rowIndex + i]) {
      // If it does, we need to update the columns.
      for (let j = 0; j < pastedData[i].length; j++) {
        // We need to check if the column exists and is editable
        if (enableEditingOnColumn(tableColumns[columnIndex + j])) {
          // If it is, we can update the data.
          updatedData[rowIndex + i][tableColumns[columnIndex + j].id] = validateTypeOrEmpty(
            pastedData[i][j],
            tableColumns[columnIndex + j].columnDef
          );
        }
      }
    }
  }

  onDataChange(data);
  // stop editing the cell
  table.setEditingCell(null);
};
