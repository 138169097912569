import type { TimeSeries } from "@core/types/common";

export type ReaderFunction = ((timestamp: number) => any) | null;
export type TransformationFunction = (data: any, column: any[]) => void;

type DeriveTimeSeriesParams = {
  reader: ReaderFunction;
  start?: number;
  end?: number;
  delta: number;
  fns: Array<[string, TransformationFunction]>;
};

/**
 * Derives new time series data based on provided transformation functions.
 *
 * @param {Object} params - The parameters for deriving time series data.
 * @param {Function} params.reader - A function that reads data for a given timestamp.
 * @param {number} params.start - The start timestamp of the time series data.
 * @param {number} params.end - The end timestamp of the time series data.
 * @param {number} params.delta - The time step increment between each timestamp.
 * @param {Array} params.fns - An array of tuples where each tuple contains a column name and a transformation function.
 * @returns {Object[]} An array of objects, where each object represents a row of the derived time series data with keys as column names.
 *
 * @example
 * // Define a reader function to fetch data (could be from a database, API, etc.)
 * const reader = timestamp => fetchDataForTimestamp(timestamp);
 * // Define transformation functions for each column
 * const transformations = [
 *   ['heat', (data, column) => column.push(data.heat)],
 *   ['volume', (data, column) => column.push(data.volume)]
 * ];
 * // Derive the time series data
 * const derivedData = deriveTimeSeries({
 *   reader: reader,
 *   start: 1609459200000,
 *   end: 1609545600000,
 *   delta: 3600000,
 *   fns: transformations
 * });
 */
export function deriveTimeSeries({
  reader,
  start,
  end,
  delta,
  fns,
}: DeriveTimeSeriesParams): TimeSeries[] {
  const resultColumns: TimeSeries = { ts: 0 };
  for (const [columnName] of fns) {
    resultColumns[columnName] = [];
  }

  if (!start || !end) return [];

  for (let timestamp = start; timestamp < end; timestamp += delta) {
    fns.forEach(([columnName, transformFunction]) => {
      transformFunction(reader?.(timestamp), resultColumns[columnName]);
    });
  }

  const finalResult = [];
  const columnNames = Object.keys(resultColumns);
  const maxRows = resultColumns[columnNames[0]].length;
  for (let index = 0; index < maxRows; index++) {
    const row: TimeSeries = { ts: 0 };
    columnNames.forEach((columnName) => {
      row[columnName] = resultColumns[columnName][index];
    });
    finalResult.push(row);
  }
  return finalResult;
}
