import type { ReactNode } from "react";
import { observer } from "mobx-react";
import { AppBar, Box, IconButton, Tooltip, type BoxProps } from "@mui/material";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

import { APP_BAR_HEIGHT } from "@conf/ui_constants";
import { useInject } from "@hooks";

import { FontAwesomeSvgIcon } from "../../../../../libs/components/src/ui/FontAwesomeSvgIcon";

type SubHeaderProps = {
  /** Children to be rendered within the SubHeader */
  children?: ReactNode;
  /** Component to be rendered at the end of the SubHeader */
  endComponent?: ReactNode;
  /** URL of the wiki page to link to */
  wikiUrl?: string | { base: string; tabs?: Record<string, string> };
  /** ID for the SubHeader container */
  id?: string;
};

type SubHeaderSectionProps = {
  children: ReactNode;
} & BoxProps;

/**
 * - This component is used to hold any page specific controls.
 * - It also houses the link to the wiki page for the page.
 */
const SubHeader = observer(function SubHeader({
  children,
  endComponent = null,
  wikiUrl = undefined,
  id,
}: Readonly<SubHeaderProps>) {
  const { routerStore, networks, ui } = useInject("routerStore", "networks", "ui");

  const getWikiUrl = () => {
    // Check if the wikiUrl has been provided.
    if (typeof wikiUrl === "string") {
      return wikiUrl;
    }
    // Otherwise, use the routerStore to get the wikiUrl.
    const { routerState } = routerStore;
    const stateWikiUrl = wikiUrl || routerState.options.wikiUrl;
    if (stateWikiUrl) {
      // We do this instead of using the routerState.queryParams since the section selector doesnt
      // do a navigation, so the queryParams are not updated.
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("tab")) {
        const tab = searchParams.get("tab") as string;
        return `${stateWikiUrl.base}/${stateWikiUrl.tabs[tab] || ""}`;
      }
      return stateWikiUrl.base;
    }
    return null;
  };

  const openWikiPage = () => {
    const wikiLink = getWikiUrl();
    window.open(wikiLink, "_blank");
  };

  const isSubOrClusterDetailOpen = ui.is_subdetail_open || ui.is_cluster_detail_open;

  return (
    <AppBar
      position={isSubOrClusterDetailOpen ? "fixed" : "sticky"}
      sx={{
        "&.MuiAppBar-positionFixed": {
          width: ui.max_drawer_width - 1,
          top: APP_BAR_HEIGHT,
          left: 0,
        },
      }}
    >
      <Box
        minHeight={56}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        {/* Left Side Components */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          height="100%"
          flexGrow={1}
          gap={1}
          pl={3}
          pr={1}
          py={1}
          style={{ flexFlow: "wrap" }}
          id={id}
        >
          {networks.ready && children}
        </Box>
        {/* Right Side Components */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          height="100%"
        >
          {endComponent && (
            <Box
              borderLeft={1}
              borderColor="border.divider.main"
              height="inherit"
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              px={1}
              py={1}
            >
              {endComponent}
            </Box>
          )}
          <Box borderLeft={1} borderColor="border.divider.main" height="inherit">
            {!!getWikiUrl() && (
              <IconButton
                onClick={openWikiPage}
                color="inherit"
                sx={{ height: "100%", p: 1.7 }}
                size="large"
              >
                <Tooltip title="Information">
                  <FontAwesomeSvgIcon icon={faCircleInfo} sx={{ fontSize: 20 }} />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
});

export default SubHeader;

export function SubHeaderSection({ children, ...props }: Readonly<SubHeaderSectionProps>) {
  return (
    <Box
      color="grey.50"
      borderRight={2}
      borderColor="primary.light"
      height="inherit"
      px={2}
      py={1}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Box>
  );
}
