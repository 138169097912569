import type { FC } from "react";
import { createElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";

import { logger as baseLogger } from "@core/logger";
import { getUrlParam, insertUrlParam, removeUrlParam } from "@core/utils";

import UtfErrorBoundary from "../ErrorBoundary";
import { UtfTab, UtfTabs } from "../UtfTab";

const logger = baseLogger.getSubLogger({ name: "SectionSelector" });

type Section = {
  id: string;
  title: string;
  component: FC;
  disabled?: boolean;
  props?: Record<string, unknown>;
};

type SectionSelectorProps = {
  selected?: string;
  sections: Section[];
  saveToRoute?: boolean;
  topOfPage?: boolean;
  routeSelector?: string;
  triggerSelectOnMount?: boolean;
  selectCallback?: (...args: unknown[]) => unknown;
};

function NoSectionComponent() {
  // eslint-disable-next-line i18next/no-literal-string
  return <div>No section</div>;
}

export default function SectionSelector({
  selected,
  selectCallback,
  sections = [],
  saveToRoute = false,
  topOfPage = false,
  routeSelector = "tab",
  triggerSelectOnMount = false,
}: Readonly<SectionSelectorProps>) {
  // Pick first section that has permission granted and not disabled
  const defaultSectionId = useMemo(() => {
    let nonLockedSectionId = "";
    // ensure the "sections" prop is provided
    if (!Array.isArray(sections) || !sections.length) {
      logger.error(`"sections" prop must be provided!`);
      return "";
    }
    // store in the url
    if (saveToRoute) {
      nonLockedSectionId = getUrlParam(routeSelector) ?? "";
    }
    // find the first non-disabled section
    sections.forEach((section) => {
      if (nonLockedSectionId) return;
      if (!section.disabled) nonLockedSectionId = section.id;
    });
    return nonLockedSectionId;
  }, [routeSelector, saveToRoute, sections]);

  const [activeSectionId, setActiveSectionId] = useState(defaultSectionId);
  const isValidSection = useCallback(
    (sectionId: string) => sections.map((section) => section.id).includes(sectionId),
    [sections]
  );

  // Scroll to top on tab change
  const ref = useRef<HTMLElement>();
  useEffect(() => {
    if (!ref.current?.scrollTo) return;
    ref.current?.scrollTo(0, 0);
  }, [activeSectionId]);

  // Make sure to update the url the first time the component is mounted.
  useEffect(() => {
    if (saveToRoute && isValidSection(activeSectionId)) {
      insertUrlParam(routeSelector, activeSectionId);
    }
    // Make sure to call the callback if the page loads without the default tab.
    if (
      isValidSection(activeSectionId) &&
      (activeSectionId !== defaultSectionId || triggerSelectOnMount) &&
      selectCallback
    ) {
      selectCallback(activeSectionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSectionChange = useCallback(
    (_component: undefined, newSection: string) => {
      if (activeSectionId === newSection) return;
      logger.debug("onSectionChange", newSection);
      setActiveSectionId(newSection);
      if (selectCallback) selectCallback(newSection);
      if (saveToRoute) insertUrlParam(routeSelector, newSection);
    },
    [activeSectionId, routeSelector, saveToRoute, selectCallback]
  );

  // Clear query string once the component unmounted
  useEffect(
    () => () => {
      if (saveToRoute) removeUrlParam(routeSelector);
    },
    [routeSelector, saveToRoute]
  );

  // Update active tab based on "selected" prop
  useEffect(() => {
    if (!selected || !isValidSection(selected)) return;
    onSectionChange(undefined, selected);
  }, [isValidSection, onSectionChange, selected]);

  // Generate active section component
  const section = sections?.find(({ id }) => id === activeSectionId);
  const activeSectionComponent = createElement(
    section?.component || NoSectionComponent,
    section?.props || undefined
  );

  logger.debug("RENDER %o", JSON.stringify({ defaultSectionId, activeSectionId, section }));

  return (
    <Grid container direction="column" wrap="nowrap" sx={{ height: "100%" }}>
      <Grid item>
        <Paper elevation={2} style={{ zIndex: 2, position: "relative" }}>
          <UtfTabs value={activeSectionId} onChange={onSectionChange} variant="fullWidth">
            {sections.map((option) => {
              return (
                <UtfTab
                  key={option.id}
                  label={option.title}
                  value={option.id}
                  disabled={option.disabled}
                />
              );
            })}
          </UtfTabs>
        </Paper>
      </Grid>
      <Grid item flexGrow={1} pb={1} sx={{ overflow: "hidden" }}>
        <Box
          data-testid={`section-content-${activeSectionId}`}
          ref={ref}
          sx={{
            height: "100%",
            display: topOfPage ? "block" : "flex",
            p: topOfPage ? 4 : 1,
            pl: 2,
            pr: 2,
            overflow: "auto",
            bgcolor: "background.hover",
          }}
        >
          <UtfErrorBoundary>{activeSectionComponent}</UtfErrorBoundary>
        </Box>
      </Grid>
    </Grid>
  );
}
