import React, { useEffect, useReducer, useRef } from "react";
import { RedoRounded, UndoRounded } from "@mui/icons-material";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Box } from "@mui/system";
import {
  faDrawCircle,
  faDrawPolygon,
  faDrawSquare,
  faHand,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { useMap } from "@vis.gl/react-google-maps";

import { FontAwesomeSvgIcon } from "../../ui/FontAwesomeSvgIcon";
import { useDrawingManager } from "../hooks";
import { DrawingActionKind } from "../types";
import reducer, { useDrawingManagerEvents, useOverlaySnapshots } from "./undoRedo";

type MapDrawingControlsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrawingsChange?: (drawings: any) => void;
  enabled?: boolean;
};

/**
 * MapDrawingControls
 */
export function MapDrawingControls({ onDrawingsChange, enabled }: MapDrawingControlsProps) {
  const map = useMap();
  const drawingManager = useDrawingManager();
  const [drawingMode, setDrawingMode] = React.useState<google.maps.drawing.OverlayType | null>(
    null
  );

  const [state, dispatch] = useReducer(reducer, {
    past: [],
    now: [],
    future: [],
  });

  useEffect(() => {
    // if no longer enabled, clear drawings, and set mode to hand
    if (!enabled) {
      dispatch({ type: DrawingActionKind.CLEAR });
      setDrawingMode(null);
    }
  }, [enabled]);

  // We need this ref to prevent infinite loops in certain cases.
  // For example when the radius of circle is set via code (and not by user interaction)
  // the radius_changed event gets triggered again. This would cause an infinite loop.
  // This solution can be improved by comparing old vs. new values. For now we turn
  // off the "updating" when snapshot changes are applied back to the overlays.
  const overlaysShouldUpdateRef = useRef<boolean>(false);

  useDrawingManagerEvents(drawingManager, overlaysShouldUpdateRef, dispatch);
  useOverlaySnapshots(map, state, overlaysShouldUpdateRef);

  // Notify parent component about changes
  useEffect(() => {
    if (onDrawingsChange) onDrawingsChange(state.now);
  }, [onDrawingsChange, state.now]);

  // Update drawing mode
  useEffect(() => {
    if (drawingManager) {
      drawingManager.setDrawingMode(drawingMode);
    }
  }, [drawingManager, drawingMode]);

  const handleDrawingModeChange = (
    event: React.MouseEvent<HTMLElement>,
    mode: google.maps.drawing.OverlayType | null
  ) => {
    if (!drawingManager) return;
    setDrawingMode(mode);
  };

  if (!drawingManager || !enabled) return null;

  return (
    <Box mt={0.5} sx={{ display: "flex", justifyContent: "center" }}>
      <ToggleButtonGroup
        size="small"
        data-testid="map-drawing-controls"
        sx={{ mr: 0.5, backgroundColor: "white" }}
        onChange={handleDrawingModeChange}
        value={drawingManager?.getDrawingMode()}
        color="primary"
        exclusive
      >
        {/* Hand Control */}
        <ToggleButton value={null} selected={drawingMode === null} size="small" data-testid="hand">
          <FontAwesomeSvgIcon icon={faHand} />
        </ToggleButton>
        {/* Circle */}
        <ToggleButton
          value={google.maps.drawing.OverlayType.CIRCLE}
          selected={drawingMode === google.maps.drawing.OverlayType.CIRCLE}
          size="small"
          data-testid="circle"
        >
          <FontAwesomeSvgIcon icon={faDrawCircle} />
        </ToggleButton>
        {/* Polygon */}
        <ToggleButton
          value={google.maps.drawing.OverlayType.POLYGON}
          selected={drawingMode === google.maps.drawing.OverlayType.POLYGON}
          size="small"
          data-testid="polygon"
        >
          <FontAwesomeSvgIcon icon={faDrawPolygon} />
        </ToggleButton>
        {/* Rectangle */}
        <ToggleButton
          value={google.maps.drawing.OverlayType.RECTANGLE}
          selected={drawingMode === google.maps.drawing.OverlayType.RECTANGLE}
          size="small"
          data-testid="rectangle"
        >
          <FontAwesomeSvgIcon icon={faDrawSquare} />
        </ToggleButton>
      </ToggleButtonGroup>
      <Button
        onClick={() => dispatch({ type: DrawingActionKind.UNDO })}
        disabled={!state.past.length}
        size="small"
        variant="contained"
        data-testid="undo"
      >
        <UndoRounded />
      </Button>
      <Button
        onClick={() => dispatch({ type: DrawingActionKind.REDO })}
        disabled={!state.future.length}
        size="small"
        variant="contained"
        data-testid="redo"
      >
        <RedoRounded />
      </Button>
      {/* Clear */}
      <Button
        size="small"
        onClick={() => dispatch({ type: DrawingActionKind.CLEAR })}
        color="error"
        variant="contained"
        disabled={!state.now.length}
        sx={{ ml: 0.5 }}
        data-testid="clear"
      >
        <FontAwesomeSvgIcon icon={faTrash} />
      </Button>
    </Box>
  );
}
