import type { MRT_Row, MRT_RowData } from "material-react-table";

import { getRowValueByColumnID } from "./getRowValueByColumnId";

export function coordinatesSort(
  rowA: MRT_Row<MRT_RowData>,
  rowB: MRT_Row<MRT_RowData>,
  columnId: string
) {
  let a = getRowValueByColumnID(rowA, columnId);
  let b = getRowValueByColumnID(rowB, columnId);

  if (!Array.isArray(a)) a = JSON.stringify([0, 0]);
  if (!Array.isArray(b)) b = JSON.stringify([0, 0]);

  // Perform sorting without converting to strings. However, if a or b is not a number, we compare as string to avoid errors.
  if (
    (typeof a === "number" && typeof b === "number") ||
    (typeof a === "string" && typeof b === "string")
  )
    return a > b ? 1 : a < b ? -1 : 0;

  a = `${a}`;
  b = `${b}`;

  return a > b ? 1 : a < b ? -1 : 0;
}
