import { SvgIcon } from "@mui/material";

// eslint-disable-next-line import/no-default-export
export default function SubstationIcon(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path d="M9.77,24A10.66,10.66,0,0,1,8,3V5.2A8.53,8.53,0,1,0,18.78,16H21a10.66,10.66,0,0,1-10.32,8ZM4.27,13.34a6.4,6.4,0,1,1,6.39,6.4,6.4,6.4,0,0,1-6.39-6.4Zm1.5,0a4.88,4.88,0,1,0,4.88-4.87,4.88,4.88,0,0,0-4.88,4.87Zm13.43,0A8.52,8.52,0,0,0,10.66,4.8V0A13.34,13.34,0,0,1,24,13.32Z" />
    </SvgIcon>
  );
}
