const features = {
  home: "HOME",

  // Sales
  sales: "SALES",
  pricing: "SALES_PRICING",

  // Metering
  metering: "METERING",
  fault_detection: "METERING_FAULT_DETECTION",

  // Distribution
  dist: "DISTRIBUTION",
  dist_rta: "DISTRIBUTION_RETURNTEMPANALYSIS",
  dist_rtsp: "DISTRIBUTION_RTSAVINGPOTENTIAL",
  dist_dload: "DISTRIBUTION_DESIGNLOAD",

  // Production
  production: "PRODUCTION",
  forecast: "PRODUCTION_LOADFORECAST",
  optimization: "PRODUCTION_OPTIMIZATION",
  scenario_analysis: "SCENARIO_ANALYSIS",

  // Other route based permissions
  datalibrary: "DATALIBRARY",
  mapview: "MAPVIEW",
  profile: "PROFILE",

  // App-wide permissions
  substationlist: "SUBSTATIONLIST",
  substation_overview: "SUBSTATIONEXPLORE_OVERVIEW",
  substation_consumption: "SUBSTATIONEXPLORE_CONSUMPTION",
  substation_fault_detection: "SUBSTATIONEXPLORE_FAULT_DETECTION",
  clusterlist: "CLUSTERLIST",
  cluster_overview: "CLUSTEREXPLORE_OVERVIEW",
  cluster_consumption: "CLUSTEREXPLORE_CONSUMPTION",
};

export const FEATURE_ACCESS_LABELS = new Map([
  [features.home, "Home"],
  [features.sales, "Sales"],
  [features.pricing, "Pricing"],

  [features.metering, "Metering"],
  [features.fault_detection, "Fault Detection"],

  [features.dist, "Distribution"],
  [features.dist_rta, "RT Analysis"],
  [features.dist_rtsp, "RT - Saving Potential"],
  [features.dist_dload, "Design Load"],

  [features.production, "Production"],
  [features.forecast, "Forecast"],
  [features.optimization, "Optimization"],
  [features.scenario_analysis, "Scenario Analysis"],

  [features.datalibrary, "Data Library"],
  [features.mapview, "Map View"],
  [features.profile, "Profile"],

  // [features.substationfilter, "Global Filters"],
  [features.substationlist, "Substation List"],
  [features.substation_overview, "Substation Overview"],
  [features.substation_consumption, "Substation Consumption"],
  [features.substation_fault_detection, "Substation Fault Detection"],
  [features.clusterlist, "Cluster List"],
  [features.cluster_overview, "Cluster Explorer"],
  [features.cluster_consumption, "Cluster Consumption"],
]);

export default features;
