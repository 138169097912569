import { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, Button, IconButton, Typography } from "@mui/material";

type PageTitleProps = {
  title: string;
  pageIcon: FC | null;
  onClick: () => void;
};

export const PageTitle = observer<FC<PageTitleProps>>(
  ({ title, pageIcon: Icon = null, onClick }) => {
    const { t } = useTranslation(["navigation"]);
    const paperColor = "background.paper";

    return (
      <Box flexGrow={1}>
        <Button onClick={onClick} sx={{ color: paperColor }} component="div" fullWidth>
          {Icon && (
            <IconButton sx={{ color: paperColor }} size="large">
              <Icon />
            </IconButton>
          )}
          <Typography variant="h4" data-testid="page-title">
            {t(title)}
          </Typography>
        </Button>
      </Box>
    );
  }
);
