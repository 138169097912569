import React from "react";
import { Checkbox } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";

const Icon = styled(Box)(({ theme }) => ({
  borderRadius: 1,
  width: 16,
  height: 16,
  boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.grey["50"],
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: `inset 0 0 0 2px ${theme.palette.grey["400"]}`,
    backgroundColor: theme.palette.grey["50"],
  },
}));

const UtfCheckbox = React.forwardRef((props, ref) => (
  <Checkbox
    ref={ref}
    disableRipple
    color="default"
    icon={<Icon />}
    checkedIcon={
      <Icon
        sx={{
          "&:before": {
            display: "block",
            width: 16,
            height: 16,
            boxShadow: (theme) => `inset 0 0 0 2px ${theme.palette.secondary.main}`,
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath style='fill:none' d='M0 0h12.048v12.048H0z' transform='translate(2 2)'/%3E%3Cpath d='M6.207 10.922 4.1 8.813l-.7.7 2.807 2.812L12.222 6.3l-.7-.7z' transform='translate(.295 -.808)' style='fill:orange;fill-rule:evenodd'/%3E%3C/svg%3E%0A\")",
            content: '""',
          },
          "input:disabled ~ &:before": {
            boxShadow: (theme) => `inset 0 0 0 2px ${theme.palette.action.disabled}`,
          },
        }}
      />
    }
    inputProps={{ "aria-label": "checkbox" }}
    {...props}
  />
));

export default UtfCheckbox;
