import { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useRegisterSW } from "virtual:pwa-register/react";

import { logger as baseLogger } from "@core/logger";
import { useInject } from "@hooks";

const logger = baseLogger.getSubLogger({ name: "SW" });
const intervalMS = 60 * 60 * 1000;

export const ServiceWorker = observer<React.FC>(() => {
  const { app } = useInject("app");
  const isRegistered = useRef<boolean>(false);

  const {
    // offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      if (!r) return;
      setInterval(() => {
        void r.update();
        logger.debug("SW Registered: " + r);
      }, intervalMS);
    },
    onRegisterError(error) {
      logger.debug("SW registration error", error);
    },
  });

  useEffect(() => {
    if (isRegistered.current) return;

    const updateServiceWorkerAndReload = async () => updateServiceWorker();
    app.setUpdater(updateServiceWorkerAndReload);

    isRegistered.current = true;
  }, [app, updateServiceWorker]);

  useEffect(() => {
    app.setNeedsRefresh(needRefresh);
  }, [app, needRefresh]);

  return null;
});

ServiceWorker.displayName = "ServiceWorker";
