import { styled } from "@mui/styles";

const PREFIX = "VerticalSeparator";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: theme.spacing(1),
  },
}));

function VerticalSeparator() {
  return <Root className={classes.root} />;
}

export default VerticalSeparator;
