// old-component
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import { Avatar, Box, Button, Divider, IconButton, Paper, Typography } from "@mui/material";

// eslint-disable-next-line react-refresh/only-export-components
const DEFICONS = {
  success: DoneIcon,
  error: ErrorOutlineIcon,
  warning: WarningIcon,
  info: InfoIcon,
};

// eslint-disable-next-line react-refresh/only-export-components
const BIcon = (props) => {
  const { level } = props;
  const Icn = DEFICONS[level];
  if (Icn) {
    return <Icn />;
  }
  return null;
};

class Banner extends Component {
  render() {
    const {
      rootStore: { ui },
    } = this.props;

    return ui.banners.map((b) => {
      return (
        <Fragment key={b.id}>
          <Paper elevation={0}>
            <Box
              p={1}
              mt={1}
              color={`${b.level}.dark`}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box>
                <Avatar>
                  <BIcon level={b.level || b.code.level} />
                </Avatar>
              </Box>
              <Box flexGrow={1} ml={2}>
                <Typography>
                  {b.msg} {b.hint && "/ "}
                  {b.hint} {b.err && "/ "}
                  {b.err && JSON.stringify(b.err)}
                </Typography>
              </Box>
              {b.btn ? (
                <Button onClick={b.btn.cb} variant="contained">
                  {b.btn.title}
                </Button>
              ) : null}
              <Box>
                <IconButton
                  onClick={() => {
                    ui.removeBanner(b.id);
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Paper>
          <Divider />
        </Fragment>
      );
    });
  }
}

// eslint-disable-next-line react-refresh/only-export-components
export default inject("rootStore")(observer(Banner));
