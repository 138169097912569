import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { useInject } from "@hooks";
import { HeadingBar } from "@shared/ui/layout";

import FeaturedBlogCarousel from "./FeaturedBlogCarousel";
import UpdatesContainer from "./UpdatesContainer";

function DashPage() {
  const { dash } = useInject("dash");
  const { t } = useTranslation(["home"]);

  useEffect(() => {
    dash.fetchData();
  }, [dash]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      mt={4}
      mx={4}
      mb={0}
      height="calc(93vh - 80px)"
      flexGrow={0}
    >
      <Box display="flex" flexDirection="column" flexGrow={1} width="60%">
        <HeadingBar title={t("text_feature", { ns: "home" })} grow="noGrow" />
        <FeaturedBlogCarousel />
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexShrink: 0,
          flexGrow: 0,
          flexBasis: "8px",
        }}
      />
      <Box display="flex" flexGrow={1} minHeight={0} flexDirection="column" width="30%">
        <HeadingBar title={t("text_news", { ns: "home" })} grow="noGrow" />
        <UpdatesContainer />
      </Box>
    </Box>
  );
}

// eslint-disable-next-line import/no-default-export
export default DashPage;
