import DarkClusterIcon from "@icons/DarkCluster";
import SubstationIcon from "@icons/Substation";

export const FETCH_MAX_DAYS_IN_FUTURE = 7;
export const SUBSTATION = "substation";
export const CLUSTER = "cluster";
export const LATEST = "latest";
export const FORECAST = "forecast";
export const SIMULATION = "simulation";
export const TRAINING = "training";

export const resourceIcons = {
  substation: SubstationIcon,
  cluster: DarkClusterIcon,
};

export const FILTER_OPTIONS = new Map([
  [LATEST, "title_heading_bar_latest_store_forecast"],
  [FORECAST, "title_heading_bar_custom_forecast"],
  [SIMULATION, "title_heading_bar_custom_simulation"],
]);
