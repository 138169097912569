// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: !!! HIGH prio migration needed
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BLOCK, UID_TYPE } from "@config/blocks";
import { COL_PARSERS, COLTYPE } from "@config/constants";

// TODO: Use these types migrating to TS
// import type { BlockDefinition, BlockSpec, ColumnMeta } from "@core/models";

/**
 * split the received df into component blocks and transform/read them.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function splitDf(rdf, block_definitions) {
  // eslint-disable-next-line no-param-reassign
  block_definitions = block_definitions || BLOCK;
  const mainColumns = rdf.columns;
  const mainRows = rdf.data;
  let blockNames = new Map();

  for (let colIdx = 0; colIdx < mainColumns.length; colIdx++) {
    for (const specName of Object.keys(block_definitions)) {
      const BLKSPEC = block_definitions[specName];
      const blkParts = BLKSPEC.parse_block_name(mainColumns[colIdx]);
      if (blkParts && Object.prototype.hasOwnProperty.call(BLKSPEC.columns, blkParts.col)) {
        let meta;
        const currentColMeta = {
          col: blkParts.col,
          idx: colIdx,
          blk: blkParts.blk,
          blkName: blkParts.blkname,
          spec: BLKSPEC.columns[blkParts.col],
        };
        if (blockNames.has(blkParts.blkname)) {
          meta = blockNames.get(blkParts.blkname);
          meta.columns.push(currentColMeta);
        } else {
          const indexCol = mainColumns.indexOf(BLKSPEC.index);
          meta = {
            blk: block_definitions[blkParts.blk],
            name: BLKSPEC.name,
            columns: [
              {
                col: BLKSPEC.index,
                idx: indexCol,
                blkName: blkParts.blkname,
                blk: blkParts.blk,
                spec: BLKSPEC.columns[BLKSPEC.index],
              },
              currentColMeta,
            ],
          };
        }
        blockNames.set(blkParts.blkname, meta);
      }
    }
  }
  blockNames = Array.from(blockNames);
  const allBlocks = [];
  for (const [blkName, blkMeta] of blockNames) {
    const indexColumnName = blkMeta.blk.index;
    const cblk = {
      columns: {},
      data: [],
      idx: indexColumnName,
      name: blkName,
      blockname: blkMeta.name,
      idxMap: new Map(),
    };
    for (let i = 0; i < blkMeta.columns.length; i++) {
      const m = blkMeta.columns[i];
      cblk.columns[m.col] = { idx: i, spec: m.spec };
    }
    for (let rowIdx = 0; rowIdx < mainRows.length; rowIdx++) {
      const row = mainRows[rowIdx];
      cblk.data.push(
        blkMeta.columns.map((m) => {
          const val = COL_PARSERS[m.spec.type](row[m.idx]);
          if (m.col === indexColumnName) {
            cblk.idxMap.set(val, rowIdx);
          }
          return val;
        })
      );
    }
    allBlocks.push([cblk.name, cblk]);
  }
  return allBlocks;
}

function parsingBlockDefinition(blockName, blockDefinitions) {
  let setBlockDefinition = null;
  for (const [, BLKSPEC] of Object.entries(blockDefinitions)) {
    const blockParts = BLKSPEC.parse_block_name(blockName);

    if (blockParts !== null) {
      setBlockDefinition = { spec: BLKSPEC, ...blockParts };
      break;
    }
  }
  return setBlockDefinition;
}

function initializingBlockDefinition(blockDefinitions) {
  let idx = 0;
  const blockDefinition = {
    columns: {
      uid: {
        col: "uid",
        idx,
        blk: blockDefinitions.blk,
        blkName: blockDefinitions.blkname,
        spec: UID_TYPE,
      },
    },
    data: [],
    idx: "uid",
    blk: blockDefinitions.blk,
    blkName: blockDefinitions.blkname,
    idxMap: new Map(),
  };

  for (const [column, columnSpec] of Object.entries(blockDefinitions.spec.columns)) {
    idx += 1;
    blockDefinition.columns[column] = {
      col: column,
      idx,
      blk: blockDefinitions.blk,
      blkName: blockDefinitions.blkname,
      spec: columnSpec,
    };
  }
  return blockDefinition;
}

function parsingBlockData(block, currentBlockData) {
  if (block === "changepoint_heat_energy" || block === "changepoint_volume") {
    return currentBlockData.block_data;
  }
  return currentBlockData.block_data[block];
}

function processingBlockData(block, currentBlock, blockColumns) {
  const row = [];
  row.push(currentBlock.uid);
  block.idxMap.set(currentBlock.uid, block.data.length);

  try {
    Object.values(block.columns).forEach((colMeta) => {
      if (colMeta.col !== block.idx) {
        const blockData = parsingBlockData(colMeta.col, currentBlock);
        const value =
          colMeta.spec.type === COLTYPE.virtual
            ? colMeta.spec.valueFunction(row, blockColumns, blockData)
            : blockData;

        row.push(COL_PARSERS[colMeta.spec.valueType || colMeta.spec.type](value));
      }
    });
    return row;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return row;
}

function getCurrentBlockDefiniton(currentBlockName, blockNameCache) {
  const currentBlockDefinition = blockNameCache.get(currentBlockName);
  if (currentBlockDefinition === undefined) {
    throw Error(`block not recognised ${currentBlockName}`);
  } else {
    return currentBlockDefinition;
  }
}

function getBlockData(currentBlockName, blockCache) {
  const block = blockCache.get(currentBlockName);
  if (block === undefined) {
    throw Error(`block definition not generated ${currentBlockName}`);
  } else {
    return block;
  }
}

/**
 * transforms new json style blocks returned from mdslv4
  to old df style block storage to decrease the memory used
  and to not need to rewrite all components for new api.

  this also parses to correct datatype format using predefined column definitions.

  if column doesn't exists in block, value will be undefined.
  if column exists and value doesn't -- it will be null.
*/
export function splitDfV4(rawDataBlocks, blockDefinitions = BLOCK, asObject = false) {
  const blockCache = new Map();
  const blockNameCache = new Map();

  for (const currentBlock of rawDataBlocks) {
    const currentBlockName = currentBlock.block_name;
    if (!blockNameCache.has(currentBlockName)) {
      const parseBlockDefinition = parsingBlockDefinition(currentBlockName, blockDefinitions);
      if (parseBlockDefinition !== null) {
        blockNameCache.set(currentBlockName, parseBlockDefinition);
      }
    }

    const currentBlockDefinition = getCurrentBlockDefiniton(currentBlockName, blockNameCache);

    if (!blockCache.has(currentBlockName)) {
      const initializeBlockDefinition = initializingBlockDefinition(currentBlockDefinition);

      blockCache.set(currentBlockName, initializeBlockDefinition);
    }
    const block = getBlockData(currentBlockName, blockCache);

    const blockColumns = Object.keys(block.columns);

    if (!(currentBlock.block_data === null || currentBlock.block_data === undefined)) {
      const processBlockData = processingBlockData(block, currentBlock, blockColumns);
      block.data.push(processBlockData);
    }
  }
  return asObject ? Object.fromEntries(blockCache) : Array.from(blockCache);
}
