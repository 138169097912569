import { DateTime } from "luxon";

import type { TimeSeries } from "@core/types/common";

/**
 * Calculate the daily averages of hourly data
 * @param {Array} hourlyData - The array of hourly data objects with timestamp and sensor values
 * @param {string} timeZone - The IANA time zone identifier
 * @returns {Array} An array of objects containing daily averages for each sensor
 */
export function calculateDailyAverages(hourlyData: TimeSeries[]): TimeSeries[] {
  if (!Array.isArray(hourlyData) || hourlyData.length === 0) return [];
  const dailyAverages: { [key: string]: TimeSeries } = {};

  // Ensure ts property exists and is a number
  const firstTimestamp = hourlyData[0].ts;
  const lastTimestamp = hourlyData[hourlyData.length - 1].ts;

  if (typeof firstTimestamp !== "number" || typeof lastTimestamp !== "number") {
    throw new Error("Invalid timestamp in hourlyData. Provide a number");
  }

  const startDate = DateTime.fromMillis(firstTimestamp).startOf("day").toMillis();
  const endDate = DateTime.fromMillis(lastTimestamp).endOf("day").toMillis();

  for (let date = startDate; date <= endDate; date = 3600 * 24 * 1000 + date) {
    // Remove the data to be processed from hourlyData
    const dailyData = hourlyData.filter(
      (row) =>
        // Creating a date object is too expensive, so we just compare the timestamp
        // Larger than start of day and smaller than end of day
        row.ts >= date && row.ts < date + 3600 * 24 * 1000
    );

    if (dailyData.length > 1) {
      const sumPerComponent: { key: string; value: number; count: number }[] = [];
      // We calculate per component per price point, because fields can be missing
      // independently from each other
      Object.keys(dailyData[0]).forEach((key) => {
        if (key !== "ts") {
          sumPerComponent.push({ key, value: 0, count: 0 });
        }
      });
      dailyData.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          if (key !== "ts") {
            const component = sumPerComponent.find((component) => component.key === key);
            // Avg only if value is not null/undefined
            if (component && Number.isFinite(value)) {
              component.value += value;
              component.count += 1; // We have to count fields independently
            }
          }
        });
      });

      const averages: TimeSeries = { ts: date };
      sumPerComponent.forEach((component) => {
        averages[component.key] = component.value / component.count || null;
      });

      dailyAverages[date] = averages;
    }
  }

  return Object.values(dailyAverages);
}
