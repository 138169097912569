const MEASURED_PEAK_POWER_COLUMNS = {
  flow_date_peak_power: {
    label: "Highest measured peak",
  },
  avg_peak_power: {
    label: "Average Peak Power",
  },
};

export default MEASURED_PEAK_POWER_COLUMNS;
