import { COLUMN_SECTION } from "./constants";

const CORE_COLUMNS = {
  heat_energy_sum: {
    label: "text_energy_kwh",
    info: "text_energy_kwh_desc",
    section: COLUMN_SECTION.energy,
  },
  volume_sum: {
    label: "text_volume_m3",
    info: "text_volume_m3_desc",
    section: COLUMN_SECTION.flow,
  },
  returntemp_flowweighted_avg: {
    label: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_flow_weighted",
    info: "sub_text_return_temp_c_flow_weighted_desc",
    section: COLUMN_SECTION.temperatures,
  },
  returntemp_powerweighted_avg: {
    label: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_power_weighted",
    info: "sub_text_return_temp_c_power_weighted_desc",
    section: COLUMN_SECTION.temperatures,
  },
  returntemp_unweighted_avg: {
    label: "text_return_temperature_c",
    sublabel: "sub_text_return_temp_c_unweighted",
    info: "sub_text_return_temp_c_unweighted_desc",
    section: COLUMN_SECTION.temperatures,
  },
  supplytemp_flowweighted_avg: {
    label: "text_supply_temperature_c",
    sublabel: "sub_text_delta_temp_c_flow_weighted",
    info: "sub_text_supply_temp_c_flow_weighted_desc",
    section: COLUMN_SECTION.temperatures,
  },
  supplytemp_powerweighted_avg: {
    label: "text_supply_temperature_c",
    sublabel: "sub_text_supply_temp_c_power_weighted",
    info: "sub_text_supply_temp_c_power_weighted_desc",
    section: COLUMN_SECTION.temperatures,
  },
  supplytemp_unweighted_avg: {
    label: "text_supply_temperature_c",
    sublabel: "sub_text_supply_temp_c_unweighted",
    info: "sub_text_supply_temp_c_unweighted_desc",
    section: COLUMN_SECTION.temperatures,
  },
  heat_energy_daily_var: {
    label: "text_heat_energy_kwh",
    sublabel: "Normal year | FlowWeighted",
    info: "text_heat_energy_kwh_desc",
    section: COLUMN_SECTION.usage_patterns,
  },
};

export default CORE_COLUMNS;
