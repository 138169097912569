import { useEffect, type PropsWithChildren } from "react";
import {
  ControlPosition,
  Map,
  MapControl,
  useMap,
  useMapsLibrary,
  type MapProps,
} from "@vis.gl/react-google-maps";

import { MapDrawingControls } from "./controls/MapDrawingControls";
import { MapLoader } from "./Loader";
import utilifeedStyles from "./styles.json";
import type { Overlay } from "./types";

/**
 * UtfMap Props
 */
export type UtfMapProps = {
  defaultCenter?: google.maps.LatLngLiteral;
  drawingTools?: boolean;
  onDrawingsChange?: (drawings: Overlay[] | undefined) => void;
} & MapProps;

export function UtfMap({
  children,
  defaultCenter,
  drawingTools,
  onDrawingsChange,
  id,
  mapTypeId,
  ...props
}: PropsWithChildren<UtfMapProps>) {
  const map = useMap(id);
  const maps = useMapsLibrary("maps");

  useEffect(() => {
    if (!map || !maps) return;
    const defaultStyles = new maps.StyledMapType(utilifeedStyles, { name: "Utilifeed" });
    if (!map.mapTypes.get("utilifeed")) map.mapTypes.set("utilifeed", defaultStyles);
    //
    const newMapTypeId = mapTypeId === "roadmap" ? "utilifeed" : mapTypeId;
    map.setMapTypeId(newMapTypeId);
  }, [map, mapTypeId, maps]);

  return (
    <div data-testid={`utf-${props.mapId ?? "map"}`} style={{ height: "100%", width: "100%" }}>
      <MapLoader>
        <Map
          id={id}
          defaultZoom={12}
          defaultCenter={
            defaultCenter ?? {
              // Utilifeed Office Coords
              lat: 57.70055963189751,
              lng: 11.949964604273221,
            }
          }
          // disable drag-n-dropable-yellow-fella icon (street wiew)
          streetViewControl
          // remove controls to change map display type
          mapTypeControl={false}
          // "greedy": All touch * gestures and scroll events pan or zoom the map.
          gestureHandling="greedy"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {children}
          {/* if drawingToolsOn */}
          <MapControl position={ControlPosition.TOP_CENTER}>
            <MapDrawingControls onDrawingsChange={onDrawingsChange} enabled={drawingTools} />
          </MapControl>
        </Map>
      </MapLoader>
    </div>
  );
}
