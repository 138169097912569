/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, type PropsWithoutRef } from "react";
import type * as Sentry from "@sentry/react";

import UtfErrorBoundary from "./ErrorBoundary";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = PropsWithoutRef<any>;

function withErrorBoundary<PropsWithE extends Props>(
  SafeComponent: React.ComponentType<PropsWithE>,
  errorBoundaryProps?: Sentry.ErrorBoundaryProps
): React.ForwardRefExoticComponent<Props & React.RefAttributes<unknown>> {
  const Wrapped = forwardRef<unknown, PropsWithE>((props, ref) => (
    <UtfErrorBoundary {...errorBoundaryProps}>
      <SafeComponent {...props} ref={ref} />
    </UtfErrorBoundary>
  ));

  // Format for display in DevTools
  const name = SafeComponent.displayName || SafeComponent.name || "Unknown";
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}

export default withErrorBoundary;
