/* eslint-disable no-param-reassign */
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

const XLSXFILETYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const CSVFILETYPE = "text/plain;charset=UTF-8";

export function setupCustomExporters(H) {
  H.Chart.prototype.prepareCustomExportData = function prepareCustomExportData() {
    let filename = "chart";
    let rows;
    const { options } = this;

    // Use the custom export data if its set (fast)
    if (options.exporting?.data) {
      rows = options.exporting.data;
      rows.sort((a, b) => a[0] - b[0]);
    } else {
      // or let the Highcharts compute export data (slow)
      rows = this.getDataRows() || [];
    }

    // Get the filename, copied from the Chart.fileDownload function
    if (options.exporting.filename) {
      filename = options.exporting.filename;
    } else if (this.title?.textStr) {
      filename = this.title.textStr.replace(/ /g, "-").toLowerCase();
    }

    return { rows, filename };
  };

  // Custom XLSX download handler
  H.Chart.prototype.downloadXLSX = function downloadXLSX() {
    const { rows, filename } = this.prepareCustomExportData();

    // Prepare XLSX file
    const ws = XLSX.utils.aoa_to_sheet(rows);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const wbData = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbData], {
      type: XLSXFILETYPE,
    });
    FileSaver.saveAs(blob, `${filename}.xlsx`);
  };

  // Default lang string, overridable in i18n options
  H.getOptions().lang.downloadXLSX = "Download XLSX";

  // Add the menu item handler
  H.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
    textKey: "downloadXLSX",
    onclick() {
      this.downloadXLSX();
    },
  };

  // Replace the menu item
  const { menuItems } = H.getOptions().exporting.buttons.contextButton;
  menuItems[menuItems.indexOf("downloadXLS")] = "downloadXLSX";

  // Custom CSV download handler
  H.Chart.prototype.downloadCSV = function downloadCSV() {
    const { rows, filename } = this.prepareCustomExportData();

    // Prepare CSV file
    const ws = XLSX.utils.aoa_to_sheet(rows);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: CSVFILETYPE });
    FileSaver.saveAs(blob, `${filename}.csv`);
  };

  // Default lang string, overridable in i18n options
  H.getOptions().lang.downloadCSV = "Download CSV";

  // Add the menu item handler
  H.getOptions().exporting.menuItemDefinitions.downloadCSV = {
    textKey: "downloadCSV",
    onclick() {
      this.downloadCSV();
    },
  };

  // Replace the menu item
  menuItems[menuItems.indexOf("downloadCSV")] = "downloadCSV";
}
