import type { ReactNode } from "react";
import { Fragment, createElement, useEffect, useState } from "react";
import { createPortal } from "react-dom";

/**
 * Use this hook to replace Layout level SubBar components content.
 *
 * Useful for page controls.
 *
 * @export
 * @param {FC} content
 * @return {*}
 */
export function useSubBarPortal(content: ReactNode) {
  const [portalEl, setPortalEl] = useState<Element | DocumentFragment | null>(null);

  useEffect(() => {
    const layoutSubBarPortal = document.getElementById("utf-page-controls-portal");
    setPortalEl(layoutSubBarPortal);
    return () => {
      setPortalEl(null);
    };
  }, []);

  if (!portalEl) return null;

  return createElement(Fragment, null, createPortal(content || null, portalEl) as ReactNode);
}
