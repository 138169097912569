export const LEVEL = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
};

export const CODES = {
  unreachable: "unreachable",
  offline: "offline",
  online: "online",
  perm: "permission",
  server_error: "server_error",
  nodata: "no_data",
  bad_request: "bad_request",
  no_permission: "no_permission",
  success: "success",
  app_update: "app_update",
};

export const ERRTYPE = {
  server: "server",
  connection: "connection",
  perm: "permission",
  data: "data",
};

const MODULE = {
  [CODES.unreachable]: {
    hint: "Unable to reach api service. Please try again later. If the problem persists, report the issue to support@utilifeed.com, preferably with a screenshot attached and description of what you were trying to achieve - Thanks Utilifeed",
    type: ERRTYPE.server,
    level: LEVEL.error,
  },
  [CODES.server_error]: {
    hint: "Server error. Please report the issue to support@utilifeed.com, preferably with a screenshot attached and a description of what you were trying to achieve - Thanks Utilifeed",
    type: ERRTYPE.server,
    level: LEVEL.error,
  },
  [CODES.offline]: {
    hint: "You have lost internet connectivity. App is offline.",
    type: ERRTYPE.connection,
    level: LEVEL.error,
  },
  [CODES.online]: {
    hint: "You have established internet connectivity. App is online.",
    type: ERRTYPE.connection,
    level: LEVEL.success,
  },
  [CODES.perm]: {
    hint: "You do not have sufficient permission to perform the action.",
    type: ERRTYPE.perm,
    level: LEVEL.warning,
  },
  [CODES.nodata]: {
    hint: "no data retrieved for the resource",
    type: ERRTYPE.data,
    level: LEVEL.warning,
  },
  [CODES.bad_request]: {
    hint: "Please report the issue to support@utilifeed.com, preferably with a screenshot attached and a description of what you were trying to achieve - Thanks Utilifeed",
    type: ERRTYPE.data,
    level: LEVEL.warning,
  },
  [CODES.app_update]: {
    hint: null,
    type: ERRTYPE.data,
    level: LEVEL.info,
  },
  [CODES.no_permission]: {
    hint: null,
    type: ERRTYPE.perm,
    level: LEVEL.warning,
  },
  [CODES.success]: {
    hint: null,
    type: ERRTYPE.data,
    level: LEVEL.success,
  },
};

export default MODULE;
