import { Backdrop, Popover as MuiPopover } from "@mui/material";

function Popover({ open, handleClose, children, BackdropProps, ...props }) {
  return (
    <div>
      <Backdrop
        open={Boolean(open)}
        style={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          opacity: 0.2,
        }}
        {...BackdropProps}
      >
        <MuiPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            paper: {
              margin: "10px 0",
              boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
              borderRadius: "2px",
            },
          }}
          {...props}
        >
          {children}
        </MuiPopover>
      </Backdrop>
    </div>
  );
}
export default Popover;
