import { COLUMN_SECTION } from "./constants";

const BUILDING_COLUMNS = {
  a_temp: {
    label: "Heated area (m2)",
    info: "Heated floor area in the building",
    section: COLUMN_SECTION.building,
  },
  construction_year: {
    label: "Construction year",
    info: "Construction year for the building where the substation is located.",
    section: COLUMN_SECTION.building,
  },
};

export default BUILDING_COLUMNS;
