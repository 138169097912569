import { flow, makeAutoObservable } from "mobx";

import ERR, { CODES } from "@conf/errors";

import { type RootStoreType } from "./root_store";

class AppStore {
  parent: RootStoreType;

  current_notf = 0;

  needsRefresh = false;

  updateAndReloadApplication = () => this.needsRefresh;

  constructor(parent: RootStoreType) {
    makeAutoObservable(this, {}, { autoBind: true, deep: false });

    this.parent = parent;
  }

  nextId() {
    // each new notification has a unique id for dom
    this.current_notf = this.current_notf > 1000 ? 0 : this.current_notf + 1;
    return this.current_notf;
  }

  gotError(code: string, meta = {}) {
    this.parent.ui.banners.push({
      ...ERR[code],
      code,
      ...meta,
      id: this.nextId(),
    });
  }

  showMsg(level: string, msg: string) {
    this.parent.ui.addBanner({
      level,
      msg,
      id: this.nextId(),
    });
  }

  setNeedsRefresh(state: boolean) {
    this.needsRefresh = state;
  }

  setUpdater(updaterFn: () => boolean) {
    this.updateAndReloadApplication = updaterFn;
  }

  onOnlineStatusChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "offline") {
      this.gotError(CODES.offline);
    } else {
      this.gotError(CODES.online);
    }
  };

  startup = flow(function* startup(this: AppStore) {
    window.addEventListener("online", this.onOnlineStatusChange);
    window.addEventListener("offline", this.onOnlineStatusChange);

    yield true;
  });
}

export { AppStore };
