import { useCallback, useEffect, useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { PropTypes } from "prop-types";

import { logger as baseLogger } from "@core/logger";
import CloseIcon from "@icons/Close";

const PREFIX = "UtfDatePicker";

const classes = {
  label: `${PREFIX}-label`,
  inputRoot: `${PREFIX}-inputRoot`,
  adornedEnd: `${PREFIX}-adornedEnd`,
  notchedOutline: `${PREFIX}-notchedOutline`,
  clearIcon: `${PREFIX}-clearIcon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.label}`]: {
    ...theme.typography.h6,
    color: theme.palette.grey["600"],
    textTransform: "lowercase",
    "&::first-letter": {
      textTransform: "uppercase",
    },
    "&.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.inputRoot}`]: {
    color: "inherit",
    "&:hover $notchedOutline": {
      borderColor: "inherit",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputBase-inputSizeSmall": {
      padding: "5.5px 7px",
      marginLeft: ({ allowClear }) => (allowClear ? "-10px" : "4px"),
    },
  },

  [`& .${classes.adornedEnd}`]: {
    "& .MuiInputAdornment-positionEnd": {
      color: "inherit",
      "& button": {
        color: "inherit",
      },
    },
  },

  [`& .${classes.notchedOutline}`]: {
    borderColor: "inherit",
  },

  [`& .${classes.clearIcon}`]: {
    padding: 0,
    paddingTop: "12px",
    "& > svg": {
      width: "24px",
      fontSize: "24px",
    },
  },
}));

const logger = baseLogger.getSubLogger({ name: "UtfDatePicker" });

/**
 * @deprecated use DateTimePicker component instead
 */
export default function UtfDatePicker({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  disabled,
  labelPosition,
  views,
  format,
  inputProps,
  allowClear,
  ...props
}) {
  const testId = props["data-testid"] || `utf-datepicker-${label}`;
  const [currentValue, setCurrentValue] = useState(value);

  const onPickerChange = useCallback(
    (nextValue) => {
      logger.debug("onPickerChange Next:", String(nextValue));
      setCurrentValue(nextValue);
      if (onChange) onChange(nextValue);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    logger.debug("onClear Next: null");
    setCurrentValue(null);
    if (onChange) onChange(null);
  }, [onChange]);

  useEffect(() => {
    let nextValue = value;
    if (nextValue === currentValue) return;

    if (value !== null && !value?.isLuxonDateTime) {
      logger.warn(
        "Invalid value! You must provide Luxon date object or null.",
        value?.invalid || value
      );
      nextValue = null;
    }
    logger.debug(
      `"value" prop changed. Sync internal state value.
Current: ${String(currentValue)}
Next: ${String(nextValue)}`
    );
    setCurrentValue(nextValue);
  }, [format, value, currentValue]);

  return (
    <StyledBox sx={{ display: "flex", alignItems: "center" }} data-testid={testId}>
      {labelPosition === "start" && (
        <Typography pr={1} variant="caption">
          {`${label}:`}
        </Typography>
      )}
      <DesktopDateTimePicker
        value={currentValue}
        onChange={onPickerChange}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        views={views}
        format={format}
        renderInput={(params) => (
          <TextField
            inputRef={params.inputRef}
            size="small"
            label={labelPosition === "top" ? label : null}
            color="secondary"
            InputLabelProps={{ className: classes.label }}
            {...inputProps}
            InputProps={{
              ...params.InputProps,
              "data-testid": `${label}-picker`,
              style: { textAlign: "end" },
              classes: {
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
                adornedEnd: classes.adornedEnd,
              },
              startAdornment: allowClear && currentValue !== null && (
                <IconButton
                  color="error"
                  onClick={onClear}
                  className={classes.clearIcon}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              ),
              ...inputProps?.InputProps,
            }}
            inputProps={{
              ...params.inputProps,
              disabled,
            }}
          />
        )}
      />
    </StyledBox>
  );
}

UtfDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf(["start", "top"]),
  value: PropTypes.instanceOf(DateTime),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(DateTime),
  maxDate: PropTypes.instanceOf(DateTime),
  disabled: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.string),
  format: PropTypes.string,
  "data-testid": PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  allowClear: PropTypes.bool,
  ampm: PropTypes.bool,
};

UtfDatePicker.defaultProps = {
  labelPosition: "top",
  minDate: undefined,
  maxDate: undefined,
  disabled: false,
  views: ["year", "month", "day"],
  format: "yyyy-MM-dd",
  "data-testid": undefined,
  inputProps: {},
  allowClear: false,
  ampm: false,
  value: null,
};
