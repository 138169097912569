import { Box, Tooltip } from "@mui/material";
import { MRT_Header } from "material-react-table";

import QuestionIcon from "@icons/Question";

import { InfoBlockColumn } from "./const";

/**
 * This is a component that renders a default header for a table column.
 *
 * The component renders non-standard fields such as info icon, sublabel and the unit
 */
// eslint-disable-next-line import/no-default-export
export default function DefaultHeader({ column }: MRT_Header<InfoBlockColumn>) {
  // @ts-expect-error
  const { columns: childColumns, header, info, sublabel, sublabelUnit } = column.columnDef;

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          color: column.getIsFiltered() ? "primary.main" : "grey.800",
          typography: "caption",
          fontSize: 14,
          lineHeight: "16px",
          textAlign: "left",
        }}
      >
        {/* Label */}
        {typeof header !== "object" && header}

        {/* Tooltip */}
        {info && (
          <Box display="flex" alignItems="flex-start">
            <Tooltip title={info} placement="right-start">
              <Box
                sx={{
                  height: 18,
                  pl: 1,
                  "& svg": {
                    width: 12,
                    height: 18,
                  },
                }}
              >
                <QuestionIcon />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* Sub Label */}
      <Box
        sx={{
          // preserve column height to avoid layout shifts for empty sublabels
          display: "flex",
          minHeight: 14,
          fontSize: 12,
          textAlign: "left",
          color: "#757575",
          textWrap: "wrap",
        }}
      >
        {(!childColumns && sublabel) || ""}
        {sublabelUnit}
      </Box>
    </div>
  );
}
