/* react/jsx-props-no-spreading */
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import type { MRT_DensityState, MRT_RowData } from "material-react-table";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { PAGE_PORTAL_EL_ID } from "@config/constants";
import { usePrevious } from "@hooks";

import type { UtfTableProps } from "./utils";
import { handlePaste, rowsPerPageOptions, useDefaultTableOptions } from "./utils";

/**
 * A base component to construct rich datatables
 *
 * This is a wrapper component for using Material React Table with defaults of apps needs.
 *
 * `columns` and `data` props are the only required props, but there are over 170 other optional props.
 *
 * See more info on creating columns and data on the official docs site:
 * @link https://www.material-react-table.com/docs/getting-started/usage
 *
 * See the full props list on the official docs site:
 * @link https://www.material-react-table.com/docs/api/props
 */
function UtfTable<TData extends MRT_RowData>({
  onDataChange = () => {},
  onCellChange = () => {},
  // material-react-table props
  data,
  columns,
  enableEditing = false,
  editDisplayMode = "row",
  initialState,
  state,
  displayColumnDefOptions,
  ...initialProps
}: Readonly<UtfTableProps<TData>>) {
  // Density state
  const [density, setDensity] = useState<MRT_DensityState>(state?.density ?? "comfortable");
  // FullScreen state
  const [isFullScreen, setIsFullScreen] = useState<boolean>(Boolean(state?.isFullScreen));
  const [portalEl, setPortalEl] = useState<DocumentFragment | HTMLElement | null>(null);
  // Conditional column virtualization state
  const [enableColumnVirtualization, setEnableColumnVirtualization] = useState<boolean>(false);

  // Populate the theme and default table options
  const defaultProps = useDefaultTableOptions<TData>(initialProps, {
    rowCount: (data || []).length,
    isFullScreen,
  });

  // Table state must be stable
  const tableState = useMemo(
    () => ({
      ...state,
      density,
      isFullScreen,
    }),
    [density, isFullScreen, state]
  );

  // Initialize Material React Table
  const table = useMaterialReactTable<TData>({
    data,
    columns,
    initialState: {
      expanded: true,
      pagination: { pageIndex: 0, pageSize: rowsPerPageOptions[0] },
      ...initialState,
    },
    displayColumnDefOptions: {
      ...{ ...(displayColumnDefOptions ?? {}) },
      "mrt-row-select": {
        muiTableBodyCellProps: { align: "center" },
        muiTableHeadCellProps: { align: "center" },
        ...{ ...(displayColumnDefOptions?.["mrt-row-select"] ?? {}) },
      },
      "mrt-row-expand": {
        size: 50,
        minSize: 50,
        maxSize: 50,
        ...{ ...(displayColumnDefOptions?.["mrt-row-expand"] ?? {}) },
      },
    },
    state: tableState,
    onDensityChange: setDensity,
    onIsFullScreenChange: setIsFullScreen,

    // get merged defaults from user-provided and the theme props
    ...defaultProps,

    // props that can NOT be overriden by the user-provied props
    enableColumnVirtualization,
    enableEditing,
    editDisplayMode,
    muiEditTextFieldProps: ({ cell, table: tableInstance }: any) => ({
      onBlur: (event: any) => {
        // onBlur is more efficient, but could use onChange instead
        onCellChange(cell.row.original, cell.column.id, event.target.value);
      },
      onPaste: (event: any) => {
        handlePaste(event, cell, tableInstance, data, onDataChange);
      },
    }),
  });

  const materialReactTable = <MaterialReactTable table={table} />;

  // Nested columns are causing crash with `enableColumnVirtualization` prop set to `true`
  // This workaround will disable the virtualization if any of the nested columns are enabled
  const visibleColumns = table.getVisibleLeafColumns();
  const visibleColumnCount = visibleColumns.length;
  const prevVisibleColumnCount = usePrevious(visibleColumnCount);
  useEffect(() => {
    if (!prevVisibleColumnCount || prevVisibleColumnCount === visibleColumnCount) return;
    setEnableColumnVirtualization(
      !visibleColumns.some(({ depth }: { depth: number }) => depth !== 0)
    );
  }, [visibleColumns, table, prevVisibleColumnCount, visibleColumnCount]);

  // This is a workaround to render the table on top of everything once its in fullscreen state
  useLayoutEffect(() => {
    const fullScreenPortal = document.getElementById(PAGE_PORTAL_EL_ID);
    setPortalEl(fullScreenPortal);
    return () => {
      setPortalEl(null);
    };
  }, []);

  if (isFullScreen && portalEl !== null) {
    return <>{createPortal(materialReactTable, portalEl)}</>;
  }

  return materialReactTable;
}

export default UtfTable;
