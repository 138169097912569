import { forwardRef, useEffect, useState, type Ref } from "react";
import DatePicker, { type DatePickerProps } from "react-datepicker";
import {
  ArrowDropDown,
  CalendarMonth,
  ChevronLeft,
  ChevronRight,
  Clear,
} from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { DateTime, type DateTimeFormatOptions } from "luxon";

import { logger as baseLogger } from "@core/logger";

import "./style/datepicker.scss";

const logger = baseLogger.getSubLogger({ name: "UtfDateRangePicker" });

type UtfMonthYearInputProps = {
  resolution?: "day" | "month" | "year";
  value: string;
  onClick?: () => void;
  "data-testid"?: string;
};

/**
 * Custom Input for Date Range Picker
 * This is used in the header, to select the month and/or year when clicking on the header.
 *
 * This is however just the display component, the actual date picker is rendered in the header.
 */
const UtfMonthYearInput = forwardRef(
  ({ resolution, onClick, value, ...props }: UtfMonthYearInputProps, ref: Ref<HTMLSpanElement>) => {
    const outputFormat: { [key in UtfMonthYearInputProps["resolution"]]: DateTimeFormatOptions } = {
      day: {
        month: "long",
        year: "numeric",
      },
      month: {
        year: "numeric",
      },
      year: {
        year: "numeric",
      },
    };

    return (
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography
            variant="body2"
            onClick={onClick}
            ref={ref}
            sx={{ cursor: "pointer" }}
            data-testid={props["data-testid"]}
          >
            {/* Format of value is MM/yyyy if we're picking days,
              -If we're picking months, it's yyyy
              -If we're picking years, it's yyyy-yyyy
          */}
            {DateTime.fromFormat(value, "MM/yyyy").toLocaleString(outputFormat[resolution])}
          </Typography>{" "}
        </Grid>
        <Grid item>
          <ArrowDropDown fontSize="inherit" />
        </Grid>
      </Grid>
    );
  }
);

type HeaderProps = {
  customHeaderCount: number;
  monthDate: Date;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  resolution: "day" | "month" | "year";
  monthsShown: number;
  maxDate?: Date | null;
  minDate?: Date | null;
  // decreaseYear: () => void;
  // increaseYear: () => void;
  // prevYearButtonDisabled: boolean;
  // nextYearButtonDisabled: boolean;
};

/**
 * Custom Header for Date Range Picker
 * NB: This renders for each month displayed in the picker
 *
 *
 * @param { HeaderProps } props
 * @param { number } props.customHeaderCount
 * @param { Date } props.monthDate
 * @param { (month: number) => void } props.changeMonth
 * @param { (year: number) => void } props.changeYear
 * @param { () => void } props.decreaseMonth
 * @param { () => void } props.increaseMonth
 * @param { () => void } props.decreaseYear
 * @param { () => void } props.increaseYear
 * @param { boolean } props.prevMonthButtonDisabled
 * @param { boolean } props.nextMonthButtonDisabled
 * @param { boolean } props.prevYearButtonDisabled
 * @param { boolean } props.nextYearButtonDisabled
 */
function UtfDateRangeHeader(props: HeaderProps) {
  const {
    customHeaderCount,
    monthDate,
    changeMonth,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    resolution,
    monthsShown,
    // decreaseYear,
    // increaseYear,
    // prevYearButtonDisabled,
    // nextYearButtonDisabled,
  } = props;

  const decreaseDate = () => {
    if (resolution === "day") decreaseMonth();
    else if (resolution === "month") changeYear(monthDate.getFullYear() - 1);
  };

  const increaseDate = () => {
    if (resolution === "day") increaseMonth();
    else if (resolution === "month") changeYear(monthDate.getFullYear() + 1);
  };

  // ! The logic for customHeaderCount + monthsShown seems a bit confusing, but basically,
  // if we have 2 months, we dont want to show the right button on the first month, and the left button on the second month
  // if we have 1 month, we dont want to hide any buttons
  // This logic just handles that. customHeaderCount is the index of the month, provided by the lib

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      data-testid="date-range-header"
      position="relative"
    >
      {/* Left button */}
      <IconButton
        aria-label="prev"
        size="small"
        sx={customHeaderCount === 1 ? { visibility: "hidden" } : {}}
        onClick={decreaseDate}
        disabled={prevMonthButtonDisabled}
        data-testid={
          customHeaderCount === 1
            ? "date-range-header__prev-month-hidden"
            : "date-range-header__prev-month"
        }
      >
        <ChevronLeft fontSize="inherit" />
      </IconButton>
      {/* Title but also a date picker */}
      <DatePicker
        selected={monthDate}
        minDate={props.minDate}
        maxDate={props.maxDate}
        onChange={(date: Date) => {
          if (date) {
            switch (resolution) {
              case "day":
                changeMonth(date.getMonth() - customHeaderCount + 1);
                changeYear(date.getFullYear());
                break;
              case "month":
                changeYear(date.getFullYear());
                break;
              case "year":
                break;
              default:
                break;
            }
          }
        }}
        dateFormat="MM/yyyy"
        showMonthYearPicker={resolution === "day"}
        showYearPicker={resolution === "month"}
        customInput={
          <UtfMonthYearInput
            resolution={resolution}
            value={monthDate.toISOString()}
            data-testid={`date-range-header__month-year-picker_${customHeaderCount}`}
          />
        }
      />
      {/* Right button */}
      <IconButton
        aria-label="next"
        size="small"
        sx={customHeaderCount + monthsShown === 2 ? { visibility: "hidden" } : {}}
        onClick={increaseDate}
        disabled={nextMonthButtonDisabled}
        data-testid={
          customHeaderCount + monthsShown === 2
            ? "date-range-header__next-month-hidden"
            : "date-range-header__next-month"
        }
      >
        <ChevronRight fontSize="inherit" />
      </IconButton>
    </Box>
  );
}

const UtfDateRangeInput = forwardRef((props: any, ref: any) => (
  <TextField
    variant="standard"
    size={props.size || "medium"}
    value={props.value || ""}
    label={props.label}
    ref={ref}
    placeholder={props.placeholder}
    // Min width of 22 characters
    sx={{ input: { cursor: "pointer", color: props.onDark ? "white" : "black" }, minWidth: "30ch" }}
    onClick={props.onClick}
    color="primary"
    aria-label="date-range"
    InputLabelProps={{
      style: { color: props.onDark ? "white" : "black" },
    }}
    InputProps={{
      readOnly: false,
      // @ts-expect-error Yes, this is a valid prop
      "data-testid": props["data-testid"],
      onChange: props.onChange,
      endAdornment: (
        <InputAdornment position="end">
          {props.isClearable ? (
            <IconButton
              aria-label="clear"
              size="small"
              onClick={props.onClear}
              sx={{ visibility: props.value ? "visible" : "hidden" }}
              color="error"
              data-testid="date-range-header__clear-button"
            >
              <Clear fontSize="inherit" />
            </IconButton>
          ) : null}
          <IconButton aria-label="open-calendar" size="small">
            <CalendarMonth fontSize="medium" color={props.onDark ? "info" : "primary"} />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
));

export type DateRangeProps = {
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  onChange: (start: DateTime | null, end: DateTime | null) => void;
  placeholder?: string | null;
  size?: "small" | "medium";
  maxDuration?: {
    days: number;
    months: number;
    years: number;
  };
  maxDate?: DateTime | null;
  minDate?: DateTime | null;
  "data-testid"?: string;
  resolution?: "day" | "month" | "year";
  onDark?: boolean;
  isClearable?: boolean;
} & Omit<
  DatePickerProps,
  "startDate" | "endDate" | "minDate" | "maxDate" | "onChange" | "placeholderText"
>;

function convertToJsDate(date: DateTime | Date | null | undefined): Date | null {
  if (!date) return null;
  // @ts-expect-error The error is the point of this function
  if (date.isLuxonDateTime) {
    // Here we convert to a js date
    // This uses the local timezone, which is fine, we just need to make sure we're consistent
    // and convert back to the original timezone when we need to
    // @ts-expect-error propery toJSDate does not exist
    return date.toJSDate();
  }
  return date as Date;
}

/**
 * Converts a JavaScript Date object to a Luxon DateTime object.
 * The function also adjusts for the timezone offset.
 *
 * @param {Date | null} date - The JavaScript Date object to convert.
 * @returns {DateTime | null} - The converted Luxon DateTime object.
 */
const toLuxonDateTime = (date: Date | null): DateTime | null => {
  if (!date) return null;

  // And now here we remove that excess offset that might have been added
  const applyOffset = date.getTime() - date.getTimezoneOffset() * 60_000;
  // Then we remove the timezone, so that luxon defaults to the default timezone
  const actualTime = new Date(applyOffset).toISOString().replace("Z", "");

  return DateTime.fromISO(actualTime);
};

const UtfDateRangePicker = forwardRef((props: DateRangeProps, ref: any) => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState<Date | null>(convertToJsDate(props.startDate));
  const [endDate, setEndDate] = useState<Date | null>(convertToJsDate(props.endDate));
  const [maxDate, setMaxDate] = useState<DateTime | null>(props.maxDate || null);
  const [minDate, setMinDate] = useState<DateTime | null>(props.minDate || null);
  const onDark = props.onDark || false;

  const onChange = ([start, end]: [Date | null, Date | null]) => {
    setStartDate(start);
    setEndDate(end);
    const formattedStartDate = start ? toLuxonDateTime(start) : null;
    const formattedEndDate = end ? toLuxonDateTime(end) : null;

    if (props.maxDuration && start && !end) {
      // If start date is set but end date is not set
      // We disable all dates that are more than the maximum duration
      let calculatedMaxDate = formattedStartDate.plus(props.maxDuration);
      if (maxDate && calculatedMaxDate > maxDate) {
        // If the calculated max date is greater than the max date set by the user
        calculatedMaxDate = maxDate;
      }
      setMaxDate(calculatedMaxDate);
    } else setMaxDate(props.maxDate || null);

    logger.trace("onChange", {
      startDate: String(formattedStartDate),
      endDate: String(formattedEndDate),
    });
    props.onChange(formattedStartDate, formattedEndDate);
  };

  const onClear = () => {
    setStartDate(null);
    setEndDate(null);
    props.onChange(null, null);
  };

  useEffect(() => {
    if (props.minDate) setMinDate(props.minDate);
    if (props.maxDate) setMaxDate(props.maxDate);
  }, [props.minDate, props.maxDate]);

  useEffect(() => {
    if (props.startDate && props.endDate) {
      // @ts-expect-error The error is the point of this function
      setStartDate(props.startDate.isLuxonDateTime ? props.startDate.toJSDate() : props.startDate);
      // @ts-expect-error The error is the point of this function
      setEndDate(props.endDate.isLuxonDateTime ? props.endDate.toJSDate() : props.endDate);
    }
  }, [props.startDate, props.endDate]);

  const colorInject = {
    "--primary": theme.palette.primary.main,
    "--primary-dark": theme.palette.primary.dark,
    "--primary-light": theme.palette.primary.light,
    "--secondary": theme.palette.secondary.main,
    "--secondary-dark": theme.palette.secondary.dark,
    "--secondary-light": theme.palette.secondary.light,
  } as React.CSSProperties;

  // Fixes the timezone related a day-off issue
  // see: https://github.com/Hacker0x01/react-datepicker/issues/1018
  const fixedMinMax = {
    min: convertToJsDate(minDate),
    max: convertToJsDate(maxDate),
  };

  return (
    <div style={colorInject} data-testid={props["data-testid"]}>
      <DatePicker
        ref={ref}
        startDate={startDate}
        endDate={endDate}
        minDate={fixedMinMax.min}
        maxDate={fixedMinMax.max}
        onChange={onChange}
        selectsRange
        monthsShown={props.monthsShown ?? 2}
        dateFormat={props.dateFormat || "yyyy-MM-dd"}
        disabledKeyboardNavigation
        placeholderText={props.placeholder || "Select Date Range"}
        popperPlacement="bottom-start"
        renderCustomHeader={(headerProps) => (
          <UtfDateRangeHeader
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...headerProps}
            resolution={props.resolution ?? "day"}
            monthsShown={props.monthsShown ?? 2}
            maxDate={fixedMinMax.max}
            minDate={fixedMinMax.min}
          />
        )}
        customInput={
          <UtfDateRangeInput
            onClear={onClear}
            size={props.size}
            label={props.placeholder || "Select Date Range"}
            data-testid={`${props["data-testid"]}-input`}
            onDark={onDark}
            isClearable={props.isClearable}
          />
        }
        showMonthYearPicker={props.resolution === "month" || props.resolution === "year"}
      />
    </div>
  );
});

UtfDateRangePicker.displayName = "UtfDateRangePicker";

export default UtfDateRangePicker;
