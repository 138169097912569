import { action, computed, flow, makeObservable, observable } from "mobx";

import { getValueFromBlock, NETWORK_BLOCK_TYPES as NBT } from "@conf/blocks";
import { have_blocks, range } from "@core/utils";

class MeteringDash {
  data_available = false;
  data_processing_done = false;
  errors = {};

  constructor(parent) {
    makeObservable(this, {
      data_available: observable,
      data_processing_done: observable,
      errors: observable,
      updateState: action.bound,
      requirements: computed,
      blocks_required: computed,
      clearStore: action.bound,
    });

    this.parent = parent;
    this.data = null;
    this.pdata = null;
    this.getData = this.getData.bind(this);
  }

  updateState(key, val) {
    this[key] = val;
  }

  get requirements() {
    /* generate parameters we should refetch data for. */
    const lpMonth = this.parent.networks.LastProcessedMonth;
    const ymrange = [];
    for (const m of range(lpMonth.month, 0, -1)) {
      ymrange.push([m, lpMonth.year]);
    }
    for (const m of range(1, 13)) {
      ymrange.push([m, lpMonth.year - 1]);
    }
    return {
      network: this.parent.networks.current_network,
      ymrange,
      years: [lpMonth.year, lpMonth.year - 1],
    };
  }

  get blocks_required() {
    /* blocks to fetch for current set of requirements */
    const blockNames = [];
    blockNames.push(NBT.metering_latest_upload.to_block_name());
    for (const [m, year] of this.requirements.ymrange) {
      blockNames.push(NBT.substation_count.to_block_name({ year, month: m }));
    }
    return blockNames;
  }

  getData = flow(function* () {
    if (this.parent.networks.current_network) {
      this.updateState("data_available", false);
      this.updateState("data_processing_done", false);
      this.data = yield this.parent.newapi.getInfoBlocksV4({
        resource_type: "network",
        resource_id: this.parent.networks.current_network.uid,
        block_names: this.blocks_required,
      });
      this.updateState("data_available", true);
    }
  });

  processData = () => {
    const res = {
      updated_at: null,
      total_updated: null,
      hourseries: [],
      processed_time: null,
      measured_to_processed: null,
      dayseries: [],
      hourfullseries: [],
      series: [],
      seriesmax: 0,
      seriesmin: 0,
    };
    const resource_id = this.parent.networks.current_network.uid;
    const metering_block = NBT.metering_latest_upload.to_block_name();
    const series = [Array(12), Array(12)];
    const { years } = this.requirements;
    if (have_blocks(this.data, [metering_block])) {
      let processed_time = getValueFromBlock(
        this.data,
        metering_block,
        resource_id,
        "processed_time"
      );
      if (processed_time) {
        processed_time = processed_time.setZone(this.parent.networks.network_timezone);
      }
      res.processed_time = processed_time;
      res.last_updated_subs = "NA";
      let updated_at = getValueFromBlock(this.data, metering_block, resource_id, "updated_at");
      if (updated_at) {
        updated_at = updated_at.setZone(this.parent.networks.network_timezone);
      }
      const total_uploaded_array = getValueFromBlock(
        this.data,
        metering_block,
        resource_id,
        "substations_processed_counts"
      ).slice();
      const valid_counts_array = getValueFromBlock(
        this.data,
        metering_block,
        resource_id,
        "substations_valid_counts"
      ).slice();

      if (total_uploaded_array && total_uploaded_array.length > 0) {
        res.total_updated = total_uploaded_array.reduce((a, b) => a + b);
        for (const numsub of total_uploaded_array) {
          if (numsub !== 0) {
            res.last_updated_subs = numsub;
            break;
          }
        }
      } else {
        res.total_updated = null;
      }
      const measured_to_processed_val = getValueFromBlock(
        this.data,
        metering_block,
        resource_id,
        "observed_to_processed"
      );
      if (measured_to_processed_val) {
        res.measured_to_processed = parseInt(measured_to_processed_val / (60 * 60));
      } else {
        res.measured_to_processed = 0;
      }
      res.updated_at = updated_at;

      // lower graphs

      const dayseries = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
      ];
      const chour = this.parent.networks.CurrentHour;
      let cday = chour.weekday;
      let i = 0;
      for (const hour_back of range(0, 168)) {
        const xloc = chour.plus({ hour: -1 * hour_back });
        if (xloc.weekday !== cday) {
          cday = xloc.weekday;
          i += 1;
        }
        if (i > 6) {
          break;
        }
        dayseries[0][i] += total_uploaded_array[hour_back];
        dayseries[1][i] += valid_counts_array[hour_back];
      }
      dayseries[0].reverse();
      dayseries[1].reverse();
      const hourseries = [
        total_uploaded_array.slice(0, 24).reverse(),
        valid_counts_array.slice(0, 24).reverse(),
      ];
      const hourfullseries = [valid_counts_array.reverse()];
      res.hourseries = hourseries;
      res.hourfullseries = hourfullseries;
      res.dayseries = dayseries;
    }
    // Processed Substations per month graph
    let minv = null;
    let maxv = null;
    this.requirements.ymrange.map(([m, y]) => {
      const blkname = NBT.substation_count.to_block_name({ year: y, month: m });
      const count = getValueFromBlock(this.data, blkname, resource_id, "active");
      if (y === years[0]) {
        series[0][m - 1] = count;
      } else {
        series[1][m - 1] = count;
      }
      if (minv === null || minv > count) {
        minv = count;
      }
      if (maxv === null || maxv > count) {
        maxv = count;
      }
      return null;
    });
    res.series = series;
    res.seriesmax = maxv * 1.1;
    res.seriesmin = minv * 0.9;
    this.pdata = res;
    this.updateState("data_processing_done", true);
  };

  clearStore() {
    this.data = null;
    this.pdata = null;
    this.updateState("data_available", false);
    this.updateState("data_processing_done", false);
  }
}

export default MeteringDash;
