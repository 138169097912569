import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import type { Theme } from "@mui/material";
import { Divider, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import { alpha, Box } from "@mui/system";
import { DateTime } from "luxon";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
  TOOLBOX_DEFAULT_OPTIONS,
  TOOLBOX_OPTION_GRAPH_GRID,
  TOOLBOX_OPTION_REFERENCE,
} from "@config/chartToolbox";
import {
  columns,
  DATETIME_FORMAT_YYYY_MM_DD_HH_MM,
  REF_PREFIX,
  TOOLBOX_OPTIONS_WITH_REFERENCE,
} from "@config/consumption";
import type { TimeSeries } from "@core/types/common";
import { formatNumberForLocale, numbersOnly } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { generateFlowLimiterPlot } from "@core/utils/generateFlowLimiterPlot";
import QuestionIcon from "@icons/Question";
import { ChartToolbox, GraphContainer, HighchartsBase } from "@shared/ui/analytics/charts";
import type { HighchartsBaseRef } from "@shared/ui/analytics/charts/HighchartsBase/HighchartsBase";
import { generateToolboxAnnotations } from "@shared/ui/analytics/charts/utils";
import { DateRangePicker } from "@shared/ui/inputs";
import withErrorBoundary from "@shared/ui/withErrorBoundary";

import { useConsumption } from "./Consumption.store";
import { consumptionLogger, isTemperature } from "./utils";

const logger = consumptionLogger.getSubLogger({ name: "<SignatureDiagram/>" });
const HEIGHT = 550;

export const SignatureDiagram = withErrorBoundary(
  observer(({ disableReferencePeriod = false }) => {
    const chartRef = useRef<HighchartsBaseRef>();
    const theme = useTheme<Theme>();
    const { t } = useTranslation(["extendView", "_common"]);
    const {
      hasCompleteData,
      flowLimiter,
      averaged,
      chartWidth,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      setReferencePeriod,
      seriesInRanges,
      seriesInReferencePeriod,
      referencePeriod,
      fetchedRange,
    } = useConsumption();

    const [signatureDiagramToolbox, setSignatureDiagramToolbox] = useState<string[]>([]);
    const [visibleSeries, setVisibleSeries] = useState<{ [key: string]: boolean }>({
      heat: true,
      vol: false,
      st: false,
      rt: false,
      dt: false,
    });
    const lastClickedLegend = useRef<string | undefined>();
    const isRefSeriesActive =
      Boolean(referencePeriod.start && referencePeriod.end) &&
      signatureDiagramToolbox.includes(TOOLBOX_OPTION_REFERENCE);

    // How many of RT, ST or DT series are visible
    const visibleTemperatureSeries = Object.keys(visibleSeries).reduce((prev, current) => {
      if (!isTemperature(current)) return prev;
      if (visibleSeries[current]) return prev + 1;
      return prev;
    }, 0);

    const chartId = "signature";

    let series: any[] = [];
    let refSeries: any[] = [];
    const yAxis = [];
    const xAxisTitle = `${t("text_outdoor_temperature")} °C`;
    const exportData: any[] = [];

    if (hasCompleteData) {
      series = [];
      refSeries = [];
      let yAxisSeries;

      if (visibleTemperatureSeries) {
        yAxis.push({
          visible: true,
          lineWidth: 1,
          opposite: false,
          title: {
            useHTML: true,
            text: `<span class="${chartId}-title-temp">${t("text_temperature" as any)}</span>`,
          },
          yUnit: "°C",
          labels: {
            align: "right",
            x: -15,
          },
        });
      }

      columns.forEach((col) => {
        const { key } = col;
        // @ts-expect-error mui
        const color = theme.palette[col.color[0]][isRefSeriesActive ? "light" : "main"];
        // @ts-expect-error mui
        const refColor = theme.palette[col.color[0]].dark;
        const visible = visibleSeries[key];
        const name = col.suffix === "°C" ? `${t(col.name as any)}` : t(col.name as any);
        let yAxisIndex = yAxis.length;
        const { showInLegend } = col;

        if (isTemperature(key)) yAxisIndex = 0;

        const refKey = `${REF_PREFIX}${key}`;
        const refVisible = Boolean(isRefSeriesActive && visibleSeries[refKey]);

        let data: any[] = [];
        if (visible)
          data = seriesInRanges.map((row: TimeSeries) => ({
            x: row.outdoor,
            y: row[key],
            z: row.ts,
          }));

        const seriesItem = {
          id: key,
          type: "scatter",
          name,
          color,
          chartColor: color,
          textColor: isRefSeriesActive ? "#424242" : "white",
          visible,
          showInLegend,
          includeInDataExport: visible,
          data,
          timeStamp: seriesInRanges.map((row: TimeSeries) => row.ts),
          tooltip: {
            valueSuffix: col.suffix,
          },
          yAxis: yAxisIndex,
          events: {
            legendItemClick: createSeriesToggleHandler(setVisibleSeries, lastClickedLegend),
          },
          useHTML: true,
        };

        series.push(seriesItem);

        data = [];
        if (refVisible) {
          data = seriesInReferencePeriod.map((row: TimeSeries) => ({
            x: row.outdoor,
            y: row[key],
            z: row.ts,
          }));
        }
        refSeries.push({
          ...seriesItem,
          ref: true,
          id: refKey,
          visible: refVisible,
          name: `${t("text_reference")} ${name}`,
          color: refColor,
          chartColor: refColor,
          textColor: "white",
          showInLegend: isRefSeriesActive,
          includeInDataExport: refVisible,
          data,
          timeStamp: seriesInReferencePeriod.map((row: TimeSeries) => row.ts),
        });

        yAxisSeries = {
          visible: visible || refVisible,
          lineWidth: 1,
          opposite: false,
          title: {
            useHTML: true,
            text: `<span class="${chartId}-title-${key}">${name}</span>`,
          },
          yUnit: col.suffix,
          labels: {
            align: "right",
            x: -15,
          },
          plotLines: undefined,
        };

        // Draw the plot line for "Flow Limiter" if its exists
        if (key === "vol" && flowLimiter) {
          // @ts-expect-error ignore
          yAxisSeries.plotLines = [
            generateFlowLimiterPlot(flowLimiter, t("text_chart_label_flow_limiter")),
          ];
        }

        if (!isTemperature(key)) yAxis.push(yAxisSeries);
      });

      series = [...series, ...refSeries];

      // Prepare export data for instant CSV and XLSX exports
      const seriesToExport = series.filter((seriesItem) => seriesItem.visible);
      exportData[0] = [xAxisTitle, ...seriesToExport.map((seriesItem) => seriesItem.name)];

      seriesToExport.forEach((seriesItem, seriesIndex) => {
        seriesItem.data.forEach((seriesData: any, dataIndex: number) => {
          const rowIndex = dataIndex + 1;
          if (!exportData[rowIndex]) exportData[rowIndex] = [];
          exportData[rowIndex][0] = seriesData.x;
          exportData[rowIndex][seriesIndex + 1] = seriesData.y;
        });
      });
    }

    // Auto-toggling reference series based on the base series visibility
    useDeepCompareEffect(() => {
      const lastClickedLegendSeriesId = lastClickedLegend.current;

      // Auto-enable ref series if the base series clocked to toggle ON
      if (
        isRefSeriesActive &&
        typeof lastClickedLegendSeriesId === "string" &&
        // Do nothing if the clicked legend item was a reference series
        !lastClickedLegendSeriesId?.startsWith(REF_PREFIX) &&
        // Base series must be visible
        visibleSeries[lastClickedLegendSeriesId] === true
      ) {
        const refId = `${REF_PREFIX}${lastClickedLegendSeriesId}`;
        setVisibleSeries((current) => ({ ...current, [refId]: true }));
        return;
      }

      // Do not execute below as that is for the initial activiation of the reference period
      if (lastClickedLegendSeriesId) {
        lastClickedLegend.current = undefined;
        return;
      }

      // Make the reference series visible based on the currently visible base series
      const nextVisibleSeries: { [seriesId: string]: boolean } = {};
      Object.entries(visibleSeries)
        .filter(([seriesId]) => !seriesId.startsWith(REF_PREFIX))
        .forEach(([seriesId, isVisible]) => {
          const refId = `${REF_PREFIX}${seriesId}`;

          // Reset ref visibilities on reference period disabled
          if (!isRefSeriesActive && visibleSeries[refId] === true) {
            nextVisibleSeries[refId] = false;
            return;
          }

          if (
            isRefSeriesActive &&
            (!(refId in nextVisibleSeries) || nextVisibleSeries?.[refId] !== true) &&
            isVisible === true &&
            !lastClickedLegend.current
          ) {
            // Here we are sure that user clicked a base series which was hidden before
            // Enabling corrosponding ref series visibility on
            nextVisibleSeries[refId] = true;
          }
        });

      if (Object.keys(nextVisibleSeries).length === 0) return;

      setVisibleSeries((current) => ({ ...current, ...nextVisibleSeries }));
    }, [visibleSeries, isRefSeriesActive]);

    // Find the "xMax" and "xMin" based on the Outdoor Temperature series
    // The reference period must be always visible
    // Hence, we combine the data from both and calculate the xMax and xMin from both data
    const { xMin, xMax } = useMemo(() => {
      const xData = seriesInRanges.map((d: any) => d.outdoor);
      const xDataRef = seriesInReferencePeriod.map((d: any) => d.outdoor);
      let max = Math.max(...[...(xData || []), ...(xDataRef || [])].filter(numbersOnly));
      max += (max / 100) * 5; // 5% gap as right margin

      let min;
      if (isRefSeriesActive) {
        min = Math.min(...[...(xData || []), ...(xDataRef || [])].filter(numbersOnly));
        min -= (min / 100) * 5; // 5% gap as left margin
      }

      return { xMax: max, xMin: min };
    }, [isRefSeriesActive, seriesInRanges, seriesInReferencePeriod]);

    const toolboxOptions = disableReferencePeriod
      ? TOOLBOX_DEFAULT_OPTIONS
      : TOOLBOX_OPTIONS_WITH_REFERENCE;

    const onReferencePeriodSet = (start: DateTime | null, end: DateTime | null) => {
      setReferencePeriod(start, end);
      // Turn on the reference period option
      if ((!start || !end) && !signatureDiagramToolbox.includes(TOOLBOX_OPTION_REFERENCE))
        setSignatureDiagramToolbox((current) => [...current, TOOLBOX_OPTION_REFERENCE]);
    };

    useEffect(() => {
      if (referencePeriod.end && referencePeriod.start)
        setSignatureDiagramToolbox((current) => [...current, TOOLBOX_OPTION_REFERENCE]);
    }, [referencePeriod.end, referencePeriod.start]);

    logger.trace("RENDER");

    return (
      <GraphContainer
        title={t("text_signature_diagram")}
        hasData
        subTitles={
          <>
            {!disableReferencePeriod && (
              <>
                <Box mt={-1}>
                  <DateRangePicker
                    size="small"
                    placeholder={t("text_select_reference_period_range")}
                    onChange={onReferencePeriodSet}
                    minDate={fetchedRange.start}
                    maxDate={fetchedRange.end}
                    data-testid={`${chartId}-reference-period`}
                    startDate={referencePeriod.start?.isValid ? referencePeriod.start : null}
                    endDate={
                      referencePeriod.end?.isValid ? referencePeriod.end.minus({ days: 1 }) : null
                    }
                    dateFormat="dd/MM/yyyy"
                    isClearable
                  />
                </Box>
                <Tooltip title={t("text_reference_period_enable_toolbox_tip")}>
                  <div>
                    <QuestionIcon />
                  </div>
                </Tooltip>
              </>
            )}
            <Divider orientation="vertical" flexItem />
            <Box display="flex" alignItems="center" height="100%">
              <ChartToolbox
                options={toolboxOptions}
                values={signatureDiagramToolbox}
                setValues={setSignatureDiagramToolbox}
                disabled={!hasCompleteData}
                data-testid={`${chartId}-toolbox`}
              />
            </Box>
          </>
        }
        data-testid={`chart-${chartId}-container`}
      >
        <HighchartsBase
          // @ts-expect-error Who knows
          ref={chartRef}
          chart={{
            id: chartId,
            width: chartWidth,
            height: HEIGHT,
            spacing: [24, 24, 24, 24],
            zoomType: "xy",
          }}
          legend={{
            floating: false,
            enabled: true,
            alignColumns: false,
            useHTML: true,
            align: "left",
            layout: "horizontal",
            verticalAlign: "top",
            itemMarginTop: 0,
            itemMarginBottom: 5,
            width: 750, // This will break if the font size is changed
            // We need to make an html legend, thats the real fix
            itemStyle: theme.typography.body2,
            // Disable symbol
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,

            labelFormatter() {
              const { color, name, userOptions } = this;
              const { id, ref, visible } = userOptions;

              const nameWithoutRef = name.replace(`${t("text_reference")} `, "");
              const nameEl = `<span style="visibility: ${
                ref ? "hidden" : "visible"
              }">${nameWithoutRef}</span>`;

              const itemStyles = [
                `border-bottom: 3px solid ${visible ? color : alpha(color, 0.5)};`,
                ref ? "height:15px;" : "",
              ].join(" ");

              return `<div data-testid="${chartId}__legend__${id}" style="${itemStyles}">${nameEl}</div>`;
            },
          }}
          plotOptions={{
            scatter: {
              marker: {
                radius: 3,
                symbol: "circle",
              },
            },
          }}
          tooltip={{
            animation: false,
            formatter() {
              const { series: chartSeries, point, y, x } = this;
              const { z } = point;

              let pointDate = DateTime.fromMillis(z);
              if (averaged) pointDate = pointDate.set({ hour: 0, minute: 0 });

              const formattedDate = pointDate.toFormat(DATETIME_FORMAT_YYYY_MM_DD_HH_MM);

              return `<b>${chartSeries.name} ${formatNumberForLocale(y)}${
                chartSeries.tooltipOptions.valueSuffix
              } / ${formatNumberForLocale(x)}°C <br/>${formattedDate}</b>`;
            },
          }}
          annotations={generateToolboxAnnotations({
            series,
            options: signatureDiagramToolbox,
          })}
          xAxis={{
            title: {
              text: xAxisTitle,
            },
            gridLineWidth: signatureDiagramToolbox.includes(TOOLBOX_OPTION_GRAPH_GRID) ? 1 : 0,
            min: xMin,
            max: xMax,
          }}
          yAxis={yAxis}
          series={series}
          exporting={{
            data: exportData,
          }}
          navigation={{
            buttonOptions: {
              enabled: series.length > 0,
            },
          }}
          exportData={exportData}
          showViewData={false}
        />
      </GraphContainer>
    );
  })
);
