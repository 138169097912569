import { DateTime } from "luxon";

enum FilterTypes {
  CATEGORICAL = "categorical",
  HISTOGRAM = "histogram",
}

enum FilterGroups {
  TEMPERATURES = "text_temperatures",
  ECONOMY = "filter_group_economy",
  CUSTOMER = "text_customer",
  USER_SPECIFIC = "filter_group_user",
  LOCATION = "text_location",
  ENERGY = "text_energy",
  FLOW = "text_flow",
}

enum FilterDataOriginTypes {
  INFO_BLOCK = "info_block",
  NAME = "name",
  CMS = "CMS",
  CLUSTER = "CLUSTER",
}

enum FilterDataTypes {
  DATE = "date",
  NUMBER = "number",
  STRING = "string",
}

// Stuff in the state is changed by components
export type FilterState = {
  isActive: boolean;
  is_active?: boolean;
  include: boolean; // This is to include or include missing values for the specific filter
  selected?: Set<string>; // Selected values for categorical filters
  reset?: boolean;
  min?: number | null;
  max?: number | null;
  minRange?: number | null;
  maxRange?: number | null;
  scale?: "linear" | "logarithmic";
  year?: number;
  month?: number | null;
  loading?: boolean;
  excludeMatches: boolean; // Should matching substations be included or excluded from the result
};

// Stuff in the data is changed by the store, and read by components
export type Filter = {
  param: string;
  block?: string;
  label: string;
  sublabel?: string;
  type: FilterTypes;
  group: FilterGroups;
  is_recommended: boolean;
  lb?: string;
  state: FilterState;
  data: { [key: string]: any }; // Substation block data
  options?: string[]; // All options for categorical filters
  dataOriginType: FilterDataOriginTypes;
  substationsMissingData: string[]; // Substations missing data for this filter
  dataType?: FilterDataTypes; // Used to override the default data type for the filter
};

const FILTER_LIST: Filter[] = [
  {
    param: "substation_uid",
    block: "customer",
    label: "text_substation_id",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.CUSTOMER,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.NAME,
  },
  {
    param: "cluster_uid",
    label: "text_cluster",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.CUSTOMER,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.CLUSTER,
  },
  {
    param: "fav",
    label: "filter_text_favorite_sub",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.USER_SPECIFIC,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
      // By default "Favorites" is selected
      selected: new Set(["Favourites"]),
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.CMS,
  },
  /** Location */
  {
    param: "street_name",
    block: "install_address",
    label: "filter_text_street_name",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.LOCATION,
    is_recommended: false,
    state: {
      isActive: true,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "zip",
    block: "install_address",
    label: "filter_text_zip_code",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.LOCATION,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "grid_area",
    block: "distribution",
    label: "filter_text_grid_area",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.LOCATION,
    is_recommended: true,
    state: {
      isActive: true,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "coordinates_quality",
    block: "install_address",
    label: "filter_text_coords_quality",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.LOCATION,
    is_recommended: false,
    state: {
      isActive: true,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  /** Energy */
  {
    param: "heat_energy_sum",
    block: "core",
    label: "filter_text_energy",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.ENERGY,
    lb: "yearly",
    is_recommended: true,
    state: {
      isActive: true,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  /** Volume */
  {
    param: "volume_sum",
    block: "core",
    label: "filter_text_volume",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.FLOW,
    lb: "yearly",
    is_recommended: true,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  /** Economy */
  {
    param: "tariff_id",
    block: "pricing",
    label: "filter_text_pricing_tariff",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.ECONOMY,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "wn_value",
    block: "pricing",
    label: "filter_text_wn_value",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.ECONOMY,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  /** Customer */
  {
    param: "name",
    block: "customer",
    label: "filter_text_customer_name",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.CUSTOMER,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "gdpr_segment",
    block: "customer",
    label: "filter_text_private_or_commercial",
    type: FilterTypes.CATEGORICAL,
    group: FilterGroups.CUSTOMER,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "processed_time",
    block: "ufint_latest_upload",
    label: "text_latest_data_upload",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.CUSTOMER,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
      minRange: DateTime.now().minus({ years: 6 }).toMillis(),
      maxRange: DateTime.now().toMillis(),
      scale: "logarithmic",
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
    dataType: FilterDataTypes.DATE,
  },
  /** Temperatures */
  {
    param: "supplytemp_flowweighted_avg",
    block: "core",
    label: "filter_text_supply_temperature_c",
    sublabel: "text_flow_weighted",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "supplytemp_powerweighted_avg",
    block: "core",
    label: "filter_text_supply_temperature_c",
    sublabel: "text_power_weighted",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "supplytemp_unweighted_avg",
    block: "core",
    label: "filter_text_supply_temp_c",
    sublabel: "text_unweighted",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "returntemp_flowweighted_avg",
    block: "core",
    label: "filter_text_return_temperature_c",
    sublabel: "text_flow_weighted",
    type: FilterTypes.HISTOGRAM,
    lb: "yearly",
    group: FilterGroups.TEMPERATURES,
    is_recommended: false,
    state: {
      isActive: true,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "returntemp_powerweighted_avg",
    block: "core",
    label: "filter_text_return_temperature_c",
    sublabel: "text_power_weighted",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "returntemp_unweighted_avg",
    block: "core",
    label: "filter_text_return_temperature_c",
    sublabel: "text_unweighted",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "deltatemp_flowweighted_avg",
    block: "core",
    label: "filter_text_delta_temperature_c",
    sublabel: "text_flow_weighted",
    type: FilterTypes.HISTOGRAM,
    lb: "yearly",
    group: FilterGroups.TEMPERATURES,
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "deltatemp_powerweighted_avg",
    block: "core",
    label: "filter_text_delta_temperature_c",
    sublabel: "text_power_weighted",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "deltatemp_unweighted_avg",
    block: "core",
    label: "filter_text_delta_temperature_c",
    sublabel: "text_unweighted",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
  {
    param: "flow_limit",
    block: "pricing",
    label: "filter_text_flow_limiter",
    type: FilterTypes.HISTOGRAM,
    group: FilterGroups.TEMPERATURES,
    lb: "yearly",
    is_recommended: false,
    state: {
      isActive: false,
      include: true,
      excludeMatches: false,
    },
    substationsMissingData: [],
    data: {},
    dataOriginType: FilterDataOriginTypes.INFO_BLOCK,
  },
];

export { FILTER_LIST, FilterDataOriginTypes, FilterDataTypes, FilterGroups, FilterTypes };
