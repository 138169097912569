import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Flag } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { DateTime } from "luxon";

import { SUBSTATION_STATES } from "@pages/MeteringDash/FaultDetection/FaultDetection.constants";
import { useInject } from "@hooks";
import { BaseCard } from "@shared/ui/analytics/cards";

const PREFIX = "FaultDetectionSummary";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "-22px",
    paddingRight: "4px",
  },
}));

type FaultsType = {
  ignored: string;
  Returntemp: JSX.Element | null;
  supplytemp_dipp: JSX.Element | null;
  Flow: JSX.Element | null;
};

const FAULTS: { [key: string]: string } = {
  Returntemp: "text_fd_return_temperature",
  Flow: "text_flow",
  supplytemp_dipp: "text_fd_supplytemperature_dipp",
};

const MAX_NOTE_LENGTH = 500;

const FaultDetectionSummary = observer(() => {
  const { t } = useTranslation(["extendView", "_messages", "_action", "_common"]);
  const { networks, sub, notifications, utfapi, faultDetection } = useInject(
    "networks",
    "sub",
    "notifications",
    "utfapi",
    "faultDetection"
  );
  const noteRef = useRef<HTMLInputElement>(null);

  const [subFaults, setSubFaults] = useState<FaultsType | Record<string, never>>({});
  const [subFaultsStatus, setSubFaultStatus] = useState<string>("relevant");
  const [note, setNote] = useState("");
  const [isSaveDisable, setIsSaveDisable] = useState(true);

  // get substation faults and status
  useEffect(() => {
    if (!faultDetection.ready) {
      faultDetection.init();
      return;
    }
    const rows = faultDetection.tableData.allRow.filter(
      (
        row
      ): row is { sub_id: string; fault_detection: FaultsType; fault_detection_ignored: string } =>
        row.sub_id === sub.current_substation
    );
    if (rows.length === 0) {
      setSubFaults({});
      // Set to default value
      setSubFaultStatus("relevant");
      return;
    }

    const { fault_detection: fdFaults, fault_detection_ignored: fdIgnoreStatus } = rows[0];
    setSubFaults(fdFaults);
    const fdStatus = fdIgnoreStatus?.toLowerCase() || "relevant";
    if (fdStatus === "ignored") {
      setSubFaultStatus("ignore");
    }
    setSubFaultStatus(fdStatus);
  }, [faultDetection, sub.current_substation, faultDetection.ready, faultDetection.tableData]);

  useEffect(() => {
    if (sub.current_substation) {
      (async () => {
        let substationNote;
        try {
          const getSubstationNote = await utfapi.getSubstationNote(sub.current_substation);
          substationNote = getSubstationNote?.data?.collection?.[0]?.note || null;
        } catch (error) {
          console.error("Error fetching substation note:", error);
        }
        setNote(substationNote ?? "");
      })();
    }
  }, [utfapi, sub.current_substation]);

  // Status change handler
  const handleStatusChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const status = value === "ignored" ? "ignore" : value;

    try {
      faultDetection.setSubstationFaultStatus(
        String(networks.current_network?.uid),
        sub.current_substation,
        status
      );

      setSubFaultStatus(value);
      notifications.success(
        t("message_substation_status_updated_successfully", { ns: "_messages" })
      );
      // notify the fault Detection page to refetch the data
      faultDetection.toggleTriggerFetch();
    } catch (e) {
      notifications.error(t("message_substation_status_update_failed", { ns: "_messages" }));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-void
    void faultDetection.getData();
  }, [faultDetection]);

  const isFlagsExist = !!Object.entries(subFaults)
    // remove ignored options. only keep the flags value
    .filter((fault) => fault[0] !== "ignored")
    // check if sub include at least 1 flag
    .map((fault) => fault[1] !== null)
    .includes(true);

  const noteChangeHandler = (value: string) => {
    setNote(value);
    setIsSaveDisable(false);
  };

  const noteClearHandler = () => {
    setNote("");
    noteRef.current?.focus();
  };

  const noteSaveHandler = async () => {
    try {
      const addNote = await utfapi.addSubstationNote(sub.current_substation, { note });
      if (addNote === true) {
        setIsSaveDisable(true);
        // Remove focus
        noteRef.current?.blur();
        notifications.success("Note successfully saved");
        // notify the fault Detection page to refetch the data
        faultDetection.toggleTriggerFetch();
      } else {
        notifications.error("Error saved note");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      notifications.error(err.error);
    }
  };

  return (
    <StyledGrid container spacing={1}>
      {/* Fault Detection Relevance Card */}
      <Grid item xs={4}>
        <BaseCard
          title={t("text_fault_detection_relevance")}
          loading={!faultDetection.dataFetched}
          height="230"
          testId="fault-detection-relevance-card"
        >
          <Box width="100%" display="flex" flexDirection="column" px={2}>
            <Box
              sx={{
                width: "100%",
                p: 2,
                borderStyle: "solid",
                // eslint-disable-next-line sonarjs/no-duplicate-string
                borderColor: "grey.200",
                borderWidth: "1px 1px 0px",
              }}
            >
              <Typography variant="body1" data-testid="fault-detection-relevance-info">
                {t("text_current_fault_status")}
              </Typography>
              <Box
                component={Typography}
                variant="body2"
                color="grey.800"
                textTransform="capitalize"
                data-testid="fault-detection-relevance-status"
              >
                {subFaultsStatus}
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                p: 2,
                border: "1px solid",
                borderColor: "grey.200",
              }}
            >
              {subFaultsStatus && (
                <FormControl style={{ width: "100%" }}>
                  <Select
                    data-testid="fault-detection-relevance-select"
                    labelId="fault-detection-status"
                    id="fault-detection-status-select"
                    value={subFaultsStatus}
                    onChange={handleStatusChange}
                    sx={{
                      height: "32px",
                      "& fieldset": {
                        borderColor: "primary.main",
                      },
                      "& svg": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {SUBSTATION_STATES.map(({ value, label }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        data-testid={`fault-detection-relevance-select-option-${value}`}
                      >
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Box>
        </BaseCard>
      </Grid>
      {/* Fault Criterias Card */}
      <Grid item xs={3}>
        <BaseCard
          title={t("text_fault_criterias", { ns: "extendView" })}
          loading={!faultDetection.dataFetched}
          error={faultDetection.dataFetched && !isFlagsExist}
          errorMessage={t("message_no_faults_flagged", { ns: "_messages" })}
          testId="fault-detection-criterias"
        >
          <Box
            display="flex"
            flexDirection="column"
            p={2}
            width="100%"
            height="100%"
            data-testid="fault-flags-container"
          >
            {subFaults &&
              isFlagsExist &&
              Object.entries(subFaults).map(
                ([key, value]) =>
                  typeof value === "object" &&
                  value !== null && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        height: "33%",
                        px: 2,
                        gap: 1,
                        color: "error.main",
                        borderStyle: "solid",
                        borderColor: "grey.200",
                        borderWidth: "1px 1px 0 1px",
                        textTransform: "lowercase",
                        "&:last-child": {
                          borderWidth: "1px ",
                        },
                      }}
                      data-testid={`fault-flag-${key}`}
                      key={`${key}`}
                    >
                      <Flag />

                      <Typography variant="subtitle2">{t(FAULTS[key])}</Typography>
                    </Box>
                  )
              )}
          </Box>
        </BaseCard>
      </Grid>
      {/* Notes Card */}
      <Grid item xs={5}>
        <BaseCard title={t("text_notes")} loading={!faultDetection.dataFetched} height="230">
          <Box
            component="form"
            height="100%"
            px={2}
            py={2}
            pt={3}
            display="flex"
            width="100%"
            flexDirection="row"
            gap={1}
            noValidate
            autoComplete="off"
          >
            <Box display="flex" width="100%">
              <TextField
                id="outlined-multiline-static"
                sx={{
                  marginBottom: "10px",
                  borderWidth: "1px 1px 0px",
                  borderColor: "grey.200",
                }}
                inputProps={{
                  maxLength: MAX_NOTE_LENGTH,
                }}
                multiline
                fullWidth
                rows={5}
                value={note}
                placeholder="Write a note..."
                helperText={`${note.length}/${MAX_NOTE_LENGTH} characters`}
                defaultValue=""
                FormHelperTextProps={{
                  className: classes.root,
                }}
                onChange={(event: { target: { value: string } }) =>
                  noteChangeHandler(event.target.value)
                }
                inputRef={noteRef}
              />
            </Box>
            <Box display="flex" flexDirection="column-reverse" gap={1} pb={1}>
              <Button
                variant="contained"
                disabled={isSaveDisable}
                onClick={noteSaveHandler}
                // to not focus input after click save
                onMouseDown={(event: { preventDefault: () => void }) => event.preventDefault()}
                data-testid="note-save-btn"
              >
                {t("action_save", { ns: "_action" })}
              </Button>
              <Button
                variant="outlined"
                onClick={noteClearHandler}
                // to focus  input after click clear
                onMouseDown={(event: { preventDefault: () => void }) => event.preventDefault()}
                disabled={!note}
                data-testid="note-clear-btn"
              >
                {t("action_clear", { ns: "_action" })}
              </Button>
            </Box>
          </Box>
        </BaseCard>
      </Grid>
    </StyledGrid>
  );
});

export default FaultDetectionSummary;
