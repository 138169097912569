/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export type FontAwesomeSvgIconProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
} & SvgIconProps;

export const FontAwesomeSvgIcon = forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>(
  (props, ref) => {
    const { icon, ...iconProps } = props;

    const {
      icon: [width, height, , , svgPathData],
    } = icon;

    // TODO: Someone with more time than me should uncomment this
    // !This allows for icons to adapt to the MUI size prop, but right now a lot of icons are not sized correctly
    // const style = {
    //   fontSize: "inherit",
    // };

    // if (iconProps.style) {
    //   Object.assign(style, iconProps.style);
    // } else {
    //   iconProps.style = style;
    // }

    return (
      <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`} {...iconProps}>
        {typeof svgPathData === "string" ? (
          <path d={svgPathData} />
        ) : (
          /**
           * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
           * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
           * of a duotone icon. 40% is the default opacity.
           *
           * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
           */
          svgPathData.map((d: string, i: number) => (
            <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
          ))
        )}
      </SvgIcon>
    );
  }
);
