/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-duplicate-string */
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons";

import { Overlay } from "./types";

export const markerIconSVG = (color: string) => {
  const {
    icon: [width, height, , , svgPathData],
  } = faLocationDot;
  return window.btoa(
    `<svg xmlns="http://www.w3.org/2000/svg" fill="${color}" width="32px" height="32px" viewBox="0 0 ${width} ${height}">
      <path d="${svgPathData}" />
    </svg>`
  );
};

export const markerIconURL = (
  color = "#1a5e8a" // Default color
) => `data:image/svg+xml;base64,${markerIconSVG(color)}`;

export const OverlayContainsMarker = (overlay: Overlay, marker: google.maps.Marker) => {
  const markerPosition = marker.getPosition();
  if (!markerPosition) return false;
  // If overlay.geometry is a polygon, we need to use our own contains method
  if (overlay.geometry instanceof google.maps.Polygon) {
    return google.maps.geometry.poly.containsLocation(marker.getPosition()!, overlay.geometry);
  }
  if (overlay.geometry instanceof google.maps.Circle) {
    // Within the radius of the circle
    return (
      overlay.geometry.getBounds()!.contains(marker.getPosition()!) &&
      google.maps.geometry.spherical.computeDistanceBetween(
        overlay.geometry.getCenter()!,
        marker.getPosition()!
      ) <= overlay.geometry.getRadius()
    );
  }

  // @ts-expect-error getBounds do not exists
  const bounds = overlay.geometry.getBounds();
  return bounds && bounds.contains(marker.getPosition());
};
