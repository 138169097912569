import type { YAxisPlotLinesOptions } from "highcharts";

import { formatNumberForLocale } from "@core/utils";

export function generateFlowLimiterPlot(value: number, label: string): YAxisPlotLinesOptions {
  return {
    value,
    width: 1,
    zIndex: 5,
    color: "red",
    dashStyle: "Dash",
    label: {
      align: "right",
      x: -5,
      text: `${label}: ${formatNumberForLocale(value)} m³/h `,
    },
  };
}
