import { action, computed, flow, makeObservable, observable } from "mobx";
import { DateTime, Settings } from "luxon";
import posthog from "posthog-js";

import { FilterTypes } from "@utilifeed/config/filters.const";
import { DEFAULT_CURRENCY, DEFAULT_NETWORK_TIMEZONE_NAME, isFeatureFlagOn } from "@conf";
import { NETWORK_BLOCK_TYPES, updateStoreValueFromBlock } from "@conf/blocks";
import { CODES } from "@conf/errors";
import { DEFAULT_NETWORK_TIMEZONE } from "@conf/ui_constants";
import { logger as baseLogger } from "@core/logger";
import { getLatestProcessedMonth, getLatestProcessedYear, isValue } from "@core/utils";

import { DEFAULT_TARGET_ENERGY, DEFAULT_TARGET_TEMP } from "../pages/RTAnalysis/constants";
import { autoSave } from "./cache";

const logger = baseLogger.getSubLogger({ name: "networkStore" });

export class NetworkStore {
  current_network = null;

  network_timezone = DEFAULT_NETWORK_TIMEZONE;

  tz_name = DEFAULT_NETWORK_TIMEZONE_NAME;

  currency = null;

  coordinates = null;

  current_substations = new Map();

  current_substations_v3_map = new Map();

  current_clusters = new Set();

  networkClusters = new Map();

  _network_feature_set = new Set();

  _test_feature_set = null;

  ready = false;

  networks = [];

  sub_ignore_filters = false;

  filtered_view = null;

  filtered_cluster_view = null;

  SUBSTATION_BLOCK = null;

  CLUSTER_BLOCK = null;

  substations_count = null; // Block count

  clusters_count = null;

  active_clusters = new Map();

  network_resources_fetched = false;

  hidden_date = DateTime.local();

  lpYear = null;

  lpMonth = null;

  networkActiveYear = null;

  networkActiveMonth = null;

  networkActiveEPMonth = null;

  networkActiveEPMonthClusters = null;

  networkActiveWeek = null;

  preferences = {};

  get CurrentHour() {
    let ctime = null;
    if (isFeatureFlagOn("TEST_ADMIN") || this.test_run) {
      ctime = this.hidden_date.setZone(this.network_timezone).startOf("hour");
    } else {
      ctime = DateTime.now().startOf("hour");
    }
    return ctime;
  }

  get LastProcessedMonth() {
    return getLatestProcessedMonth(this.CurrentHour, this.networkActiveMonth);
  }

  get LastProcessedYear() {
    return getLatestProcessedYear(this.CurrentHour, this.networkActiveYear);
  }

  get LastEPMonth() {
    return getLatestProcessedMonth(this.CurrentHour, this.networkActiveEPMonth);
  }

  get LastProcessedWeek() {
    return this.networkActiveWeek;
  }

  updateNetworkWeek(ts) {
    this.networkActiveWeek = ts;
  }

  dtFromYM(year, month, boundary = "start") {
    let rdate;
    if (boundary === "start") {
      if (month) {
        rdate = DateTime.fromObject({ year, month }).startOf("month");
      } else {
        rdate = DateTime.fromObject({ year, month: 6 }).startOf("year");
      }
    } else if (month) {
      rdate = DateTime.fromObject({ year, month }).endOf("month").plus({ seconds: 1 });
    } else {
      rdate = DateTime.fromObject({ year, month: 6 }).endOf("year").plus({ seconds: 1 });
    }
    return rdate;
  }

  setTestDate(dt, timezone = DEFAULT_NETWORK_TIMEZONE) {
    this.test_run = true;
    this.network_timezone = timezone;
    this.hidden_date = dt;
  }

  constructor(root) {
    makeObservable(this, {
      includeOrExcludeSubstation: true,
      tz_name: true,
      currency: true,
      networkActiveEPMonthClusters: true,
      CurrentHour: true,
      LastProcessedMonth: true,
      LastProcessedYear: true,
      LastEPMonth: true,
      LastProcessedWeek: true,
      dtFromYM: true,
      shouldStore: true,
      saveToCache: true,
      clearCache: true,
      selectDefaultCurrentNetwork: true,
      refresh: true,
      check: true,
      updateUserPreferences: true,
      haveAccess: false,
      updateNetworkResources: true,
      updateNetworkTimezone: true,
      selectNetwork: true,
      checkNetworkRouteAccess: true,
      current_network: observable,
      network_timezone: observable,
      coordinates: observable,
      current_substations: observable,
      current_substations_v3_map: observable,
      current_clusters: observable,
      networkClusters: observable,
      ready: observable,
      networks: observable,
      sub_ignore_filters: observable,
      filtered_view: observable,
      filtered_cluster_view: observable,
      SUBSTATION_BLOCK: observable,
      CLUSTER_BLOCK: observable,
      substations_count: observable,
      clusters_count: observable,
      active_substations: computed,
      active_clusters: observable,
      network_resources_fetched: observable,
      hidden_date: observable,
      lpYear: observable,
      lpMonth: observable,
      networkActiveYear: observable,
      networkActiveMonth: observable,
      networkActiveEPMonth: observable,
      networkActiveWeek: observable,
      preferences: observable,
      updateNetworkWeek: action.bound,
      setTestDate: action.bound,
      allSubstationCluster: computed,
      loadFromCache: action.bound,
      updateCurrentSubstation: action.bound,
      network_names: computed,
      filtered_substation_count: computed,
      updateNetworksFromNames: action.bound,
      refreshTime: action.bound,
      setSubIgnoreFilters: action.bound,
      _network_feature_set: observable,
      _test_feature_set: observable,
      updateNetworkFeatureSet: action.bound,
      features: computed,
    });

    this.parent = root;
    this.has_run = false; // if not run and no cache , run
    this.test_run = false;
    this.SUBSTATION_BLOCK = {};
    this.CLUSTER_BLOCK = null;
    this.loadFromCache();
    this.shouldStore = this.shouldStore.bind(this);
    this.saveToCache = this.saveToCache.bind(this);

    autoSave(this, this.shouldStore, this.saveToCache);
  }

  shouldStore() {
    return [this.current_network, this.hidden_date, this.network_timezone];
  }

  get features() {
    if (isFeatureFlagOn("TEST_ADMIN") || this.test_run) {
      // this check is required security wise, use should not able able to override using localstorage.
      if (this._test_feature_set !== null) {
        return this._test_feature_set;
      }
      return this._network_feature_set;
    }
    return this._network_feature_set;
  }

  get allSubstationCluster() {
    const targetName = `all$${this.current_network.name}`;
    for (const [cClusterUid, cClusterName] of this.active_clusters) {
      if (cClusterName === targetName) {
        return cClusterUid;
      }
    }
    return null;
  }

  loadFromCache() {
    let pd = null;
    let d;
    try {
      d = localStorage.getItem("networks");
    } catch {
      // noop. avoid crash on test env
    }

    if (d) {
      pd = JSON.parse(d);
      if (Object.hasOwn(pd, "networks")) {
        this.networks = pd.networks;
      }
      if (Object.hasOwn(pd, "current_network")) {
        this.current_network = pd.current_network;
      }
      if (Object.hasOwn(pd, "tz")) {
        this.network_timezone = pd.tz;
      }
      if (Object.hasOwn(pd, "ctime") && pd.ctime) {
        this.hidden_date = DateTime.fromISO(pd.ctime).setZone(this.network_timezone);
      }
    }
    let fa;
    try {
      fa = localStorage.getItem("feature_access");
    } catch {
      // noop. avoid crash on test env
    }

    try {
      pd = JSON.parse(fa);
      this._test_feature_set = pd ? new Set(pd) : null;
    } catch {
      this._test_feature_set = null;
    }
  }

  updateCurrentSubstation(substation) {
    // DEPRECATE it
    this.parent.sub.updateCurrentSubstation(substation);
  }

  updateNetworkFeatureSet(set) {
    this._network_feature_set = set;
  }

  saveToCache() {
    const tocache = JSON.stringify({
      current_network: this.current_network,
      networks: this.networks,
      ctime: this.hidden_date.toISO(),
      tz: this.network_timezone,
    });
    localStorage.setItem("networks", tocache);
  }

  clearCache() {
    localStorage.removeItem("networks");
  }

  get network_names() {
    return this.networks;
  }

  get filtered_substation_count() {
    if (this.sub_ignore_filters) {
      return this.current_substations.size;
    }
    if (this.ready) {
      return this.active_substations.size;
    }
    return this.current_substations.size;
  }

  /**
   * This function is used to either include or exclude a substation from the filter set. It is used
   * in the active_substations getter.
   * It is extracted to a function to reduce the complexity of the
   * getter. Generally, the filterSet is the final list of substations that pass every single filter.
   * The filterSet is modified in place, by checking if the substation should be included or
   * excluded. The match for the filter is determined by the filterFunc, which is passed in as a
   * parameter. If the filter passes, then the substation is either included or excluded based on the
   * exclude parameter. Finally, if the value is missing, then the substation is either included or
   * excluded based on the includeMissing parameter. Note that the missing values are also affected
   * by the exclude parameter.
   *
   * @param {Set} filterSet The existing list of substations
   * @param {string} substation The substation being filtered
   * @param {any} value The value to check for
   * @param {Function} filterFunc The function to use to check the value
   * @param {boolean} exclude Whether or not the substation should be excluded or included if the filter passes.
   * @param {boolean} includeMissing Whether or not missing values should be considered as a match.
   */
  includeOrExcludeSubstation(filterSet, substation, value, filterFunc, exclude, includeMissing) {
    // So, first we just check if the value is missing
    const isMatch = value ? filterFunc(value) : includeMissing;
    // We then delete the substation from the filterSet if it is not a match, or vice versa if we are excluding
    if (isMatch === exclude) {
      filterSet.delete(substation);
    }
  }

  // TODO: Split this into multiple functions

  get active_substations() {
    if (!(this.ready && this.parent.filters.isReady)) {
      return [];
    }
    const { filters } = this.parent.filters;

    // Start with all the substations
    const newActiveSubstations = new Map(this.current_substations);
    // If ignore substation filters is set, then we don't need to filter
    if (this.sub_ignore_filters) {
      return newActiveSubstations;
    }

    //  Okay, start filtering
    filters
      .filter((filter) => filter.state.isActive)
      .forEach((filterData) => {
        const filterState = filterData.state;
        const selectedOptions = new Set(filterState.selected);
        // Skip filtering if all options are selected.
        // This avoids unnecessary filtering when the filter is effectively inactive.
        if (
          filterData.type === FilterTypes.CATEGORICAL &&
          selectedOptions.size === filterData.options?.length
        ) {
          return;
        }
        const { include: includeMissingDataSubs, excludeMatches } = filterState;
        logger.debug("Filtering [%s] %j", filterData.param, {
          excludeMatches,
          includeMissingDataSubs,
        });

        let min, max;
        // Filter the substations based on the filter type
        switch (filterData.type) {
          // TODO: These functions can definitely be stored on the filter type
          case FilterTypes.CATEGORICAL:
            if (!filterState.selected) return;
            newActiveSubstations.forEach((_, substationId) => {
              // filterData.data is a Map<string, any>
              // where the key is the substation uid, and the value is the block data
              const data = filterData.data.get(substationId);
              this.includeOrExcludeSubstation(
                newActiveSubstations,
                substationId,
                data,
                (value) => {
                  return Array.isArray(value)
                    ? value.some((option) => selectedOptions.has(option))
                    : selectedOptions.has(value);
                },
                excludeMatches,
                includeMissingDataSubs
              );
            });
            break;
          case FilterTypes.HISTOGRAM:
            min = Math.max(filterState.minRange, Math.min(filterState.min, filterState.maxRange));
            max = Math.max(filterState.minRange, Math.min(filterState.max, filterState.maxRange));
            if (Number.isNaN(min) && Number.isNaN(max)) {
              min = filterState.minRange;
              max = filterState.maxRange;
            }
            newActiveSubstations.forEach((v, sub) => {
              const blockValue = filterData.data.get(sub);
              this.includeOrExcludeSubstation(
                newActiveSubstations,
                sub,
                isValue(blockValue) ? blockValue : null,
                (val) => val >= min && val <= max,
                filterState.excludeMatches,
                includeMissingDataSubs
              );
            });
            break;
          default:
            this.parent.notifications.error("Unknown filter type");
            break;
        }
      });
    return newActiveSubstations;
  }

  updateNetworksFromNames(networknames) {
    this.networks = networknames;
  }

  selectDefaultCurrentNetwork = flow(function* selectDefaultCurrentNetwork(
    new_current_network = null
  ) {
    if (this.networks && this.networks.length > 0) {
      // we have a previous current_network which is fine for now.
      if (
        this.current_network &&
        this.networks.filter((cn) => cn.uid === this.current_network.uid).length > 0
      ) {
        if (!this.has_run) {
          yield this.selectNetwork(this.current_network);
        }
        // we are good here.
      } else if (new_current_network && this.networks.includes(new_current_network)) {
        // our old current network is not valid now.
        // use new_network provided then select first one as random
        yield this.selectNetwork(new_current_network);
      } else if (this.networks.length > 0) {
        yield this.selectNetwork(this.networks[0]);
      }
    }
  });

  refresh = flow(function* refresh() {
    try {
      const [networknames, rc] = yield this.parent.utfapi.getNetworksForUserV4();
      if (rc === 404 || rc === 401 || networknames.length === 0) {
        if (rc === 401) {
          yield this.parent.utfapi.registerUserInMDSL();
        }
        const userProfile = yield this.parent.session.getUserProfile();
        if (userProfile) {
          this.parent.app.gotError(CODES.no_permission, {
            msg: `you do not have permission to any network, please contact your administrator, with your user_id : ${userProfile.sub}`,
            btn: {
              title: "Logout",
              cb: () => {
                this.parent.session.logout();
              },
            },
          });
        }
        return;
      }
      this.updateNetworksFromNames(networknames);
      yield this.selectDefaultCurrentNetwork();
    } catch (err) {
      logger.debug(err);
    }
  });

  check = flow(function* () {
    if (!this.has_run) {
      yield this.refresh();
      this.has_run = true;
    }
  });

  updateUserPreferences = flow(function* () {
    try {
      const servePref = yield this.parent.utfapi.getPreferences();
      if (servePref) {
        this.parent.preferences.fromJson(servePref);
      }
      const profile = yield this.parent.session.getUserProfile();
      if (profile) {
        this.parent.preferences.setUserId(profile?.sub);
      }
    } catch (err) {
      logger.debug("unable to get user preferences");
      this.parent.preferences.disableSync();
    }
  });

  haveAccess = (access_list) => {
    if (access_list === undefined || access_list === null || access_list.length === 0) {
      return true;
    }
    // if access_list is HOME only, then we have access
    if (access_list.length === 1 && access_list[0] === "HOME") {
      return true;
    }
    for (const access of access_list) {
      if (this.features.has(access)) {
        return true;
      }
    }
    return false;
  };

  updateNetworkResources = flow(function* (network) {
    const currentNetwork = network || this.current_network;
    try {
      if (this.networks.filter((cn) => cn.uid === currentNetwork.uid).length > 0) {
        const resources = yield this.parent.utfapi.getNetworkResourcesV4(currentNetwork.uid);
        this.current_substations = new Map(
          resources.substations?.map((k) => [k.uid, k.name]) || []
        );
        this._network_feature_set = new Set((resources.frontend_features || []).map((k) => k.name));
        this.current_substations_v3_map = new Map(
          resources.substations?.map((k) => [k.name, k.uid]) || []
        );
        if (resources.substations) {
          this.current_substations = new Map(
            resources.substations?.map((s) => [s.uid, s.name]) || []
          );
        }
        if (resources.clusters !== null) {
          this.current_clusters = new Map(resources.clusters?.map((c) => [c.uid, c.name]) || []);
        } else {
          this.current_clusters = new Map();
        }
        if (resources.clusters) {
          this.active_clusters = new Map(resources.clusters?.map((c) => [c.uid, c.name]) || []);
        }
      }

      // TODO: Call this on Cluster filter on mount to avoid longer initial page load time
      // For every cluster, we add go and fetch the data .getClusterSubstations({ clusterUid })
      // and then we add the info to networkClusters
      if (this.current_clusters.size > 0) {
        this.networkClusters = new Map();
        for (const [clusterUid, clusterName] of this.current_clusters) {
          const clusterData = yield this.parent.utfapi.getClusterSubstations({ clusterUid });
          this.networkClusters.set(clusterUid, {
            name: clusterName,
            substations: clusterData.substations,
          });
        }
      }
    } catch (err) {
      logger.error("updateNetworkResources", err);
    }
  });

  updateNetworkTimezone = flow(
    function* updateNetworkTimezone() {
      const networkUid = this.current_network.uid;
      try {
        const data = yield this.parent.newapi.getInfoBlocksV4({
          resource_type: "network",
          resource_id: networkUid,
          block_names: [
            NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
            NETWORK_BLOCK_TYPES.lava_calc.to_block_name(),
            NETWORK_BLOCK_TYPES.location.to_block_name(),
            NETWORK_BLOCK_TYPES.metering_latest_upload.to_block_name(),
          ],
        });
        updateStoreValueFromBlock(
          this,
          "currency",
          data,
          NETWORK_BLOCK_TYPES.location.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.location.col.currency,
          DEFAULT_CURRENCY
        );
        updateStoreValueFromBlock(
          this,
          "tz_name",
          data,
          NETWORK_BLOCK_TYPES.location.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.location.col.tz_name,
          DEFAULT_NETWORK_TIMEZONE
        );
        updateStoreValueFromBlock(
          this,
          "network_timezone",
          data,
          NETWORK_BLOCK_TYPES.location.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.location.col.timezone,
          DEFAULT_NETWORK_TIMEZONE
        );
        updateStoreValueFromBlock(
          this,
          "coordinates",
          data,
          NETWORK_BLOCK_TYPES.location.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.location.col.weather_coordinates,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveYear",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.core_year,
          null
        );
        updateStoreValueFromBlock(
          this,
          "meteringLatestUpload",
          data,
          NETWORK_BLOCK_TYPES.metering_latest_upload.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.metering_latest_upload.col.valid_time,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveMonth",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.core_month,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveWeek",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.week_nr,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveEPMonth",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.ep_month,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveEPMonthClusters",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.ep_month_clusters,
          null
        );
        updateStoreValueFromBlock(
          this.parent.rta,
          "target_temp",
          data,
          NETWORK_BLOCK_TYPES.lava_calc.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.lava_calc.col.returntemp_target,
          DEFAULT_TARGET_TEMP
        );
        updateStoreValueFromBlock(
          this.parent.rta,
          "target_energy",
          data,
          NETWORK_BLOCK_TYPES.lava_calc.to_block_name(),
          networkUid,
          NETWORK_BLOCK_TYPES.lava_calc.col.efficiency_value,
          DEFAULT_TARGET_ENERGY
        );
      } catch (err) {
        logger.debug(err);
      }

      /**
       * Set application-wide default timezone from the network
       * This is used for all date/time display and calculations
       *
       * We dont want to use the UTC offset from the network, because it does not take into account
       * daylight saving time. Instead we use the tz_name, which is a valid IANA timezone name.
       * This is needed to for making Luxon and MUIx DatePickers aware of the DTS (Daylight Saving Time) offset.
       */

      // First we just check if the tz_name is valid
      // If not, we just use the default timezone
      let setTimezone = this.tz_name;
      if (!DateTime.local().setZone(setTimezone).isValid) {
        setTimezone = DEFAULT_NETWORK_TIMEZONE.toLowerCase();
        logger.error(
          `Network timezone is invalid: ${this.tz_name}. Using default timezone: ${DEFAULT_NETWORK_TIMEZONE}`
        );
      }

      logger.debug(`Network timezone: ${setTimezone}`);
      Settings.defaultZone = setTimezone;

      // Notify user if browser timezone is different than the networks'
      const tzNotificationStorageKey = "timezoneNotificationShown";
      try {
        // Check if user has a different timezone than the network
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone?.toLowerCase();
        const doTimezonesDiffer = browserTimeZone !== setTimezone.toLowerCase();
        // Check if user has already been notified
        const notificationShown = sessionStorage.getItem(tzNotificationStorageKey);
        if (doTimezonesDiffer && notificationShown !== "true") {
          // Make first char, and all chars after / uppercase
          const formattedTimezone = setTimezone
            .split("/")
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
            .join("/");

          this.parent.notifications.info(
            `Your timezone is different than the networks timezone. All data will be displayed in ${formattedTimezone} timezone.`
          );
        }
      } catch (e) {
        logger.debug(e);
      }

      this.refreshTime();
    }.bind(this)
  );

  /**
   * Refreshes the current hour, month and year.
   * Note: This refreshes active filters as well. So make sure you want to call this.
   */
  refreshTime() {
    const k = this.CurrentHour;
    this.currentHour = k;
    this.lpMonth = getLatestProcessedMonth(k, this.networkActiveMonth);
    this.lpYear = getLatestProcessedYear(k, this.networkActiveYear);
  }

  selectNetwork = flow(function* selectNetwork(network) {
    if (this.networks.filter((cn) => cn.uid === network.uid).length > 0) {
      this.ready = false;
      this.filter_set = new Set();
      try {
        this.parent.newapi.trimCache();
        this.current_network = network;
        yield this.updateNetworkTimezone();
        this.updateCurrentSubstation(null);
        yield this.updateUserPreferences();
        yield this.updateNetworkResources();
        this.checkNetworkRouteAccess();

        // We use $set, because its for setting properties on the user not an event
        posthog.capture("$set", {
          $set: { selected_network: network.name },
        });

        this.ready = true;
        // Reset the filters, so that they refetch data
        yield this.parent.filters.reset(); // This checks for network ready, so we need to do it after
      } catch (err) {
        logger.error("SelectNetwork failed: ", err);
      }
    }
  });

  setSubIgnoreFilters(event) {
    this.sub_ignore_filters = event.target.checked;
  }

  checkNetworkRouteAccess() {
    // Initially routerStore will be checking access
    // @see routeEntryHandler in routes.tsx
    if (!this.parent.initialized) return;
    try {
      const { routerStore } = this.parent;
      const { routerState } = routerStore;

      logger.debug("checkNetworkRouteAccess", JSON.stringify(routerState));

      if (!this.haveAccess(routerState?.options?.access)) {
        logger.debug("User have no access to this route. Redirecting to home...");
        routerStore.goTo("feature_access_denied");
      }
    } catch (e) {
      logger.error("Cannot check route network access: ", e);
    }
  }
}
