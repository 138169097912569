import { FC } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { faLockKeyhole } from "@fortawesome/pro-light-svg-icons";

import { logger } from "@core/logger";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

export type SideBarRoute = {
  route?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon?: React.ComponentType<any>;
  text: string;
  children?: SideBarRoute[];
  cb?: () => void;
  access?: string[];
};

type SideBarItemProps = {
  routes: SideBarRoute;
};

function LockIcon() {
  return <FontAwesomeSvgIcon icon={faLockKeyhole} sx={{ fontSize: 13 }} />;
}

export const SideBarItem: React.FC<SideBarItemProps> = ({ routes }) => {
  const { route = "", Icon, text, children = [], cb = null, access } = routes;
  const { routerStore, networks, ui } = useInject("routerStore", "networks", "ui");
  const {
    routerState: { routeName },
  } = routerStore;

  const haveRoute = route.length > 0;
  const haveChildren = children.length > 0;
  const firstChildWithRoute = children.find((child) => typeof child.route === "string");
  const haveAccess = networks.haveAccess(access);
  const isCurrentRoute = route === routeName;
  const childrenHaveRoute = !!children.find((child) => child.route === routeName);
  const isSelected = isCurrentRoute || childrenHaveRoute;
  const id = haveRoute ? route : children[0]?.route;
  const open = haveChildren && childrenHaveRoute;

  const navigateToRoute = () => {
    if (!haveAccess) return;

    if (cb) {
      cb();
    }

    (async () => {
      if (haveChildren && firstChildWithRoute) {
        return runInAction(() => routerStore.goTo(String(firstChildWithRoute.route)));
      }

      if (route.length > 0) {
        ui.closeDrawerOnSmallScreen();
        return runInAction(() => routerStore.goTo(route));
      }

      logger.error(`No "route" was found for`, route);
      return false;
    })();
  };

  return (
    <Box sx={{ mb: 1.5 }}>
      <ListItemButton
        sx={{
          zIndex: 1,
          boxShadow: haveChildren && open ? "0px 2px 4px rgba(0, 0, 0, 0.12)" : "none",
          backgroundColor: isSelected ? "grey.300" : "grey.200",
          height: 48,
          pl: 3,
        }}
        disabled={!networks.ready}
        selected={isSelected}
        onClick={navigateToRoute}
        data-testid={`sidebar-item-${id}`}
      >
        <ListItemIcon sx={{ minWidth: "auto", pr: 4 }}>
          <Box sx={{ "& svg": { fontSize: 24, color: "primary.main" } }}>
            {/* @ts-expect-error dunno-what */}
            <Icon />
          </Box>
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            color: isSelected ? "secondary" : "primary",
            variant: "button",
          }}
        />
        {haveChildren && haveAccess && (
          <div>
            {open ? (
              <ExpandLessIcon data-testid="lessIcon" color="primary" />
            ) : (
              <ExpandMoreIcon data-testid="moreIcon" color="primary" />
            )}
          </div>
        )}
        {!haveAccess && (
          <div data-testid="sidebar-lock-icon">
            <LockIcon />
          </div>
        )}
      </ListItemButton>

      {/* Nested Items */}
      {haveChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              backgroundColor: isSelected ? "grey.200" : "grey.100",
            }}
          >
            {children.map((child) => {
              if (child.route === null) return null;

              const childColor = child.route === routeName ? "secondary" : "primary";
              const haveAccessToChild = networks.haveAccess(child.access);

              const onChildClick = () => {
                (async () => {
                  if (haveAccessToChild) {
                    if (child.cb) {
                      child.cb();
                    } else {
                      await runInAction(() => routerStore.goTo(String(child.route)));
                    }
                  }
                })();
                ui.closeDrawerOnSmallScreen();
              };

              return (
                <ListItemButton
                  key={child.route}
                  onClick={onChildClick}
                  disabled={!networks.ready}
                  selected={child.route === routeName}
                  data-testid={`sidebar-item-child-${child.route}`}
                >
                  <ListItemText
                    primary={child.text}
                    primaryTypographyProps={{
                      color: childColor,
                      variant: "button",
                    }}
                    sx={{ pl: 8 }}
                  />
                  {!haveAccessToChild && <LockIcon data-testid="sidebar-lock-icon" />}
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SideBarNestedItem = observer<FC<SideBarItemProps>>(SideBarItem);

SideBarNestedItem.displayName = "SideBarNestedItem";
