import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { YAxisOptions } from "highcharts";
import type { DateTime } from "luxon";

import { DAY_MILIS, HOUR_MILIS } from "@conf";
import { defaultTheme as theme } from "@config/utilifeed.theme";
import { TimeSeries } from "@core/types/common";
import { generateFlowLimiterPlot } from "@core/utils/generateFlowLimiterPlot";

type MeterSeries = "outdoor" | "vol" | "heat" | "rt" | "st" | "dt";

type Range = {
  start?: DateTime;
  end?: DateTime;
};

export const useMeterSeries = (
  seriesInExtremes: TimeSeries[],
  averaged: boolean,
  range: Range,
  chartHeight = 550,
  flowLimiter?: number
) => {
  const { t } = useTranslation(["extendView"]);
  const [visibleSeries, setVisibleSeries] = useState<{ [key in MeterSeries]: boolean }>({
    outdoor: true,
    vol: true,
    heat: true,
    rt: true,
    st: true,
    dt: true,
  });

  const visibleSeriesCount = (Object.keys(visibleSeries) as MeterSeries[]).reduce(
    (prev: number, current: keyof typeof visibleSeries) => {
      if (visibleSeries[current]) return prev + 1;
      return prev;
    },
    0
  );

  // How many of RT or ST series are visible
  const visibleTempSeriesCount = Object.keys(visibleSeries).reduce((prev, current) => {
    if (current !== "rt" && current !== "st" && current !== "dt") return prev;
    if (visibleSeries[current]) return prev + 1;
    return prev;
  }, 0);

  const chartData = useMemo(() => {
    let timeStamp: number[] = [];
    let outdoorTempData: (number | undefined)[] = [];
    let flow: (number | undefined)[] = [];
    let heatEnergy: (number | undefined)[] = [];
    let returnTemp: (number | undefined)[] = [];
    let supplyTemp: (number | undefined)[] = [];
    let deltaTemp: (number | undefined)[] = [];

    if (seriesInExtremes) {
      outdoorTempData = seriesInExtremes.map(({ outdoor }) => outdoor);
      timeStamp = seriesInExtremes.map(({ ts }) => ts);
      flow = seriesInExtremes.map(({ vol }) => vol);
      heatEnergy = seriesInExtremes.map(({ heat }) => heat);
      returnTemp = seriesInExtremes.map(({ rt }) => rt);
      supplyTemp = seriesInExtremes.map(({ st }) => st);
      deltaTemp = seriesInExtremes.map(({ dt }) => dt);
    }

    const series = [];
    const yAxis: YAxisOptions[] = [];

    let topOffset = 60; // the height of the legend bar
    const bottomOffset = 62; // the height of the yAxis labels
    const seriesHeight = (chartHeight - topOffset - bottomOffset) / visibleSeriesCount;
    const combinedSeriesHeight = seriesHeight * visibleTempSeriesCount;

    const pointInterval = averaged ? DAY_MILIS : HOUR_MILIS;
    const pointStart = range?.start?.toMillis();

    const yAxisProps = {
      opposite: false,
      offset: 0,
      lineWidth: 1,
      resize: {
        enabled: true,
      },
      labels: {
        align: "right",
        x: -5,
        step: 1,
      },
      tickAmount: 4,
    };

    // -- Outdoor Temp
    series.push({
      id: "outdoor",
      type: "spline",
      name: t("text_outdoor_temperature"),
      pointInterval,
      pointStart,
      color: theme.palette.yellow.main,
      data: outdoorTempData,
      timeStamp,
      tooltip: {
        valueSuffix: "°C",
      },
      yAxis: 0,
      visible: visibleSeries.outdoor,
    });

    yAxis.push({
      ...yAxisProps,
      title: {
        text: `${t("text_outdoor_temperature")} °C`,
      },
      visible: visibleSeries.outdoor,
      labels: {
        ...yAxisProps.labels,
        // @ts-expect-error ignore
        color: theme.palette.yellow.main,
      },
      top: topOffset,
      height: seriesHeight,
    });

    // -- Flow
    series.push({
      id: "vol",
      name: t("text_flow"),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.green.main,
      data: flow,
      timeStamp,
      tooltip: {
        valueSuffix: "m³/h",
      },
      yAxis: 1,
      visible: visibleSeries.vol,
    });

    if (visibleSeries.outdoor) topOffset += seriesHeight;
    const yAxisFlow: YAxisOptions = {
      ...yAxisProps,
      title: {
        text: `${t("text_flow")} <br> m³/h`,
      },
      visible: visibleSeries.vol,
      labels: {
        ...yAxisProps.labels,
        // @ts-expect-error ignore
        color: theme.palette.green.main,
      },
      top: topOffset,
      height: seriesHeight,
    };

    yAxisFlow.plotLines = undefined;
    // Draw the plot line for "Flow Limiter" if its exists
    if (flowLimiter) {
      yAxisFlow.plotLines = [
        generateFlowLimiterPlot(flowLimiter, t("text_chart_label_flow_limiter")),
      ];
    }
    yAxis.push(yAxisFlow);

    // -- Heat Energy
    series.push({
      id: "heat",
      name: t("text_heat_energy"),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.orange.main,
      data: heatEnergy,
      timeStamp,
      tooltip: {
        valueSuffix: "kWh/h",
      },
      yAxis: 2,
      visible: visibleSeries.heat,
    });

    if (visibleSeries.vol) topOffset += seriesHeight + 1;
    yAxis.push({
      ...yAxisProps,
      title: {
        text: `${t("text_heat_energy")} <br> kWh/h`,
      },
      visible: visibleSeries.heat,
      labels: {
        ...yAxisProps.labels,
        // @ts-expect-error ignore
        color: theme.palette.orange.main,
      },
      top: topOffset,
      height: seriesHeight,
    });

    // -- Supply Temperature
    series.push({
      id: "st",
      name: t("text_supply_temperature"),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.pink.main,
      data: supplyTemp,
      timeStamp,
      tooltip: {
        valueSuffix: "°C",
      },
      yAxis: 3,
      visible: visibleSeries.st,
      threshold: 40,
    });

    // -- Return Temperature
    series.push({
      id: "rt",
      name: t("text_return_temperature"),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.blue.main,
      data: returnTemp,
      timeStamp,
      tooltip: {
        valueSuffix: "°C",
      },
      yAxis: 3,
      visible: visibleSeries.rt,
      threshold: 40,
    });

    // -- Delta Temperature
    series.push({
      id: "dt",
      name: t("text_delta_temperature"),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.teal.main,
      data: deltaTemp,
      timeStamp,
      tooltip: {
        valueSuffix: "°C",
      },
      yAxis: 3,
      visible: visibleSeries.dt,
      threshold: 40,
    });

    if (visibleSeries.heat) topOffset += seriesHeight;
    yAxis.push({
      ...yAxisProps,
      tickAmount: 5,
      title: {
        text: `${t("text_temperature")} <br> °C`,
      },
      visible: visibleTempSeriesCount > 0,
      labels: {
        ...yAxisProps.labels,
        // @ts-expect-error ignore
        color: theme.palette.pink.dark,
      },
      top: topOffset,
      height: combinedSeriesHeight,
    });

    return { series, yAxis };
  }, [
    averaged,
    flowLimiter,
    seriesInExtremes,
    range,
    theme.palette,
    visibleSeries,
    visibleSeriesCount,
    visibleTempSeriesCount,
    chartHeight,
    t,
  ]);

  return { chartData, visibleSeries, setVisibleSeries };
};
