import { flow, makeObservable, observable } from "mobx";
import type { CancellablePromise } from "mobx/dist/internal";
import type { Auth0Client, Auth0ClientOptions, User } from "@auth0/auth0-spa-js";
import { AuthenticationError, createAuth0Client } from "@auth0/auth0-spa-js";

import { DUMMY_TOKEN, isFeatureFlagOn } from "@conf/config";
import type { RootStoreType } from "./root_store";

class Session {
  errorMsg: string | undefined;

  user_id: string | undefined;

  client: Auth0Client | undefined;

  clientId: string;

  redirect_uri: string;

  domain: string;

  parent: RootStoreType;

  audience: string;

  authContext: Auth0ClientOptions;

  // Functions

  checkSession: () => CancellablePromise<boolean>;

  authHeaders: () => CancellablePromise<object>;

  getUserProfile: () => Promise<User>;

  isLoggedIn: boolean | undefined;

  constructor(parent: RootStoreType) {
    makeObservable(this, {
      client: true,
      clientId: true,
      redirect_uri: true,
      domain: true,
      parent: true,
      audience: true,
      authContext: true,
      checkSession: true,
      authHeaders: true,
      getUserProfile: true,
      handleError: true,
      checkProdSession: true,
      checkDevSession: true,
      login: true,
      getUserProfileAuth0: true,
      getUserProfileDummy: true,
      handleRedirectCallback: true,
      getToken: true,
      authProdHeaders: true,
      authDevHeaders: true,
      logout: true,
      errorMsg: observable,
      user_id: observable,
      isLoggedIn: observable,
    });

    this.isLoggedIn = false;

    this.parent = parent;
    this.audience = import.meta.env.REACT_APP_AUTH0_AUDIENCE;
    this.domain = import.meta.env.REACT_APP_AUTH0_DOMAIN;
    this.clientId = import.meta.env.REACT_APP_AUTH0_CLIENTID;
    this.redirect_uri = window.location.origin;

    this.getToken = this.getToken.bind(this);

    if (isFeatureFlagOn("DUMMYAUTH")) {
      this.checkSession = this.checkDevSession.bind(this);
      this.authHeaders = this.authDevHeaders.bind(this);
      this.getUserProfile = this.getUserProfileDummy.bind(this);
    } else {
      this.checkSession = this.checkProdSession.bind(this);
      this.authHeaders = this.authProdHeaders.bind(this);
      this.getUserProfile = this.getUserProfileAuth0.bind(this);
    }
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);

    /**
     * We always need to set this to the "memory" on production due to security
     * However, in order to make login session cachable on Playwright we need to use "localstorage"
     */
    const cacheLocation = "localstorage"; // Hotfix original : import.meta.env.PROD && !PLAYWRIGHT_RUN ? "memory" : "localstorage";

    this.authContext = {
      domain: this.domain,
      clientId: this.clientId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation,
      authorizationParams: {
        responseType: "token",
        redirect_uri: this.redirect_uri,
        audience: this.audience,
      },
    };
  }

  handleError(error: unknown, fallback: string): void {
    if (error instanceof AuthenticationError) {
      this.errorMsg = error.error_description;
    } else {
      this.errorMsg = fallback;
      throw error;
    }
  }

  checkProdSession = flow(function* checkProductionSession(this: Session) {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    this.isLoggedIn = yield this.client?.isAuthenticated();
    return this.isLoggedIn as boolean;
  });

  checkDevSession = flow(function* checkDevelopmentSession(this: Session) {
    yield true;
    return true;
  });

  login = flow(function* login(this: Session) {
    this.client = yield createAuth0Client(this.authContext);
    try {
      this.errorMsg = undefined;
      yield this.client?.loginWithRedirect();
    } catch (error: unknown) {
      this.handleError(error, "An error occurred while logging in.");
    }
  });

  getUserProfileAuth0: User = flow(function* getUserProfileAuth0(this: Session) {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    return yield this.client?.getUser();
  });

  getUserProfileDummy: User = flow(function* getUserProfileDummy(this: Session) {
    yield undefined;
    return { email: `dummy_${DUMMY_TOKEN}@dispostable.com` };
  });

  handleRedirectCallback = flow(function* handleRedirectCallback(this: Session) {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    try {
      yield this.client?.handleRedirectCallback();
    } catch (error) {
      this.handleError(error, "An error occurred while handling the redirect callback.");
    }
  });

  getToken = flow(function* getToken(this: Session) {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    try {
      return yield this.client?.getTokenSilently();
    } catch (error) {
      this.handleError(error, "An error occurred while getting the token.");
      return false;
    }
  });

  authProdHeaders = flow(function* authProductionHeaders(this: Session) {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    try {
      const token = yield this.client?.getTokenSilently();
      return { authorization: `Bearer ${token}` };
    } catch (error) {
      yield this.parent.clearCache();
      yield this.client?.loginWithRedirect();
      return {};
    }
  });

  authDevHeaders = flow(function* authDevelopmentHeaders() {
    yield;
    return { authorization: `Bearer ${DUMMY_TOKEN}` };
  });

  logout = flow(function* logout(this: Session) {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    yield this.client?.logout({ logoutParams: { returnTo: "https://utilifeed.com" } });

    yield this.parent.clearCache();
  });
}

export default Session;
