import type { ElementType } from "react";
import {
  Box,
  CircularProgress,
  GlobalStyles,
  Grid,
  Backdrop as MuiBackdrop,
  styled,
} from "@mui/material";

const Backdrop = styled(Box)(({ theme: { palette, zIndex, transitions } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: `800px`,
  color: "white",
  fontWeight: 600,
  textAlign: "center",
  background: `linear-gradient(-45deg, ${palette.background.default}, ${palette.primary.main}, ${palette.primary.dark})`,
  backgroundSize: "400% 400%",
  animation: `gradient 9s ${transitions.easing.easeInOut} infinite`,
  zIndex: zIndex.drawer + 1,
}));

export function LoaderScreen({ children = null, fullScreen = false }) {
  const { open, component } = fullScreen
    ? {
        open: true,
        component: MuiBackdrop,
      }
    : { open: undefined, component: "div" as ElementType };

  return (
    <Backdrop {...{ open, component }}>
      <GlobalStyles
        styles={{
          "@keyframes gradient": {
            "0%": {
              backgroundPosition: "0% 50%",
            },
            "50%": {
              backgroundPosition: "100% 50%",
            },
            "100%": {
              backgroundPosition: "0% 50%",
            },
          },
        }}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={{
          height: !fullScreen ? "calc(100vh - 80px)" : "100vh",
        }}
        data-testid="app-loader-screen"
      >
        <Box m={4}>
          <CircularProgress variant="indeterminate" sx={{ color: "white" }} />
        </Box>
        {children && <div>{children}</div>}
      </Grid>
    </Backdrop>
  );
}
