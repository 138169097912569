/* eslint-disable eqeqeq */

import { action, computed, flow, makeObservable, observable } from "mobx";
import { DateTime } from "luxon";

import { SUBSTATION_BLOCK_TYPES as SBT } from "@conf/blocks";
import { MONTH_OPTIONS } from "@conf/constants";
import { week_string } from "@core/utils";

import {
  DEFAULT_TARGET_ENERGY,
  DEFAULT_TARGET_TEMP,
  PARAMETER,
  R12,
  RT_YAXIS,
  RT_YAXIS_FOLLOW,
  SCATTER_XAXIS,
  SECTION,
  SOURCE,
  TIME_PERIOD,
  WEIGHT,
} from "./constants";

// noinspection JSPotentiallyInvalidUsageOfClassThis
class RtStore {
  // form
  current_section = SECTION.rt;

  time_period = TIME_PERIOD.yearly;

  from_year = null;

  to_year = null;

  comparing = false;

  from_month = null;

  to_month = null;

  from_week = null;

  to_week = null;

  parameter = PARAMETER.return_temperature;

  weight = WEIGHT.flow_weighted;

  source = SOURCE.measured;

  // for rtsp
  target_temp = DEFAULT_TARGET_TEMP;

  target_energy = DEFAULT_TARGET_ENERGY;

  // for rt histograms
  yaxis = RT_YAXIS.volume;

  // for rt scatter
  is_log = false;

  scatter_xaxis = SCATTER_XAXIS.energy;

  // data
  is_data_available = false;

  constructor(parent) {
    makeObservable(this, {
      updateTargetTemperature: true,
      updateTargetEnergy: true,
      shouldFetchData: true,
      requiredBlocksRtAnalysis: true,
      requiredBlocksRtTable: true,
      fetchDataRtAnalysis: true,
      fetchDataRtSpAnalysis: true,
      current_section: observable,
      time_period: observable,
      from_year: observable,
      to_year: observable,
      comparing: observable,
      from_month: observable,
      to_month: observable,
      from_week: observable,
      to_week: observable,
      parameter: observable,
      weight: observable,
      source: observable,
      target_temp: observable,
      target_energy: observable,
      yaxis: observable,
      is_log: observable,
      scatter_xaxis: observable,
      is_data_available: observable,
      resetForm: action.bound,
      updateTimePeriod: action.bound,
      updateFromYear: action.bound,
      updateToYear: action.bound,
      updateComparingToggle: action.bound,
      updateFromMonth: action.bound,
      updateToMonth: action.bound,
      updateFromWeek: action.bound,
      updateToWeek: action.bound,
      updateParameter: action.bound,
      updateWeight: action.bound,
      updateSource: action.bound,
      updateCurrentSection: action.bound,
      resetForSP: action.bound,
      updateYaxis: action.bound,
      updateScatterXaxis: action.bound,
      updateIsLogToggle: action.bound,
      formatDecimalValue: action.bound,
      setTargetTemperature: action.bound,
      setTargetEnergy: action.bound,
      currentYearMonth: computed,
      compareYearMonth: computed,
      durationString: computed,
      durationFileNamePart: computed,
    });

    this.parent = parent;

    this.shouldFetchData = this.shouldFetchData.bind(this);
    this.fetchDataRtAnalysis = this.fetchDataRtAnalysis.bind(this);
    this.requiredBlocksRtSpAnalysis = this.requiredBlocksRtAnalysis.bind(this);
  }

  resetForm() {
    this.from_year = this.parent.networks.lpYear.year;
    this.to_year = this.parent.networks.lpYear.year - 1;
    this.comparing = false;
    this.from_month = null;
    this.to_month = null;
    this.time_period = TIME_PERIOD.yearly;
    this.parameter = PARAMETER.return_temperature;
    this.weight = WEIGHT.flow_weighted;
    this.source = SOURCE.measured;
    this.current_section = SECTION.rt;
  }

  updateTimePeriod(newTimePeriod) {
    this.time_period = newTimePeriod;
    switch (newTimePeriod) {
      case TIME_PERIOD.monthly:
        this.from_year = this.parent.networks.lpMonth.year;
        this.to_year = this.parent.networks.lpMonth.year - 1;
        this.source = SOURCE.measured;
        this.to_month = "01";
        this.from_month = "01";
        break;
      case TIME_PERIOD.yearly:
        this.from_year = this.parent.networks.lpYear.year;
        this.to_year = this.parent.networks.lpYear.year - 1;
        this.to_month = null;
        this.from_month = null;
        break;
      default:
        this.from_year = this.parent.networks.LastProcessedWeek.year;
        this.to_year = this.parent.networks.LastProcessedWeek.year;
        this.source = SOURCE.measured;
        this.from_month = null;
        this.to_month = null;
        this.from_week = 0;
        this.to_week = 1;
    }
  }

  updateFromYear(newFromYear) {
    this.from_year = newFromYear;
    if (!this.comparing) {
      this.to_year = this.from_year - 1;
    }
    if (newFromYear > this.parent.networks.LastProcessedYear.year) {
      this.from_month = "01";
    }
    if (newFromYear === R12) {
      void this.fetchDataRtAnalysis();
    }
  }

  updateToYear(newToYear) {
    this.to_year = newToYear;
    if (newToYear > this.parent.networks.LastProcessedYear.year) {
      this.to_month = "01";
    }
  }

  updateComparingToggle() {
    this.comparing = !this.comparing;
    if (this.comparing) {
      switch (this.time_period) {
        case TIME_PERIOD.weekly:
          // eslint-disable-next-line no-case-declarations
          const cw = this.parent.networks.LastProcessedWeek;
          this.to_week = this.from_week >= 4 ? 4 : this.from_week + 1;
          this.from_year = cw.minus({ weeks: this.from_week }).year;
          this.to_year = cw.minus({ weeks: this.to_week }).year;
          break;
        case TIME_PERIOD.monthly:
          this.updateToMonth(this.from_month);
          this.updateToYear(this.from_year - 1);
          break;
        case TIME_PERIOD.yearly:
          this.updateToYear(this.from_year - 1);
          break;
        default:
          // TODO: handle this
          break;
      }
    }
  }

  updateFromMonth(newFromMonth) {
    this.from_month = newFromMonth;
    if (!this.comparing) {
      this.to_month = newFromMonth;
    }
  }

  updateToMonth(newToMonth) {
    this.to_month = newToMonth;
  }

  updateFromWeek(newFromWeek) {
    this.from_week = newFromWeek;
    if (!this.comparing) {
      this.to_week = newFromWeek >= 4 ? 4 : newFromWeek + 1;
    }
    const cw = this.parent.networks.LastProcessedWeek;
    this.from_year = cw.minus({ weeks: this.from_week }).year;
    this.to_year = cw.minus({ weeks: this.to_week }).year;
  }

  updateToWeek(newToWeek) {
    this.to_week = newToWeek;
  }

  updateParameter(newParameter) {
    this.parameter = newParameter;
  }

  updateWeight(newWeight) {
    this.weight = newWeight;
    this.yaxis = RT_YAXIS_FOLLOW[newWeight];
  }

  updateSource(newSource) {
    this.source = newSource;
  }

  updateCurrentSection(newSection) {
    if (this.current_section !== newSection) {
      this.current_section = newSection;
      this.is_data_available = false;
      if (newSection === SECTION.rtsp) {
        this.resetForSP();
      }
    }
  }

  resetForSP() {
    this.to_year = this.from_year - 1;
    this.comparing = false;
    this.to_month = this.from_month;
    this.weight = WEIGHT.flow_weighted;
  }

  updateYaxis(newYaxis) {
    this.yaxis = newYaxis;
  }

  updateScatterXaxis(value) {
    this.scatter_xaxis = value;
  }

  updateIsLogToggle() {
    this.is_log = !this.is_log;
  }

  updateTargetTemperature = flow(function* (newTargetTemperature) {
    const updated = yield this.parent.utfapi.updateNetworkData({
      data: [
        {
          block_data: {
            returntemp_target: newTargetTemperature,
          },
          block_name: "lava_calc",
          uid: this.parent.networks.current_network.uid,
        },
      ],
    });
    if (updated) {
      this.setTargetTemperature(newTargetTemperature);
    }
    return updated;
  });

  formatDecimalValue(value) {
    const re = /[^e0-9,.]/;
    let cleanValue = value.replace(re, "");
    if (cleanValue.includes(",") && cleanValue.includes(".")) {
      cleanValue = cleanValue.replace(".", "");
    }
    if (cleanValue.includes(".") && !cleanValue.includes(",")) {
      cleanValue = cleanValue.replace(".", ",");
    }
    if (cleanValue.includes("e")) {
      cleanValue = cleanValue.replace(",", "");
      cleanValue = cleanValue.replace(".", "");
    }
    return cleanValue;
  }

  setTargetTemperature(value) {
    this.target_temp = this.formatDecimalValue(value);
  }

  updateTargetEnergy = flow(function* (newTargetTemperature) {
    yield this.parent.utfapi.updateNetworkData({
      data: [
        {
          block_data: {
            efficiency_value: newTargetTemperature,
          },
          block_name: "lava_calc",
          uid: this.parent.networks.current_network.uid,
        },
      ],
    });
    this.setTargetEnergy(newTargetTemperature);
  });

  setTargetEnergy(value) {
    this.target_energy = this.formatDecimalValue(value);
  }

  get currentYearMonth() {
    if (this.time_period === TIME_PERIOD.monthly) {
      return `${MONTH_OPTIONS.get(this.from_month) || ""} ${this.from_year}`;
    }
    if (this.time_period === TIME_PERIOD.yearly) {
      return this.from_year;
    }
    return week_string(this.parent.networks.LastProcessedWeek.minus({ weeks: this.from_week }));
  }

  get compareYearMonth() {
    if (this.time_period === TIME_PERIOD.monthly) {
      return `${MONTH_OPTIONS.get(this.to_month) || ""} ${this.to_year}`;
    }
    if (this.time_period === TIME_PERIOD.yearly) {
      return this.to_year;
    }
    return week_string(this.parent.networks.LastProcessedWeek.minus({ weeks: this.to_week }));
  }

  get durationString() {
    if (this.comparing) {
      return `${this.currentYearMonth}, ${this.compareYearMonth}`;
    }
    return `${this.currentYearMonth}`;
  }

  get durationFileNamePart() {
    switch (this.time_period) {
      case TIME_PERIOD.monthly:
        if (!this.comparing) {
          return `${this.from_year}-${this.from_month}`;
        }
        return `${this.from_year}-${this.from_month}_${this.to_year}-${this.to_month}`;
      case TIME_PERIOD.weekly:
        if (!this.comparing) {
          return `${this.from_year}-${
            this.parent.networks.LastProcessedWeek.minus({ weeks: this.from_week }).weekNumber
          }`;
        }
        return `${this.from_year}-${
          this.parent.networks.LastProcessedWeek.minus({ weeks: this.from_week }).weekNumber
        }_${this.to_year}-${
          this.parent.networks.LastProcessedWeek.minus({ weeks: this.to_week }).weekNumber
        }`;

      default:
        if (this.comparing) {
          return `${this.from_year}_${this.to_year}`;
        }
        return this.from_year;
    }
  }

  shouldFetchData() {
    return [
      this.parent.networks.current_network && this.parent.networks.current_network.uid,
      this.parent.networks.ready,
      this.from_year,
      this.from_month,
      this.to_year,
      this.to_month,
      this.from_week,
      this.to_week,
      this.source,
      this.parameter,
      this.current_section,
    ];
  }

  requiredBlocksRtAnalysis = () => {
    const blockNames = [];
    let [from_month, to_month] = [12, 12];
    if (this.source === SOURCE.measured) {
      [from_month, to_month] =
        this.time_period === TIME_PERIOD.yearly ? [null, null] : [this.from_month, this.to_month];
    }
    if (this.source === SOURCE.measured) {
      if (this.time_period === TIME_PERIOD.weekly) {
        blockNames.push(
          SBT.core_rolling_weekly.to_block_name({
            year: this.from_year,
            month: from_month,
            week: this.from_week,
          })
        );
        blockNames.push(
          SBT.core_rolling_weekly.to_block_name({
            year: this.to_year,
            month: to_month,
            week: this.to_week,
          })
        );
      } else if (this.from_year == R12) {
        const currentDateTime = DateTime.now();
        blockNames.push(SBT.core_rolling_yearly.to_block_name());
        blockNames.push(SBT.core.to_block_name({ year: currentDateTime.year - 1 }));
      } else {
        blockNames.push(SBT.core.to_block_name({ year: this.from_year, month: from_month }));
        blockNames.push(SBT.core.to_block_name({ year: this.to_year, month: to_month }));
      }
    } else {
      blockNames.push(
        SBT.epcore_normalized.to_block_name({ year: this.from_year, month: from_month })
      );
      blockNames.push(SBT.epcore_normalized.to_block_name({ year: this.to_year, month: to_month }));
    }

    return blockNames;
  };

  requiredBlocksRtTable = () => {
    const blockNames = {
      install: SBT.install_address.to_block_name(),
      customer: SBT.customer.to_block_name(),
    };
    let [from_month, to_month] = [12, 12];
    if (this.source === SOURCE.measured) {
      [from_month, to_month] =
        this.time_period === TIME_PERIOD.yearly ? [null, null] : [this.from_month, this.to_month];
      if (this.time_period === TIME_PERIOD.weekly) {
        blockNames.core = SBT.core_rolling_weekly.to_block_name({
          year: this.from_year,
          month: from_month,
          week: this.from_week,
        });
        if (this.comparing) {
          blockNames.core_prev = SBT.core_rolling_weekly.to_block_name({
            year: this.to_year,
            month: to_month,
            week: this.to_week,
          });
        }
      } else {
        if (this.from_year == R12) {
          blockNames.core = SBT.core_rolling_yearly.to_block_name();
        } else {
          blockNames.core = SBT.core.to_block_name({ year: this.from_year, month: from_month });
        }
        if (this.comparing) {
          blockNames.core_prev = SBT.core.to_block_name({ year: this.to_year, month: to_month });
        }
      }
    } else {
      blockNames.core = SBT.epcore_normalized.to_block_name({
        year: this.from_year,
        month: from_month,
      });
      if (this.comparing) {
        blockNames.core_prev = SBT.epcore_normalized.to_block_name({
          year: this.to_year,
          month: to_month,
        });
      }
    }
    return blockNames;
  };

  fetchDataRtAnalysis = flow(
    function* () {
      if (!this.parent.networks.ready) {
        return;
      }
      this.is_fetching = true;
      this.is_data_available = false;
      this.is_data_processed = false;
      this.data = null;
      yield null;
      try {
        this.data = yield this.parent.newapi.getInfoBlocksV4({
          resource_type: "network_substations",
          resource_id: this.parent.networks.current_network.uid,
          block_names: this.requiredBlocksRtAnalysis(),
        });
        if (this.data !== null) {
          this.is_data_available = true;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("error while fetching data", err);
        //  send notification to main
      } finally {
        this.is_fetching = false;
      }
    }.bind(this)
  );

  fetchDataRtSpAnalysis = flow(
    function* () {
      if (!this.parent.networks.ready) {
        return;
      }
      this.is_fetching = true;
      this.is_data_available = false;
      this.is_data_processed = false;
      this.data = null;
      yield null;
      try {
        this.data = yield this.parent.newapi.getInfoBlocksV4({
          resource_type: "network_substations",
          resource_id: this.parent.networks.current_network.uid,
          block_names: this.requiredBlocksRtAnalysis(),
        });
        if (this.data !== null) {
          this.is_data_available = true;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("error while fetching data", err);
        //  send notification to main
      } finally {
        this.is_fetching = false;
      }
    }.bind(this)
  );
}

export default RtStore;
