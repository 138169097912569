// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: disable nocheck flag above and add missing types
const FLOW_REDUCTION_CONSTANT = 1.16;

const formatDecimalNumber = (value) => {
  if (String(value).includes(",")) {
    // eslint-disable-next-line no-param-reassign
    value = value.replace(",", ".");
  }
  return value;
};

/**
 * Calculate "Cost Saving Potential"  of a substation
 *
 * @export
 * @param {object} row data of a single substation as object
 * @param {string} energyAttr attribute to get energy
 * @param {string} tempAttr attribute to get return temperature flow weighted
 * @param {string} energyTarget
 * @param {number} tempTarget
 * @return {number} Saving potential
 */
export function costSavingPotential(row, energyAttr, tempAttr, energyTarget, tempTarget) {
  // eslint-disable-next-line no-param-reassign
  energyTarget = formatDecimalNumber(energyTarget);
  // eslint-disable-next-line no-param-reassign
  tempTarget = formatDecimalNumber(tempTarget);
  if (!row[energyAttr] || !row[tempAttr]) return 0;
  const k = (row[energyAttr] / 1000) * energyTarget * (row[tempAttr] - tempTarget);
  return Math.max(0, k);
}

/**
 * Calculate "Flow Reduction Potential" of a substation
 *
 * @export
 * @param {object} row values object
 * @param {string} energyAttr heat energy sum attr
 * @param {string} stAttr supply temperature attr flow weighted
 * @param {string} rtAttr return temperature attr flow weighted
 * @param {number} tempTarget target temperature
 * @return {number | null} Reduction potential
 */
export function flowReductionPotential(row, energyAttr, stAttr, rtAttr, tempTarget) {
  const heatEnergySum = row[energyAttr];
  const returnTemp = row[rtAttr];
  const supplyTemp = row[stAttr];
  // eslint-disable-next-line no-param-reassign
  tempTarget = formatDecimalNumber(tempTarget);
  if (!heatEnergySum || !returnTemp || !supplyTemp) return 0;

  if (supplyTemp - returnTemp === 0 || supplyTemp - tempTarget === 0) return null;

  if (supplyTemp < tempTarget && returnTemp < tempTarget) return 0;

  const k =
    heatEnergySum / ((supplyTemp - returnTemp) * FLOW_REDUCTION_CONSTANT) - // actual
    heatEnergySum / ((supplyTemp - tempTarget) * FLOW_REDUCTION_CONSTANT); // if rt was best
  return Math.max(k, 0);
}
