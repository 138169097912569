import { makeAutoObservable, observable } from "mobx";

import type { RootStoreType as RootStore } from "@stores/root_store";

type DialogType = "confirm" | "alert" | "prompt";

type Dialog = {
  type: DialogType;
  title: string;
  message: string;
  label?: string;
  options: DialogOptions;
  resolve: (value: string, result?: string) => void;
  reject: (reason?: any) => void;
};

type DialogOptions = {
  cancelText?: string;
  confirmText?: string;
  defaultInput?: string;
};

export default class InteractionStore {
  parent: RootStore;

  dialog: Dialog | null = null;

  constructor(parent: RootStore) {
    this.parent = parent;

    makeAutoObservable(
      this,
      {
        dialog: observable,
        parent: false,
      },
      {
        autoBind: true,
      }
    );
  }

  /*
  / Add a dialog to the store
  / @param {DialogType} type - The type of dialog to display
  / @param {string} title - The title of the dialog
  / @param {string} message - The message to display
  / @param {object} options - The options to pass to the dialog
  */
  addDialog(type: DialogType, title: string, message: string, options = {}): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.dialog = {
        type,
        title,
        message,
        options,
        resolve,
        reject,
      };
    });
  }

  /*
  / Close the current dialog, this is generally for confirmations
  / @param {string} value - The value to resolve the dialog with
  */
  closeDialog(value: "confirmed" | "canceled", result?: string) {
    if (this.dialog) {
      this.dialog.resolve(result || value);
      this.dialog = null;
    }
  }

  /*
  / Cancel the current dialog
  */
  cancelDialog() {
    if (this.dialog) {
      this.dialog.reject();
      this.dialog = null;
    }
  }

  /*
  / This function displays a confirm dialog
  / @param {string} title - The title of the dialog
  / @param {string} message - The message to display
  / @param {object} options - The options to pass to the dialog
  */
  confirm(title: string, message: string, options = {}) {
    return this.addDialog("confirm", title, message, options);
  }

  /*
  / This function displays an alert dialog
  / @param {string} title - The title of the dialog
  / @param {string} message - The message to display
  / @param {object} options - The options to pass to the dialog
  */
  alert(title: string, message: string, options = {}) {
    return this.addDialog("alert", title, message, options);
  }

  /*
  / This function displays a prompt dialog
  / @param {string} title - The title of the dialog
  / @param {string} message - The message to display
  / @param {object} options - The options to pass to the dialog
  */
  prompt(title: string, message: string, options = {}) {
    return this.addDialog("prompt", title, message, options);
  }
}
