import { useState } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { DateTime } from "luxon";

import { MiniCalendar } from "@shared/ui/outputs";

const PREFIX = "Update";

const classes = {
  root: `${PREFIX}-root`,
  ts: `${PREFIX}-ts`,
  date: `${PREFIX}-date`,
  detail: `${PREFIX}-detail`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "row",
    borderBottom: ["1px", "solid", theme.palette.grey["200"]].join(" "),
    minHeight: 100,
    backgroundColor: theme.palette.grey["50"],
    flexGrow: 1,
    cursor: "pointer",
    maxWidth: "100%",
    alignItems: "flex-start",
    overflow: "visible",
    "&:hover": {
      cursor: "pointer",
      "& $title": {
        backgroundPosition: 0,
      },
    },
    "&:hover $title::before": {
      width: "100%",
    },
  },

  [`& .${classes.ts}`]: {
    padding: theme.spacing(2),
    height: theme.spacing(10),
    textAlign: "center",
  },

  [`& .${classes.date}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.detail}`]: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: "hidden",
  },

  [`& .${classes.title}`]: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.main} 50%, ${theme.palette.text.primary} 50%)`,
    backgroundSize: "200% 100%",
    backgroundPosition: "-100%",
    display: "inline-block",
    padding: "5px 0",
    position: "relative",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    transition: "all 0.3s ease-in-out",
    "&:before": {
      content: "''",
      background: theme.palette.primary.main,
      display: "block",
      position: "absolute",
      bottom: "6px",
      left: 0,
      width: 0,
      height: "3px",
      transition: "all 0.3s ease-in-out",
    },
  },
}));

function Update({ data }) {
  const onClick = () => {
    if (typeof window !== "undefined") {
      window.open(data.link, "_blank");
    }
  };

  return (
    <StyledBox className={classes.root} onClick={onClick}>
      <div className={classes.ts}>
        <MiniCalendar date={DateTime.fromISO(data.published)} />
      </div>
      <div className={classes.detail}>
        <Typography component="div" variant="subtitle2" className={classes.title}>
          {data.title}
        </Typography>
        <Typography component="div" variant="body2">
          {data.summary}
        </Typography>
      </div>
    </StyledBox>
  );
}

export default Update;
