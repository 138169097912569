import { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

export function useDrawingManager(initialValue: google.maps.drawing.DrawingManager | null = null) {
  const map = useMap();
  const theme: { palette: any } = useTheme();
  const drawing = useMapsLibrary("drawing");

  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(
    initialValue
  );

  useEffect(() => {
    if (!map || !drawing) return;

    // If already on this map, skip
    if (drawingManager && drawingManager.getMap() === map) return;

    // https://developers.google.com/maps/documentation/javascript/reference/drawing
    const newManager = new drawing.DrawingManager({
      map,
      drawingControl: false, // so we can have our own controls
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          // google.maps.drawing.OverlayType.MARKER, // Used later for changing substation coords?
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      // markerOptions: {
      //   draggable: true,
      // },
      circleOptions: {
        editable: true,
        fillColor: theme.palette.primary.main,
        strokeColor: theme.palette.primary.main,
      },
      polygonOptions: {
        editable: true,
        draggable: true,
        fillColor: theme.palette.primary.main,
        strokeColor: theme.palette.primary.main,
      },
      rectangleOptions: {
        editable: true,
        draggable: true,
        fillColor: theme.palette.primary.main,
        strokeColor: theme.palette.primary.main,
      },
    });

    newManager.setMap(map);
    setDrawingManager(newManager);
    // DrawingManager should only be created once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing, map, theme.palette]);

  return drawingManager;
}
