export const DRAWER_WIDTH = 312;
export const SIDENAV_WIDTH = 380;
export const BOTTOM_ACTION_BAR_HEIGHT = 64;
export const FILTER_HEIGHT = 220;
export const FILTER_HEIGHT_EXPANDED = 500;
export const MAX_FILTER_HEIGHT = 400;
export const MAX_EXPANDED_FILTER_WIDTH = 1000;
export const MENU_WIDTH = 60;
export const MAX_MENU_WIDTH = 80;
export const BAR_HEIGHT = 56;
export const SUBHEAD_HEIGHT = 48;
export const APP_BAR_HEIGHT = 72;
export const SECTION_X_SEP = 32;
export const CARD_HEIGHT = 300;
export const DEFAULT_NETWORK_TIMEZONE = "Europe/Stockholm";
