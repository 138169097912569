import { SvgIcon } from "@mui/material";

export default function CloseIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <g data-name="Group 9088">
        <g
          data-name="Rectangle 7200"
          transform="rotate(45 5.333 12.876)"
          style={{ fill: "#bb4a4a", stroke: "#bb4a4a" }}
        >
          <rect width="1.886" height="15.084" rx=".943" style={{ stroke: "none" }} />
          <rect x=".5" y=".5" width=".886" height="14.084" rx=".443" style={{ fill: "none" }} />
        </g>
        <g
          data-name="Rectangle 7201"
          transform="rotate(-45 1.61 .666)"
          style={{ fill: "#bb4a4a", stroke: "#bb4a4a" }}
        >
          <rect width="1.885" height="15.084" rx=".943" style={{ stroke: "none" }} />
          <rect x=".5" y=".5" width=".885" height="14.084" rx=".443" style={{ fill: "none" }} />
        </g>
      </g>
    </SvgIcon>
  );
}
