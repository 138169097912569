import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { observer } from "mobx-react";
import { Box, Skeleton, Typography, useTheme } from "@mui/material";

import { useInject } from "@hooks";

import StepperConnector from "./StepperConnector";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function FeaturedBlogCarousel() {
  const { dash } = useInject("dash");
  const featureBlogs = dash.news.slice(0, 4);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = featureBlogs.length;
  const onClickNextHandler = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 3) {
        return 3;
      }
      return prevActiveStep + 1;
    });
  };

  const onClickPrevHandler = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        return 0;
      }
      return prevActiveStep - 1;
    });
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  if (featureBlogs.length === 0) {
    return <Skeleton />;
  }
  return (
    <Box
      sx={{
        height: "90%",
        display: "flex",
        flexDirection: "column",
        mt: 1,
        p: 2,
        backgroundColor: "background.paper",
      }}
    >
      <AutoPlaySwipeableViews
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
        interval={10000}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        className="autoCarousel"
        data-testid="img-carousel-container"
      >
        {featureBlogs.map((step, index) => (
          <div
            key={step.title}
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "100%",
                display: "flex",
                width: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              src={step.hero_image_link}
              alt={step.title}
              onClick={() => window.open(`${step.link}`, "_blank")}
              key={step.title}
              data-testid={`img-${index}`}
            />
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                background: "linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent)",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                color: "#fff",
                padding: "0.5rem",
                cursor: "pointer",
              }}
              p={4}
              width="100%"
              height="40%"
              bgcolor="rgba(0, 0, 0, 0.75)"
              onClick={() => window.open(`${step.link}`, "_blank")}
            >
              <Typography
                component="h2"
                p={5}
                sx={{
                  fontFamily: "Playfair Display Variable, serif",
                  fontOpticalSizing: "auto",
                  fontWeight: "500",
                  fontSize: "3em",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "2em",
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "1.5em",
                  },
                }}
              >
                {step.title}
              </Typography>
            </Box>
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <StepperConnector
        onClickNextHandler={onClickNextHandler}
        onClickPrevHandler={onClickPrevHandler}
        activeStep={activeStep}
        maxSteps={maxSteps}
        setActiveHandler={handleStepChange}
      />
    </Box>
  );
}

export default observer(FeaturedBlogCarousel);
