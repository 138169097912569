import { action, computed, flow, makeObservable, observable } from "mobx";

import { blkReader, EP_SIMULATION_BLOCK } from "@conf/blocks";
import { formatNumberForUnitMainLabel, getLatestProcessedMonth } from "@core/utils";

import { DEFAULT_HOUR_STEP, heatEnergy, HOUR_STEPS, MAX_STEP_HOURS } from "./constants";

function defaultCardsData() {
  const res = { err: false };
  for (const step of HOUR_STEPS) {
    res[`${step.hour}`] = {};
  }
  return res;
}

function defaultTrendsData() {
  return {
    heat_energy: {},
    rettemp: {},
    design_flow: {},
    design_power: {},
    err: false,
  };
}
class Production {
  resource_type = "cluster";

  resource_id = null;

  currentHour = null;

  lastProcessedDate = null;

  graph_metrics = heatEnergy;

  graph_duration = DEFAULT_HOUR_STEP;

  fetchedConsumptionData = null;

  cardsdata = defaultCardsData();

  graphSeries = [];

  is_fetching_graphs = false;

  fetchedConsumptionTrendsData = null;

  data_processing_done = false;

  trendsMetrics = defaultTrendsData();

  graph_series_changed = false;

  get graph_metrics_unit() {
    return (
      this.graphSeries?.measured_unit ||
      this.graphSeries?.estimated_unit ||
      EP_SIMULATION_BLOCK.columns[this.graph_metrics].unit
    );
  }

  fetchData = flow(function* () {
    this.is_fetching_graphs = true;
    this.data_processing_done = false;
    this.trendsMetrics = defaultTrendsData();
    this.cardsdata = defaultCardsData();
    const lastProcessedMonth = getLatestProcessedMonth(
      this.currentHour,
      this.parent.networks.networkActiveMonth
    );

    const minDate = this.currentHour.plus({ hours: -1 * MAX_STEP_HOURS });
    const maxDate = this.currentHour.plus({ hours: MAX_STEP_HOURS });
    const maxTrainingDate = lastProcessedMonth.endOf("month").plus({ seconds: 1 });
    const minTrainingDate = maxTrainingDate.plus({ months: -12 }).startOf("month");
    const components = ["heat_energy", "volume"];
    const subORClusterName =
      this.resource_type === "substation"
        ? this.parent.networks.current_substations.get(this.resource_id)
        : this.parent.networks.active_clusters.get(this.resource_id);
    try {
      const data = yield Promise.all([
        this.parent.newapi.getMeterDataV4({
          resource_type: this.resource_type,
          resource_uid: this.resource_id,
          network_id: this.parent.networks.current_network.uid,
          components,
          stage: "clean",
          ts_start: minDate,
          ts_end: maxDate,
        }),
        this.parent.newapi.getMeterDataV4({
          resource_type: this.resource_type,
          resource_uid: this.resource_id,
          network_id: this.parent.networks.current_network.uid,
          components: [
            "t",
            "heat_energy",
            "volume",
            "heat_energy_lower",
            "volume_lower",
            "heat_energy_upper",
            "volume_upper",
          ],
          stage: "forecast",
          ts_start: minDate,
          ts_end: maxDate,
        }),
      ]);
      const combinedBlocks = {};
      if (data[0]) {
        combinedBlocks.meter_data = data[0];
      }

      if (data[1]?.columns?.heat_energy || data[1]?.columns?.volume) {
        combinedBlocks.ep_simulation = data[1];
      } else {
        const EPData = yield this.parent.newapi.getEPOData({
          resource_type: this.resource_type,
          resource_id: subORClusterName,
          meter_type: components,
          mt_start_date: minTrainingDate,
          mt_end_date: maxTrainingDate,
          cs_start_date: minDate,
          cs_end_date: maxDate,
          return_predictors: ["t"],
        });
        if (EPData) {
          combinedBlocks.ep_simulation = EPData.ep_simulation;
        }
      }
      if (!data[0] && !data[2]) {
        for (const cut of HOUR_STEPS) {
          this.cardsdata[`${cut.hour}`].error = "no_data";
        }
      }
      this.fetchedConsumptionData = combinedBlocks;
    } catch (err) {
      for (const cut of HOUR_STEPS) {
        this.cardsdata[`${cut.hour}`].error = "no_data";
      }
    } finally {
      this.is_fetching_graphs = false;
    }
  });

  constructor(parent) {
    makeObservable(this, {
      resource_type: observable,
      resource_id: observable,
      currentHour: observable,
      lastProcessedDate: observable,
      graph_metrics: observable,
      graph_duration: observable,
      fetchedConsumptionData: observable,
      cardsdata: observable,
      is_fetching_graphs: observable,
      fetchedConsumptionTrendsData: observable,
      data_processing_done: observable,
      trendsMetrics: observable,
      graph_metrics_unit: computed,
      resetForNetwork: action.bound,
      changeCluster: action.bound,
      changeGraphMetricsFromEvent: action.bound,
      changeGraphDurationFromEvent: action.bound,
      calculateInfoCardsData: action.bound,
      calculateGraphMetricSeries: action.bound,
      graph_series_changed: observable,
    });

    this.parent = parent;
  }

  resetForNetwork() {
    const defaultResourceId = this.parent.networks.allSubstationCluster;
    if (defaultResourceId !== this.resource_id) {
      this.resource_id = defaultResourceId;
    }
    this.currentHour = this.parent.networks.CurrentHour;
    this.lastProcessedDate = this.parent.networks.LastProcessedYear;
  }

  changeCluster(cluster) {
    this.resource_id = cluster;
  }

  changeGraphMetricsFromEvent(e) {
    this.graph_metrics = e.target.value;
  }

  changeGraphDurationFromEvent(e) {
    this.graph_duration = e.target.value;
  }

  calculateInfoCardsData() {
    if (!this.fetchedConsumptionData || !this.fetchedConsumptionData.ep_simulation) {
      return;
    }
    const cutoffCurrent = this.currentHour.toMillis();
    const data = this.fetchedConsumptionData.ep_simulation;
    const rows = data.data;
    for (const cut of HOUR_STEPS) {
      const cutoffForward = this.currentHour.plus({ hours: cut.hour }).toMillis();
      const cutoffBackword = this.currentHour.plus({ hours: -1 * cut.hour }).toMillis();
      const metrics = {};
      const epColIdx = data.columns.heat_energy.idx;
      metrics.total_use_forward = 0;
      metrics.total_use_backward = 0;
      metrics.forward_count = 0;
      metrics.backward_count = 0;
      metrics.max_peak = null;
      for (const ts of data.idxMap.keys()) {
        if (cutoffCurrent <= ts && ts < cutoffForward) {
          if (rows[data.idxMap.get(ts)]) {
            const val = rows[data.idxMap.get(ts)][epColIdx];
            metrics.total_use_forward += val;
            metrics.forward_count += 1;
            if ((metrics.max_peak != null && metrics.max_peak < val) || metrics.max_peak == null) {
              metrics.max_peak = val;
            }
          }
        }
        if (cutoffBackword <= ts && ts < cutoffCurrent) {
          if (rows[data.idxMap.get(ts)]) {
            metrics.total_use_backward += rows[data.idxMap.get(ts)][epColIdx];
            metrics.backward_count += 1;
          }
        }
      }
      if (metrics.forward_count !== cut.hour) {
        metrics.error = "data not available";
      }
      if (metrics.backward_count !== cut.hour) {
        metrics.error = "data not available";
      }
      metrics.trend =
        ((metrics.total_use_forward - metrics.total_use_backward) / metrics.total_use_backward) *
        100;
      metrics.unit = data.columns.heat_energy.spec.unit;
      this.cardsdata[`${cut.hour}`] = metrics;
    }
  }

  calculateGraphMetricSeries() {
    this.graph_series_changed = false;
    this.graphSeries = [];
    let tsblock = "";
    if (this.fetchedConsumptionData.meter_data) {
      tsblock = "meter_data";
    } else if (this.fetchedConsumptionData.ep_simulation) {
      tsblock = "ep_simulation";
    }
    const SeriesReader = blkReader(this.fetchedConsumptionData, [
      ["name", [tsblock, "datetime"], "t"],
      ["name", ["meter_data", this.graph_metrics], "measured"],
      ["name", ["ep_simulation", this.graph_metrics], "estimated"],
      ["name", ["ep_simulation", `${this.graph_metrics}_lower`], "estimated_lower"],
      ["name", ["ep_simulation", `${this.graph_metrics}_upper`], "estimated_upper"],
      ["name", ["ep_simulation", "t"], "outdoor"],
    ]);
    const dur = parseInt(this.graph_duration);

    const cutoffForward = this.currentHour.plus({ hours: dur }).toMillis();
    const cutoffBackward = this.currentHour.plus({ hours: -1 * dur }).toMillis();

    const isGraphMetricsExist =
      this.fetchedConsumptionData.meter_data?.columns.hasOwnProperty(this.graph_metrics) &&
      this.fetchedConsumptionData.meter_data.columns[this.graph_metrics].spec.unit;
    const meterDataHeatEnergyUnit =
      this.graph_metrics === "heat_energy"
        ? "kWh/h"
        : this.fetchedConsumptionData.meter_data.columns[this.graph_metrics].spec.unit || "";
    const mtrSpecUnit = isGraphMetricsExist ? meterDataHeatEnergyUnit : "";

    const epSimulationHeatEnergyUnit =
      this.graph_metrics === "heat_energy"
        ? "kWh/h"
        : this.fetchedConsumptionData.ep_simulation?.columns[this.graph_metrics].spec.unit || "";
    const estSpecUnit = this.fetchedConsumptionData.ep_simulation?.columns[this.graph_metrics]
      ? epSimulationHeatEnergyUnit
      : "";

    const series = {
      measured: [],
      measured_unit: mtrSpecUnit,
      estimated: [],
      estimated_unit: estSpecUnit,
      estimated_range: [],
      estimated_range_unit: estSpecUnit,
      outdoor: [],
      outdoor_unit: this.fetchedConsumptionData.weather_data?.columns.t.spec.unit || "",
      startTs: cutoffBackward,
    };
    let curTs = cutoffBackward;
    while (curTs <= cutoffForward) {
      const row = SeriesReader(curTs);
      if (row) {
        series.measured.push(row.measured);
        series.estimated.push(row.estimated);
        series.estimated_range.push([row.estimated_lower, row.estimated_upper]);
        series.outdoor.push([curTs, row.outdoor]);
      } else {
        series.measured.push(null);
        series.estimated.push(null);
        series.estimated_range.push([null, null]);
        series.outdoor.push([curTs, null]);
      }
      curTs += 3600000;
    }
    this.graphSeries = series;
    this.data_processing_done = true;
    this.graph_series_changed = true;
  }
}

export default Production;
