import { SvgIcon } from "@mui/material";

export default function ToolIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <defs xmlns="http://www.w3.org/2000/svg">
        <clipPath id="emaa5yaaga">
          <path
            data-name="Rectangle 6990"
            transform="translate(392 1920)"
            style={{ stroke: "#707070", fill: "#fdfdfd" }}
            d="M0 0h24v24H0z"
          />
        </clipPath>
      </defs>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.819 4.472a1.054 1.054 0 0 0-.742-.767 1.065 1.065 0 0 0-1.04.273l-2.495 2.494-1.728-.288-.288-1.728 2.5-2.5a1.065 1.065 0 0 0-.5-1.787 5.86 5.86 0 0 0-7.109 7.178L.867 14.9a2.973 2.973 0 0 0 0 4.2 2.843 2.843 0 0 0 2.1.9 2.941 2.941 0 0 0 2.095-.869l7.538-7.544a5.882 5.882 0 0 0 7.214-7.115zm-2.437 4.715a4.615 4.615 0 0 1-4.758 1.1l-.366-.122-8.114 8.08a1.755 1.755 0 0 1-2.427 0 1.724 1.724 0 0 1 0-2.431L9.8 7.728l-.089-.361a4.62 4.62 0 0 1 4.365-6.12 5.042 5.042 0 0 1 .811.068l-2.705 2.71.537 3.242 3.247.542L18.674 5.1a4.6 4.6 0 0 1-1.292 4.087zM3.124 16.214a.625.625 0 1 0 .625.625.623.623 0 0 0-.625-.625z"
          transform="translate(2.002 2.002)"
          style={{ fill: "#fdfdfd" }}
          data-name="Mask Group 429"
        />
      </g>
    </SvgIcon>
  );
}
