/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  ButtonGroup,
  Dialog,
  Grid,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  MRT_ColumnOrderState,
  MRT_VisibilityState,
  useMaterialReactTable,
} from "material-react-table";

import { MONTH_OPTIONS, R12 } from "@config";
import { logger as baseLogger } from "@core/logger";
import { localeObjectSort } from "@core/utils";
import { usePrevDist } from "@hooks";

import { UtfButton } from "../../../inputs";
import { MonthOption, YearOption, type InfoBlockColumn } from "../InfoBlockGrid/const";
import { ColumnList } from "./List";
import { CollapsablePanel } from "./Panels";

const logger = baseLogger.getSubLogger({ name: "ColumnManager" });

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  "&&": {
    padding: 0,
    zIndex: 4,
    "& > *": {
      typography: "h5",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    "& .MuiGrid-item:not(.MuiGrid-container)": {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const DialogContent = styled(MuiDialogContent)(() => ({
  "&&": {
    padding: 0,
    height: "75vh",
    overflowY: "hidden",
    width: "100%",
    // Hide Scrollbar
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome */,
    },
  },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&&": {
    boxShadow: theme.shadows[1],
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}));

const HeaderInfo = styled(Grid)(({ theme }) => ({
  display: "flex",
  height: "100%",
  gap: theme.spacing(0.5),
  paddingRight: theme.spacing(3),
  alignItems: "center",
  textTransform: "none",
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.grey["400"],
  borderLeft: `2px solid ${theme.palette.grey["100"]}`,
}));

type ColumnManagerProps = {
  columns: InfoBlockColumn[];
  columnOrder: MRT_ColumnOrderState;
  columnVisibility: MRT_VisibilityState;
  month?: MonthOption;
  year?: YearOption;
  locale?: { ns: any; selectedColumns?: string; availableColumns?: string };
  onColumnsUpdated: (updatedData: {
    columnOrder: MRT_ColumnOrderState;
    columnVisibility: MRT_VisibilityState;
  }) => void;
};

const MRT_ROW_EXPANDER_ID = "mrt-row-expand";

// eslint-disable-next-line import/no-default-export
export default function ColumnManager({
  columnOrder: initialColumnOrder,
  columnVisibility: initialColumnVisibility,
  columns,
  onColumnsUpdated = () => null,
  year,
  month,
  locale = { ns: [] },
}: Readonly<ColumnManagerProps>) {
  const { t } = useTranslation([...locale.ns, "dataLibrary", "_action"] as const);
  // Initialize a new react-table from existing parent instance
  // This instance to be used to toggle and order items
  const dataTable = useMaterialReactTable({
    initialState: {
      columnOrder: initialColumnOrder,
      columnVisibility: initialColumnVisibility,
    },
    columns,
    data: [],
  });
  const { setColumnVisibility, setColumnOrder: setColumnOrderState } = dataTable;
  const visibleColumns = dataTable.getVisibleLeafColumns();
  const hasGroupingHandle = initialColumnOrder.find((columnId) => columnId === MRT_ROW_EXPANDER_ID);

  // Build section names from columns
  const sections = useMemo(() => {
    const sectionNames = columns
      // @ts-expect-error legacy
      .map((col) => col.section)
      .filter(Boolean)
      .sort();

    return [...new Set(sectionNames)];
  }, [columns]);

  const getColItemsBySectionName = useCallback(
    (sectionName) =>
      dataTable
        .getAllColumns()
        // @ts-expect-error legacy
        .filter((col) => col.columnDef.section === sectionName)
        .sort(localeObjectSort("header", "asc", "")),
    [dataTable]
  );

  const setColumnOrder = useCallback(
    (updatedColumnOrder) => {
      const nextColumnOrder = [...updatedColumnOrder];
      if (hasGroupingHandle) {
        nextColumnOrder.unshift(MRT_ROW_EXPANDER_ID);
      }

      setColumnOrderState([...new Set(nextColumnOrder)]);
    },
    [setColumnOrderState, hasGroupingHandle]
  );

  // DnD handle for visible columns
  const handleDrop = useCallback(
    (dragIndex, hoverIndex) => {
      let nextVisibleColumns = visibleColumns.map((item) => item?.parent ?? item);
      // Remove duplicates
      nextVisibleColumns = nextVisibleColumns.filter(
        (item, index) => nextVisibleColumns.indexOf(item) === index
      );
      const [removed] = nextVisibleColumns.splice(dragIndex, 1);
      // Move item to the hovered index
      nextVisibleColumns.splice(hoverIndex, 0, removed);
      // Create array of column id's with the new order
      const newOrder = nextVisibleColumns
        .map((item) =>
          Array.isArray(item.columns) && item.columns.length > 0
            ? item.columns.map(({ id }) => id)
            : item.id
        )
        .flat();
      logger.debug("[handleDrop]", newOrder);
      setColumnOrder(newOrder);
    },
    [setColumnOrder, visibleColumns]
  );

  // Event handler for both Dialog close and confirmation
  const handleOnClose = useCallback(
    (applyChanges) => {
      const { columnOrder, columnVisibility } = dataTable.getState();
      logger.debug("[handleOnClose]", { applyChanges, columnOrder, columnVisibility });
      onColumnsUpdated(applyChanges === true ? { columnOrder, columnVisibility } : null);
    },
    [dataTable, onColumnsUpdated]
  );

  // Remove all items from selected list
  const clearList = useCallback(() => {
    const nextVisibility = dataTable
      .getAllFlatColumns()
      // Filter out the columns that marked as non-hideable (hideable=false)
      // @ts-expect-error legacy
      .filter(({ columnDef }) => (Object.hasOwn(columnDef, "hideable") ? columnDef.hideable : true))
      .reduce((newVisibilities, { id }) => {
        // eslint-disable-next-line no-param-reassign
        newVisibilities[id] = false;
        return newVisibilities;
      }, {});
    logger.debug("[clearList]", nextVisibility);
    setColumnVisibility(nextVisibility);
  }, [dataTable, setColumnVisibility]);

  // Append new columns to the end of the array
  const prevVisible = usePrevDist(visibleColumns);
  useEffect(() => {
    const prevVisibleColIds = (prevVisible || []).map((col) => col.id);
    if (!prevVisibleColIds.length || visibleColumns.length === prevVisibleColIds.length) return;

    const newVisibleColIds = visibleColumns
      .filter((column) => !prevVisibleColIds.includes(column.id))
      .map((col) => col.id);
    if (newVisibleColIds) {
      logger.debug("[RENDER] newVisibleColumns", newVisibleColIds);
      setColumnOrder([...prevVisibleColIds, ...newVisibleColIds]);
    }
  }, [prevVisible, setColumnOrder, visibleColumns]);

  // @ts-expect-error translation
  const activePeriodLabel = year === R12 ? year : `${t(MONTH_OPTIONS.get(month))}, ${year}`;

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      aria-labelledby="column-manager-title"
      aria-describedby="dialog-description"
      data-testid="column-manager-dialog"
      onClose={() => handleOnClose(false)}
    >
      <DialogTitle id="column-manager-title">
        <Grid height={56} container alignItems="center" sx={{ color: "grey.800" }}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              borderRight: (theme) => `1px solid ${theme.palette.grey["200"]}`,
              boxShadow: (theme) => theme.shadows[1],
            }}
          >
            {t((locale.selectedColumns ?? "text_selected_columns") as any)}
          </Grid>
          <Grid
            item
            container
            xs
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              borderLeft: (theme) => `1px solid ${theme.palette.grey["200"]}`,
              boxShadow: (theme) => theme.shadows[1],
            }}
          >
            <Grid item>{t((locale.availableColumns ?? "text_available_columns") as any)}</Grid>
            {year && (
              <HeaderInfo item>
                <span>{t("text_availability_for" as any)}:</span>
                <Box
                  component="span"
                  sx={{
                    color: "grey.800",
                    textTransform: "capitalize",
                  }}
                  data-testid="active-period"
                >
                  {/* Month, Year */}
                  {activePeriodLabel}
                </Box>
              </HeaderInfo>
            )}
          </Grid>
        </Grid>
      </DialogTitle>

      {/* Content */}
      <DialogContent>
        <Grid container spacing={0} height="99.85%">
          {/* Selected Columns */}
          <Box
            sx={{
              width: "50%",
              maxHeight: "100%",
              overflowY: "auto",
              boxShadow: ({ shadows }) => shadows[1],
              zIndex: 3,
            }}
            data-testid="selected-columns"
          >
            <ColumnList dnd listItems={visibleColumns} onDrop={handleDrop} />
          </Box>

          {/* Collapsable Section Columns */}
          <Box
            sx={{
              width: "50%",
              maxHeight: "100%",
              overflowY: "scroll",
              boxShadow: ({ shadows }) => shadows[1],
            }}
            data-testid="column-sections"
          >
            {Array.from(sections).map((sectionName: string, index: number) => {
              const items = getColItemsBySectionName(sectionName);
              const sectionId = `section-panel-${index}`;
              return (
                <CollapsablePanel
                  index={index}
                  data-testid={sectionId}
                  key={sectionId}
                  header={
                    <Typography
                      key={`panel-${sectionId}-${sectionName}-header`}
                      variant="button"
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderBottom: 0,
                        borderTop: 0,
                      }}
                    >
                      {t(sectionName as any)}
                    </Typography>
                  }
                >
                  <ColumnList key={`panel-${sectionId}-${sectionName}-list`} listItems={items} />
                </CollapsablePanel>
              );
            })}
          </Box>
        </Grid>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <ButtonGroup
          sx={{
            display: "flex",
            flexGrow: 1,
            gap: 1,
            "& > *": {
              marginRight: (theme) => theme.spacing(1),
            },
          }}
        >
          <UtfButton
            onClick={() => handleOnClose(true)}
            size="large"
            color="primary"
            variant="contained"
            autoFocus
            data-testid="dialog-confirm-button"
          >
            {t("action_confirm" as any, { ns: "_action" })}
          </UtfButton>
          <UtfButton
            size="large"
            onClick={clearList}
            color="primary"
            variant="outlined"
            data-testid="dialog-clear-button"
          >
            {t("action_clear_list" as any, { ns: "_action" })}
          </UtfButton>
        </ButtonGroup>

        <UtfButton
          onClick={() => handleOnClose(false)}
          size="large"
          color="primary"
          variant="outlined"
          data-testid="dialog-cancel-button"
        >
          {t("action_cancel" as any, { ns: "_action" })}
        </UtfButton>
      </DialogActions>
    </Dialog>
  );
}
