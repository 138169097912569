import { Component } from "react";
import { inject, observer } from "mobx-react";
import { styled } from "@mui/styles";

import Update from "./Update";

const PREFIX = "UpdatesContainer";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.palette.grey["200"],
    flexGrow: "1",
    height: 0,
    minHeight: "300px",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
}));

class UpdatesContainer extends Component {
  render() {
    const {
      rootStore: { dash },
    } = this.props;

    return (
      <Root className={classes.root}>
        <div style={{ maxHeight: 200 }}>
          {dash.news.map((update) => (
            <Update key={update.cms_id} data={update} />
          ))}
        </div>
      </Root>
    );
  }
}
export default inject("rootStore")(observer(UpdatesContainer));
