import { Radio as MUIRadio } from "@mui/material";
import { styled } from "@mui/styles";

const StyledMUIRadio = styled(MUIRadio)(({ theme }) => ({
  "& svg:nth-child(1)": {
    color: ({ circleColor }) => circleColor || theme.palette.primary.main,
  },
  "& svg:nth-child(2)": {
    color: theme.palette.secondary.main,
  },
}));

function Radio(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledMUIRadio {...props} />;
}

// eslint-disable-next-line import/no-default-export
export default Radio;
