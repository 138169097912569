import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { Button, Menu, MenuItem } from "@mui/material";

type LanguageSelectProps = {
  value: string;
  width: number;
  options: Map<string, string>;
  onChange: (nextLangId: string) => void;
};

function LanguageSelect({ value, width, options, onChange }: Readonly<LanguageSelectProps>) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (nextLangId: LanguageSelectProps["value"]) => {
    onChange(nextLangId);
    handleClose();
  };

  const EndIcon = anchorEl ? <ArrowDropUp /> : <ArrowDropDownIcon />;

  const languages = Array.from(options);

  return (
    <>
      <Button
        endIcon={EndIcon}
        variant="outlined"
        onClick={handleClick}
        data-testid="app-language-select-list"
        style={{ width: `${width - 10}px` }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "inherit",
          border: "solid 1px",
          borderColor: "primary.main",
          color: "grey.800",
          typography: "body1",
          fontWeight: "normal",
          textTransform: "capitalize",
        }}
      >
        {options.get(value)}
      </Button>

      <Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {languages?.map(([id, label]: any) => (
          <MenuItem key={id} onClick={() => handleSelect(id)} selected={value === id}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export { LanguageSelect };
