import type { Dispatch, MutableRefObject, SetStateAction } from "react";

export function createSeriesToggleHandler<SeriesType extends { [key: string]: boolean }>(
  setVisibleSeries: Dispatch<SetStateAction<SeriesType>>,
  lastClickedSeriesId?: MutableRefObject<string | undefined>
): (this: Highcharts.Series, event: Highcharts.SeriesLegendItemClickEventObject) => boolean {
  return function handleSeriesVisibilityToggle(
    this: Highcharts.Series,
    event: Highcharts.SeriesLegendItemClickEventObject
  ): boolean {
    const { id: seriesId } = event.target.userOptions;
    const { visible: isSeriesVisible, chart } = this;

    // Update last clicked series ID if provided
    if (lastClickedSeriesId) {
      lastClickedSeriesId.current = seriesId;
    }

    // Prevent hiding the last visible series
    if (isSeriesVisible && chart.series.filter((series) => series.visible).length < 2) {
      event.preventDefault();
      return false;
    }

    // Toggle series visibility
    setVisibleSeries((prevVisibility) => ({
      ...prevVisibility,
      [seriesId as string]: !prevVisibility[seriesId as string],
    }));

    // Prevent Highcharts default visibility update
    return false;
  };
}
