import { TOOLBOX_DEFAULT_OPTIONS, TOOLBOX_OPTION_REFERENCE } from "./chartToolbox";

export const REF_PREFIX = "ref_";
export const INTERVAL_HOURLY = "1h";
export const INTERVAL_DAILY = "24h";
export const DATETIME_FORMAT_YYYY_MM_DD_HH_MM = "yyyy-MM-dd, HH:mm";
export const DATETIME_FORMAT_YYYY_MM_DD = "yyyy-MM-dd";

export const INTERVAL_OPTIONS = new Map([
  [INTERVAL_HOURLY, "text_hourly"],
  [INTERVAL_DAILY, "text_daily"],
]);

export const SPEED_OPTIONS = [
  { label: "text_last_week", type: "week", value: 1, disableMonth: 0.25 }, // 1 week
  { label: "text_last_month", type: "month", value: 1, disableMonth: 1 }, // 1 month
  { label: "text_last_3_months", type: "months", value: 3, disableMonth: 3 }, // 3 months
  { label: "text_last_year", type: "year", value: 1, disableMonth: 12 }, // 1 year
];

export const DIAGRAM_SORT_OPTIONS = new Map([
  ["heat", "text_heat_energy"],
  ["vol", "text_flow"],
  ["st", "text_supply_temperature"],
  ["rt", "text_return_temperature"],
  ["dt", "text_delta_temperature"],
]);

export const columns: {
  key: string;
  name: string;
  suffix: string;
  showInLegend: boolean;
  color: [string, string];
  lineColor: [string, string];
}[] = [
  {
    key: "heat",
    name: "text_heat_energy",
    suffix: "kWh/h",
    showInLegend: true,
    color: ["orange", "main"],
    lineColor: ["orange", "light"],
  },
  {
    key: "vol",
    name: "text_flow",
    suffix: "m³/h",
    showInLegend: true,
    color: ["green", "main"],
    lineColor: ["green", "light"],
  },
  {
    key: "st",
    name: "text_supply_temperature",
    suffix: "°C",
    showInLegend: true,
    color: ["pink", "main"],
    lineColor: ["pink", "light"],
  },
  {
    key: "rt",
    name: "text_return_temperature",
    suffix: "°C",
    showInLegend: true,
    color: ["blue", "main"],
    lineColor: ["blue", "light"],
  },
  {
    key: "dt",
    name: "text_delta_temperature",
    suffix: "°C",
    showInLegend: true,
    color: ["teal", "main"],
    lineColor: ["teal", "light"],
  },
];

export const TOOLBOX_OPTIONS_WITH_REFERENCE = {
  ...TOOLBOX_DEFAULT_OPTIONS,
  [TOOLBOX_OPTION_REFERENCE]: "action_reference",
};
