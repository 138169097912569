import { useRef } from "react";

import { useFirstMountState } from "./useFirstMountState";

const strictEquals = (prev: unknown, next: unknown) => prev === next;

/**
 * It will only update once the value actually changes.
 * This is important when other hooks are involved and
 * you aren't just interested in the previous props version,
 * but want to know the previous distinct value.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePrevDist(value: any, compare = strictEquals) {
  const prevRef = useRef();
  const curRef = useRef(value);
  const isFirstMount = useFirstMountState();

  if (!isFirstMount && !compare(curRef.current, value)) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
}
