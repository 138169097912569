import React from "react";
import { observer } from "mobx-react";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  type BoxProps,
  type SxProps,
} from "@mui/material";

type HeaderAddonProps = {
  addon: React.ReactNode;
  testId?: string;
} & BoxProps;

const HeaderAddon = observer(function HeaderAddon({ addon, testId = "" }: HeaderAddonProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        paddingLeft: 2,
        marginRight: -1,
        borderLeft: addon ? 1 : 0,
        borderColor: addon ? "grey.200" : undefined,
      }}
      key={testId}
    >
      <Typography
        color="secondary"
        variant="subtitle2"
        data-testid={`${testId}__subtitle`}
        key={testId}
      >
        {addon}
      </Typography>
    </Box>
  );
});

type TitleAddonProps = {
  addon: React.ReactNode | React.ReactNode[];
  testId: string;
};
const TitleAddon = observer(function TitleAddon({ addon, testId }: TitleAddonProps) {
  return Array.isArray(addon) ? (
    addon.map((addonI, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <HeaderAddon key={`${testId}-addon-${index}`} addon={addonI} testId={testId} />
    ))
  ) : (
    <HeaderAddon addon={addon} testId={testId} />
  );
});

export type BaseCardProps = {
  title?: React.ReactNode;
  titleAddon?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  errorMessage?: string | null;
  testId?: string;
  height?: string | number;
  minHeight?: number;
  sx?: SxProps;
  size?: "small" | "medium";
};
/**
 * - This component is used as the base for all other cards.
 * - It is used to display a title, as well as any children passed to it.
 * - In addition to the data, the component also displays a loading indicator and an error message.
 * */
const BaseCard = observer(function BaseCard({
  title = "",
  titleAddon = [],
  children,
  loading = false,
  error = false,
  errorMessage = "Unable to load data",
  testId = "",
  height = "inherit",
  minHeight = 0,
  sx = {},
  size = "medium",
}: BaseCardProps) {
  const renderComponents = () => {
    if (error) {
      return (
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      );
    }
    return loading ? <CircularProgress color="primary" size="3.5rem" /> : children;
  };

  return (
    <Paper elevation={1} style={{ height: "100%", minHeight }} sx={sx}>
      <Box flexGrow={1} height={height} minHeight={minHeight}>
        <Grid
          style={{ height: "inherit" }}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item maxWidth="100%" component={Paper} elevation={1}>
            <Box
              px={2}
              flexGrow={1}
              height={size === "small" ? 32 : 48}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, textTransform: "capitalize" }}
                data-testid={`${testId}__title`}
              >
                {title}
              </Typography>

              {titleAddon ? <TitleAddon addon={titleAddon} testId={testId} /> : null}
            </Box>
          </Grid>
          <Grid item xs zIndex={0} sx={{ maxWidth: "100% !important" }}>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
              data-testid={`${testId}__body`}
            >
              {renderComponents()}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});

export default BaseCard;
