import { useContext } from "react";
import { MobXProviderContext } from "mobx-react";

import { rootStore } from "@stores/root_store";

type RootStoreType = typeof rootStore;

type UseInjectBackType<P extends keyof RootStoreType> = { [K in P]: RootStoreType[K] };

/**
 * A hook alternative to Mobx's `inject(...allStores)`
 *
 * @deprecated Use `useInject` for memory optimization.
 */
export function useInjectAll(): RootStoreType {
  return useContext(MobXProviderContext) as RootStoreType;
}

/**
 * A hook alternative to Mobx's `inject("ui","networks")`
 */
export function useInject<P extends keyof RootStoreType>(...storeNames: P[]): UseInjectBackType<P> {
  const ProviderStore = useContext(MobXProviderContext).rootStore as RootStoreType;
  const PartialStore: Partial<UseInjectBackType<P>> = {};

  storeNames.forEach((storeName) => {
    if (!ProviderStore[storeName]) {
      throw new Error(`${storeName} is not defined`);
    }
    PartialStore[storeName] = ProviderStore[storeName];
  });
  return PartialStore as UseInjectBackType<P>;
}

// eslint-disable-next-line import/no-default-export
export default useInjectAll;
